import { Typography } from '@mui/material';
import React from 'react';
import { H, V } from '../../common/Layout';
import { useStyling } from '../../common/Theme';
import { getStringFromDateRange } from '../helpers/activityUtils';
import I18n from '../../common/i18n/I18n';

export default ({ option }) => {
    const { theme } = useStyling();
    const createOption = option && option.createOption;
    const label = createOption ? (
        <I18n token="activity.option.create" values={{ label: option.label }} />
    ) : (
        option.label
    );

    return (
        <H>
            <V sx={{ width: '100%' }}>
                <Typography sx={{ fontStyle: createOption ? 'italic' : 'normal' }}>{label}</Typography>
            </V>
            {option.dateRange && (
                <V sx={{ gap: 2 }}>
                    <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>
                        {getStringFromDateRange(option.dateRange)}
                    </Typography>
                </V>
            )}
        </H>
    );
};
