import { isNil } from 'lodash';
import React, { useContext } from 'react';
import { FormContext } from '../../common/form/FormContext';
import FormModal from '../../common/form/FormModal';
import I18n from '../../common/i18n/I18n';
import { OrganizationInviteFormModalProps } from './inquiry/inquiry';
import InquiryContactDetailsForm from './InquiryContactDetailsForm';
import InvitePreview from './InvitePreview';
import OrganizationInviteFormItems from './OrganizationInviteFormItems';
import { ORGANIZATION_TYPE } from './inquiry/inquiry';

export default ({
    isPreview,
    setIsPreview,
    isOpen,
    sendInvitation,
    onClose,
    isLoading,
    isSaving,
    isEmailModalOpen,
    itemsWithNoEmail,
    data,
    onClickContinue,
    entityDetails,
    getInquiryPreviewData,
    isPreviewLoading,
    previewData
}: OrganizationInviteFormModalProps) => {
    const { validation } = useContext(FormContext);
    const additionalActions = [];

    if (isEmailModalOpen) {
        additionalActions.push({
            label: <I18n token="organization.add.contact.button.continue" />,
            onClick: onClickContinue,
            variant: 'contained',
            disabled: !validation?.isValid
        });
    } else if (isPreview) {
        additionalActions.push(
            {
                label: <I18n token="shortlist.lineItem.person.back" />,
                onClick: () => setIsPreview(false),
                variant: 'text'
            },
            {
                label: <I18n token="shortlist.lineItem.person.sendRequest" />,
                onClick: sendInvitation,
                variant: 'contained',
                disabled: isSaving || isPreviewLoading,
                loading: isSaving || isLoading
            }
        );
    } else {
        additionalActions.push({
            label: <I18n token="shortlist.lineItem.person.button.preview" />,
            onClick: () => setIsPreview(true),
            variant: 'contained',
            disabled: !validation?.isValid
        });
    }

    return (
        <FormModal
            id={'org-inquiry'}
            title={<I18n token={isPreview ? 'preview.label' : 'organization.inquiry.form.label'} />}
            isOpen={isOpen}
            onClose={onClose}
            isLoading={isLoading}
            isSaving={isSaving}
            additionalActions={additionalActions}
            hideDefaultButtons
        >
            {isNil(isEmailModalOpen) ? (
                <></>
            ) : isEmailModalOpen ? (
                <InquiryContactDetailsForm name={'organizations'} itemsWithNoContact={itemsWithNoEmail} />
            ) : isPreview ? (
                <InvitePreview
                    type={ORGANIZATION_TYPE}
                    entityDetails={entityDetails}
                    getInquiryPreviewData={getInquiryPreviewData}
                    isPreviewLoading={isPreviewLoading}
                    previewData={previewData}
                />
            ) : (
                <OrganizationInviteFormItems data={data} />
            )}
        </FormModal>
    );
};
