export const arrayToSelectOptions = (arr) => {
    return arr && arr.length > 0
        ? arr.map((item) => {
              return { id: item, label: item };
          })
        : [];
};

export const selectOptionsToArray = (options) => {
    return options && options.length > 0 ? options.map((op) => op.id) : [];
};
