import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ShowrunnrLogoMark = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="none" />
            <g filter="url(#filter0_d_2129_4933)">
                <rect x="3" y="15.3535" width="14.6918" height="1.91997" fill="#41A441" />
                <rect x="6.90454" y="6.72656" width="14.0955" height="1.91997" fill="#E31E0D" />
                <rect x="11.3451" y="12.4258" width="7.65088" height="2.0751" fill="#1CA1BF" />
                <rect x="5.23999" y="9.49854" width="7.65088" height="2.0751" fill="#F0E22A" />
            </g>
            <defs>
                <filter
                    id="filter0_d_2129_4933"
                    x="2.1"
                    y="5.82656"
                    width="19.8"
                    height="12.3469"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.45" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2129_4933" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2129_4933" result="shape" />
                </filter>
            </defs>
        </svg>
    </>,
    'ShowrunnrLogoMark'
);
