import React, { useContext, useState } from 'react';
import Validator from '../form/Validator';
import OkCancel from '../components/OkCancel';
import { verify, VerifyRequest } from './api';
import { AuthFormHeader, authLinks, NeedAccount } from './AccountLinks';
import I18n, { I18nContext, I18nContextType, useI18n } from '../i18n/I18n';
import { Box, Container, Paper, Typography } from '@mui/material';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import { handleAndCommitChange } from '../form/forms';
import Nav from '../nav/Nav';
import useHistory from '../utils/useHistory';
import { useLocation } from 'react-router-dom';
import { failedToastMessage, successToastMessage } from '../utils/commonUtils';

const validationRules = {
    username: [Validator.RULES.isRequired, Validator.RULES.email],
    code: [Validator.RULES.isRequired]
};

interface ValidationState {
    isValid: boolean;
    errors: any;
}

const NEEDS_VERIFICATION = 'NEEDS_VERIFICATION';
const VERIFIED = 'VERIFIED';

export default () => {
    const { changeRoute } = useHistory();
    const i18nContext: I18nContextType = useContext(I18nContext);
    const [signupState, setSignupState] = useState(NEEDS_VERIFICATION);

    const validator = new Validator(validationRules, {
        // If true validator will keep going even after first field fails validation.
        runAll: false,
        i18nContext: i18nContext
    });
    const location = useLocation();
    const username = location.state?.username || '';
    const [values, setValues] = React.useState<VerifyRequest>({
        username: username || '',
        code: ''
    });

    const [valid, setValid] = React.useState<ValidationState>({
        isValid: false,
        errors: {}
    });

    const onChange = (name, value) => {
        handleAndCommitChange(values, name, value, validator, setValues, setValid);
    };

    const onOk = () => {
        verify(values)
            .then(() => {
                successToastMessage('dialog.verified');
                setSignupState(VERIFIED);
            })
            .catch((err) => {
                failedToastMessage(`Signup failed: ${err.message}`);
            });
    };

    const onLoginClick = () => {
        changeRoute(authLinks.login.path, {}, true, { username: values.username });
    };

    const onCancel = () => {
        changeRoute(authLinks.login.path);
    };

    const hasErrors = (fieldName) => {
        return Array.isArray(valid.errors[fieldName]) && valid.errors[fieldName].length > 0;
    };

    const title = useI18n('dialog.verify.email');
    const loginTitle = useI18n('dialog.verified.email');
    const loginText = useI18n('dialog.verified.email.text');

    return (
        <>
            <Nav />

            <Container
                key={signupState === NEEDS_VERIFICATION ? 'verify' : 'verified'}
                sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <Paper sx={{ maxWidth: '400px', p: 4 }}>
                        <FormContainer>
                            <FormItem>
                                <AuthFormHeader title={signupState === NEEDS_VERIFICATION ? title : loginTitle} />
                            </FormItem>
                            {signupState !== NEEDS_VERIFICATION && (
                                <FormItem>
                                    <Typography>{loginText}</Typography>
                                </FormItem>
                            )}
                            {signupState !== VERIFIED && (
                                <>
                                    <FormItem>
                                        <FormWidget
                                            name="username"
                                            disabled={!!username}
                                            label={<I18n token="account.form.email" />}
                                            value={values.username}
                                            errors={valid.errors.username}
                                            hasErrors={hasErrors('username')}
                                            onChange={onChange}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <FormWidget
                                            name="code"
                                            label={<I18n token="account.form.code" />}
                                            value={values.code}
                                            errors={valid.errors.code}
                                            hasErrors={hasErrors('code')}
                                            onChange={onChange}
                                        />
                                    </FormItem>
                                </>
                            )}
                        </FormContainer>
                        <FormContainer>
                            <FormItem>
                                <OkCancel
                                    okLabelI18n={
                                        signupState === NEEDS_VERIFICATION ? 'dialog.verify.email' : 'dialog.login'
                                    }
                                    onOk={signupState === NEEDS_VERIFICATION ? onOk : onLoginClick}
                                    onCancel={signupState === NEEDS_VERIFICATION ? onCancel : undefined}
                                    isOkDisabled={signupState === NEEDS_VERIFICATION ? !valid.isValid : undefined}
                                />
                            </FormItem>
                            {signupState === NEEDS_VERIFICATION && (
                                <FormItem>
                                    <NeedAccount />
                                </FormItem>
                            )}
                        </FormContainer>
                    </Paper>
                </Box>
            </Container>
        </>
    );
};
