/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { GridRenderEditCellParams } from '@mui/x-data-grid-premium';
import React, { useState } from 'react';
import { StyledTooltip } from '../components/StyledTooltip';
import { useStyling } from '../Theme';

export default (props: GridRenderEditCellParams) => {
    const { value, isOpen, message } = props;
    const [inputValue, setInputValue] = useState(value);
    const { theme } = useStyling();
    return (
        <StyledTooltip open={isOpen} title={message}>
            <input
                css={css`
                    height: 100%;
                    width: 100%;
                    border: none;
                    padding-left: 8px;
                    padding-right: 8px;
                    font-size: inherit;
                    background-color: transparent;
                    color: ${theme.palette.text.primary};
                `}
                value={inputValue || ''}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
            />
        </StyledTooltip>
    );
};
