import React from 'react';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import { TermsetField } from '../attributes';

type TermsetValueEditorProps = {
    field: TermsetField;
};

export default (props: TermsetValueEditorProps) => {
    const { field } = props;
    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name={field.name}
                        component="MetadataAutocomplete"
                        metadataName={field?.termset?.replace(/\s/g, '')}
                        label={field.label}
                        allowCreate={field.allowCreate}
                        identifier="termset"
                        multiple={field.isMultiSelect}
                        hideHelperText
                    />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
