import React, { createContext, useMemo, useState } from 'react';

type EnvironmentPermissionContextType = {
    allowWIP: boolean;
};

export const EnvironmentPermissionContext = createContext<EnvironmentPermissionContextType>(undefined);

export const EnvironmentPermissionProvider = ({ children }) => {
    const namespace = window._env_.NAMESPACE;
    const [allowWip, setAllowWip] = useState(false);

    const providerValue = useMemo(() => {
        if (namespace === 'dev') {
            setAllowWip(true);
        }
        return { allowWIP: allowWip };
    }, [namespace, allowWip]);

    return (
        <EnvironmentPermissionContext.Provider value={providerValue}>{children}</EnvironmentPermissionContext.Provider>
    );
};
