import React from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { H } from '../../../common/Layout';
import { useSearchkit } from '@searchkit/client';
import { SkFacetSet } from 'sr-types/lib/search/v1/graphql';
import EllipsisText from '../../../common/components/EllipsisText';
import I18n from '../../../common/i18n/I18n';

export const BooleanFacet = ({ facet, showCount }: { facet: SkFacetSet; showCount: boolean }) => {
    const api = useSearchkit();
    return (
        <H sx={{ gap: 1 }}>
            <H sx={{ gap: 0.5 }}>
                <EllipsisText sx={{ fontSize: 'medium' }} text={<I18n token={facet.label} />} />
                {showCount && <Typography sx={{ fontSize: 'small' }}>({facet.entries[0].count})</Typography>}
            </H>
            <FormControlLabel
                sx={{ mx: 0 }}
                componentsProps={{
                    typography: {
                        sx: { fontSize: 'small' }
                    }
                }}
                control={
                    <Switch
                        size={'small'}
                        checked={api.isFilterSelected({ identifier: facet.identifier, value: 'true' })}
                        onChange={(e, checked) => {
                            const pageSize = api.getSearchState().page.size;
                            api.removeFiltersByIdentifier(facet.identifier);
                            api.addFilter({ identifier: facet.identifier, value: checked ? 'true' : 'false' });
                            api.setPage({
                                from: 0,
                                size: pageSize
                            });
                            api.search();
                        }}
                    />
                }
                label={''}
            />
        </H>
    );
};

BooleanFacet.DISPLAY = 'BooleanFacet';
