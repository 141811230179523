import React, { useMemo, useRef, useState } from 'react';
import { ButtonGroup, Checkbox, Popover, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useStyling } from '../../Theme';
import { Icons } from '../../icons/Icons';

export type QuickFilter = {
    id: string;
    label: string;
    options: string[];
    originalOptions?: string[];
    displayType?: string;
    values?: string[];
};

export type FilterItemProps = {
    filter: QuickFilter;
    onFilterChanged: (QuickFilter) => void;
    requiredFilters: string[]
};

export default (props: FilterItemProps) => {
    const { filter, onFilterChanged, requiredFilters } = props;
    const { theme } = useStyling();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const isRequiredFilter = requiredFilters.includes(filter.id);

    const order = useMemo(() => {
        const map = {};
        filter.options.forEach((o, index) => {
            map[o] = index;
        });
        return map;
    }, [filter]);

    const handleMenuItemClick = (option: string) => {
        if (!filter.values) {
            filter.values = [];
        }
        if (filter.values.includes(option)) {
            filter.values = filter.values.filter((v) => v !== option);
        } else {
            filter.values = [...filter.values, option];
        }
        onFilterChanged(filter);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event?: Event) => {
        if (event && anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleClear = () => {
        handleClose();
        filter.values = [];
        onFilterChanged(filter);
    };

    const { hasValues, more, text } = useMemo(() => {
        const len = filter.values ? filter.values.length : 0;
        const hasValues = len > 0;
        const more = len > 1 ? '+' + (len - 1) : '';
        const text = hasValues ? filter.values.sort((a, b) => order[a] - order[b])[0] : filter.label;
        return { hasValues, more, text };
    }, [filter.values]);

    return (
        <React.Fragment>
            <ButtonGroup
                color={hasValues ? 'active' : 'inactive'}
                variant={hasValues ? 'contained' : 'outlined'}
                ref={anchorRef}
                size={'small'}
            >
                <Tooltip 
                   title={hasValues ? `${filter.label}` : ""}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -6],
                                    },
                                },
                            ],
                        },
                    }}>
                    <Button size={'small'} sx={{ borderRadius: '5px' }} onClick={handleToggle}>
                        <p style={{ margin: 0 }}>{text}</p>
                        {more ? (
                            <p
                                style={{
                                    color: theme.palette.text.disabled,
                                    paddingLeft: '4px',
                                    margin: 0
                                }}
                            >
                                {more}
                            </p>
                        ) : (
                            <></>
                        )}
                        {hasValues && !isRequiredFilter ? (
                            <Icons.Close style={{ paddingLeft: '4px' }} onClick={handleClear} />
                        ) : (
                            <></>
                        )}
                    </Button>
                </Tooltip>
            </ButtonGroup>
            <Popover
                disablePortal
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
                sx={{
                    mt: 1.5
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                        id={`filter-${filter.id}-menu`}
                        autoFocusItem
                        sx={{
                            // pr: 2
                            maxHeight: '320px',
                            // this is added as per the input from @jay(UX)
                            maxWidth: '296px',
                        }}
                    >
                        {filter.options.map((option, index) => (
                            <MenuItem
                                key={index}
                                sx={{ py: 0, pl: 1, pr: 2 }}
                                onClick={() => handleMenuItemClick(option)}
                            >
                                <Checkbox checked={(filter.values || []).includes(option)} />
                                {option}
                            </MenuItem>
                        ))}
                    </MenuList>
                </ClickAwayListener>
            </Popover>
        </React.Fragment>
    );
};
