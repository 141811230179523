import React from 'react';
import { Contact } from 'sr-types/lib/production/v1/graphql';
import I18n, { useI18n } from '../../common/i18n/I18n';
import { H } from '../../common/Layout';
import { useStyling } from '../../common/Theme';
import ContactOptionRenderer from '../../person/ContactOptionRenderer';
import AddMore from './AddMore';
import NoContent from './NoContent';
import NoValueAvailable from './NoValueAvailable';

type AssociatedPeopleProps = {
    contacts: Contact[];
    onAdd?: React.MouseEventHandler<HTMLButtonElement>;
    isSaving?: boolean;
    isReadOnly?: boolean;
};

export default ({ contacts, onAdd, isSaving = false, isReadOnly = false }: AssociatedPeopleProps) => {
    const { theme } = useStyling();
    return !!contacts && contacts.length ? (
        <H
            gap={1}
            sx={{ width: '100%', alignItems: 'center' }}
            responsive
            tileWidth={110}
            customMoreElement={
                !isReadOnly && (
                    <AddMore i18nToken="production.header.keyPeople.addKeyPeople" onAdd={onAdd} disabled={isSaving} />
                )
            }
        >
            {contacts.map((person: Contact, index) => {
                return (
                    <ContactOptionRenderer
                        key={index}
                        sx={{ width: '220px' }}
                        option={person}
                        getSubLabel={(contact: Contact) => {
                            return contact.productionRole ? (
                                contact.productionRole
                            ) : contact.role ? (
                                contact.role
                            ) : (
                                <I18n token="production.header.keyPeople.no.role.assigned" />
                            );
                        }}
                        enableHyperlink
                    />
                );
            })}
        </H>
    ) : isReadOnly ? (
        <NoValueAvailable text={useI18n('production.header.keyPeople.notAvailable')} />
    ) : (
        <NoContent
            i18nToken="production.header.keyPeople.addKeyPeople"
            onAdd={onAdd}
            disabled={isSaving}
            containerSx={{
                g: 1,
                justifyContent: 'center',
                width: '220px',
                height: '43px',
                color: theme.palette.primary.main,
                '&:hover': {
                    cursor: 'pointer'
                }
            }}
        />
    );
};
