import { isNil } from 'lodash';
import React, { useContext } from 'react';
import { FormContext } from '../../common/form/FormContext';
import FormModal from '../../common/form/FormModal';
import I18n from '../../common/i18n/I18n';
import { PersonInviteFormModalProps } from './inquiry/inquiry';
import InquiryContactDetailsForm from './InquiryContactDetailsForm';
import InvitePreview from './InvitePreview';
import PersonInviteFormItems from './PersonInviteFormItems';
import { PEOPLE_TYPE } from './inquiry/inquiry';

export default ({
    isPreview,
    setIsPreview,
    isOpen,
    onClose,
    sendInvitation,
    isSaving,
    productionName,
    productionOptions,
    productionDateRange,
    isLoading,
    isEmailModalOpen,
    peopleWithNoContact,
    onClickContinue,
    entityDetails,
    getInquiryPreviewData,
    isPreviewLoading,
    previewData
}: PersonInviteFormModalProps) => {
    const { validation } = useContext(FormContext);

    return (
        <FormModal
            id="crew-invite"
            title={<I18n token={isPreview ? 'preview.label' : 'shortlist.lineItem.person.form'} />}
            isOpen={isOpen}
            onClose={onClose}
            isLoading={isLoading}
            additionalActions={
                isEmailModalOpen
                    ? [
                          {
                              label: <I18n token="organization.add.contact.button.continue" />,
                              onClick: onClickContinue,
                              variant: 'contained',
                              disabled: !validation?.isValid
                          }
                      ]
                    : isPreview
                      ? [
                            {
                                label: <I18n token="shortlist.lineItem.person.back" />,
                                onClick: () => {
                                    setIsPreview(false);
                                },
                                variant: 'text'
                            },
                            {
                                label: <I18n token="shortlist.lineItem.person.sendRequest" />,
                                onClick: sendInvitation,
                                variant: 'contained',
                                disabled: isSaving || isPreviewLoading,
                                loading: isSaving || isLoading
                            }
                        ]
                      : [
                            {
                                label: <I18n token="shortlist.lineItem.person.button.preview" />,
                                onClick: () => {
                                    setIsPreview(true);
                                },
                                variant: 'contained',
                                disabled: !validation?.isValid
                            }
                        ]
            }
            hideDefaultButtons
        >
            {isNil(isEmailModalOpen) ? (
                <></>
            ) : isEmailModalOpen ? (
                <InquiryContactDetailsForm name={'people'} itemsWithNoContact={peopleWithNoContact} />
            ) : isPreview ? (
                <InvitePreview
                    type={PEOPLE_TYPE}
                    entityDetails={entityDetails}
                    getInquiryPreviewData={getInquiryPreviewData}
                    isPreviewLoading={isPreviewLoading}
                    previewData={previewData}
                />
            ) : (
                <PersonInviteFormItems
                    productionName={productionName}
                    productionOptions={productionOptions}
                    productionDateRange={productionDateRange}
                />
            )}
        </FormModal>
    );
};
