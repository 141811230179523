import React from 'react';
import DataGrid from '../../list/DataGrid';
import DropzoneTextArea from '../../components/DropzoneTextArea';
import { FormControlLabel, Switch } from '@mui/material';
import I18n from '../../i18n/I18n';
import { MuiEvent } from '@mui/x-data-grid-pro';
import { GridStateColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import upload from '../../utils/upload';

export const PROCESS_URL = '/storage/v1/process';

export const MIME_TO_EXT = {
    'text/csv': ['.csv', '.tsv'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
};

export default ({
    EmptyNoRowsOverlay,
    feature,
    defaultColumns,
    setDefaultColumns,
    columnVisibility,
    text,
    setText,
    placeholder,
    activeOrganizationAccount,
    openUploadDialog,
    setOpenUploadDialog,
    setIsDataImported,
    disableTextBasedSelector,
    hasDataToImport,
    hasHeaders,
    setHasHeaders,
    altColDefLabel,
    isAltColDef,
    setIsAltColDef
}) => (
    <>
        <DataGrid
            id={'data-import-grid'}
            slots={{
                noRowsOverlay: EmptyNoRowsOverlay
            }}
            containerSx={{
                minHeight: '56px',
                width: '100%',
                flexGrow: 0,
                '.MuiDataGrid-overlay': {
                    top: 8
                },
                '.MuiDataGrid-footerContainer': {
                    border: 'none'
                },
                '.MuiDataGrid-virtualScroller': {
                    overflow: 'hidden'
                },
                '.MuiDataGrid-main': {
                    overflow: 'visible'
                }
            }}
            feature={feature}
            rowCount={0}
            rows={[]}
            hideFooter
            columns={defaultColumns.map((c) => ({ ...c, sortable: false }))}
            defaultColumnVisibility={columnVisibility}
            serverPagination={false}
            includeActions={false}
            disablePagination={true}
            disableColumnResize={true}
            onColumnOrderChange={(colDefs: GridStateColDef[], event: MuiEvent) => {
                setDefaultColumns(colDefs);
                event.defaultMuiPrevented = true;
            }}
        />
        <DropzoneTextArea
            value={text}
            onChange={(e) => setText(e.target.value)}
            onDropRead={(newText) => setText(newText)}
            placeholder={placeholder}
            converter={(file) => {
                return upload(activeOrganizationAccount, PROCESS_URL, file);
            }}
            onError={(msg) => {
                console.error(msg);
            }}
            triggerUploadDialog={openUploadDialog}
            setOpenUploadDialog={setOpenUploadDialog}
            accept={MIME_TO_EXT}
            setIsDataImported={setIsDataImported}
            disableTextBasedSelector={disableTextBasedSelector}
        />
        {!hasDataToImport && (
            <FormControlLabel
                sx={{ flexGrow: 0, ml: 0 }}
                control={<Switch size={'small'} />}
                label={<I18n token="import.contacts.uploadfile.hasheaders" />}
                checked={hasHeaders}
                onChange={(event, newValue) => setHasHeaders(newValue)}
            />
        )}
        {altColDefLabel && (
            <FormControlLabel
                sx={{ flexGrow: 0, ml: 0 }}
                control={<Switch size={'small'} />}
                label={altColDefLabel}
                checked={isAltColDef}
                onChange={(event, newValue) => setIsAltColDef(newValue)}
            />
        )}
    </>
);
