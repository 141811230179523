import { useOnMobile } from '../hooks/breakpoints';
import { Grid } from '@mui/material';
import React from 'react';

export default ({ first = false, half = false, third = false, quarter = false, vertical = false, gap = 1, sx = undefined, children = undefined, span = undefined, ...rest }) => {
    const isMobile = useOnMobile();
    const s = span || (isMobile ? 12 : half ? 6 : third ? 4 : quarter ? 3 : 12);
    return rest.hidden ? (
        <></>
    ) : (
        <Grid
            className="_formItem"
            item
            sm={s}
            xs={quarter ? 6 : 12}
            flexWrap={'nowrap'}
            sx={{ ...sx, display: 'flex', flexDirection: vertical ? 'column' : 'row', gap: gap }}
            {...rest}
        >
            {children}
        </Grid>
    );
};
