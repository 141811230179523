import React, { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { auto } from '@popperjs/core';
import { useStyling } from '../common/Theme';
import { H, V } from '../common/Layout';
import I18n, { useI18n } from '../common/i18n/I18n';
import DelimitedLabels from '../common/components/DelimitedLabels';
import { ContactWithKey } from './helpers/peopleUtils';

export const ListActionButtons = ({ isAdded, person, onMarkClick, onUnmarkClick, isLoading, isDisabled }) => {
    const { theme } = useStyling();

    return (
        <H sx={{ width: auto, gap: 2 }}>
            {isAdded && (
                <Typography sx={{ color: theme.palette.text.disabled }}>
                    <I18n token="form.added" />
                </Typography>
            )}
            <LoadingButton
                variant="text"
                loading={isLoading}
                disabled={isDisabled}
                onClick={(e) => {
                    e.stopPropagation();
                    isAdded ? onUnmarkClick(person) : onMarkClick(person);
                }}
            >
                {isAdded ? <I18n token="form.remove" /> : <I18n token="form.add" />}
            </LoadingButton>
        </H>
    );
};

interface KeyPeopleTileProps {
    item: ContactWithKey;
    onMarkClick: (person: ContactWithKey) => void;
    onUnmarkClick: (person: ContactWithKey) => void;
    isLoading: { id: string; isSaving: boolean };
    isDisabled?: boolean;
}

export default (props: KeyPeopleTileProps) => {
    const { item: person, onMarkClick, onUnmarkClick, isLoading, isDisabled = false } = props;
    const { theme } = useStyling();
    const noRole = useI18n('production.header.keyPeople.no.role.assigned');

    const delimitedLabels = useMemo(() => {
        const labels = [];
        if (person?.productionRole) {
            labels.push(person.productionRole);
        } else if (person?.role) {
            labels.push(person.role);
        }
        if (!labels.length) {
            labels.push(noRole);
        }

        return labels;
    }, [noRole, person?.productionRole, person?.role]);

    if (!person) return null;

    return (
        <H className="clickable" sx={{ h: 7 }} key={person?.id + person.contactInfo?.email}>
            <V sx={{ alignSelf: 'stretch', gap: 0.5 }}>
                <Typography> {`${person.name.firstName} ${person.name.lastName}`}</Typography>
                <H>
                    <H variant="body2" sx={{ color: theme.palette.text.disabled, gap: 0.5 }}>
                        {Array.isArray(delimitedLabels) && delimitedLabels.length > 0 && (
                            <DelimitedLabels>{delimitedLabels}</DelimitedLabels>
                        )}
                    </H>
                </H>
            </V>
            <ListActionButtons
                isAdded={person?.isKey}
                person={person}
                onMarkClick={onMarkClick}
                onUnmarkClick={onUnmarkClick}
                isLoading={isLoading?.id === person.personReference?.id && isLoading.isSaving}
                isDisabled={isDisabled || (isLoading?.id !== person.personReference?.id && isLoading.isSaving)}
            />
        </H>
    );
};
