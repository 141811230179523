import React, { useState, useContext, useRef, useEffect } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import I18n from '../../common/i18n/I18n';
import { H, V } from '../../common/Layout';
import DataGrid from '../../common/list/DataGrid';
import { toDisplayDateAndTime } from '../../common/utils/dateTime';
import Confirm from '../../common/components/Confirm';
import { Typography } from '@mui/material';
import { useUnlinkResourceFromActivity, useLinkResourceToActivity } from '../helpers/productionApi';
import FormWidget from '../../common/form/FormWidget';
import { EMPTY_SCHEDULE, DEFAULT_DURATION } from '../../common/form/widgets/ScheduleWidget';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormModal from '../../common/form/FormModal';
import Validator from '../../common/form/Validator';
import ActivityForm from '../schedule/ActivityForm';
import { ActivityFormType, EMPTY_ACTIVITY, ActivityOrder, getStringFromDateRange } from '../helpers/activityUtils';
import { cloneDeep } from 'lodash';
import { useStyling } from '../../common/Theme';
import { FormProvider } from '../../common/form/FormContext';
import AssociateActivityOptionRenderer from './AssociateActivityOptionRenderer';

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 100, sortable: true },
    { field: 'start', headerName: 'Start', flex: 1, minWidth: 100, sortable: true },
    { field: 'end', headerName: 'End', flex: 1, minWidth: 100, sortable: true },
    { field: 'activityType', headerName: 'Type', flex: 1, minWidth: 100, sortable: true },
    { field: 'location', headerName: 'Location', flex: 1, minWidth: 100, sortable: true }
];

const EMPTY_STATE = {
    associateActivity: []
};

const validationRules = {
    associateActivity: [Validator.RULES.isRequired]
};

export const getActivityOptions = (item) => {
    return {
        ...item,
        label: item.name,
        ref: { ...item.reference, label: item.name }
    };
};

export default ({ resourceData, productionId, productionData, listedLinkedActivities, setListedLinkedActivities }) => {
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [activityToUnlink, setActivityToUnlink] = useState(undefined);
    const [isSearchActivityOpen, setIsSearchActivityOpen] = useState(false);
    const [activityModalState, setActivityModalState] = useState(false);
    const [prepopulatedActivity, setPrepopulatedActivity] = useState<ActivityFormType>(EMPTY_ACTIVITY);
    const [isActivityFormSaveDisabled, setIsActivityFormSaveDisabled] = useState(false);
    const [isActivityFormSaving, setIsActivityFormSaving] = useState(false);
    const [isSearchFormSaving, setIsSearchFormSaving] = useState(false);
    const [values, setValues] = useState(cloneDeep(EMPTY_STATE));
    const { linkResourceToActivity } = useLinkResourceToActivity();
    const { unlinkResourceFromActivity } = useUnlinkResourceFromActivity();
    const activityFormRef = useRef(null);

    const closeActivityModal = (associateActivity) => {
        const val = associateActivity
            .map((e) => {
                if (e.label !== prepopulatedActivity.name) {
                    return e;
                }
            })
            .filter((e) => e);
        if (val) {
            setValues({ associateActivity: [...val] });
        }
        setActivityModalState(false);
    };

    const handleAssociateActivitySave = () => {
        const activitiesObj = [];
        setIsSearchFormSaving(true);
        values.associateActivity?.forEach((val) => {
            linkResourceToActivity(val.id, resourceData.identity.id).then(() => setIsSearchFormSaving(false));
            const activityObj = productionData.plans?.[0].activities?.find((item) => item.identity.id === val.id);
            if (activityObj) {
                // we get reference object for existing activities, hence, need to fetch activity details from production plans
                activitiesObj.push(activityObj);
            } else {
                // if activity is new, and not added in production yet, we can directly use val object as it will have all activity details
                activitiesObj.push(val);
            }
        });
        setListedLinkedActivities([...listedLinkedActivities, ...activitiesObj]);
        setIsSearchActivityOpen(false);
        setValues({ ...EMPTY_STATE });
    };

    const rows = React.useMemo(
        () =>
            listedLinkedActivities?.map((entity, index) => {
                return {
                    key: index,
                    ...entity,
                    id: entity.identity?.id || entity.id,
                    start: toDisplayDateAndTime(
                        entity.dateRange.start || '',
                        entity.dateRange.startTime,
                        entity.dateRange.dateTimeDuration?.unit === 'hour'
                    ),
                    end: toDisplayDateAndTime(
                        entity.dateRange.end || '',
                        entity.dateRange.endTime,
                        entity.dateRange.dateTimeDuration?.unit === 'hour'
                    ),
                    location: entity?.locations?.[0]?.label,
                    _raw: entity
                };
            }),
        [listedLinkedActivities]
    );

    const unlinkActivity = () => {
        if (activityToUnlink) {
            setIsSearchFormSaving(true);
            unlinkResourceFromActivity(activityToUnlink, resourceData.identity.id).then(() =>
                setIsSearchFormSaving(false)
            );
            const listedActivities = listedLinkedActivities
                .map((activity) => {
                    if (activity.identity.id !== activityToUnlink) {
                        return activity;
                    }
                })
                .filter((act) => act);
            setListedLinkedActivities([...listedActivities]);
        }
        setDeleteConfirmationOpen(false);
    };

    const onSucess = (inputData) => {
        const val = values.associateActivity
            .map((e) => {
                if (e.label !== prepopulatedActivity.name) {
                    return e;
                }
            })
            .filter((e) => e);
        setValues({
            associateActivity: [...val, { ...inputData, label: inputData.name, id: inputData.identity.id }]
        });
    };

    return (
        <>
            <DataGrid
                id={'activity-list'}
                clickableColumn={''}
                feature={'Activity'}
                rowCount={rows?.length}
                rows={rows || []}
                columns={columns}
                sortingMode={'server'}
                includeActions={true}
                addTitle={<I18n token="grid.button.add" />}
                toolbar={{ add: true }}
                onAdd={() => {
                    setIsSearchActivityOpen(true);
                }}
                onDelete={(resourceId: string) => {
                    setActivityToUnlink(resourceId);
                    setDeleteConfirmationOpen(true);
                }}
            />
            <Confirm
                open={deleteConfirmationOpen}
                title={<I18n token="resource.associatedActivities.remove.confirm.title" />}
                okLabelI18n="resource.associatedActivities.confirm.title"
                body={
                    <Typography>
                        <I18n token="resource.associatedActivities.remove.confirm.message" />
                    </Typography>
                }
                onConfirm={unlinkActivity}
                onCancel={() => {
                    setDeleteConfirmationOpen(false);
                }}
            />
            {isSearchActivityOpen && (
                <FormModal
                    isOpen={isSearchActivityOpen}
                    width="700px"
                    title={
                        activityModalState ? (
                            <I18n token="production.activity.new" />
                        ) : (
                            <I18n token="resource.associatedActivities.form.title" />
                        )
                    }
                    id={`associate-activity-search`}
                    onClose={() => {
                        if (activityModalState) {
                            closeActivityModal(values.associateActivity);
                        } else {
                            setIsSearchActivityOpen(false);
                            setValues({ ...EMPTY_STATE });
                        }
                    }}
                    hideDefaultButtons={activityModalState}
                    onSave={handleAssociateActivitySave}
                    isSaveDisabled={!activityModalState && !values.associateActivity?.length}
                    isLoading={isSearchFormSaving || isActivityFormSaving}
                    additionalActions={
                        activityModalState
                            ? [
                                  {
                                      label: <I18n token="dialog.cancel" />,
                                      onClick: () => {
                                          closeActivityModal(values.associateActivity);
                                      },
                                      variant: 'text'
                                  },
                                  {
                                      label: <I18n token="dialog.save" />,
                                      onClick: () => {
                                          setIsActivityFormSaving(true);
                                          activityFormRef.current.handleSave(onSucess).then(() => {
                                              setActivityModalState(false);
                                              setIsActivityFormSaving(false);
                                          });
                                      },
                                      variant: 'contained',
                                      disabled: isActivityFormSaveDisabled
                                  }
                              ]
                            : []
                    }
                >
                    {activityModalState ? (
                        <ActivityForm
                            ref={activityFormRef}
                            isInModal={false}
                            // for now we are saving activity to the first plan, in future we will have plan list and plan details, add new activity form will be in plan details
                            planId={productionData.plans?.[0].identity.id}
                            productionId={productionId}
                            prepopulatedActivity={prepopulatedActivity}
                            isCreate={true}
                            isResourceForm={true}
                            setIsSaveDisabled={setIsActivityFormSaveDisabled}
                        />
                    ) : (
                        <>
                            <FormProvider state={values} setState={setValues} validationRules={validationRules}>
                                <FormPanel>
                                    <FormItem>
                                        <FormWidget
                                            multiple
                                            name="associateActivity"
                                            component="ReferenceAutocomplete"
                                            entity="Activity"
                                            label={<I18n token="resource.associatedActivities.search.label" />}
                                            filter={{
                                                expressions: [
                                                    { field: 'production', value: { values: [productionId] } }
                                                ]
                                            }}
                                            getOption={getActivityOptions}
                                            OptionRenderer={AssociateActivityOptionRenderer}
                                            addOption
                                            onCreateHandle={(e) => {
                                                setActivityModalState(true);
                                                setPrepopulatedActivity({
                                                    ...EMPTY_ACTIVITY,
                                                    dateRange: {
                                                        ...EMPTY_SCHEDULE,
                                                        dateTimeDuration: { ...DEFAULT_DURATION }
                                                    },
                                                    dependencies: {
                                                        lastDependency: {
                                                            order: ActivityOrder.predecessor,
                                                            activityRefId: null,
                                                            lag: 1,
                                                            lagUnit: 'Days',
                                                            type: 'Start to Start'
                                                        }
                                                    },
                                                    name: e.label
                                                });
                                            }}
                                            filterById={listedLinkedActivities?.map((list) => list.identity.id)}
                                        />
                                    </FormItem>
                                </FormPanel>
                            </FormProvider>
                        </>
                    )}
                </FormModal>
            )}
        </>
    );
};
