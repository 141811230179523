import { Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
import { InquiryInput, InquiryMessage, InquiryPreviewInput, SupplierDetails } from 'sr-types/lib/shortlist/v1/graphql';
import { FormContext } from '../../common/form/FormContext';
import I18n from '../../common/i18n/I18n';
import { Icons } from '../../common/icons/Icons';
import { H, V } from '../../common/Layout';
import { useStyling } from '../../common/Theme';
import OpportunityEmailPreview from '../../supply/opportunity/details/OpportunityEmailPreview';
import { get } from 'lodash';
import { toPersonInquiryInput, PEOPLE_TYPE, ORGANIZATION_TYPE, toOrgInquiryInput } from './inquiry/inquiry';
import Loading from '../../common/components/Loading';
import MultipleChipPreview from '../../common/components/MultipleChipPreview';

export default ({
    type,
    entityDetails,
    getInquiryPreviewData,
    isPreviewLoading,
    previewData
}: {
    type: string;
    entityDetails: any;
    getInquiryPreviewData: (options: any) => void;
    isPreviewLoading: boolean;
    previewData: InquiryMessage;
}) => {
    const { state } = useContext(FormContext);
    const { theme, isDarkMode } = useStyling();
    const selectedItems = get(state, type);
    const itemDetails = entityDetails.items.find((item) => selectedItems[0].id === item.id);

    const supplierDetails: SupplierDetails = {
        contact: {
            contactInfo: itemDetails.contactInfo
        }
    };
    let inquiryInput: InquiryInput[];
    if (type == PEOPLE_TYPE) {
        inquiryInput = toPersonInquiryInput(state);
        const names = itemDetails.name ? itemDetails.name.split(' ') : [];
        if (names.length) {
            supplierDetails.personName = {
                firstName: names[0],
                lastName: names.length > 1 ? names.slice(1).join(' ') : ''
            };
        }
    } else if (type == ORGANIZATION_TYPE) {
        inquiryInput = toOrgInquiryInput(state);
        supplierDetails.orgPublicName = itemDetails.name;
    }

    const inquiryPreviewInput = useMemo(() => {
        if (inquiryInput?.length) {
            const inquiryPreviewInput: InquiryPreviewInput = {
                shortlistLineItemId: inquiryInput[0].shortlistLineItemId,
                supplierDetails,
                inquiryDetails: inquiryInput[0].inquiryPayload
            };
            return inquiryPreviewInput;
        }
    }, [inquiryInput?.length]);

    useEffect(() => {
        if (inquiryPreviewInput) {
            getInquiryPreviewData({
                variables: { inquiryInput: inquiryPreviewInput }
            });
        }
    }, [inquiryPreviewInput]);

    const content = previewData?.inquiryMessage?.content;
    const inquiryFor = previewData?.inquiryFor;
    const inviteeName = previewData?.inquiryMessage?.senderInfo?.name;

    return isPreviewLoading ? (
        <Loading />
    ) : (
        previewData && (
            <V sx={{ p: 2, gap: 2 }}>
                <V sx={{ gap: 1 }}>
                    <H sx={{ color: theme.palette.grey[isDarkMode ? 100 : 700] }}>
                        {type == PEOPLE_TYPE ? (
                            <Icons.People fontSize={'medium'} />
                        ) : (
                            <Icons.Organization fontSize={'medium'} />
                        )}
                        <Typography fontSize={'medium'}>
                            <I18n token="shortlist.lineItem.person.invitee" />
                        </Typography>
                    </H>
                    <MultipleChipPreview data={state[type]} />
                </V>
                <OpportunityEmailPreview content={content} inquiryFor={inquiryFor} isPreview />
                <Typography fontSize={'small'} color={theme.palette.text.primary}>
                    <I18n token={'form.respond.note'} values={{ name: inviteeName }} />
                </Typography>
            </V>
        )
    );
};
