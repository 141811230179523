import React, { useContext, useState } from 'react';
import { ToolbarPropsOverrides } from '@mui/x-data-grid-premium';
import { get } from 'lodash';
import { FormContext } from '../../common/form/FormContext';
import FormPanel from '../../common/form/FormPanel';
import { H } from '../../common/Layout';
import DataGrid from '../../common/list/DataGrid';
import { AssociatedOrganizations } from '../helpers/production';

import useAssociatedOrganizationsGridColumns, { columnVisibility } from './useAssociatedOrganizationsGridColumns';

const withFormLayout = (Component) => {
    return ({ withFormLayout, ...props }) => {
        if (withFormLayout) {
            return (
                <FormPanel>
                    <H
                        sx={{
                            height: '300px'
                        }}
                    >
                        <Component {...props} />
                    </H>
                </FormPanel>
            );
        } else {
            return <Component {...props} />;
        }
    };
};

const DataGridWithFormLayout = withFormLayout(DataGrid);

export default ({
    withFormLayout = true,
    customToolbar = [],
    onSetRows = undefined,
    onDelete = undefined
}: {
    withFormLayout?: boolean;
    customToolbar?: ToolbarPropsOverrides[];
    onSetRows?: (rows) => void;
    onDelete?: (row) => void;
}) => {
    const { state, validation, handleChange } = useContext(FormContext);
    const [isValid, setIsValid] = useState(false);

    const defaultColumns = useAssociatedOrganizationsGridColumns(setIsValid);

    const organizationsToRows = (organizations: AssociatedOrganizations[]) => {
        return organizations
            ? organizations.map((org, index) => {
                  const row = { id: index };
                  defaultColumns.forEach((col) => {
                      row[col.field] = get(org, col.field);
                  });
                  row['_raw'] = org;
                  return row;
              })
            : [];
    };

    const handleRowsChange = (newRows) => {
        handleChange(
            'associatedOrgs',
            newRows.map((r) => {
                return {
                    organization: r.organization,
                    organizationTypes: r.organizationTypes,
                    organizationCategories: r.organizationCategories,
                    organizationReference: r.organizationReference
                };
            })
        );
        if (Array.isArray(newRows) && newRows.length && newRows[newRows.length - 1]?.organizationReference?.id) {
            onSetRows?.(newRows);
        }
    };

    const onDeleteRow = (deleteRowIndex) => {
        const newRows = state.associatedOrgs.filter((org, idx) => {
            return idx !== deleteRowIndex;
        });
        handleChange('associatedOrgs', newRows);
        onDelete?.(newRows);
    };

    return (
        <DataGridWithFormLayout
            id={withFormLayout ? 'associated-orgs-editor' : 'associated-orgs-tab'}
            enableInlineEditing={true}
            feature={'Production'}
            rowCount={state.associatedOrgs ? state.associatedOrgs.length : 0}
            rows={organizationsToRows(state.associatedOrgs)}
            setRows={handleRowsChange}
            columns={defaultColumns}
            defaultColumnVisibility={columnVisibility}
            forceDefaultColumnVisibilty
            errors={validation?.errors?.associatedOrgs}
            serverPagination={false}
            toolbar={{ add: true, custom: customToolbar }}
            withFormLayout={withFormLayout}
            onDelete={onDeleteRow}
            isRowValid={() => isValid}
            sortingMode='client'
        />
    );
};
