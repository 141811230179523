import { Link, Typography } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import I18n from '../i18n/I18n';
import { useStyling } from '../Theme';

export default () => {
    const { theme } = useStyling();
    const SHOWRUNNR_POLICY_LINK = 'https://showrunnr.com/privacy-policy';
    return (
        <Typography
            sx={{
                color: theme.palette.primary.main,
                display: 'flex',
                maxWidth: '400px',
                width: '100%',
                placeContent: 'flex-end',
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '10px'
            }}
        >
            <Link
                href={SHOWRUNNR_POLICY_LINK}
                sx={{ textDecoration: 'none' }}
                target="_blank"
                rel="noopener noreferrer"
            >
                <I18n token="dialog.privacy" />
            </Link>
        </Typography>
    );
};
