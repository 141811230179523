import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Production = createSvgIcon(
    <svg fill="currentColor" stroke="currentColor" viewBox={'0 0 21 21'}>
        <g transform="matrix(0.45289186,0,0,0.46133887,-0.00283454,0.00444497)">
            <path
                d="m 34.09,33 a 7.42,7.42 0 0 0 -6.34,2.38 1,1 0 1 0 1.44,1.38 5.44,5.44 0 0 1 4.55,-1.76 5.44,5.44 0 0 1 3.66,3.24 1,1 0 0 0 0.92,0.6 1,1 0 0 0 0.4,-0.09 1,1 0 0 0 0.51,-1.32 A 7.4,7.4 0 0 0 34.09,33 Z"
                transform="translate(-1.82,-2.3)"
            />
            <path
                d="m 40.29,28.31 a 2.47,2.47 0 0 1 -1.4,0.47 2.5,2.5 0 0 1 -1.15,-0.93 1,1 0 1 0 -1.63,1.15 4.31,4.31 0 0 0 2.44,1.75 3.42,3.42 0 0 0 0.45,0 4.72,4.72 0 0 0 2.44,-0.85 1,1 0 0 0 -1.15,-1.63 z"
                transform="translate(-1.82,-2.3)"
            />
            <path
                d="m 30.67,29.36 a 2.32,2.32 0 0 0 0.46,0 4.83,4.83 0 0 0 2.43,-0.84 1,1 0 0 0 0.25,-1.39 1,1 0 0 0 -1.39,-0.25 2.48,2.48 0 0 1 -1.4,0.47 2.52,2.52 0 0 1 -1.16,-0.92 1,1 0 1 0 -1.63,1.14 4.31,4.31 0 0 0 2.44,1.79 z"
                transform="translate(-1.82,-2.3)"
            />
            <path
                d="m 21.41,17.21 a 1,1 0 0 0 -1.31,0.51 5.48,5.48 0 0 1 -3.66,3.28 5.43,5.43 0 0 1 -4.55,-1.79 1,1 0 0 0 -1.42,0 1,1 0 0 0 0,1.41 7.6,7.6 0 0 0 5.53,2.38 4.46,4.46 0 0 0 0.81,-0.06 7.46,7.46 0 0 0 5.15,-4.4 1,1 0 0 0 -0.55,-1.33 z"
                transform="translate(-1.82,-2.3)"
            />
            <path
                d="m 18.81,11.4 a 4.39,4.39 0 0 0 -2.44,1.75 1,1 0 0 0 0.24,1.39 1,1 0 0 0 0.58,0.18 A 1,1 0 0 0 18,14.3 2.54,2.54 0 0 1 19.16,13.37 2.46,2.46 0 0 1 20.55,13.85 1,1 0 0 0 22,13.6 1,1 0 0 0 21.75,12.21 4.37,4.37 0 0 0 18.81,11.4 Z"
                transform="translate(-1.82,-2.3)"
            />
            <path
                d="m 11.28,14.76 a 2.66,2.66 0 0 1 1.4,0.47 1,1 0 1 0 1.14,-1.63 4.34,4.34 0 0 0 -2.89,-0.81 4.39,4.39 0 0 0 -2.44,1.75 1,1 0 0 0 0.24,1.39 1,1 0 0 0 0.58,0.18 1,1 0 0 0 0.82,-0.42 2.4,2.4 0 0 1 1.15,-0.93 z"
                transform="translate(-1.82,-2.3)"
            />
            <path
                d="m 47.87,21.4 a 1,1 0 0 0 -0.9,-0.26 32.46,32.46 0 0 1 -10.8,0.05 32.42,32.42 0 0 1 -8.73,-3 A 13.56,13.56 0 0 0 27.38,14 L 25.47,3.12 A 1,1 0 0 0 24.9,2.38 1,1 0 0 0 23.96,2.44 32,32 0 0 1 13.83,6.19 32.15,32.15 0 0 1 3,6.14 1,1 0 0 0 1.84,7.29 L 3.75,18.12 C 5.45,27.76 17.47,32.53 18,32.73 a 1.13,1.13 0 0 0 0.36,0.06 1,1 0 0 0 0.66,-0.24 30.61,30.61 0 0 0 3.69,-4 l -0.07,0.42 c -1.7,9.64 8,18.24 8.38,18.6 a 1,1 0 0 0 0.66,0.24 1.13,1.13 0 0 0 0.36,-0.06 c 0.51,-0.2 12.53,-5 14.23,-14.61 L 48.18,22.31 A 1,1 0 0 0 47.87,21.4 Z M 18.14,30.62 C 15.8,29.57 7,25.19 5.72,17.77 L 4.05,8.33 A 34.4,34.4 0 0 0 14.18,8.16 34.33,34.33 0 0 0 23.75,4.85 l 1.66,9.45 C 26.72,21.71 20,28.84 18.14,30.62 Z m 26.14,2.15 C 43,40.19 34.2,44.57 31.86,45.62 30,43.84 23.28,36.71 24.59,29.3 l 0.9,-5.14 A 20.52,20.52 0 0 0 27,20.25 34.23,34.23 0 0 0 35.79,23.16 34.4,34.4 0 0 0 46,23.33 Z"
                transform="translate(-1.82,-2.3)"
            />
        </g>
    </svg>,
    'Production'
);
