import React from 'react';
import GuestEditor from '../../../person/GuestEditor';
import { EMPTY_PERSON_MODAL_STATE } from '../../../shortlists/ShortlistLineItemEditor';
import { constants } from '../../../common/constants';
import { UserProfile } from '../../../common/auth/api';

export const getDefaultOwner = (userProfile : UserProfile) => {
    return {
        ...userProfile.personReference,
        label :  userProfile.name.firstName + ' ' + userProfile.name.lastName
    }
}

export const doSavePerson = (setIsFormSaving, handleChange, guestEditorRef, setPersonModalState) => {
    setIsFormSaving(true);
    guestEditorRef.current.handleSave((input, id) => {
        const person = {
            id,
            label: `${input.name.firstName} ${input.name.lastName}`,
            type: 'Person'
        };
        handleChange(person);
        setPersonModalState(EMPTY_PERSON_MODAL_STATE);
        setIsFormSaving(false);
    });
};

type PersonStateType = {
    open: boolean;
    prepopulate: {
        name: {
            firstName: string;
            lastName: string;
        };
    };
};

type AddProjectOwnerModalType = {
    personModalState: PersonStateType;
    setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    guestEditorRef: React.MutableRefObject<any>;
};

export default ({ personModalState, setIsSaveDisabled, guestEditorRef }: AddProjectOwnerModalType) => {
    return (
        <GuestEditor
            isInModal={true}
            entityIdFromModal={constants.createId}
            setIsSaveDisabled={setIsSaveDisabled}
            ref={guestEditorRef}
            prePopulateName={{
                name: personModalState.prepopulate.name
            }}
            disabledFields={{
                creativeCredits: { department: true, showReference: true },
                productionRole: true,
                experiences: { deparment: true, title: true, organizationReference: true }
            }}
        />
    );
};
