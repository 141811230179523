import { Avatar, SvgIcon, SxProps } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { stringToColor, useStyling } from '../../common/Theme';
import useLoadAttachment from '../../common/components/useLoadAttachment';
import { SvgIconComponent } from '@mui/icons-material';

export const extractInitials = (str: string): string => {
    return isEmpty(str)
        ? ''
        : str.includes(' ')
          ? str
                .split(' ')
                .map((part) => part.substring(0, 1).toUpperCase())
                .join('')
          : str.substring(0, 2).toUpperCase();
};

export enum AvailableSizes {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg'
}

export type AvatarSize = {
    width: string | number;
    height: string | number;
    fontSize: string | number;
};

export type AvatarSizesType = {
    [key in AvailableSizes]: AvatarSize;
};

export const AvatarSizes: AvatarSizesType = {
    xs: { width: 16, height: 16, fontSize: '8px' },
    sm: { width: 24, height: 24, fontSize: '12px' },
    md: { width: 48, height: 48, fontSize: '16px' },
    lg: { width: 64, height: 64, fontSize: '1.5rem' }
};

type RoundAvatarProps = {
    title?: string;
    icon?: SvgIconComponent;
    src?: any;
    bgColor?: string;
    imageId?: string;
    size?: AvatarSize;
    width?: number;
    height?: number;
    numOfLetters?: number;
};

export default ({ imageId, title, size = AvatarSizes.sm, numOfLetters = 2, bgColor }: RoundAvatarProps) => {
    const { theme } = useStyling();
    const hasPicture = !isNil(imageId);
    const bgCol = hasPicture ? 'rgba(0,0,0,0)' : bgColor ?? stringToColor(title);
    const fgCol = theme.palette.getContrastText(bgCol);
    const sx: SxProps = {
        width: size.width,
        height: size.height,
        fontSize: size.fontSize,
        color: fgCol,
        bgcolor: bgCol
    };
    const initials = title ? extractInitials(title).substring(0, numOfLetters) : undefined;
    const { attachment, free } = useLoadAttachment(imageId);
    return (
        <Avatar alt={initials} src={attachment} sx={sx} onLoad={() => free()}>
            {initials}
        </Avatar>
    );
};
