import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { TermInput, TermsetInfoInput } from 'sr-types/lib/metadata/v1/graphql';
import { UserContext } from '../../common/auth/UserContext';
import { enqueueSnackbar } from '../../common/components/Toast';
import { useI18n } from '../../common/i18n/I18n';
import { addTerm, addTermsToTermset, client, saveTermset } from './termset';

export const useTermsetSave = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [save] = useMutation(saveTermset, { client: client, context: { headers: { ownerId: activeOrganizationAccount } } });
    const successMessage = useI18n('termset.save.success');
    const errorMessage = useI18n('termset.save.error');
    //TODO:create interface or type for termsetSave
    const termsetSave = (termset, showMessage = false) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    input: termset
                }
            })
                .then((savedTermsetResponse) => {
                    if (savedTermsetResponse.data.saveTermset.errors) {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(savedTermsetResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(savedTermsetResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };
    return { termsetSave };
};

export const useAddTerm = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [addOneTerm] = useMutation(addTerm, { client: client, context: { headers: { ownerId: activeOrganizationAccount } } });
    const successMessage = useI18n('term.save.success');
    const errorMessage = useI18n('term.save.error');
    //TODO:create interface or type for termsetSave
    const saveOneTerm = (termset, term, showMessage = false) => {
        return new Promise((resolve, reject) => {
            addOneTerm({
                variables: {
                    termset: termset,
                    input: term
                }
            })
                .then((savedTermsetResponse) => {
                    if (savedTermsetResponse?.data?.saveTermset?.errors) {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(savedTermsetResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(savedTermsetResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };
    return { saveOneTerm };
};

export const useAddTerms = () => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const [addTerms] = useMutation(addTermsToTermset, { client: client, context: { headers: { ownerId: activeOrganizationAccount } } });
    const successMessage = useI18n('terms.save.success');
    const errorMessage = useI18n('terms.save.error');

    const saveTerms = (termset: TermsetInfoInput, terms: TermInput[], showMessage = false) => {
        return new Promise((resolve, reject) => {
            addTerms({
                variables: {
                    termset: termset,
                    input: terms
                }
            })
                .then((savedTermsetResponse) => {
                    if (savedTermsetResponse?.data?.addTermsToTermset?.errors) {
                        showMessage &&
                            enqueueSnackbar(errorMessage, {
                                variant: 'error'
                            });
                        reject(savedTermsetResponse);
                    } else {
                        showMessage &&
                            enqueueSnackbar(successMessage, {
                                variant: 'success'
                            });
                        resolve(savedTermsetResponse);
                    }
                })
                .catch((err) => {
                    showMessage &&
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    reject(err);
                });
        });
    };
    return { saveTerms };
};
