import React, { useContext, useState } from 'react';
import FormItem from '../../../common/form/FormItem';
import { FormContainer } from '../../../common/form/FormContainer';
import FormModal from '../../../common/form/FormModal';
import FormPanel from '../../../common/form/FormPanel';
import { Contact } from '../../../common/components/types';
import { cloneDeep, get } from 'lodash';
import FormWidget from '../../../common/form/FormWidget';
import { handleAndCommitChange, hasErrors } from '../../../common/form/forms';
import Validator, { ValidationState } from '../../../common/form/Validator';
import { I18nContext, I18nContextType } from '../../../common/i18n/I18n';

const validationRules = {
    'contactInfo.email.address': [Validator.RULES.email],
    'contactInfo.phone.number': [Validator.RULES.phoneNumber]
};

export default ({ title, isBusy, contact, onClose, onEditContact }) => {
    const [values, setValues] = useState<Contact>(cloneDeep(contact));
    const i18nContext: I18nContextType = useContext(I18nContext);

    const validator = new Validator(validationRules, {
        // If true validator will keep going even after first field fails validation.
        runAll: false,
        i18nContext: i18nContext
    });

    const [valid, setValid] = useState<ValidationState>({
        isValid: false,
        errors: {}
    });

    const onChange = (name, value) => {
        handleAndCommitChange(values, name, value, validator, setValues, setValid);
    };

    return (
        <FormModal
            id={'contact-editor'}
            title={title}
            isOpen={true}
            onClose={onClose}
            isLoading={false}
            isSaving={isBusy}
            isSaveDisabled={isBusy || !valid.isValid}
            onSave={() => onEditContact(cloneDeep(values))}
            expanded={false}
        >
            <FormPanel>
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            name="name.firstName"
                            label="First Name"
                            value={values.name?.firstName}
                            errors={get(valid.errors, 'name.firstName')}
                            hasErrors={hasErrors(valid, 'name.firstName')}
                            onChange={onChange}
                        />
                    </FormItem>
                    <FormItem>
                        <FormWidget
                            name="name.lastName"
                            label="Last Name"
                            value={values.name?.lastName}
                            errors={get(valid.errors, 'name.lastName')}
                            hasErrors={hasErrors(valid, 'name.lastName')}
                            onChange={onChange}
                        />
                    </FormItem>
                    <FormItem>
                        <FormWidget
                            name="contactInfo.email.address"
                            label="Email"
                            value={values.contactInfo?.email?.address}
                            errors={get(valid.errors, 'contactInfo.email.address')}
                            hasErrors={hasErrors(valid, 'contactInfo.email.address')}
                            onChange={onChange}
                        />
                    </FormItem>
                    <FormItem>
                        <FormWidget
                            component="PhoneNumber"
                            name="contactInfo.phone.number"
                            label="Phone"
                            value={values.contactInfo?.phone?.number}
                            errors={get(valid.errors, 'contactInfo.phone.number')}
                            hasErrors={hasErrors(valid, 'contactInfo.phone.number')}
                            onChange={onChange}
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            name="organization"
                            label="Organization"
                            value={values.organization}
                            errors={get(valid.errors, 'organization')}
                            hasErrors={hasErrors(valid, 'organization')}
                            onChange={onChange}
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            allowCreate
                            component="MetadataAutocomplete"
                            metadataName="Role"
                            name={`role`}
                            label="Role"
                            value={values.role}
                            errors={valid.errors.role}
                            hasErrors={hasErrors(valid, `role`)}
                            onChange={onChange}
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            name="production"
                            label="Production"
                            value={values.production}
                            errors={get(valid.errors, 'production')}
                            hasErrors={hasErrors(valid, 'production')}
                            onChange={onChange}
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            allowCreate
                            component="MetadataAutocomplete"
                            metadataName="Role"
                            name={`productionRole`}
                            label="Role"
                            value={values.productionRole}
                            errors={valid.errors.productionRole}
                            hasErrors={hasErrors(valid, `productionRole`)}
                            onChange={onChange}
                        />
                    </FormItem>
                </FormContainer>
            </FormPanel>
        </FormModal>
    );
};
