import React from 'react';
import { createSvgIcon } from '@mui/material';

export const CardList = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5" y="3" width="14" height="4" rx="1" stroke="currentColor" strokeOpacity="1" strokeWidth="1.8" />
        <rect x="5" y="10" width="14" height="4" rx="1" stroke="currentColor" strokeOpacity="1" strokeWidth="1.8" />
        <rect x="5" y="17" width="14" height="4" rx="1" stroke="currentColor" strokeOpacity="1" strokeWidth="1.8" />
    </svg>,
    'CardList'
);
