import {
    ApolloClient,
    createHttpLink,
    DocumentNode,
    gql,
    InMemoryCache,
    useLazyQuery,
    useMutation,
    useQuery
} from '@apollo/client';
import { useContext } from 'react';
import { authLink } from '../auth/api';
import { UserContext } from '../auth/UserContext';
import { constants } from '../constants';
import { searchClient, slimResultsQuery } from '../list/slimQuery';
import { Reference } from 'sr-types/lib/search/v1/graphql';
import { useSearchkitVariables } from '@searchkit/client';
import { remove } from 'lodash';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/note/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const splitNote = (text) => {
    const lines = text.split('\n');
    const first = lines.shift();
    const rest = lines.join('\n');
    return { first, rest };
};

export const saveNote: DocumentNode = gql`
    mutation SaveNote($input: NoteInput!) {
        saveNote(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const removeNote: DocumentNode = gql`
    mutation DeleteNote($id: ID!) {
        deleteNote(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const useGetNote = (linkedEntityId: string, skip: boolean = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: noteData,
        loading: isNoteLoading,
        error: noteFetchError,
        refetch: noteRefetch
    } = useQuery(slimResultsQuery('Note'), {
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        variables: {
            filters: [
                {
                    identifier: 'entity',
                    value: 'Note'
                },
                {
                    identifier: 'entityId',
                    value: linkedEntityId
                }
            ],
            page: {
                from: 0,
                size: 100
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true,
        skip: skip
    });

    return { noteData, isNoteLoading, noteFetchError, noteRefetch };
};

export const useSaveNote = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [saveMutation, { loading: isNoteSaving }] = useMutation(saveNote, { client: client });

    const saveNewNote = ({ input, onSuccess, onError }) =>
        saveMutation({
            variables: {
                input
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
            .then((res) => {
                if (res.data.saveNote.errors) {
                    onError?.();
                } else {
                    onSuccess?.(res.data.saveNote.id);
                }
            })
            .catch(() => {
                onError?.();
            });

    return { saveNewNote, isNoteSaving };
};
