import { Theme } from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { DropzoneArea } from 'mui-file-dropzone';
import React, { useContext, useEffect } from 'react';
import { AttachmentInput } from 'sr-types/lib/production/v1/graphql';
import { convertBytesToMbsOrKbs } from '../../attachments/attachmentsApi';
import { UserContext } from '../../auth/UserContext';
import { enqueueSnackbar } from '../../components/Toast';
import I18n from '../../i18n/I18n';
import { V } from '../../Layout';
import { useStyling } from '../../Theme';
import upload, { UPLOAD_URL } from '../../utils/upload';
import DropzoneInformations from './DropzoneInformations';

type DropzoneProps = {
    onChange: (res, saveFile: AttachmentInput) => void;
    filesLimit?: number;
    initialFiles?: (string | File)[];
    maxFileSize?: number;
    acceptedFiles?: string[];
    saveFiles?: AttachmentInput[];
    dropzoneKey?: number;
    dropzoneRef?: any;
    dropzoneText?: string;
    showPreviews?: boolean;
    showPreviewsInDropzone?: boolean;
    uploadUrl?: string;
    disableDropzone?: boolean;
};

export default (props: DropzoneProps) => {
    const {
        onChange,
        filesLimit = 1,
        initialFiles = undefined,
        maxFileSize = 10 * 1024 * 1024,
        acceptedFiles = undefined,
        saveFiles = [],
        dropzoneKey = 0,
        dropzoneRef = undefined,
        dropzoneText = 'Drag and drop a file here or click',
        showPreviews = false,
        showPreviewsInDropzone = true,
        uploadUrl = undefined,
        disableDropzone = false
    } = props;
    const { activeOrganizationAccount } = useContext(UserContext);
    const { theme } = useStyling();

    const handleToastMessages = (token: string, values, variant: 'info' | 'success' | 'error') => {
        return enqueueSnackbar(<I18n token={token} values={values} />, { variant });
    };

    useEffect(() => {
        const dropFile = () => {
            if (saveFiles.length >= filesLimit) {
                handleToastMessages(
                    'attachment.files.limit.exceeded',
                    {
                        filesLimit: filesLimit
                    },
                    'error'
                );
            }
        };
        document.addEventListener('drop', dropFile);
        return () => {
            document.removeEventListener('drop', dropFile);
        };
    });

    const useDropzoneStyles = (theme: Theme): ClassNameMap<string> =>
        makeStyles({
            dropZone: {
                minHeight: '100px',
                padding: 1
            }
        })();

    const classes = useDropzoneStyles(theme);

    const uploadFiles = (files, promises) => {
        let newSaveFiles = [];
        if (files.length) {
            Promise.all(promises)
                .then((resArray) => {
                    newSaveFiles = resArray
                        .map((res, index) => {
                            if (res.status === 201) {
                                const saveFile = {
                                    name: files[index].name,
                                    type: files[index].type,
                                    size: files[index].size,
                                    entityReference: res.data.reference
                                };
                                onChange(res, saveFile);
                                return saveFile;
                            }
                        })
                        .filter((element) => element);
                })
                .catch((err) => {
                    console.log('upload failed:', err);
                })
                .finally(() => {
                    newSaveFiles.map((file) =>
                        handleToastMessages('attachment.preview.add', { fileName: `${file.name}` }, 'success')
                    );
                });
        }
    };

    return (
        <V gap={1}>
            <DropzoneArea
                key={dropzoneKey}
                ref={dropzoneRef}
                dropzoneClass={classes.dropZone}
                onDelete={(deletedFile) => console.log(deletedFile)}
                showPreviewsInDropzone={showPreviewsInDropzone}
                showPreviews={showPreviews}
                showAlerts={false}
                maxFileSize={maxFileSize}
                acceptedFiles={acceptedFiles}
                filesLimit={filesLimit}
                dropzoneText={dropzoneText}
                initialFiles={initialFiles}
                dropzoneProps={{ disabled: disableDropzone }}
                getFileLimitExceedMessage={(filelimit) => {
                    handleToastMessages(
                        'attachment.files.limit.exceeded.alert',
                        {
                            filesLimit: filesLimit
                        },
                        'error'
                    );
                    return '';
                }}
                onChange={(files) => {
                    if (files) {
                        if (!Array.isArray(files)) {
                            files = [files];
                        }
                        const array = files.filter((file) => {
                            const isFound =
                                saveFiles.length &&
                                saveFiles.some((f) => {
                                    return f.name === file.name;
                                });

                            return file.name && !isFound;
                        });
                        const arrayWithPromises = array.map((filteredFile) => {
                            return upload(activeOrganizationAccount, uploadUrl || UPLOAD_URL, filteredFile);
                        });
                        uploadFiles(array, arrayWithPromises);
                    }
                }}
                onDrop={(droppedFiles) => {
                    droppedFiles.map((file) =>
                        handleToastMessages('attachment.preview.upload', { fileName: `${file.name}` }, 'info')
                    );
                }}
                onDropRejected={(rejectedFiles) => {
                    rejectedFiles.map((file) => {
                        if (file.size > maxFileSize) {
                            handleToastMessages(
                                'attachment.add.large.file.error',
                                {
                                    fileName: file.name,
                                    currentFileSize: convertBytesToMbsOrKbs(file.size),
                                    maxFileSize: convertBytesToMbsOrKbs(maxFileSize)
                                },
                                'error'
                            );
                        }
                        if (!acceptedFiles.includes(file.type)) {
                            handleToastMessages(
                                'attachment.add.unsupported.file',
                                {
                                    fileName: file.name
                                },
                                'error'
                            );
                        }
                    });
                }}
            />
            <DropzoneInformations maxFileSize={maxFileSize} filesLimit={filesLimit} />
        </V>
    );
};
