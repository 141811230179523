import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import React from 'react';
import I18n from '../i18n/I18n';
import useHistory from '../utils/useHistory';
import useDefaultRoute from './useDefaultRoute';

export default () => {
    const { changeRoute } = useHistory();
    const { pathname, orgId } = useDefaultRoute();

    return (
        <Dialog open={true} keepMounted>
            <DialogTitle>
                <I18n token="security.access.denied.title" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <I18n token="security.access.denied" />
                </DialogContentText>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => {
                            changeRoute(pathname, { orgId: orgId });
                        }}
                    >
                        <I18n token="nav.home" />
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};
