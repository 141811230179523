import React from 'react';
import { useStyling } from '../../common/Theme';
import { SxProps, Typography } from '@mui/material';
import I18n from '../../common/i18n/I18n';
import { V } from '../../common/Layout';

type NoContentProps = {
    i18nToken: string;
    onAdd?: React.MouseEventHandler<HTMLButtonElement>;
    containerSx?: SxProps;
    disabled?: boolean;
};

export default (props: NoContentProps) => {
    const { i18nToken, onAdd, containerSx, disabled = false } = props;
    const { theme } = useStyling();

    return (
        <V
            sx={
                containerSx || {
                    g: 1,
                    width: '220px',
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }
            }
        >
            <Typography>
                {!!onAdd && (
                    <Typography
                        component="span"
                        sx={{ color: disabled ? theme.palette.text.disabled : theme.palette.primary.main }}
                        onClick={disabled ? () => {} : onAdd}
                    >
                        <I18n token={i18nToken} />
                    </Typography>
                )}
            </Typography>
        </V>
    );
};
