import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Attachment } from 'sr-types/lib/production/v1/graphql';
import { Icons } from '../icons/Icons';

export const FILE_SIZE_UNITS = {
    mb: 'MB',
    kb: 'KB',
    bytes: 'B'
};

export const mockUpAttachments: Attachment[] = [
    {
        entityReference: {
            id: '6a3a2bd1-94f9-3201-916f-7e0727dd5dcc',
            label: '20191129_214752170_iOS.png'
        },
        name: 'Little Lion',
        size: 2.3,
        type: 'image/jpg',
        createdOn: '2024-01-16T09:42:59Z',
        createdBy: 'Amila Dzambo'
    },
    {
        entityReference: {
            id: 'cbe749eb-b538-3566-8ec6-814e608ee230',
            label: 'poster.jpg'
        },
        name: 'AI Panda',
        size: 3.5,
        type: 'image/jpg',
        createdOn: '2024-08-01T09:42:59Z',
        createdBy: 'Nedim Cholich'
    },
    {
        entityReference: {
            id: 'pdf-file-id',
            label: 'pdf.pdf'
        },
        name: 'Pdf File Preview',
        size: 4,
        type: 'application/pdf',
        createdOn: '2024-03-01T09:42:59Z',
        createdBy: 'Senad Mesetovic'
    },
    {
        entityReference: {
            id: 'zip-file-id',
            label: 'zip.zip'
        },
        name: 'Zip File Preview',
        size: 1.2,
        type: 'application/zip',
        createdOn: '2024-01-17T09:42:59Z',
        createdBy: 'Amina Suljic'
    },
    {
        entityReference: {
            id: 'doc-file-id',
            label: 'zip.zip'
        },
        name: 'Document File Preview',
        size: 3.2,
        type: 'application/doc',
        createdOn: '2024-03-17T09:42:59Z',
        createdBy: 'Maida Hodzic'
    }
];

export const FILE_TYPE_ICONS = {
    pdf: Icons.PdfFile,
    zip: Icons.ZipFolder,
    doc: Icons.Document
};

export const imageExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg'];

export const filesExtensions = ['application/pdf', 'application/zip'];

export const spreadsheetExtensions = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv'
];

export const documentExtensions = [
    'application/msword',
    'application/doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export const powerpointExtensions = [
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint'
];

export const acceptedFilesMock = [
    ...imageExtensions,
    ...filesExtensions,
    ...spreadsheetExtensions,
    ...powerpointExtensions,
    ...documentExtensions
];

const bytesInKiloB = 1024 as const;
const bytesInMegaB = 1048576 as const;

export function convertBytesToMbsOrKbs(filesize: number) {
    let size = '';
    if (filesize >= bytesInMegaB) {
        size = (Math.ceil(filesize / bytesInMegaB) * 10) / 10 + FILE_SIZE_UNITS['mb'];
    } else if (filesize >= bytesInKiloB) {
        size = (Math.ceil(filesize / bytesInKiloB) * 10) / 10 + FILE_SIZE_UNITS['kb'];
    } else {
        size = filesize + FILE_SIZE_UNITS['bytes'];
    }
    return size;
}
