import React, { useMemo } from 'react';
import { Money } from 'sr-types/lib/production/v1/graphql';
import { parseNumber } from './numbers';
import { Typography } from '@mui/material';
import { getBrowserLocale, getL10nDef } from '../i18n/localization';
import { BigNumber, bignumber } from 'mathjs';
import { isNil, isNumber } from 'lodash';

export const NANOS = 1000000000;

export const isMoney = (obj: any) => {
    return !isNil(obj) && obj.hasOwnProperty('currencyCode') && obj.hasOwnProperty('units') && obj.hasOwnProperty('nanos') && isFinite(obj.units) && isFinite(obj.nanos);
};

export const moneyToBigNumber = (money: Money): BigNumber => {
    return bignumber(money.units).add(bignumber(money.nanos).div(NANOS));
};

export const numberToMoney = (num: number | BigNumber, currencyCode: string) => {
    const newValue = bignumber(num);
    const units = newValue.floor().toNumber();
    const nanos = newValue.mod(1).mul(NANOS).floor().toNumber();
    return {
        currencyCode: currencyCode,
        units: units,
        nanos: nanos
    };
};

export const getCurrencyConfig = (locale) => {
    const format = new Intl.NumberFormat(locale, { style: 'currency', currency: 'USD' });
    const parts = format.formatToParts(12345.6);
    const groupSeparator = parts.find((part) => part.type === 'group').value;
    const decimalSeparator = parts.find((part) => part.type === 'decimal').value;
    const isPrefix = parts.findIndex((part) => part.type === 'currency') === 0;
    return { isPrefix, groupSeparator, decimalSeparator };
};

type FormatMoneyOptions = {
    locale?: string;
    showCurrencySymbol?: boolean;
};

export const formatMoney = (money: Money, options: FormatMoneyOptions = {}): string => {
    const { locale = getBrowserLocale(), showCurrencySymbol = false } = options;
    const l10n = getL10nDef(locale);
    const currency = l10n.currencies[money.currencyCode];
    if (isMoney(money) && currency) {
        const val = moneyToBigNumber(money);
        const { parsed, numDecimals } = parseNumber(val.toString());
        if (isNaN(parsed)) {
            return '';
        } else {
            const realMaxDec = numDecimals > currency.decimal_digits ? currency.decimal_digits : numDecimals;
            return new Intl.NumberFormat([locale], {
                style: showCurrencySymbol ? 'currency' : 'decimal',
                currency: money.currencyCode,
                minimumFractionDigits: 0,
                maximumFractionDigits: realMaxDec
            }).format(parsed);
        }
    } else {
        return '';
    }
};

type ParseMoneyOptions = {
    locale: string;
    currencyCode: string;
};

export const parseMoney = (num: string | number, options: ParseMoneyOptions): Money => {
    const { currencyCode, locale } = options;
    const l10n = getL10nDef(locale);
    const currency = l10n.currencies[currencyCode];
    const newValue = typeof num === 'string' ? parseNumber(num, { locale, decimals: currency.decimal_digits }).parsed : num;
    return numberToMoney(newValue, currency.code);
};

export type MoneyLabelProps = {
    money: Money;
    locale?: string;
    showCurrencySymbol?: boolean;
};

export const MoneyLabel = (props: MoneyLabelProps) => {
    const { money, locale = undefined, showCurrencySymbol = true } = props;
    const formatted = useMemo(() => {
        return formatMoney(money, { locale, showCurrencySymbol });
    }, [money]);

    return <Typography>{formatted}</Typography>;
};
