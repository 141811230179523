import React, { useContext, useState } from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import { FormContext } from '../../common/form/FormContext';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import { cloneDeep, findIndex } from 'lodash';
import Validator, { ValidationState } from '../../common/form/Validator';
import { handleAndCommitChange } from '../../common/form/forms';
import { Paper } from '@mui/material';
import I18n, { I18nContext, I18nContextType } from '../../common/i18n/I18n';
import { useIsWithinBreakpoints } from '../../common/hooks/useIsWithinBreakpoints';

const validationRules = {
    name: [Validator.RULES.isRequired]
};

export default ({ pageSize = 10 }) => {
    const { state, setExpandedSections } = useContext(FormContext);
    const [values, setValues] = useState(cloneDeep(state));
    const i18nContext: I18nContextType = useContext(I18nContext);

    const akaIndex = findIndex(state['otherTitles'], { key: 'AKA' });

    const validator = new Validator(validationRules, {
        // If true validator will keep going even after first field fails validation.
        runAll: false,
        i18nContext: i18nContext
    });

    const [valid, setValid] = useState<ValidationState>({
        isValid: false,
        errors: {}
    });

    const onChange = (name, value) => {
        handleAndCommitChange(values, name, value, validator, setValues, setValid);
    };

    const hasErrors = (fieldName) => {
        return Array.isArray(valid.errors[fieldName]) && valid.errors[fieldName].length > 0;
    };
    const isMobileScreen = useIsWithinBreakpoints(['s', 'xs']);
    return (
        <Paper
            elevation={5}
            sx={{
                pt: 1,
                width: '100%',
                px: 2
            }}
            onClick={() => {
                setExpandedSections([]);
            }}
        >
            <FormPanel>
                <FormContainer>
                    <FormItem half>
                        <FormWidget name="name" label={<I18n token="production.form.field.name" />} />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            name={'otherTitles'}
                            label={<I18n token="production.form.field.aka" />}
                            mapTo={'AKA'}
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            component="MetadataAutocomplete"
                            metadataName="ShowType"
                            name="productionType"
                            label={<I18n token="production.form.field.type" />}
                            errors={valid.errors.productionType}
                            hasErrors={hasErrors('productionType')}
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            component="MetadataAutocomplete"
                            metadataName="Genre"
                            name="genre"
                            label={<I18n token="production.form.field.genre" />}
                            errors={valid.errors.genre}
                            hasErrors={hasErrors('genre')}
                            multiple
                        />
                    </FormItem>
                    <FormItem>
                        <FormWidget
                            component={'Number'}
                            hidden={state['productionType'] != 'Series'}
                            name="showAttributes.noOfEpisodes"
                            label={<I18n token="production.form.field.noOfEpisodes" />}
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            component={'Number'}
                            hidden={state['productionType'] != 'Season'}
                            name="showAttributes.seasonNo"
                            label={<I18n token="production.form.field.seasonNo" />}
                        />
                    </FormItem>
                    <FormItem half>
                        <FormWidget
                            component={'Number'}
                            hidden={state['productionType'] != 'Season'}
                            name="showAttributes.noOfEpisodes"
                            label={<I18n token="production.form.field.noOfEpisodes" />}
                        />
                    </FormItem>
                    <FormItem>
                        <FormWidget
                            component={'Number'}
                            hidden={state['productionType'] != 'Episode'}
                            name="showAttributes.episodeNo"
                            label={<I18n token="production.form.field.episodeNo" />}
                        />
                    </FormItem>
                    <FormItem>
                        <FormWidget
                            component="MetadataAutocomplete"
                            metadataName="ProductionStatus"
                            name="productionStatus"
                            label={<I18n token="production.form.field.status" />}
                            errors={valid.errors.productionStatus}
                            hasErrors={hasErrors('productionStatus')}
                            showChips
                        />
                    </FormItem>
                    <FormItem>
                        <FormWidget
                            name="productionGroup"
                            allowFreeForm={true}
                            component="ReferenceAutocomplete"
                            entity="Production"
                            label={<I18n token="production.form.field.associatedTitle" />}
                        />
                    </FormItem>
                </FormContainer>
            </FormPanel>
        </Paper>
    );
};
