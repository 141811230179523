import React, { useMemo } from 'react';
import { Attribute } from '../attributes';
import { LabelValueDisplay } from '../AttributeValue';

type MeasurementAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: MeasurementAttributePreviewProps) => {
    const { attribute } = props;

    const valuesJSON = useMemo(() => {
        return attribute?.value ? JSON.parse(attribute?.value) : {};
    }, [attribute]);

    return (
        attribute && (
            <LabelValueDisplay
                label={attribute.label}
                value={valuesJSON?.value && valuesJSON.unit ? valuesJSON.value + ' ' + valuesJSON.unit : '-'}
            />
        )
    );
};
