import { gql } from '@apollo/client';
import { getTimezone } from './timezone';

export interface Location {
    geoCoords?: GeoCoords;
    address?: Address;
    label: string;
    primary?: boolean;
    timezone?: Timezone;
}

export interface Timezone {
    id: string;
    name: string;
}
export interface GeoCoords {
    lat: number;
    lon: number;
}

export interface Address {
    lines?: string[];
    locality: string;
    adminArea: string;
    postalCode?: string;
    countryRegion: string;
    countryRegionIso2: string;
}

export const AddressFragment = gql`
    fragment AddressFields on Address {
        lines
        locality
        adminArea
        postalCode
        countryRegion
        countryRegionIso2
    }
`;

export const LOCATION_FIELDS = gql`
    fragment LocationFields on Location {
        geoCoords {
            lat
            lon
        }
        address {
            ...AddressFields
        }
        primary
        label
    }
    ${AddressFragment}
`;

export const locationToString = (location: Location, createLabelUsingAddress: boolean = false) => {
    const arr = [];
    if (location) {
        if (location.label && location.label.length && !createLabelUsingAddress) {
            arr.push(location.label);
        } else if (location.address) {
            if (location.address.lines?.length) {
                location.address.lines.forEach((line) => {
                    arr.push(line);
                });
            }
            if (location.address.locality) {
                arr.push(location.address.locality);
            }
            if (location.address.adminArea) {
                arr.push(location.address.adminArea);
            }
            if (location.address.countryRegion) {
                arr.push(location.address.countryRegion);
            }
            if (location.address.postalCode) {
                arr.push(location.address.postalCode);
            }
        }
    }
    return arr.length ? arr.join(', ') : undefined;
};

export const locationLabels = (locations: Location[], noOfLocations = 0) => {
    let locLabels = [];
    if (locations && locations.length) {
        locLabels = locations
            .map((loc, index) => {
                return locationToString(loc);
            })
            .filter((l) => {
                return l !== undefined;
            });
        if (noOfLocations === 0 || locations.length <= noOfLocations) {
            return locLabels;
        } else {
            return locLabels.slice(0, noOfLocations);
        }
    }
    return locLabels;
};

export const getLocation = async (placeDetails, appendTimeZone: boolean = false): Promise<Location> => {
    if (placeDetails) {
        const geoCoords = {
            lat: 0,
            lon: 0
        };
        if (placeDetails.geometry && placeDetails.geometry.location) {
            geoCoords.lat = placeDetails.geometry.location.lat();
            geoCoords.lon = placeDetails.geometry.location.lng();
        }
        const address = {
            lines: [],
            locality: '',
            adminArea: '',
            countryRegion: '',
            countryRegionIso2: '',
            postalCode: ''
        };
        if (placeDetails.address_components && placeDetails.address_components.length > 0) {
            placeDetails.address_components.forEach((add) => {
                if (add.types.includes('premise')) {
                    address.lines.push(add.long_name);
                }
                if (add.types.includes('street_number')) {
                    address.lines.push(add.long_name);
                }
                if (add.types.includes('route')) {
                    address.lines.push(add.long_name);
                }
                if (add.types.includes('sublocality_level_2')) {
                    address.lines.push(add.long_name);
                }
                if (add.types.includes('sublocality_level_1')) {
                    address.lines.push(add.long_name);
                }
                if (add.types.includes('locality')) {
                    address.locality = add.long_name;
                }
                if (add.types.includes('administrative_area_level_2')) {
                    address.adminArea = add.long_name;
                }
                if (add.types.includes('country')) {
                    address.countryRegion = add.long_name;
                    address.countryRegionIso2 = add.short_name;
                }
                if (add.types.includes('postal_code')) {
                    address.postalCode = add.long_name;
                }
            });
        }
        const location = {
            address: address,
            geoCoords: geoCoords,
            label: placeDetails.formatted_address,
            primary: false
        };

        if (appendTimeZone) {
            location['timezone'] = await getTimezone(
                placeDetails.geometry.location.lat(),
                placeDetails.geometry.location.lng()
            );
        }

        return location;
    }
};
