import React, { useEffect, useState } from 'react';
import { Chip, Collapse, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { H, V } from '../Layout';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import EditablePanelActions from './EditablePanelActions';

export default ({
    gap = 0,
    children,
    Header,
    isCollapsable = false,
    sx = {},
    readonly = false,
    commentOnly = false,
    actions = [],
    collapsed = false,
    dataTestId = 'editablePanel'
}) => {
    const [contentCollapsed, setContentCollapsed] = useState(false);

    useEffect(() => {
        setContentCollapsed(collapsed);
    }, [collapsed]);

    return (
        <V sx={sx} data-testid={dataTestId}>
            <H>
                {Header}
                {readonly && (
                    <Tooltip title={<I18n token={'form.read.only.description'} />}>
                        <Chip label={<I18n token={'form.read.only.label'} />} variant={'outlined'} />
                    </Tooltip>
                )}
                {commentOnly && (
                    <Tooltip title={<I18n token={'form.comment.only.description'} />}>
                        <Chip label={<I18n token={'form.comment.only.label'} />} variant={'outlined'} />
                    </Tooltip>
                )}
                <EditablePanelActions actions={actions} />
                {isCollapsable ? (
                    <IconButton
                        size="small"
                        onClick={() => {
                            setContentCollapsed(!contentCollapsed);
                        }}
                    >
                        {contentCollapsed ? <Icons.UnfoldMore /> : <Icons.UnfoldLess />}
                    </IconButton>
                ) : (
                    <React.Fragment />
                )}
            </H>
            <Collapse in={!contentCollapsed} sx={{ width: '100%' }}>
                <H sx={{ p: 1, gap: gap, flexFlow: 'wrap', alignItems: 'flex-start' }}>{children}</H>
            </Collapse>
        </V>
    );
};
