import { Autocomplete, TextField, Typography } from '@mui/material';
import { Filter, useSearchkit } from '@searchkit/client';
import React, { useMemo } from 'react';
import { SkFacetSet, SkFacetSetEntry } from 'sr-types/lib/search/v1/graphql';
import { V } from '../../../common/Layout';
import { useI18n } from '../../../common/i18n/I18n';

export const SingleSelectFacet = ({ facet, requiredFilters }: { facet: SkFacetSet; requiredFilters: string[] }) => {
    const api = useSearchkit();
    const selected = useMemo<SkFacetSetEntry>(() => {
        const filter: Filter[] = api.getFiltersByIdentifier(facet.identifier);
        if (filter && Array.isArray(filter) && filter.length > 0) {
            return facet.entries.find((entry: SkFacetSetEntry) => entry.id.split('_')[1] === filter[0].value);
        } else {
            return null;
        }
    }, [api, facet.entries, facet.identifier]);
    const placeholder = useI18n('filter.search', { label: facet.label });
    const isRequired = requiredFilters.includes(facet.identifier);
    const label = isRequired ? facet.label + ' *' : facet.label;

    return (
        <V>
            <Typography sx={{ fontSize: 'small' }}>{label}</Typography>
            <Autocomplete
                multiple={false}
                fullWidth
                disableCloseOnSelect
                disableClearable={isRequired}
                getOptionLabel={(option: SkFacetSetEntry) => {
                    return option.label || '';
                }}
                isOptionEqualToValue={(option: SkFacetSetEntry, value: SkFacetSetEntry) => {
                    return option?.id === value?.id;
                }}
                options={facet.entries}
                renderOption={(props, option: SkFacetSetEntry, { selected }) => (
                    <li {...props} key={props.id}>
                        {option.label}
                    </li>
                )}
                renderInput={(params) => {
                    return <TextField variant={'standard'} {...params} placeholder={placeholder} />;
                }}
                filterSelectedOptions
                value={selected}
                onChange={(event, f: SkFacetSetEntry) => {
                    const apiFilters = api.getFiltersByIdentifier(facet.identifier);
                    if (f) {
                        if (apiFilters?.length === 0 || apiFilters?.[0].identifier != f?.id) {
                            api.removeFiltersByIdentifier(facet.identifier);
                            api.addFilter({ identifier: facet.identifier, value: f?.id.split('_')[1] });
                            api.search();
                        }
                    } else {
                        api.removeFiltersByIdentifier(facet.identifier);
                        api.search();
                    }
                }}
            />
        </V>
    );
};

SingleSelectFacet.DISPLAY = 'SingleSelectFacet';
