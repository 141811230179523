import { createSvgIcon } from '@mui/material';
import React from 'react';

export const Quote = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.25 2C8.25 1.30964 8.80964 0.75 9.5 0.75H14.5C15.1904 0.75 15.75 1.30964 15.75 2H19.5C20.3284 2 21 2.67157 21 3.5V7.74734L19 7.75V4H15.75C15.75 4.69036 15.1904 5.25 14.5 5.25H9.5C8.80964 5.25 8.25 4.69036 8.25 4H5V21H12V23H4.5C3.67157 23 3 22.3284 3 21.5V3.5C3 2.67157 3.67157 2 4.5 2H8.25ZM9.75 2.25V3.75H14.25V2.25H9.75Z"
            fill="currentColor"
        />
        <path
            d="M14.1904 12.1086C14.1904 12.9429 14.8373 13.5029 16.8462 14.0171C18.8436 14.5429 20.9886 15.4114 21 17.9257C20.9886 19.7657 19.6267 20.76 17.9016 21.0914V23H15.2459V21.0571C13.5435 20.7029 12.1135 19.6057 12 17.6629H13.9521C14.0542 18.7143 14.7692 19.5371 16.5851 19.5371C18.5259 19.5371 18.9685 18.5543 18.9685 17.9486C18.9685 17.1257 18.5259 16.3371 16.3127 15.8114C13.8499 15.2171 12.1589 14.1886 12.1589 12.1429C12.1589 10.4171 13.5322 9.29714 15.2459 8.93143V7H17.8903V8.95429C19.7289 9.41143 20.6595 10.8171 20.7163 12.3486H18.7755C18.7301 11.2286 18.14 10.4743 16.5738 10.4743C15.087 10.4743 14.1904 11.1486 14.1904 12.1086Z"
            fill="currentColor"
        />
    </svg>,
    'Quote'
);
