import { Autocomplete, Checkbox, TextField, Typography } from '@mui/material';
import { useSearchkit } from '@searchkit/client';
import React, { useEffect, useMemo, useState } from 'react';
import { SkFacetSetEntry } from 'sr-types/lib/search/v1/graphql';
import { V } from '../../../common/Layout';
import { useI18n } from '../../../common/i18n/I18n';
import { Icons } from '../../../common/icons/Icons';

const icon = <Icons.CheckboxBlank fontSize="small" />;
const checkedIcon = <Icons.Checkbox fontSize="small" />;

export const CustomComboBoxFacet = ({ facet }) => {
    const api = useSearchkit();
    const options = useMemo(
        () => facet.entries.map((entry: SkFacetSetEntry) => ({ label: entry.label, value: entry.id.split('_')[1] })),
        [facet.entries]
    );
    const [filters, setFilters] = useState(() =>
        api.getFiltersByIdentifier(facet.identifier).map((filter) => ({ label: filter.value }))
    );
    const label = useI18n(facet.label);
    const placeholder = useI18n('filter.search', { label: label });

    useEffect(() => {
        const apiFilters = api.getFiltersByIdentifier(facet.identifier);
        if (apiFilters.length != filters.length) {
            const pageSize = api.getSearchState().page.size;
            api.removeFiltersByIdentifier(facet.identifier);
            filters.forEach((f) => {
                api.addFilter({ identifier: facet.identifier, value: f.label });
            });
            api.setPage({
                from: 0,
                size: pageSize
            });
            api.search();
        }
    }, [api, facet.identifier, filters]);

    return (
        <V>
            <Typography sx={{ fontSize: 'small' }}>{label}</Typography>
            <Autocomplete
                multiple
                fullWidth
                disableCloseOnSelect
                size={'small'}
                isOptionEqualToValue={(option, value) => {
                    return option && value && option.label === value.label;
                }}
                options={[...options]}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={props.id}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.label}
                    </li>
                )}
                renderInput={(params) => <TextField variant={'standard'} {...params} placeholder={placeholder} />}
                filterSelectedOptions
                value={[...filters]}
                onChange={(event, filters) => {
                    setFilters(filters);
                }}
            />
        </V>
    );
};

CustomComboBoxFacet.DISPLAY = 'CustomComboBoxFacet';
