import React from 'react';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';
import PersonSelector from '../../common/components/PersonSelector';

export default () => {
    const filter = {
        expressions: [
            {
                field: 'type',
                value: { values: ['Facility'] }
            }
        ]
    };
    return (
        <FormPanel>
            <FormContainer>
                <FormItem half>
                    <PersonSelector
                        name="person"
                        label="Contact"
                        multiple={false}
                        includePublic={false}
                        autoFocus={false}
                        placeholder={'Contact name and details'}
                        noOptionsText={'Search for contacts by name'}
                        setIsScrollable={(isScrollable) => {
                            console.log('setIsScrollable:', isScrollable);
                        }}
                    />
                </FormItem>
                <FormItem half>
                    <PersonSelector
                        name="personMulti"
                        label="Contact multi"
                        multiple={true}
                        includePublic={false}
                        autoFocus={false}
                        placeholder={'Contact name and details'}
                        noOptionsText={'Search for contacts by name'}
                        setIsScrollable={(isScrollable) => {
                            console.log('setIsScrollable:', isScrollable);
                        }}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="personNew" component="ReferenceAutocomplete" entity="Person" label="Reference Person" />
                </FormItem>
                <FormItem half>
                    <FormWidget multiple name="personNewMulti" component="ReferenceAutocomplete" entity="Person" label="Reference Person multi" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="product" component="ReferenceAutocomplete" entity="Product" label="Reference" />
                </FormItem>
                <FormItem half>
                    <FormWidget multiple name="productMulti" component="ReferenceAutocomplete" entity="Product" label="Reference multi" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget type="chips" name="productChips" filter={filter} component="ReferenceAutocomplete" entity="Product" label="Reference chips" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget type="radio" name="productRadio" filter={filter} component="ReferenceAutocomplete" entity="Product" label="Reference radio" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="location" component="Location" label="Location" required={true}/>
                </FormItem>
                <FormItem half>
                    <FormWidget multiple name="locationMulti" component="Location" label="Location" required={true}/>
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget disabled name="location" component="Location" label="Disabled location" />
                </FormItem>
                <FormItem half>
                    <FormWidget disabled multiple name="locationMulti" component="Location" label="Disabled location multi" />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
