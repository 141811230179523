import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import ResponsiveActions from '../ResponsiveActions';
import { H, V } from '../../Layout';
import { ActionType } from '../ActionType';
import EllipsisText from '../EllipsisText';

export type SidePanelProps = {
    id: string;
    title: string | ReactElement;
    actions: ActionType[];
    children: any;
};

export default (props: SidePanelProps) => {
    const { id, title, actions, children } = props;
    return (
        <V id={id} sx={{ height: '100%', gap: 1, p: 1 }}>
            <H sx={{ px: 1 }}>
                <EllipsisText text={title} sx={{ flexGrow: 2, fontSize: 'medium' }} />
                {actions && actions.length ? <ResponsiveActions actions={actions} isActivated={true} /> : <></>}
            </H>
            {children}
        </V>
    );
};
