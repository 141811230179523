import React, { useContext } from 'react';
import RoundAvatar from '../../production/header/RoundAvatar';
import { useStyling } from '../Theme';
import { UserContext } from '../auth/UserContext';
import { Icons } from '../icons/Icons';

export const getInitials = (name) => {
    return (
        (name.firstName ? name.firstName.substring(0, 1) : '?') + (name.lastName ? name.lastName.substring(0, 1) : '?')
    );
};

export default () => {
    const { userProfile } = useContext(UserContext);

    if (userProfile) {
        const hasPicture = userProfile.profileImageReference && userProfile.profileImageReference.id;

        return userProfile.name ? (
            <RoundAvatar
                title={getInitials(userProfile.name)}
                imageId={hasPicture ? userProfile.profileImageReference?.id : undefined}
                width={24}
                height={24}
            />
        ) : (
            <Icons.AccountCircle />
        );
    } else {
        return <React.Fragment />;
    }
};
