import React, { useState } from 'react';
import { cloneDeep, set } from 'lodash';
import { FormProvider, getKeys, Listener } from '../../common/form/FormContext';
import Validator from '../../common/form/Validator';
import FormSection from '../../common/form/FormSection';
import LabTypes from './LabTypes';
import LabValidation from './LabValidation';
import LabSizes from './LabSizes';
import LabMetadata from './LabMetadata';
import LabReference from './LabReference';
import LabDateTime from './LabDateTime';
import { EMPTY_SCHEDULE } from '../../common/form/widgets/ScheduleWidget';
import LabScheduler from './LabScheduler';
import FormButtons from '../../common/form/FormButtons';
import { ScrollableLayout } from '../../common/Layout';
import { getDateRangeListeners, getDateRangeValidators } from '../../supply/booking/ScheduleCalculator';
import WebReference from '../../common/components/WebReference';

const validationRules = {
    full: [Validator.RULES.isRequired],
    half1: [Validator.RULES.isRequired],
    half2: [],
    quarter1: [Validator.RULES.isRequired],
    quarter2: [],
    quarter3: [Validator.RULES.isRequired],
    quarter4: [],
    textArea: [
        Validator.Custom((prop, state, isModified) => {
            return !isModified || state[prop] === 'Abc123';
        }, 'Must be Abc123')
    ],
    numberInt: [Validator.RULES.isNumber],
    numberDec: [Validator.RULES.isNumber],
    date: [Validator.RULES.isValidDate],
    time: [],
    ...getDateRangeValidators('dateRange'),
    noValidation: [],
    isRequired: [Validator.RULES.isRequired],
    mustBeNumber: [Validator.RULES.isNumber],
    wrongValue: [
        Validator.Custom((prop, state) => {
            return state[prop] !== 'wrong';
        }, 'Must not be "Wrong value"')
    ],
    person: [Validator.RULES.isRequired],
    personMulti: [Validator.RULES.isRequired],
    personNew: [Validator.RULES.isRequired],
    personNewMulti: [Validator.RULES.isRequired],
    status: [Validator.RULES.isRequired],
    product: [Validator.RULES.isRequired],
    productMulti: [Validator.RULES.isRequired],
    webReferenceLink: [Validator.RULES.url]
};

const EMPTY_SIZES = {
    full: '',
    half1: '',
    half2: '',
    quarter1: '',
    quarter2: '',
    quarter3: '',
    quarter4: ''
};

const EMPTY_TYPES = {
    text: '',
    textDisabled: '',
    textArea: '',
    autocomplete: 'one',
    autocompleteFreeForm: '',
    autocompleteMulti: [],
    autocompleteDisabled: 'two',
    mapped: [{ key: 'aka', value: "I'm in a map" }],
    conditional: '',
    numberInt: '',
    numberDec: ''
};

const EMPTY_VALIDATION = {
    noValidation: '',
    isRequired: '',
    mustBeNumber: '',
    wrongValue: 'wrong'
};

const EMPTY_DATETIME = {
    date: '',
    time: ''
};

export const EMPTY_DATERANGE = {
    dateRange: cloneDeep(EMPTY_SCHEDULE)
};

const EMPTY_REFERENCE = {
    person: [
        {
            name: {
                firstName: 'Doralynn',
                lastName: 'Paulack'
            },
            contactInfo: {
                phone: null,
                email: {
                    address: 'dpaulack2t@people.com.cn',
                    typeString: 'Business',
                    verified: false
                }
            },
            organization: 'Jerde LLC',
            role: 'Supervisor',
            production: 'I Melt with You',
            productionRole: 'Supervisor',
            personReference: {
                id: '01GQ6KWYWZRQ74SSR9K0FCJ7HW',
                label: 'Doralynn Paulack',
                type: 'Person',
                uriPrefix: 'people',
                eventId: '',
                deleted: false
            }
        }
    ],
    personMulti: [
        {
            name: {
                firstName: 'Carolyn',
                lastName: 'Johnson'
            },
            contactInfo: {
                phone: null,
                email: {
                    address: 'carolyn_johnson@bigpond.com',
                    typeString: 'Business',
                    verified: false
                }
            },
            organization: 'Carolyn Johnson Films',
            role: '',
            production: 'Life in Colour',
            productionRole: 'Series Producer',
            personReference: {
                id: '01GV97B77WHRFVPMESBHPT0N71',
                label: 'Carolyn Johnson',
                type: 'Person',
                uriPrefix: 'people',
                eventId: '',
                deleted: false
            }
        },
        {
            name: {
                firstName: 'Adam',
                lastName: 'Miller'
            },
            contactInfo: {
                phone: null,
                email: {
                    address: 'amiller@emai.com',
                    typeString: 'Business',
                    verified: false
                }
            },
            organization: 'new adam studio',
            role: 'Executive Producer',
            production: 'Miss Annie Rooney',
            productionRole: 'Executive Producer',
            personReference: {
                id: '01G5GVC43YFNR8VWRSQ47XJSH9',
                label: 'Adam Miller',
                type: 'Person',
                uriPrefix: 'people',
                eventId: '',
                deleted: false
            }
        }
    ],
    personNew: {
        label: 'Amy Weiler',
        id: '01GJCKRQQXACBGQ7EPXK8QZPV2',
        type: 'Person',
        uriPrefix: 'people',
        eventId: '',
        deleted: false
    },
    personNewMulti: [
        {
            label: 'John Brennan',
            id: '01FSBJ2M34612RD6V27KXEBC8Q',
            type: 'Person',
            uriPrefix: 'people',
            eventId: '',
            deleted: false
        },
        {
            label: 'John Doe',
            id: '01GM5CK29N86GGZ5EGQ91GDJTQ',
            type: 'Person',
            uriPrefix: 'people',
            eventId: '',
            deleted: false
        }
    ],
    product: {
        id: '01G549Q044SQQ1N37RYDMW3XEX',
        label: 'Civil War Stage',
        type: 'Product',
        uriPrefix: 'products',
        eventId: '',
        deleted: false
    },
    productMulti: [
        {
            id: '01G3VMFYSJ198DQ8SPBGJQ4XGW',
            label: 'Executive Demo',
            type: 'Product',
            uriPrefix: 'products',
            eventId: '',
            deleted: false
        },
        {
            id: '01GBZBA0XRMXF0VF784TAKX42J',
            label: 'VP Demo',
            type: 'Product',
            uriPrefix: 'products',
            eventId: '',
            deleted: false
        }
    ],
    productChips: {
        id: '01GBDDFEYWWS0TFSDM5D69YEBH',
        label: 'Edit Room',
        type: 'Product',
        uriPrefix: 'products',
        eventId: '',
        deleted: false
    },
    productRadio: {
        id: '01G549RYM8VWZKQ5TG06V5A5YH',
        label: 'Infinity Stage',
        type: 'Product',
        uriPrefix: 'products',
        eventId: '',
        deleted: false
    },
    location: {
        address: {
            locality: 'New York',
            adminArea: 'New York County',
            countryRegion: 'United States',
            countryRegionIso2: 'US',
            postalCode: ''
        },
        geoCoords: {
            lat: 40.7313474,
            lon: -73.9825664
        },
        label: '14th Street, New York, NY, USA',
        primary: true
    },
    locationMulti: [
        {
            address: {
                locality: 'New York',
                adminArea: 'New York County',
                countryRegion: 'United States',
                countryRegionIso2: 'US',
                postalCode: ''
            },
            geoCoords: {
                lat: 40.7313474,
                lon: -73.9825664
            },
            label: '14th Street, New York, NY, USA',
            primary: true
        },
        {
            address: {
                locality: 'Washington',
                adminArea: 'District of Columbia',
                countryRegion: 'United States',
                countryRegionIso2: 'US',
                postalCode: ''
            },
            geoCoords: {
                lat: 38.938678,
                lon: -77.0327217
            },
            label: '14th Street Northwest, Washington, DC, USA',
            primary: false
        }
    ]
};

const EMPTY_METADATA = {
    status: '',
    statusMulti: [],
    statusChips: '',
    statusRadio: ''
};

const EMPTY_WEB_REFERENCE = {
    webReferenceLink: undefined
};

export default () => {
    const [values, setValues] = useState({
        ...cloneDeep(EMPTY_SIZES),
        ...cloneDeep(EMPTY_TYPES),
        ...cloneDeep(EMPTY_VALIDATION),
        ...cloneDeep(EMPTY_DATETIME),
        ...cloneDeep(EMPTY_DATERANGE),
        ...cloneDeep(EMPTY_REFERENCE),
        ...cloneDeep(EMPTY_METADATA),
        ...cloneDeep(EMPTY_WEB_REFERENCE),
        transient: {
            scheduleDisabled: false,
            showFullSchedule: false,
            disableWeekends: false
        }
    });
    const [isSaving, setIsSaving] = useState(false);

    const listeners: Listener[] = [
        {
            prop: 'text',
            func: (prop, value, newState) => {
                // Functions can also be asynchronous.
                return new Promise((resolve /*, reject*/) => {
                    set(newState, 'textDisabled', value.toUpperCase());
                    resolve(true);
                });
            }
        },
        ...getDateRangeListeners('dateRange')
    ];
    return (
        <FormProvider
            validationRules={validationRules}
            state={values}
            setState={setValues}
            listeners={listeners}
            expandedSections={[]}
            allowMultipleExpansion
        >
            <ScrollableLayout preview={values}>
                <FormButtons
                    isSaving={isSaving}
                    onClickCancel={() => {}}
                    onClickSave={() => {
                        // Saving simulation.
                        setIsSaving(true);
                        setTimeout(() => {
                            setIsSaving(false);
                        }, 2000);
                    }}
                />
                <FormSection
                    id="lab-sizes"
                    title={'Field sizes'}
                    fields={getKeys(EMPTY_SIZES)}
                    Component={LabSizes}
                    renderCollapsed={false}
                    data-testid={'sizes'}
                />
                <FormSection
                    id="lab-types"
                    title={'Types'}
                    fields={getKeys(EMPTY_TYPES)}
                    Component={LabTypes}
                    renderCollapsed={false}
                    data-testid={'types'}
                />
                <FormSection
                    id="lab-validation"
                    title={'Validation'}
                    fields={getKeys(EMPTY_VALIDATION)}
                    Component={LabValidation}
                    renderCollapsed={false}
                    data-testid={'validation'}
                />
                <FormSection
                    id="lab-datetime"
                    title={'Date/Time'}
                    fields={getKeys(EMPTY_DATETIME)}
                    Component={LabDateTime}
                    renderCollapsed={false}
                    data-testid={'dateRange'}
                />
                <FormSection
                    id="lab-scheduler"
                    title={'Scheduler'}
                    fields={getKeys(EMPTY_DATERANGE)}
                    Component={LabScheduler}
                    renderCollapsed={false}
                    data-testid={'scheduler'}
                />
                <FormSection
                    id="lab-reference"
                    title={'Reference'}
                    fields={Object.keys(EMPTY_REFERENCE)}
                    Component={LabReference}
                    renderCollapsed={false}
                    data-testid={'reference'}
                />
                <FormSection
                    id="lab-metadata"
                    title={'Metadata'}
                    fields={getKeys(EMPTY_METADATA)}
                    Component={LabMetadata}
                    renderCollapsed={false}
                    data-testid={'metadata'}
                />
                <FormSection
                    id="lab-webReference"
                    title={'Web Reference'}
                    fields={getKeys(EMPTY_WEB_REFERENCE)}
                    Component={WebReference}
                    renderCollapsed={false}
                    data-testid={'webReference'}
                />
            </ScrollableLayout>
        </FormProvider>
    );
};
