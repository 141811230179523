import { atom } from 'recoil';

const localStorageEffect =
    (key: string) =>
    ({ setSelf, onSet }) => {
        const savedValue = localStorage.getItem(key);
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue, _, isReset) => {
            isReset ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(newValue));
        });
    };

export const isDarkModeAtom = atom({
    key: 'isDarkModeAtom',
    default: window.matchMedia('(prefers-color-scheme: dark)').matches,
    effects: [localStorageEffect('isDarkMode')]
});
