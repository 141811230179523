import React from 'react';
import { Attribute } from './../attributes';
import I18n from '../../common/i18n/I18n';
import { LabelValueDisplay } from '../AttributeValue';

type BooleanAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: BooleanAttributePreviewProps) => {
    const { attribute } = props;
    return (
        attribute && (
            <LabelValueDisplay
                label={attribute.label}
                value={<I18n token={`attributes.boolean.${attribute.value ? 'true' : 'false'}`} />}
            />
        )
    );
};
