import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchkit } from '@searchkit/client';
import { TextField, Typography } from '@mui/material';
import { V } from '../../../common/Layout';
import I18n, { useI18n } from '../../../common/i18n/I18n';
import { debounce } from 'lodash';

export const TextFacet = ({ facet }) => {
    const api = useSearchkit();
    const ref = useRef<any>();
    const filterValue = useMemo(() => {
        return api
            .getFiltersByIdentifier(facet.identifier)
            .map((filter) => filter.value)
            .toString();
    }, [api, facet.identifier]);
    const label = useI18n(facet.label);
    const placeholder = useI18n('filter.search.label') + ' ' + label;

    useEffect(() => {
        if (ref && ref.current && JSON.parse(sessionStorage.getItem('TextFacetActive') || 'false')) {
            ref.current.focus();
        }
    }, [filterValue]);

    const search = (filterValue) => {
        api.removeFiltersByIdentifier(facet.identifier);
        if (filterValue != '') api.addFilter({ identifier: facet.identifier, value: filterValue });
        api.search();
    };

    const debouncedOnChange = debounce(search, 1000);

    const onChange = (event) => {
        debouncedOnChange(event.target.value);
        sessionStorage.setItem('TextFacetActive', 'true');
    };

    return (
        <V>
            <Typography sx={{ fontSize: 'small' }}>{<I18n token={facet.label}></I18n>}</Typography>
            <TextField
                inputRef={ref}
                id="text-facet"
                key={'textFacet'}
                placeholder={placeholder}
                variant="standard"
                fullWidth
                defaultValue={filterValue}
                onChange={onChange}
                onBlur={() => {
                    sessionStorage.setItem('TextFacetActive', 'false');
                }}
            />
        </V>
    );
};

TextFacet.DISPLAY = 'TextFacet';
