import React from 'react';
import { H } from '../../common/Layout';
import EllipsisText from '../../common/components/EllipsisText';
import NoContent from './NoContent';

export default ({ description }) => {
    return (
        <H>
            <EllipsisText text={description} sx={{ fontSize: '12px' }} />
        </H>
    );
};
