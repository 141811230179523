import { LoadingButton } from '@mui/lab';
import { Container, Paper } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import I18n from '../i18n/I18n';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import SessionRecording from '../nav/SessionRecording';
import { AuthFormHeader } from './AccountLinks';
import { useLocation } from 'react-router-dom';
import PolicyLink from './PolicyLink';
import { V } from '../Layout';

export default ({ title, values, valid, onChange, hasErrors, keyboardEventHandler, isBusy, onContinue }) => {
    const location = useLocation();
    const email = location.state?.email || '';
    const username = location.state?.username || '';

    return (
        <Container key="login" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
            <V
                fill
                sx={{
                    gap: '8px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Paper
                    sx={{
                        maxWidth: '400px',
                        p: 4,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <FormContainer>
                        <FormItem>
                            <AuthFormHeader title={email ? <I18n token="dialog.createAccount" /> : title} />
                        </FormItem>
                        <FormItem>
                            <FormWidget
                                name="username"
                                label={<I18n token="account.form.email" />}
                                value={values.username}
                                errors={valid?.errors?.username}
                                hasErrors={hasErrors('username')}
                                onChange={onChange}
                                onKeyUp={keyboardEventHandler}
                                disabled={!!email || !!username}
                            />
                        </FormItem>
                        <FormItem>
                            <LoadingButton
                                sx={{ width: '100%' }}
                                variant={'contained'}
                                loading={isBusy}
                                id="continueButton"
                                onClick={onContinue}
                                disabled={!valid?.isValid || isBusy}
                            >
                                <I18n token={'dialog.continue'} />
                            </LoadingButton>
                        </FormItem>
                        <SessionRecording />
                    </FormContainer>
                </Paper>
                <PolicyLink />
            </V>
        </Container>
    );
};
