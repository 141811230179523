import { LoadingButton } from '@mui/lab';
import React, { useRef, useState } from 'react';
import { AttachmentInput } from 'sr-types/lib/production/v1/graphql';
import { Reference } from 'sr-types/lib/search/v1/graphql';
import { enqueueSnackbar } from '../components/Toast';
import FormItem from '../form/FormItem';
import FormPanel from '../form/FormPanel';
import FormWidget from '../form/FormWidget';
import I18n from '../i18n/I18n';
import { H, V } from '../Layout';
import AttachmentsPreview from './AttachmentsPreview';

type AttachmentsEditorProps = {
    entityReference: Reference;
    maxFileSize?: number;
    acceptedFiles?: Array<string>;
    filesLimit?: number;
    insideSidePanel?: boolean;
    onSave: (entityId: string, input: AttachmentInput[]) => Promise<any>;
    disableDropzone?: boolean;
};

export default (props: AttachmentsEditorProps) => {
    const { entityReference, maxFileSize, acceptedFiles, filesLimit, insideSidePanel, onSave, disableDropzone } = props;

    const [saveFiles, setSaveFiles] = useState<Array<AttachmentInput>>([]);
    const [customDeleteFromPreview, setCustomDeleteFromPreview] = useState(false);

    const onChange = (saveFile) => {
        setSaveFiles((oldFiles) => [...oldFiles, saveFile]);
    };

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const dropzoneRef = useRef(null);
    const [dropzoneKey, setDropzoneKey] = useState(0);

    const saveAttachment = () => {
        onSave(entityReference.id, saveFiles).finally(() => {
            setSaveFiles([]);
            setDropzoneKey((previous) => previous + 1);
            setIsSaving(false);
        });
    };

    const onDeleteFromPreview = (file, index) => {
        dropzoneRef?.current.deleteFile(file, index);
        const newArray = saveFiles.filter((f) => f.name !== file.name);
        setSaveFiles(newArray);
        enqueueSnackbar(<I18n token="attachment.preview.remove" values={{ fileName: `${file.name}` }} />, {
            variant: 'success'
        });
    };

    return (
        <FormPanel sx={{ p: 0 }}>
            <FormItem quarter gap={0} sx={{ p: 0, minHeigth: '100px' }}>
                <FormWidget
                    dropzoneRef={dropzoneRef}
                    dropzoneKey={dropzoneKey}
                    name="attachments"
                    component="Dropzone"
                    onChange={onChange}
                    entityReference={entityReference}
                    insideSidePanel={insideSidePanel}
                    maxFileSize={maxFileSize}
                    acceptedFiles={acceptedFiles}
                    filesLimit={filesLimit}
                    showPreviewsInDropzone={false}
                    customDeleteFromPreview={customDeleteFromPreview}
                    saveFiles={saveFiles}
                    attachmentsToEntity={true}
                    disableDropzone={disableDropzone}
                />
            </FormItem>
            <V>
                <AttachmentsPreview saveFiles={saveFiles} onDelete={onDeleteFromPreview} />
                <H sx={{ justifyContent: 'flex-end' }}>
                    <LoadingButton
                        onClick={() => {
                            setIsSaving(true);
                            saveAttachment();
                        }}
                        disabled={!saveFiles.length}
                        loading={isSaving}
                    >
                        <I18n token="form.button.save" />
                    </LoadingButton>
                </H>
            </V>
        </FormPanel>
    );
};
