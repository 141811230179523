import { Typography } from '@mui/material';
import React from 'react';
import { V } from '../../common/Layout';
import TypePreview from '../TypePreview';
import { useStyling } from '../../common/Theme';
import { Field } from '../attributes';
import EllipsisText from '../../common/components/EllipsisText';

type TextAttributePreviewProps = {
    field: Field;
    onClick?: (field) => void;
};

export default (props: TextAttributePreviewProps) => {
    const { field, onClick } = props;
    const { theme } = useStyling();

    return (
        <V onClick={onClick}>
            <TypePreview type={field.fieldType} />
            <Typography>{field.label}</Typography>
               <EllipsisText text={field.description} sx={{fontSize:"smaller", color:theme.palette.text.disabled}} />
        </V>
    );
};
