import { blue, green, grey } from '@mui/material/colors';
import { find } from 'lodash';
import React, { ReactElement } from 'react';
import { Icons } from '../icons/Icons';

export type AppDefinition = {
    id: string;
    name: string;
    home: string;
    icon?: ReactElement;
};

export const PROFILES: AppDefinition = {
    id: 'profiles',
    name: 'Profiles',
    home: '/profiles'
};
export const ADMINISTRATION: AppDefinition = {
    id: 'admin',
    name: 'Administration',
    home: '/admin',
    icon: <Icons.Account sx={{ color: grey.A400 }} />
};
export const SUPPLIER: AppDefinition = {
    id: 'supplier',
    name: 'Reservation Management',
    home: '/supplier',
    icon: <Icons.EventAvailable sx={{ color: green.A400 }} />
};
export const MARKETPLACE: AppDefinition = {
    id: 'marketplace',
    name: 'Resource Marketplace',
    home: '/marketplace',
    icon: <Icons.Store sx={{ color: blue.A400 }} />
};
export const PLANNING: AppDefinition = {
    id: 'planning',
    name: 'Production Planning',
    home: '/planning',
    icon: <Icons.Assignment sx={{ color: blue.A400 }} />
};
export const GUEST: AppDefinition = {
    id: 'guest',
    name: 'Guest',
    home: '/guest/leads',
    icon: <Icons.Assignment sx={{ color: blue.A400 }} />
};

export const APPLICATIONS: AppDefinition[] = [PROFILES, ADMINISTRATION, SUPPLIER, MARKETPLACE, PLANNING, GUEST];

export const getApplicationById = (id: string): AppDefinition => {
    return find(APPLICATIONS, (app) => app.id === id);
};

export const getApplicationByName = (name: string): AppDefinition => {
    return find(APPLICATIONS, (app) => app.name === name);
};
