import { Avatar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { isNil } from 'lodash';
import React from 'react';
import { Icons } from '../common/icons/Icons';
import { H, V } from '../common/Layout';

export default ({ option }) => {
    const createOption = option && option.createOption;
    const label = createOption ? `Add ${option.label}` : option.label;

    return (
        <H>
            <Avatar sx={{ bgcolor: grey[500], color: 'white' }} aria-label="recipe">
                <Icons.Business />
            </Avatar>
            <V sx={{ width: '100%', ml: 2 }}>
                <Typography sx={{ fontStyle: createOption ? 'italic' : 'normal' }}>{label}</Typography>
                <Typography>{!isNil(option.organizationTypes) && option.organizationTypes.map((organizationType) => organizationType).join(', ')}</Typography>
                <Typography>{option.parentOrganizationName}</Typography>
            </V>
        </H>
    );
};
