import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Booking = createSvgIcon(
    <>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 3.90909H20C21.1 3.90909 22 4.76818 22 5.81818V10.5909H4V21.0909H16V23H4C2.9 23 2 22.1409 2 21.0909V5.81818C2 4.76818 2.9 3.90909 4 3.90909H5V2H7V3.90909H17V2H19V3.90909ZM4 8.68182H20V5.81818H4V8.68182Z"
            fill="inherit"
        />
        <path
            d="M20.2299 17.1083C18.8919 16.7642 18.4617 16.4083 18.4617 15.8542C18.4617 15.2183 19.057 14.775 20.053 14.775C21.1022 14.775 21.4912 15.2708 21.5265 16H22.8291C22.7878 14.9967 22.169 14.075 20.9371 13.7775V12.5H19.169V13.76C18.0255 14.005 17.1061 14.74 17.1061 15.8658C17.1061 17.2133 18.2318 17.8842 19.8762 18.275C21.3497 18.625 21.6444 19.1383 21.6444 19.6808C21.6444 20.0833 21.3556 20.725 20.053 20.725C18.8389 20.725 18.3615 20.1883 18.2967 19.5H17C17.0707 20.7775 18.0373 21.495 19.169 21.7342V23H20.9371V21.7458C22.0864 21.53 23 20.8708 23 19.675C23 18.0183 21.5678 17.4525 20.2299 17.1083Z"
            fill="inherit"
        />
    </>,
    'Booking'
);
