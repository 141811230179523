import { Localization, deDE as coreDeDE, enUS as coreEnUS, frFR as coreFrFR, hiIN as coreHiIN, zhCN as coreZhCN } from '@mui/material/locale';
import { deDE as gridDeDE, enUS as gridEnUS, frFR as gridFrFR, zhCN as gridZnCH } from '@mui/x-data-grid';
import { Localization as GridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';
import { deDE as pickersDeDE, enUS as pickersEnUS, frFR as pickersFrFR, zhCN as pickersZhCN } from '@mui/x-date-pickers/locales';
import CurrencyList from 'currency-list';
import {
    de as dateFnsDeDE,
    enCA as dateFnsEnCA,
    enGB as dateFnsEnGB,
    enUS as dateFnsEnUS,
    fr as dateFnsFr,
    frCA as dateFnsFrCA,
    hi as dateFnsIn,
    zhCN as dateFnsZhCN
} from 'date-fns/locale';

const DEFAULT_LOCALE = 'en-US';

export type CurrencyDef = {
    name: string;
    symbol_native: string;
    symbol: string;
    code: string;
    name_plural: string;
    rounding: number;
    decimal_digits: number;
};

export type CurrencyMap = {
    [code: string]: CurrencyDef;
};

export type L10nDefType = {
    label: string;
    locale: string;
    dateFns: Locale;
    muiCore: Localization;
    muiPickers: any;
    muiGrid: GridLocalization;
    currencies: CurrencyMap;
    appBundle: object;
    defaultCurrency: string;
};

export type SupportedL10nType = {
    [key in string]: L10nDefType;
};

export const supportedL10n: SupportedL10nType = {
    'en-US': {
        label: 'English - US',
        locale: 'en-US',
        dateFns: dateFnsEnUS,
        muiCore: coreEnUS,
        muiPickers: pickersEnUS,
        muiGrid: gridEnUS,
        currencies: CurrencyList.currencyList.default.en_US as CurrencyMap,
        appBundle: require('./bundles/en-US.json').default,
        defaultCurrency: 'USD'
    },
    'en-GB': {
        label: 'English - UK',
        locale: 'en-GB',
        dateFns: dateFnsEnGB,
        muiCore: coreEnUS,
        muiPickers: pickersEnUS,
        muiGrid: gridEnUS,
        currencies: CurrencyList.currencyList.default.en_GB as CurrencyMap,
        appBundle: require('./bundles/en-GB.json').default,
        defaultCurrency: 'GBP'
    },
    'en-CA': {
        label: 'English - Canada',
        locale: 'en-CA',
        dateFns: dateFnsEnCA,
        muiCore: coreEnUS,
        muiPickers: pickersEnUS,
        muiGrid: gridEnUS,
        currencies: CurrencyList.currencyList.default.en_CA as CurrencyMap,
        appBundle: require('./bundles/en-CA.json').default,
        defaultCurrency: 'CAD'
    },
    'fr-FR': {
        label: 'French - France',
        locale: 'fr-FR',
        dateFns: dateFnsFr,
        muiCore: coreFrFR,
        muiPickers: pickersFrFR,
        muiGrid: gridFrFR,
        currencies: CurrencyList.currencyList.default.fr_FR as CurrencyMap,
        appBundle: require('./bundles/fr-FR.json').default,
        defaultCurrency: 'EUR'
    },
    'fr-CA': {
        label: 'French - Canada',
        locale: 'fr-CA',
        dateFns: dateFnsFrCA,
        muiCore: coreFrFR,
        muiPickers: pickersFrFR,
        muiGrid: gridFrFR,
        currencies: CurrencyList.currencyList.default.fr_CA as CurrencyMap,
        appBundle: require('./bundles/fr-CA.json').default,
        defaultCurrency: 'CAD'
    },
    'de-DE': {
        label: 'German - Germany',
        locale: 'de-DE',
        dateFns: dateFnsDeDE,
        muiCore: coreDeDE,
        muiPickers: pickersDeDE,
        muiGrid: gridDeDE,
        currencies: CurrencyList.currencyList.default.de_DE as CurrencyMap,
        appBundle: require('./bundles/de-DE.json').default,
        defaultCurrency: 'EUR'
    },
    'zh-CN': {
        label: 'Simplified Chinese - China',
        locale: 'zh-CN',
        dateFns: dateFnsZhCN,
        muiCore: coreZhCN,
        muiPickers: pickersZhCN,
        muiGrid: gridZnCH,
        currencies: CurrencyList.currencyList.default.zh_CN as CurrencyMap,
        appBundle: require('./bundles/zh-CN.json').default,
        defaultCurrency: 'CNY'
    },
    'hi-IN': {
        label: 'Hindi - India',
        locale: 'hi-IN',
        dateFns: dateFnsIn,
        muiCore: coreHiIN,
        muiPickers: pickersEnUS,
        muiGrid: gridEnUS,
        currencies: CurrencyList.currencyList.default.hi_IN as CurrencyMap,
        appBundle: require('./bundles/hi-IN.json').default,
        defaultCurrency: 'INR'
    }
};

const LOCALE_REPLACE = {
    fr: 'fr-FR',
    en: 'en-US',
    de: 'de-DE',
    zh: 'zh-CN'
};

/**
 * Fetch browser's locale and if locality is ambiguous replace with specific one, i.e. fr -> fr_FR
 */
export const getBrowserLocale = (): string => {
    const browser = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
    return LOCALE_REPLACE[browser] || browser;
};

export const getL10nDef = (locale = undefined): L10nDefType => {
    const loc = locale || getBrowserLocale();
    return supportedL10n.hasOwnProperty(loc) ? supportedL10n[loc] : supportedL10n[DEFAULT_LOCALE];
};
