import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { H } from '../Layout';
import { useStyling } from '../Theme';
import { UserContext } from '../auth/UserContext';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';

export const MY_COLUMNS = 'my-columns';
export const DEFAULT_COLUMNS = 'default-columns';
export const ALL_COLUMNS = 'all-columns';

export type ColumnVisibilityPreset = {
    label: string;
    icon: typeof SvgIcon;
    hidden: string[];
};

export const useColumnVisibility = (
    id: string,
    defaultColumnVisibility: GridColumnVisibilityModel,
    columnVisibilityPresets: ColumnVisibilityPreset[]
) => {
    const { getPreference, savePreference, preferencesLoaded } = useContext(UserContext);

    const customColumnVisibility = useMemo(() => {
        if (preferencesLoaded) {
            return getPreference(id, MY_COLUMNS);
        }
    }, [getPreference, id, preferencesLoaded]);

    const [selectedPreset, setSelectedPreset] = useState<string>(
        isEmpty(customColumnVisibility) ? ALL_COLUMNS : MY_COLUMNS
    );

    const [columnVisibiltyModel, setColumnVisibiltyModel] = useState<GridColumnVisibilityModel>(
        cloneDeep(isEmpty(customColumnVisibility) ? defaultColumnVisibility : customColumnVisibility)
    );

    const component = useMemo(
        () => (
            <ColumnVisibilitySelector
                id={id}
                key={'presets'}
                defaultColumnVisibility={defaultColumnVisibility}
                customColumnVisibility={customColumnVisibility}
                presets={columnVisibilityPresets}
                selectedPreset={selectedPreset}
                onPresetSelected={(label, model) => {
                    setSelectedPreset(label);
                    setColumnVisibiltyModel(model);
                }}
            />
        ),
        [id, , defaultColumnVisibility, columnVisibilityPresets, selectedPreset]
    );

    return {
        selectPreset: (label: string, model: GridColumnVisibilityModel) => {
            setSelectedPreset(label);
            setColumnVisibiltyModel(model);
            savePreference(id, MY_COLUMNS, JSON.stringify(model));
        },
        selectedPreset,
        columnVisibiltyModel,
        ColumnVisibilitySelector: component
    };
};

const ColumnVisibilitySelector = ({
    id,
    defaultColumnVisibility,
    customColumnVisibility,
    presets,
    selectedPreset,
    onPresetSelected
}: {
    id: string;
    defaultColumnVisibility: GridColumnVisibilityModel;
    customColumnVisibility: GridColumnVisibilityModel;
    presets: ColumnVisibilityPreset[];
    selectedPreset: string;
    onPresetSelected: (label: string, hidden: GridColumnVisibilityModel) => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { theme, isMobile } = useStyling();
    const labelToken = 'grid.column.presets.label';

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    return (
        <H className="_presetsMenu" sx={{ ml: 1, gap: 1, width: 'unset' }}>
            {!isMobile ? (
                <Button
                    sx={{
                        whiteSpace: 'nowrap',
                        color: theme.palette.primary.main
                    }}
                    onClick={onClick}
                    variant={'text'}
                    startIcon={<Icons.ListViewTable />}
                >
                    <I18n token={labelToken} />
                </Button>
            ) : (
                <IconButton
                    sx={{
                        color: theme.palette.primary.main,
                        px: 0.5,
                        py: 0
                    }}
                    onClick={onClick}
                >
                    <Icons.Tune />
                </IconButton>
            )}

            {anchorEl && (
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    id={'col-visibility-menu'}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    open={true}
                    onClose={onClose}
                >
                    {isEmpty(defaultColumnVisibility) ? (
                        <MenuItem
                            key={'all'}
                            selected={selectedPreset === ALL_COLUMNS}
                            onClick={() => {
                                onPresetSelected(ALL_COLUMNS, {});
                                onClose();
                            }}
                        >
                            <ListItemIcon>{<Icons.AllColumns />}</ListItemIcon>
                            <ListItemText>
                                <I18n token="grid.column.presets.all" />
                            </ListItemText>
                        </MenuItem>
                    ) : (
                        <MenuItem
                            key={'default'}
                            selected={selectedPreset === DEFAULT_COLUMNS}
                            onClick={() => {
                                onPresetSelected(DEFAULT_COLUMNS, defaultColumnVisibility);
                                onClose();
                            }}
                        >
                            <ListItemIcon>{<Icons.DefaultColumns />}</ListItemIcon>
                            <ListItemText>
                                <I18n token="grid.column.presets.default" />
                            </ListItemText>
                        </MenuItem>
                    )}
                    {presets?.map((preset: ColumnVisibilityPreset, index) => (
                        <MenuItem
                            key={index}
                            selected={selectedPreset === preset.label}
                            onClick={() => {
                                const newModel: GridColumnVisibilityModel = {};
                                preset.hidden.forEach((colName) => {
                                    newModel[colName] = false;
                                });
                                onPresetSelected(preset.label, newModel);
                                onClose();
                            }}
                        >
                            <ListItemIcon>{preset.icon ? <preset.icon /> : <></>}</ListItemIcon>
                            <ListItemText>{preset.label}</ListItemText>
                        </MenuItem>
                    ))}
                    {customColumnVisibility && (
                        <MenuItem
                            key={'custom'}
                            selected={selectedPreset === MY_COLUMNS}
                            onClick={() => {
                                onPresetSelected(MY_COLUMNS, customColumnVisibility);
                                onClose();
                            }}
                        >
                            <ListItemIcon>{<Icons.Star />}</ListItemIcon>
                            <ListItemText>
                                <I18n token="grid.column.presets.my" />
                            </ListItemText>
                        </MenuItem>
                    )}
                </Menu>
            )}
        </H>
    );
};
