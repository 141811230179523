import { enqueueSnackbar } from '../../common/components/Toast';
import { rowsToProduction } from './production';

export const doSave = (
    setIsSaving,
    rows,
    isAltColDef,
    altColDefs,
    colDefs,
    defaultColumns,
    setErrors,
    onClose,
    onSave,
    bulkSave,
    activeOrganizationAccount,
    setShowImportDetails,
    setShowImportDetailsDialog
) => {
    setIsSaving(true);
    const newProductions = rowsToProduction(rows, colDefs);
    bulkSave({
        variables: {
            input: {
                records: newProductions,
                saveLogFile: true
            }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    }).then((res) => {
        let hasErrors = false;
        const newErrors = {};
        res.data.bulkSaveProduction.results.forEach((r, index) => {
            if (r.errors) {
                if (!newErrors[index]) {
                    newErrors[index] = {};
                }
                console.error('Error saving Production:', r.errors);
                newErrors[index] = r.errors;
                hasErrors = true;
                r.errors.forEach((error) => {
                    enqueueSnackbar(`Row ${index}, failed validation: ${error}`, {
                        variant: 'error'
                    });
                });
            } else {
                newProductions[index].productionReference = r.reference;
            }
        });
        if (!hasErrors) {
            enqueueSnackbar(`File processed successfully: ${res.data.bulkSaveProduction.createdCount} records`, {
                variant: 'success'
            });
            setShowImportDetails({
                createdCount: res.data.bulkSaveProduction.createdCount,
                updatedCount: res.data.bulkSaveProduction.updatedCount,
                unprocessedCount: res.data.bulkSaveProduction.unprocessedCount,
                logFile: res.data.bulkSaveProduction.logFile
            });
            setShowImportDetailsDialog(true);
            onClose();
            onSave();
        }
        setIsSaving(false);
    });
};
