import { FormControlLabel, FormGroup, Link, Switch, Typography } from '@mui/material';
import CheckBox from '@mui/material/Checkbox';
import { FilterLink, useSearchkit } from '@searchkit/client';
import React, { useState } from 'react';
import { SkFacetSet, SkFacetSetEntry } from 'sr-types/lib/search/v1/graphql';
import { Conditional, H, V } from '../../../common/Layout';
import EllipsisText from '../../../common/components/EllipsisText';
import I18n from '../../../common/i18n/I18n';

export const CustomListFacet = ({
    facet,
    loading,
    showCount
}: {
    facet: SkFacetSet;
    loading: boolean;
    showCount: boolean;
}) => {
    const api = useSearchkit();
    const [seeMore, setSeeMore] = useState(false);
    const entries = facet.entries
        .filter((entry: SkFacetSetEntry, idx) => (seeMore ? true : idx < 15))
        .map((entry: SkFacetSetEntry) => {
            const filter = { identifier: facet.identifier, value: entry.id.split('_')[1] };
            return (
                // use entry.count
                <FilterLink key={entry.id} filter={filter}>
                    <FormControlLabel
                        componentsProps={{
                            typography: {
                                sx: { fontSize: 'small' }
                            }
                        }}
                        control={<CheckBox size={'small'} checked={api.isFilterSelected(filter)} />}
                        label={showCount ? `${entry.label} (${entry.count})` : entry.label}
                    />
                </FilterLink>
            );
        });

    return (
        <V>
            <Typography sx={{ fontSize: 'small' }}>{<I18n token={facet.label} />}</Typography>
            <V>
                <FormGroup>{entries}</FormGroup>
                <Conditional condition={facet.entries?.length > 15}>
                    {() => (
                        <Link
                            className="clickable"
                            onClick={() => {
                                setSeeMore(!seeMore);
                            }}
                        >
                            {!seeMore ? 'See More' : 'See Less'}
                        </Link>
                    )}
                </Conditional>
            </V>
        </V>
    );
};

CustomListFacet.DISPLAY = 'CustomListFacet';
