import React from 'react';
import { toast, ToastContainer, ToastContainerProps } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { green, grey, red, yellow } from '@mui/material/colors';
import styled from '@emotion/styled';

const StyledContainer = styled(ToastContainer)<ToastContainerProps>(({ theme }) => ({
    '.Toastify__toast--error': {
        backgroundColor: theme.error?.main || red['200'],
        color: theme.error?.contrastText || grey['900']
    },
    '.Toastify__toast--warning': {
        backgroundColor: theme.warning?.main || yellow['200'],
        color: theme.warning?.contrastText || grey['900']
    },
    '.Toastify__toast--success': {
        backgroundColor: theme.success?.main || green['200'],
        color: theme.success?.contrastText || grey['900']
    }
}));

export const ToastColors = ['info', 'success', 'warning', 'error', 'default'] as const;

type ToastOptions = {
    variant: (typeof ToastColors)[number];
};

const defaultOptions: ToastOptions = { variant: 'info' };

export const enqueueSnackbar = (message: string | React.ReactElement, options: ToastOptions = defaultOptions) => {
    toast(message, {
        type: options.variant
    });
};

export default () => (
    <StyledContainer position={'bottom-left'} autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
);
