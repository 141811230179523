import { Container, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import OkCancel from '../components/OkCancel';
import { passwordRecovery } from './api';
import PasswordRecovery from './PasswordRecovery';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import { AuthFormHeader, authLinks } from './AccountLinks';
import Nav from '../nav/Nav';
import useHistory from '../utils/useHistory';
import I18n, { useI18n } from '../i18n/I18n';
import { emailObfuscation } from '../utils/commonUtils';
import { useLocation } from 'react-router-dom';
import { H } from '../Layout';

export default () => {
    const { goBack, searchParams, changeRoute } = useHistory();
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailUnverifiedError, setEmailUnverifiedError] = useState(false);
    const temporaryPasswordMessage = useI18n('account.email.temporary.password');
    const verifyEmailMessage = useI18n('account.verify.email');
    const location = useLocation();
    const username = location?.state?.username || '';
    const isEmailSent = Boolean(searchParams.get('emailSent'));

    const onNext = () => {
        setLoading(true);

        passwordRecovery(username)
            .then((res: any) => {
                if (res.userFound) {
                    setEmailSent(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                if (err.code === 'EmailUnverified') {
                    setEmailUnverifiedError(true);
                    setLoading(false);
                }
                console.error('login error:', err);
            });
    };

    const onAuthorization = () => {
        changeRoute(authLinks.authentication.path, { username });
    };

    const onLogin = () => {
        changeRoute(authLinks.confirmPassword.path, {}, true, { username });
    };

    const onBack = () => {
        goBack();
    };

    return (
        <>
            <Nav />
            <Container key="verify" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
                <H fill sx={{ justifyContent: 'center' }}>
                    <Paper
                        sx={{
                            maxWidth: '400px',
                            p: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <FormContainer>
                            {emailSent || isEmailSent ? (
                                <PasswordRecovery resendEmail={onNext} onLogin={onLogin} />
                            ) : (
                                <>
                                    <FormItem>
                                        <AuthFormHeader title={<I18n token="account.title.verify" />} />
                                    </FormItem>
                                    <FormItem>
                                        <Typography fontSize={'small'}>
                                            {emailUnverifiedError
                                                ? verifyEmailMessage
                                                : temporaryPasswordMessage + ' ' + emailObfuscation(username)}
                                        </Typography>
                                    </FormItem>
                                    <FormItem>
                                        <OkCancel
                                            isBusy={loading}
                                            okLabelI18n="dialog.next"
                                            cancelLabelI18n="dialog.back"
                                            onOk={emailUnverifiedError ? onAuthorization : onNext}
                                            onCancel={onBack}
                                            isOkDisabled={false}
                                        />
                                    </FormItem>
                                </>
                            )}
                        </FormContainer>
                    </Paper>
                </H>
            </Container>
        </>
    );
};
