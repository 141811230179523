import { Tooltip, Typography } from '@mui/material';
import { find } from 'lodash';
import React from 'react';
import { useStyling } from '../../common/Theme';
import I18n from '../../common/i18n/I18n';
import { KeyValue } from 'sr-types/lib/production/v1/graphql';

const Component = ({ otherTitles, width }: { otherTitles: KeyValue[]; width?: number }) => {
    const { theme } = useStyling();
    const aka = find(otherTitles, { key: 'AKA' });

    return aka ? (
        <>
            <Typography variant="h6" sx={{ fontSize: '14px', color: theme.palette.text.disabled }}>
                <I18n token="production.aka" />
            </Typography>
            <Tooltip title={<Typography fontSize="small"> {aka.value}</Typography>}>
                <Typography
                    className="ellipsis"
                    variant="h6"
                    maxWidth={width || 'inherit'}
                    sx={{ fontSize: '14px', textWrap: 'nowrap' }}
                >
                    {aka.value}
                </Typography>
            </Tooltip>
        </>
    ) : (
        <></>
    );
};
Component.displayName = 'AkaLabel';
export default Component;
