import { Dispatch, SetStateAction } from 'react';
import { FieldInput } from 'sr-types/lib/documenttemplate/v1/graphql';
import {
    AlternateIdInput,
    ContactInfoInput,
    DateRangeInput,
    DeiInfoInput,
    MembershipInput,
    PersonBasicProfileInput,
    ReferenceInput,
    EmailInput,
    PhoneInput,
    PersonInput,
    CreativeCredit,
    Experience,
    LocationInput,
    NameInput,
    SummaryInput,
    Person
} from 'sr-types/lib/person/v1/graphql';
import { FieldDefinition } from 'sr-types/lib/search/v1/graphql';
import { Attribute } from '../../../attributes/attributes';
import { client, savePersonWithBasicInfo } from '../../../common/components/person/person';
import { useMutationWithContext } from '../../../common/hooks/useMutationWithContext';

import { Reference } from '../../../common/reference/reference';
import { failedToastMessage } from '../../../common/utils/commonUtils';
import {
    AlternateIdProps,
    createURLConstructor,
    findAlternateId,
    IMDB_LABEL,
    LINKEDIN_LABEL
} from './personAlternateIds';

export interface CommonExtendedFields {
    Imdb: string;
    LinkedIn: string;
    memberships: ReferenceInput[];
    imdbAlternateId: AlternateIdInput;
    linkedInAlternateId: AlternateIdInput;
    imdbProfileId: string;
    linkedInProfileId: string;
    genderCustom: string;
    ethnicityCustom: string;
    disabilityStatusDescription: string;
    genre?: Array<string>;
    technicalExpertise?: Array<string>;
    language?: Array<string>;
    inquireOptions?: string;
    isSharedInDirectory?: boolean;
}
export interface BasicInfoInputExtended extends Omit<PersonBasicProfileInput, 'memberships'>, CommonExtendedFields {}
export interface PersonInputExtended extends Omit<PersonInput, 'memberships'>, CommonExtendedFields {}

export interface BasicInfoFormItemsProps extends AlternateIdProps {
    fieldDefinitionsData: FieldDefinition[];
    onEditSkills: () => void;
    attributes?: Attribute[];
    setAttributes?: Dispatch<SetStateAction<Attribute[]>>;
}

export const EMPTY_STATE = {
    memberships: [],
    associatedFieldDefinitionReferences: [],
    customFields: [],
    genre: [],
    language: [],
    technicalExpertise: [],
    Imdb: '',
    LinkedIn: '',
    imdbAlternateId: undefined,
    linkedInAlternateId: undefined,
    imdbProfileId: undefined,
    linkedInProfileId: undefined,
    genderCustom: undefined,
    ethnicityCustom: undefined,
    disabilityStatusDescription: undefined,
    isSharedInDirectory: false
};

export const OTHER = 'Other';
export const YES = 'Yes';

export const MUI_DATE_MONTH_AND_DAY = '0101';

export const stateToPersonBasicInfoInput = (personBasicInfo) => {
    const {
        contactInfo,
        Imdb,
        imdbAlternateId,
        linkedInAlternateId,
        LinkedIn,
        imdbProfileId,
        linkedInProfileId,
        deiInfo,
        genderCustom,
        ethnicityCustom,
        disabilityStatusDescription,
        memberships,
        summary,
        genre,
        technicalExpertise,
        language,
        associatedFieldDefinitionReferences,
        customFields,
        initialValues,
        profileImage,
        inquireOptions,
        isSharedInDirectory,
        ...state
    } = personBasicInfo;

    const contactInfoDestructured: ContactInfoInput =
        personBasicInfo && constructContactInfo(personBasicInfo.contactInfo);

    const deiInfoDestructured: DeiInfoInput =
        deiInfo && handleDeiInfoValuesToInput(deiInfo, disabilityStatusDescription, ethnicityCustom, genderCustom);

    const constructedAlternateIds = [];
    imdbAlternateId && addAlternateId(constructedAlternateIds, imdbAlternateId, imdbProfileId, IMDB_LABEL);
    linkedInAlternateId &&
        addAlternateId(constructedAlternateIds, linkedInAlternateId, linkedInProfileId, LINKEDIN_LABEL);

    const membershipObjects = memberships && createMembershipObject(memberships);
    const summaryObject = summary && constructSummary(summary.headline, summary.about);
    const profileImageReference = {
        id: profileImage,
        label: ''
    };

    const isPersonSharedInDirectory = isSharedInDirectory || inquireOptions === 'includeInquire';

    return {
        contactInfo: contactInfoDestructured,
        alternateIds: constructedAlternateIds,
        deiInfo: deiInfoDestructured,
        memberships: membershipObjects,
        summary: summaryObject,
        associatedFieldDefinitionReferences,
        customFields,
        profileImageReference,
        isSharedInDirectory: isPersonSharedInDirectory,
        ...state
    };
};

export const constructContactInfo = (contactInfo: ContactInfoInput) => {
    return {
        email:
            contactInfo && contactInfo.email && contactInfo.email.address
                ? {
                      address: contactInfo.email.address,
                      typeString: 'Business',
                      verified: false
                  }
                : null,
        phone:
            contactInfo && contactInfo.phone && contactInfo.phone.number
                ? {
                      number: contactInfo.phone.number,
                      typeString: 'Mobile'
                  }
                : null
    };
};

export const handleDeiInfoValuesToInput = (
    deiInfo: DeiInfoInput,
    disabilityStatusDescription: string,
    ethnicityCustom: string,
    genderCustom: string
) => {
    const { birthYear, disabilityStatus, ethnicity, gender, socioEconomicStatus } = deiInfo;
    const deiInfoDestructured: DeiInfoInput = {
        birthYear: (birthYear && birthYear.substring(0, 4)) || '',
        disabilityStatus: disabilityStatus === YES ? disabilityStatusDescription : disabilityStatus || '',
        ethnicity: ethnicity === OTHER ? ethnicityCustom : ethnicity || '',
        gender: gender == OTHER ? genderCustom : gender || '',
        socioEconomicStatus: socioEconomicStatus || ''
    };
    return deiInfoDestructured;
};

export const addAlternateId = (
    listOfAlternateId: Array<AlternateIdInput>,
    alternateId: AlternateIdInput,
    profileId: string,
    fieldLabel: string
) => {
    let validUrlLink: string;
    if (profileId) {
        const origin = createURLConstructor(alternateId.url)?.origin;
        validUrlLink = `${origin}/${fieldLabel === IMDB_LABEL ? 'name' : 'in'}/${profileId}`;
    }
    return listOfAlternateId.push({ ...alternateId, url: validUrlLink || alternateId.url });
};

export const createMembershipObject = (memberships: Array<Reference | ReferenceInput>) => {
    return memberships && memberships.map((m) => ({ organizationReference: m }));
};

export const constructSummary = (headline: string = '', about: string = '') => {
    return { headline, about };
};

export const updatePersonBasicInfo = (state, onClose, onSuccess, updatePersonInfo) => {
    const personBasicInfoInput = stateToPersonBasicInfoInput(state);

    return updatePersonInfo({
        input: {
            ...personBasicInfoInput
        }
    })
        .then((id) => {
            if (id) {
                onSuccess(id);
                return { id };
            } else {
                failedToastMessage('person.save.error');
                return null;
            }
        })
        .catch(() => {
            failedToastMessage('person.save.error');
            return null;
        })
        .finally(onClose);
};

export const useUpdatePersonBasicInfo = () => {
    const [save, { loading: basicInfoSaving }] = useMutationWithContext(savePersonWithBasicInfo, client);

    const updatePersonInfo = ({ input }) => {
        return new Promise<any>((resolve, reject) => {
            save({
                variables: {
                    input
                }
            })
                .then((res) => {
                    res.data.savePersonWithBasicProfile.errors
                        ? reject()
                        : resolve(res.data.savePersonWithBasicProfile.id);
                })
                .catch(() => {
                    reject();
                });
        });
    };
    return { updatePersonInfo, basicInfoSaving };
};

export const experienceToInput = (experience) => {
    const { dateRange, ...state } = experience;
    delete state.__typename;

    const dateRangeDestructured: DateRangeInput = dateRange.start !== '' && dateRange.end !== '' ? dateRange : null;

    return { dateRange: dateRangeDestructured, ...state };
};

export const extractPersonBasicInfoForPreview = (person: Person): BasicInfoInputExtended => {
    const {
        availableToHire,
        contactInfo,
        identity,
        location,
        name,
        professionalRoles,
        profileAttributes,
        profileImage,
        summary,
        alternateIds,
        deiInfo,
        memberships,
        associatedFieldDefinitionReferences,
        customFields,
        isSharedInDirectory
    } = person;

    const imdb = alternateIds?.length && findAlternateId(alternateIds, IMDB_LABEL);
    const linkedIn = alternateIds?.length && findAlternateId(alternateIds, LINKEDIN_LABEL);

    const membershipsWithReferencesExtracted: ReferenceInput[] = memberships?.map(
        (m: MembershipInput) => m.organizationReference
    );

    const emailInput: EmailInput = {
        address: contactInfo?.email?.address || '',
        typeString: contactInfo?.email?.typeString || '',
        verified: contactInfo?.email?.verified || false
    };

    const phoneInput: PhoneInput = {
        number: contactInfo?.phone?.number || '',
        typeString: contactInfo?.phone?.typeString || ''
    };

    const customFieldsInput: FieldInput[] =
        customFields?.length &&
        customFields.map(({ fieldDefinitionReference, fieldType, name, value }) => ({
            fieldDefinitionReference,
            fieldType,
            name,
            value
        }));

    const locationInput: LocationInput = location
        ? {
              ...location,
              geoCoords: {
                  lat: location?.geoCoords?.lat || null,
                  lon: location?.geoCoords?.lon || null
              },
              primary: location?.primary || false
          }
        : null;

    const nameInput: NameInput = {
        ...name,
        firstName: name?.firstName || '',
        lastName: name?.lastName || ''
    };

    const summaryInput: SummaryInput = {
        about: summary?.about || '',
        headline: summary?.headline || ''
    };

    const personBasicInfo = {
        availableToHire,
        contactInfo: {
            email: emailInput,
            phone: phoneInput
        },
        identity,
        location: locationInput,
        name: nameInput,
        professionalRoles,
        profileAttributes,
        profileImage: profileImage || '',
        summary: summaryInput,
        Imdb: imdb?.url,
        LinkedIn: linkedIn?.url,
        imdbAlternateId: imdb,
        linkedInAlternateId: linkedIn,
        deiInfo: deiInfo?.birthYear
            ? { ...deiInfo, birthYear: deiInfo.birthYear + MUI_DATE_MONTH_AND_DAY }
            : { ...deiInfo },
        memberships: memberships ? membershipsWithReferencesExtracted : [],
        imdbProfileId: undefined,
        linkedInProfileId: undefined,
        genderCustom: undefined,
        ethnicityCustom: undefined,
        disabilityStatusDescription: undefined,
        associatedFieldDefinitionReferences,
        customFields: customFieldsInput,
        isSharedInDirectory
    };
    return personBasicInfo;
};

export const removeEmptyCreativeCredits = (creativeCredits: CreativeCredit[]) => {
    return creativeCredits.filter((c) => !isCreativeCreditObjectEmpty(c));
};

export const removeEmptyExperiences = (experiences: Experience[]) => {
    return experiences.filter((e) => !isExperienceObjectEmpty(e));
};

const isExperienceObjectEmpty = (experience: Experience) => {
    return (
        experience &&
        experience.location === null &&
        experience.experienceName === '' &&
        experience.organizationReference === null &&
        experience.jobType === '' &&
        experience.dateRange.start === '' &&
        experience.dateRange.end === '' &&
        experience.keyExperience === false &&
        (experience.professionalRoles === null ||
            (experience.professionalRoles?.length && experience.professionalRoles[0] === '')) &&
        experience.title === '' &&
        experience.department === ''
    );
};

const isCreativeCreditObjectEmpty = (creativeCredit: CreativeCredit) => {
    return (
        creativeCredit &&
        creativeCredit.location === null &&
        creativeCredit.showName === '' &&
        creativeCredit.showType === '' &&
        creativeCredit.showReference === null &&
        creativeCredit.dateRange.start === '' &&
        creativeCredit.dateRange.end === '' &&
        creativeCredit.keyCredit === false &&
        (creativeCredit.professionalRoles === null ||
            (creativeCredit.professionalRoles?.length && creativeCredit.professionalRoles[0] === '')) &&
        creativeCredit.department === ''
    );
};

export const organizationMembershipsFilters = {
    expressions: [
        {
            field: 'organizationTypes',
            value: {
                values: ['Guild', 'Union']
            }
        },
        { field: 'isSharedInDirectory', value: { values: ['true'] } }
    ]
};
