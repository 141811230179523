import React from 'react';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import { Field } from '../attributes';

type BooleanValueEditorProps = {
    field: Field;
};

export default (props: BooleanValueEditorProps) => {
    const { field } = props;
    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget name={field.name} label={field.label} component="Switch" half />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
