import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import CustomAuth from '../common/auth/CustomAuth';
import Nav from '../common/nav/Nav';
import useHistory from '../common/utils/useHistory';
import InquirySuccess from './InquirySuccess';
import { setToken as setLocalStorageToken } from '../common/auth/api';
import OpportunityResponseModal from '../supply/opportunity/details/OpportunityResponseModal';

export default () => {
    const { searchParams } = useHistory();

    const leadId = searchParams.get('leadId');
    const username = searchParams.get('username');
    const [isPreview, setIsPreview] = useState(true);
    const [thankYouVisible, setThankYouVisible] = useState(false);
    const [thankYouName, setThankYouName] = useState('');
    const [token, setToken] = useState('');

    useEffect(() => {
        setLocalStorageToken(token);
    }, [token]);

    return (
        <>
            <Nav />
            {token ? (
                <>
                    {isPreview ? (
                        <OpportunityResponseModal
                            bookingId={leadId}
                            opportunity={null}
                            setOpenModal={setIsPreview}
                            openModal={isPreview}
                            setThankYouVisible={setThankYouVisible}
                            setThankYouName={setThankYouName}
                            token={token}
                        />
                    ) : (
                        <></>
                    )}
                    {thankYouVisible ? <InquirySuccess name={thankYouName} /> : <></>}
                </>
            ) : (
                <>
                    <CustomAuth
                        username={username}
                        setBearer={(token) => {
                            setToken(token);
                        }}
                    />
                </>
            )}
        </>
    );
};
