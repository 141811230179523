import { find } from 'lodash';
import { enqueueSnackbar } from '../../../common/components/Toast';
import { contactToPerson, Person } from '../../../common/components/person/person';
import { Contact } from '../../../common/components/types';
import { rowsToContacts } from './ContactListUtils';

export const doSave = (setIsSaving, rows, isAltColDef, altColDefs, colDefs, defaultColumns, setErrors, onClose, onSave, bulkSave, activeOrganizationAccount) => {
    setIsSaving(true);
    const newContacts: Contact[] = rowsToContacts(rows, isAltColDef ? altColDefs : colDefs);
    console.log('Saving', newContacts);
    const personArr: Person[] = newContacts.map(contactToPerson);
    bulkSave({
        variables: {
            input: personArr
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    }).then(
        (res) => {
            if (Array.isArray(res.data.bulkAutoCreatePerson)) {
                let hasErrors = false;
                const newErrors = {};
                res.data.bulkAutoCreatePerson.forEach((r, index) => {
                    if (r.errors) {
                        if (!newErrors[index]) {
                            newErrors[index] = {};
                        }
                        console.error('Error saving person:', r.errors);
                        hasErrors = true;
                        r.errors.forEach((error) => {
                            let { field, message } = error;
                            field = field.replace('Person.', '');
                            const col = find(defaultColumns, { field: field });
                            const fieldName = col ? col.headerName : field;
                            if (!newErrors[index][field]) {
                                newErrors[index][field] = [];
                            }
                            newErrors[index][field].push(message);
                            enqueueSnackbar(`Row ${index}, field ${fieldName} failed validation: ${message}`, {
                                variant: 'error'
                            });
                        });
                    } else {
                        newContacts[index].personReference = r.reference;
                    }
                });

                if (hasErrors) {
                    setErrors(newErrors);
                    setIsSaving(false);
                } else {
                    onSave(newContacts)
                        .then(() => {
                            setIsSaving(false);
                            console.log('Added contacts to reservation');
                            onClose();
                        })
                        .catch((err) => {
                            setIsSaving(false);
                            console.error('Failed to add contacts to reservation', err);
                            onClose();
                        });
                }
            }
        },
        (errors) => {
            console.error('Some contacts failed to save:', errors);
            setIsSaving(false);
        }
    );
};
