import React, { useContext, useEffect, useState } from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import FormPanel from '../../common/form/FormPanel';
import { FormContext } from '../../common/form/FormContext';
import { useUnitsDataLazy } from '../attributesApi';

export default () => {
    const [options, setOptions] = useState([]);
    const { state } = useContext(FormContext);

    const { getUnits, unitsData } = useUnitsDataLazy(state?.measurementType);

    useEffect(() => {
        if (unitsData?.results?.hits?.items) {
            setOptions(
                unitsData.results.hits.items.map((item) => {
                    return { ...item, label: item.name };
                })
            );
        }
    }, [unitsData]);

    useEffect(() => {
        if (state?.measurementType) {
            getUnits();
        }
    }, [state.measurementType, getUnits]);

    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget name="label" label="Label" />
                </FormItem>
                <FormItem>
                    <FormWidget name="group" label="Group" />
                </FormItem>
                <FormItem>
                    <FormWidget name="description" component="TextArea" label="Description" />
                </FormItem>
                <FormItem quarter>
                    <FormWidget component="Switch" name="required" label="Required" />
                </FormItem>
                <FormItem half>
                    <FormWidget component="Switch" name="keyInformation" label="Key Information" />
                </FormItem>
                <FormItem third>
                    <FormWidget name="min" label="Min" />
                </FormItem>
                <FormItem third>
                    <FormWidget name="max" label="Max" />
                </FormItem>
                <FormItem third>
                    <FormWidget name="decimalsNumber" component="Number" label="Number of decimals" />
                </FormItem>
                <FormItem onClick={() => {}}>
                    <FormWidget
                        name="measurementType"
                        metadataName="MeasurementType"
                        component="MetadataAutocomplete"
                        label="Measurement Type"
                    />
                </FormItem>
                <FormItem>
                    <FormWidget
                        name="unit"
                        component="Autocomplete"
                        label="Unit"
                        allowFreeForm={true}
                        disabled={!state?.measurementType}
                        options={options}
                    />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
