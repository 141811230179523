import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Cast = createSvgIcon(
    <path
        d="M9.5 19.7766V17.5266C9.5 16.6982 10.1716 16.0266 11 16.0266H13C13.8284 16.0266 14.5 16.6982 14.5 17.5266V19.7766M12 2.27661L15.1783 8.65205L22.2239 9.70468L17.1426 14.6975L18.3187 21.7235L12 18.4339L5.6813 21.7235L6.8574 14.6975L1.77614 9.70468L8.82169 8.65205L12 2.27661ZM13.75 12.4016C13.75 13.4371 12.9105 14.2766 11.875 14.2766C10.8395 14.2766 10 13.4371 10 12.4016C10 11.3661 10.8395 10.5266 11.875 10.5266C12.9105 10.5266 13.75 11.3661 13.75 12.4016Z"
        stroke="currentColor"
        fill="transparent"
        strokeOpacity="1"
        strokeWidth="1.6"
    />,
    'Cast'
);
