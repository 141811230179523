import { ArrowDropDown } from '@mui/icons-material';
import {
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from '@mui/material';
import { findIndex } from 'lodash';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useStyling } from '../Theme';
import { BreakpointSize } from '../hooks/breakpoints';
import { useCurrentBreakpoint } from '../hooks/useCurrentBreakpoint';
import { useIsWithinBreakpoints } from '../hooks/useIsWithinBreakpoints';

export type ResponsiveButtonGroupOption = {
    id: string;
    label: string;
    icon: ReactElement;
};

type ResponsiveButtonGroupImplProps = {
    value: string;
    setValue: (values: string) => void;
    options: ResponsiveButtonGroupOption[];
};

const DesktopResponsiveButtonGroup = ({ value: value, setValue, options }: ResponsiveButtonGroupImplProps) => {
    return (
        <ToggleButtonGroup
            value={value || ''}
            exclusive={true}
            onChange={(e, value) => {
                setValue(value);
            }}
        >
            {options.map((option: ResponsiveButtonGroupOption) => {
                return (
                    <ToggleButton key={option.id} value={option.id}>
                        <Tooltip title={option.label}>{option.icon}</Tooltip>
                    </ToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
};

const MobileResponsiveButtonGroup = ({ value, setValue, options }: ResponsiveButtonGroupImplProps) => {
    const { theme } = useStyling();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    //const [selectedIndex, setSelectedIndex] = React.useState(value ? findIndex(options, { id: value }) : 0);
    const selectedIndex = useMemo(() => {
        if (value) {
            const index = findIndex(options, { id: value });
            return index > 0 ? index : 0;
        } else {
            return 0;
        }
    }, [options, value]);

    const handleMenuItemClick = (index, option: ResponsiveButtonGroupOption) => {
        // setSelectedIndex(index);
        setValue(option.id);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ToggleButtonGroup ref={anchorRef}>
                <ToggleButton value={options[selectedIndex].label}>{options[selectedIndex].icon}</ToggleButton>
                <ToggleButton value={'arrow'} size="small" onClick={handleToggle}>
                    <ArrowDropDown color={'disabled'} />
                </ToggleButton>
            </ToggleButtonGroup>
            <Popper
                sx={{
                    zIndex: 999
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.id}
                                            selected={index === selectedIndex}
                                            onClick={() => {
                                                handleMenuItemClick(index, option);
                                            }}
                                            sx={{ gap: 1, backgroundColor: theme.palette.background.paper }}
                                        >
                                            {option.icon}
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

type ResponsiveButtonGroupProps = {
    options: ResponsiveButtonGroupOption[];
    selectedOption: string;
    onToggle: (newValue: string) => void;
    useMobileWithBreakpoints: BreakpointSize[];
};

export default ({ options, selectedOption, onToggle, useMobileWithBreakpoints }: ResponsiveButtonGroupProps) => {
    const [value, setValue] = useState<string>(selectedOption);
    const bp = useCurrentBreakpoint();
    const isSmallScreen = useMobileWithBreakpoints.includes(bp);

    useEffect(() => {
        onToggle(value);
    }, [onToggle, value]);

    return isSmallScreen && options.length > 1 ? (
        <MobileResponsiveButtonGroup options={options} value={value} setValue={setValue} />
    ) : (
        <DesktopResponsiveButtonGroup options={options} value={value} setValue={setValue} />
    );
};
