import React, { useEffect, useState } from 'react';
import OkCancel from '../components/OkCancel';
import { useI18n } from '../i18n/I18n';
import { Box, Container, Paper, Typography } from '@mui/material';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import CustomAuth from '../auth/CustomAuth';
import { useMutation } from '@apollo/client';
import { clientWithoutAuth, processInvitationQuery } from '../auth/account';
import useHistory from '../utils/useHistory';
import { Icons } from '../icons/Icons';

const ALREADY_PROCESSED_ERR = 'Invitation has already been processed';

export default () => {
    const [bearer, setBearer] = useState(false);
    const [processInvitation] = useMutation(processInvitationQuery, { client: clientWithoutAuth });
    const [isBusy, setIsBusy] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [error, setError] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const { routeParams, searchParams, logOut } = useHistory();
    const invitationId = routeParams.invitationId;
    const username = searchParams.get('username');

    const doProcess = (accept) => {
        processInvitation({
            variables: {
                invitationId: invitationId,
                accept: accept
            },
            context: {
                headers: {
                    Authorization: `Bearer ${bearer}`
                }
            }
        })
            .then((res) => {
                if (res.data.processInvitation.errors?.length ?? 0 > 0) {
                    const errMsg = res.data.processInvitation.errors.map((e) => e.message).join(', ');
                    if (errMsg?.includes(ALREADY_PROCESSED_ERR)) {
                        setProcessed(true);
                        setMessage(`${errMsg}. Please login to the application using your Showrunnr credentials.`);
                    } else {
                        setError(errMsg);
                    }
                } else {
                    setMessage('Your account is now ready to access. Please login to the application using your Showrunnr credentials.');
                    setProcessed(true);
                }
                setIsBusy(false);
            })
            .catch((err) => {
                setIsBusy(false);
                setError(`Failed to process invitation. Please try again`);
            });
    };

    useEffect(() => {
        if (bearer && !processed) {
            doProcess(true);
        }
    }, [bearer]);

    const title = useI18n('dialog.invitation.response');
    return !invitationId || !username || processed || error ? (
        <Container key="invitation" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Paper sx={{ maxWidth: '400px', p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FormContainer>
                        <FormItem>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                <Icons.Rsvp color={'primary'} fontSize={'large'} />
                                <Typography sx={{ fontSize: 'x-large', fontWeight: 'bold' }}>{title}</Typography>
                            </Box>
                        </FormItem>
                        {!processed && !error && (
                            <FormItem>
                                <Typography>{`Invalid invitation link`}</Typography>
                            </FormItem>
                        )}
                        {processed && (
                            <>
                                <FormItem>
                                    <Typography>{message}</Typography>
                                </FormItem>
                                <FormItem>
                                    <FormItem>
                                        <OkCancel
                                            isBusy={isBusy}
                                            okLabelI18n="dialog.login"
                                            cancelLabelI18n="dialog.cancel"
                                            onCancel={undefined}
                                            onOk={logOut}
                                            isOkDisabled={false}
                                        />
                                    </FormItem>
                                </FormItem>
                            </>
                        )}
                        {!processed && error && (
                            <>
                                <FormItem>
                                    <Typography>{error}</Typography>
                                </FormItem>
                                <FormItem>
                                    <FormItem>
                                        <OkCancel
                                            isBusy={isBusy}
                                            okLabelI18n="dialog.retry"
                                            cancelLabelI18n="dialog.cancel"
                                            onCancel={undefined}
                                            onOk={() => window.location.reload()}
                                            isOkDisabled={false}
                                        />
                                    </FormItem>
                                </FormItem>
                            </>
                        )}
                    </FormContainer>
                </Paper>
            </Box>
        </Container>
    ) : (
        <CustomAuth
            username={username}
            setBearer={(bearer) => {
                if (bearer) {
                    setBearer(bearer);
                }
            }}
        />
    );
};
