import { cloneDeep } from 'lodash';
import { Production, Reference, Schedule } from 'sr-types/lib/production/v1/graphql';
import { ValidatorType } from '../../common/form/Validator';
import { EMPTY_SCHEDULE } from '../../common/form/widgets/ScheduleWidget';

export const EMPTY_PRODUCTION_DATES: { productionDates: Schedule } = {
    productionDates: {
        dateRange: {
            ...cloneDeep(EMPTY_SCHEDULE),
            dateTimeDuration: {
                unit: 'week',
                value: 1
            }
        },
        autoSetDate: false
    }
};

export type ValidationRulesType = {
    [key: string]: ValidatorType[];
};
export type ProductionWithReference = Omit<Production, 'name'> & { name: Reference };
