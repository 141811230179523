import React from 'react';
import { KeyValueMap } from '../../../common/KeyValueMap';
import ResponsiveToolbar from '../../../common/components/ResponsiveToolbar';
import { ActionType } from '../../../common/components/ActionType';
import { Icons } from '../../../common/icons/Icons';

export const listTypes: KeyValueMap<string, ActionType> = {
    bullet: {
        icon: Icons.BulletedList,
        label: 'Bulleted List'
    },
    number: {
        icon: Icons.FormatListNumbered,
        label: 'Numbered List'
    },
    check: {
        icon: Icons.Checklist,
        label: 'Check List'
    }
};

export default ({ isSmall, blockType, setBlockType, disabled }) => {
    return (
        <ResponsiveToolbar
            isSmall={isSmall}
            disabled={disabled}
            isSmall={true}
            selected={blockType}
            onSelect={setBlockType}
            options={listTypes}
            icon={Icons.BulletedList}
        />
    );
};
