import React, { useContext, useEffect, useState } from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import FormPanel from '../../common/form/FormPanel';
import { FormContext } from '../../common/form/FormContext';
import { arrayToSelectOptionsLocalized, TermsetSelectTypeEnum } from '../attributes';

export default (props) => {
    const [options, setOptions] = useState([]);
    const [addNewTermset, setAddNewTermset] = useState(false);
    const { setState, state } = useContext(FormContext);
    const { termsetsData } = props;

    useEffect(() => {
        setState((prevVal) => {
            return { ...prevVal, selectType: { id: TermsetSelectTypeEnum.single, label: TermsetSelectTypeEnum.single, reference: undefined } };
        });
    }, [setState]);

    useEffect(() => {
        if (termsetsData) {
            const options = termsetsData.termsets.map((ts) => {
                return {
                    id: ts.name,
                    label: ts.label
                };
            });
            setOptions(options);
        }
    }, [termsetsData]);

    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget name="label" label="Label" />
                </FormItem>
                <FormItem>
                    <FormWidget name="group" label="Group" />
                </FormItem>
                <FormItem>
                    <FormWidget name="description" component="TextArea" label="Description" />
                </FormItem>
                <FormItem quarter>
                    <FormWidget component="Switch" name="required" label="Required" />
                </FormItem>
                <FormItem half>
                    <FormWidget component="Switch" name="keyInformation" label="Key Information" />
                </FormItem>
                <FormItem>
                    <FormWidget component="Chips" name={'selectType'} coerceChangeToArray={true} options={arrayToSelectOptionsLocalized(Object.values(TermsetSelectTypeEnum))} />
                </FormItem>

                <FormItem
                    onClick={() => {
                        setAddNewTermset(false);
                    }}
                >
                    <FormWidget component="Autocomplete" name="termset" label="Existing Term Lists" options={options} disabled={addNewTermset} allowFreeForm={true} />
                </FormItem>
                <FormItem
                    onClick={() => {
                        setAddNewTermset(true);
                    }}
                >
                    <FormWidget multiple name="newTermset" component="Autocomplete" label="Add new list of terms." allowFreeForm={true} disabled={!addNewTermset} />
                </FormItem>
                <FormItem half>
                    <FormWidget component="Switch" name="allowCreate" label="Allow new values" disabled={!addNewTermset} />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
