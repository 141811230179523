import { cloneDeep, find, remove } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Preference } from 'sr-types/lib/account/v1/graphql';
import { client as accountClient } from '../auth/account';
import { constants } from '../constants';
import { usePrevious } from '../hooks/usePrevious';
import { AppDefinition } from '../nav/apps';
import { log } from '../utils/commonUtils';
import { loadPreferencesQuery, savePreferenceMutation } from './Preferences';
import { OrganizationAccount, orgAccountDetails } from './account';
import { PermissionsType, UserProfile, fetchUserProfile, findPermissions, isTokenExpired } from './api';

type UserContextType = {
    application: AppDefinition;
    setApplication: (AppDefinition) => void;
    isAuthenticated: boolean;
    setIsAuthenticated: (boolean) => void;
    userProfile: UserProfile;
    setUserProfile: (UserProfile) => void;
    activeOrganizationAccount: string;
    setActiveOrganizationAccount: (newOrgId: string, skipProfileFetch?: boolean) => Promise<boolean>;
    organizationAccountDetails: OrganizationAccount;
    resetUserContext: () => void;
    preferencesLoaded: boolean;
    savePreference: (view: string, key: string, value: string, agent?: string) => Promise<any>;
    getPreference: (view: string, key: string, agent?: string) => any;
    addOnLogoutListener: (listener: LogoutListener) => () => void;
    onLogout: () => void;
};

type LogoutListener = () => void;

export const UserContext = createContext<UserContextType>(undefined);

export const UserProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!isTokenExpired());
    const prevIsAuthenticated = usePrevious(isAuthenticated);
    const [userProfile, setUserProfile] = useState<UserProfile>(undefined);
    const [activeOrganizationAccount, setActiveOrganizationAccount] = useState<string>(undefined);
    const [application, setApplication] = useState<AppDefinition>(undefined);
    const [organizationAccountDetails, setOrganizationAccountDetails] = useState<OrganizationAccount>();
    const [preferences, setPreferences] = useState<Preference[]>();
    const [preferencesLoaded, setPreferencesLoaded] = useState(false);

    const logoutListeners = useRef<Set<LogoutListener>>(new Set());

    const addOnLogoutListener = useCallback((listener: LogoutListener) => {
        logoutListeners.current.add(listener);

        return () => {
            logoutListeners.current.delete(listener);
        };
    }, []);

    // Function to call all registered listeners during logout
    const onLogout = useCallback(() => {
        logoutListeners.current.forEach((listener) => listener());
    }, []);

    useEffect(() => {
        if (prevIsAuthenticated !== isAuthenticated) {
            log(isAuthenticated ? 'User is authenticated' : 'User is not authenticated');
        }
    }, [isAuthenticated, prevIsAuthenticated]);

    /*
     * If we have logged in user we need to fetch org account details.
     */
    useEffect(() => {
        if (isAuthenticated && userProfile && activeOrganizationAccount) {
            // Skip this call if it's personal account.
            const currentOrg = find(userProfile.memberships, { id: activeOrganizationAccount });
            const isPersonal = currentOrg && currentOrg.personal;
            setOrganizationAccountDetails(undefined);
            if (!isPersonal) {
                accountClient
                    .query({
                        query: orgAccountDetails,
                        variables: { id: activeOrganizationAccount, organizationId: '' },
                        fetchPolicy: constants.apolloFetchPolicy,
                        context: {
                            headers: {
                                ownerId: activeOrganizationAccount
                            }
                        }
                    })
                    .then((res) => {
                        setOrganizationAccountDetails(res.data.organizationAccount);
                    })
                    .catch((err) => {
                        console.error('Error getting org account details:', err);
                        setOrganizationAccountDetails(undefined);
                    });
            }
        }
    }, [activeOrganizationAccount, isAuthenticated, userProfile]);

    useEffect(() => {
        if (userProfile) {
            //@ts-ignore
            window.pendo.initialize({
                visitor: {
                    id: userProfile.accountId,
                    email: userProfile.username,
                    full_name: userProfile.name.firstName + ' ' + userProfile.name.lastName
                }
            });
        }
    }, [userProfile]);

    useEffect(() => {
        if (userProfile) {
            loadPreferencesQuery(userProfile.accountId).then((res) => {
                if (res?.data?.preferences) {
                    setPreferences(res.data.preferences);
                    setPreferencesLoaded(true);
                }
            });
        }
    }, [userProfile]);

    useEffect(() => {
        window.onstorage = (event) => {
            if (event.key === 'auth-token' && !event.newValue) {
                setIsAuthenticated(false);
            }
        };
    }, []);

    const providerValue = useMemo(() => {
        const resetUserContext = () => {
            setIsAuthenticated(false);
            setUserProfile(undefined);
            setOrganizationAccountDetails(undefined);
            setActiveOrganizationAccount(undefined);
        };

        const savePreference = (view: string, key: string, value: string, agent: string = '') => {
            return savePreferenceMutation(userProfile.accountId, view, key, value, agent).then(() => {
                const newPrefs = cloneDeep(preferences);
                remove(newPrefs, (p) => {
                    return (
                        p.accountId === userProfile.accountId && p.view === view && p.key === key && p.agent === agent
                    );
                });
                newPrefs.push({
                    accountId: userProfile.accountId,
                    view: view,
                    key: key,
                    value: value,
                    agent: agent
                });
                setPreferences(newPrefs);
            });
        };

        const getPreference = (view: string, key: string, agent: string = ''): any => {
            const pref: Preference = find(preferences, {
                accountId: userProfile.accountId,
                view: view,
                key: key,
                agent: agent
            });
            if (pref) {
                return JSON.parse(pref.value);
            }
        };

        return {
            application,
            setApplication,
            isAuthenticated,
            setIsAuthenticated,
            userProfile,
            setUserProfile,
            activeOrganizationAccount,
            setActiveOrganizationAccount: (newOrgId: string, skipProfileFetch: boolean = false) => {
                return new Promise<boolean>((resolve) => {
                    log(
                        'activeOrganizationAccount changing from:',
                        activeOrganizationAccount,
                        'to:',
                        newOrgId,
                        'loading user profile...'
                    );
                    /**
                     * We make fetchUserProfile call at two places. Other one is in AppRouter
                     * @param {string} newOrgId - The new org id to set
                     * @param {boolean} [skipProfileFetch=false] - Set to true if you don't want to fetch user profile
                     * @returns {Promise<boolean>}
                     */
                    if (skipProfileFetch) {
                        setActiveOrganizationAccount(newOrgId);
                        resolve(true);
                    } else {
                        fetchUserProfile(newOrgId, (data: UserProfile) => {
                            setUserProfile(data);
                            setActiveOrganizationAccount(newOrgId);
                            resolve(true);
                        });
                    }
                });
            },
            organizationAccountDetails,
            resetUserContext,
            preferencesLoaded,
            savePreference,
            getPreference,
            onLogout,
            addOnLogoutListener
        };
    }, [
        activeOrganizationAccount,
        application,
        isAuthenticated,
        organizationAccountDetails,
        preferences,
        preferencesLoaded,
        userProfile
    ]);

    return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
};

export const useFeaturePermissions = (feature: string): PermissionsType => {
    const { userProfile, application, activeOrganizationAccount } = useContext(UserContext);

    let ret: {};
    if (feature && activeOrganizationAccount && userProfile && userProfile.memberships) {
        const features = find(userProfile.memberships, { id: activeOrganizationAccount });
        if (features?.access) {
            const access = find(features.access, { application: application.name, feature: feature });
            if (access) {
                ret = findPermissions(access.permissions);
            }
        }
    }
    return ret || {};
};
