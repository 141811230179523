import { isNil } from 'lodash';

export type Reference = {
    id: string;
    label?: string;
    eventId?: string;
    uriPrefix?: string;
    type?: string;
    deleted?: boolean;
};

export type ReferenceField = {
    reference: Reference;
    label?: string;
};

const isReferenceField = (ref: Reference | ReferenceField): ref is ReferenceField => {
    return ref && (ref as ReferenceField).reference !== undefined;
};

export const getReferenceId = (ref: Reference | ReferenceField): string => {
    if (ref) {
        if (isReferenceField(ref) && !isNil(ref.reference)) {
            return (ref as ReferenceField).reference.id;
        } else {
            return (ref as Reference).id;
        }
    }
};

const referenceToReferenceField = (value: Reference) => {
    const isOfReferenceType = !isNil(value?.id);
    if (value && isOfReferenceType) {
        const referencefieldObject: ReferenceField = {
            reference: {
                id: value.id,
                eventId: value.eventId,
                uriPrefix: value.uriPrefix,
                type: value.type,
                deleted: value.deleted
            },
            label: value.label
        };
        return referencefieldObject;
    }
    return value;
};

export const convertToReferenceFieldObject = (value: Reference | Reference[]) => {
    let returnedReferenceFieldObject;
    if (Array.isArray(value)) {
        returnedReferenceFieldObject = value.map((item) => referenceToReferenceField(item));
    } else {
        returnedReferenceFieldObject = referenceToReferenceField(value);
    }
    return returnedReferenceFieldObject;
};
