import { FetchPolicy } from '@apollo/client';

export const constants: {
    longLivedApolloFetchPolicy: FetchPolicy;
    apolloFetchPolicy: FetchPolicy;
    createId: string;
    networkStatus: Array<string>;
    defaultWidgetSize: 'small' | 'medium';
    defaultWidgetVariant: string;
    newEntityId: string;
    defaultURL: string;
    inactivityTimeout: number;
    inactivityDialogTimeout: number;
    viewKey: string;
    rowsPerPageOptions: number[];
    defaultPageSize: number;
    defaultLang: string;
    defaultCurrencyCode: string;
} = {
    longLivedApolloFetchPolicy: 'cache-first',
    apolloFetchPolicy: 'no-cache',
    createId: 'create',
    networkStatus: ['loading', 'setVariables', 'fetchMore', 'refetch', 'poll', 'ready', 'error'],
    defaultWidgetSize: 'small',
    defaultWidgetVariant: 'outlined' as const,
    newEntityId: 'new',
    defaultURL: 'https://',
    inactivityTimeout: 30 * 60, // 30 minutes
    inactivityDialogTimeout: 60, // 1 minute
    viewKey: 'view',
    rowsPerPageOptions: [24, 48, 96],
    defaultPageSize: 24,
    defaultLang: 'en-US',
    defaultCurrencyCode: 'USD'
};
