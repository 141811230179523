import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import { GridColDef, GridRenderEditCellParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { get } from 'lodash';
import { FormContext } from '../../common/form/FormContext';
import FormPanel from '../../common/form/FormPanel';
import { H } from '../../common/Layout';
import DataGrid, { RowAction } from '../../common/list/DataGrid';
import LookupGridField from '../../common/list/LookupGridField';
import { log } from '../../common/utils/commonUtils';

type AlternateId = {
    label: string;
    value: string;
    url: string;
};

export default () => {
    const { state, validation, handleChange } = useContext(FormContext);

    const altIdsToRows = (altIds: AlternateId[]) => {
        return altIds
            ? altIds.map((altId, index) => {
                  const row = { id: index };
                  colDefs.forEach((col) => {
                      row[col.field] = get(altId, col.field);
                  });
                  row['_raw'] = altId;
                  return row;
              })
            : [];
    };

    const valueGetter = (params: GridValueGetterParams) => {
        return params.row[params.field];
    };

    const defaultOptionRenderer =
        (prop) =>
        ({ option }) => {
            return (
                <Typography className="ellipsis" fontSize={'small'}>
                    {get(option, prop)}
                </Typography>
            );
        };

    const defaultEntityToOption = (entity) => {
        return entity;
    };

    const defaultFieldUpdater = (prop) => (colDefs, field, entity) => {
        const val = get(entity, prop);
        return [{ field: field, value: val }];
    };

    const idType: GridColDef = {
        field: 'label',
        headerName: 'Label',
        flex: 1,
        sortable: true,
        editable: true,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'AlternateIdLabel' }]}
                    OptionRenderer={defaultOptionRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={defaultFieldUpdater('name')}
                />
            );
        }
    };

    const idValue: GridColDef = {
        field: 'value',
        headerName: 'Value',
        flex: 1,
        sortable: true,
        editable: true,
        type: 'string',
        valueGetter: valueGetter
    };

    const idUrl: GridColDef = {
        field: 'url',
        headerName: 'URL',
        flex: 1,
        sortable: true,
        editable: true,
        type: 'string',
        valueGetter: valueGetter
    };

    const colDefs: GridColDef[] = [idType, idValue, idUrl];

    return (
        <FormPanel>
            <H
                sx={{
                    height: '300px'
                }}
            >
                <DataGrid
                    id={'prod-altid-editor'}
                    enableInlineEditing={true}
                    feature={'Production'}
                    rowCount={state.alternateIds.length}
                    rows={altIdsToRows(state.alternateIds)}
                    setRows={(allRows) => {
                        handleChange(
                            'alternateIds',
                            allRows.map((r) => {
                                if (r.label && r.value)
                                    return {
                                        label: r.label,
                                        value: r.value,
                                        url: r.url
                                    };
                            })
                        );
                    }}
                    columns={colDefs}
                    errors={validation?.errors?.alternateIds}
                    serverPagination={false}
                    toolbar={{ add: true }}
                />
            </H>
        </FormPanel>
    );
};
