import React from 'react';
import { Attribute } from '../attributes';
import { LabelValueDisplay } from '../AttributeValue';

type TermsetAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: TermsetAttributePreviewProps) => {
    const { attribute } = props;
    const attributeValue =
        Array.isArray(attribute.value) && attribute.value?.length
            ? attribute.value.join(', ')
            : !attribute.value?.length
              ? undefined
              : attribute.value;

    return attribute && <LabelValueDisplay label={attribute.label} value={attributeValue || '-'} />;
};
