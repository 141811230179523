import { Avatar } from '@mui/material';
import { extractInitials } from '../../production/header/RoundAvatar';
import { stringToColor, useStyling } from '../Theme';
import React from 'react';

function hexToRgba(hex, opacity) {
    // Remove the leading '#' if it's there
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Return the rgba string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const getInitialsAndStylingForAvatar = (value, theme) => {
    const { getContrastText } = theme.palette;
    const bgCol = stringToColor(value);
    const bgColWithOpacity = hexToRgba(bgCol, 0.7);
    const fgCol = getContrastText(bgCol);
    const sxProps = {
        color: fgCol,
        'background-color': bgColWithOpacity,
        width: '30px',
        height: '30px',
        'font-size': '12px',
        'border-radius': '50%',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
    };
    const initials = value ? extractInitials(value).substring(0, 2) : undefined;
    return {
        initials,
        sxProps
    };
};

export default ({ label }) => {
    const { theme } = useStyling();
    const { initials, sxProps } = getInitialsAndStylingForAvatar(label, theme);
    return <Avatar sx={{ ...sxProps }}>{initials}</Avatar>;
};
