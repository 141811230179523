import React, { useContext } from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import { FormContext } from '../../common/form/FormContext';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import { arrayToSelectOptions } from '../../common/utils/arrays';
import FormPanel from '../../common/form/FormPanel';
import ScheduleWidget from '../../common/form/widgets/ScheduleWidget';
import I18n from '../../common/i18n/I18n';
import { ProductionOptionRenderer } from '../../supply/booking/ProductionOptionRenderer';
import { getOption } from '../helpers/QuickAddProduction';
import ProductionTrackFormItems, { TrackSectionTitle } from './production/ProductionTrackFormItems';

export const runtimes = ['30', '40', '60', '90', '120'];

type ProductionFormItemsType = {
    enableConvertingToManaged: boolean;
    onCreateHandle: (e) => void;
};

export default ({ enableConvertingToManaged = false, onCreateHandle = undefined }: ProductionFormItemsType) => {
    const { state } = useContext(FormContext);

    return (
        <FormPanel>
            <FormContainer>
                <FormItem half>
                    {enableConvertingToManaged ? (
                        <FormWidget
                            name="name"
                            component="ReferenceAutocomplete"
                            entity="Production"
                            label={<I18n token="production.form.field.name" />}
                            OptionRenderer={ProductionOptionRenderer}
                            getOption={getOption}
                            preload={false}
                            allowFreeForm={true}
                            filter={{ expressions: [{ field: 'managedProduction', value: { values: ['false'] } }] }}
                        />
                    ) : (
                        <FormWidget name="name.label" label={<I18n token="production.form.field.name" />} />
                    )}
                </FormItem>
                <FormItem half>
                    <FormWidget name="otherTitles" label={<I18n token="production.form.field.aka" />} mapTo={'AKA'} />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        name="productionType"
                        label={<I18n token="production.form.field.type" />}
                        component="MetadataAutocomplete"
                        metadataName="ShowType"
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        component="MetadataAutocomplete"
                        metadataName="Genre"
                        name="genre"
                        label={<I18n token="production.form.field.genre" />}
                        multiple
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem hidden={state.productionType !== 'Series'}>
                    <FormWidget
                        component={'Number'}
                        name="showAttributes"
                        label={<I18n token="production.form.field.noOfEpisodes" />}
                        mapTo="noOfEpisodes"
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half hidden={state.productionType !== 'Season'}>
                    <FormWidget
                        component={'Number'}
                        name="showAttributes"
                        label={<I18n token="production.form.field.seasonNo" />}
                        mapTo="seasonNo"
                    />
                </FormItem>
                <FormItem half hidden={state.productionType !== 'Season'}>
                    <FormWidget
                        component={'Number'}
                        name="showAttributes"
                        label={<I18n token="production.form.field.noOfEpisodes" />}
                        mapTo="noOfEpisodes"
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem hidden={state.productionType !== 'Episode'}>
                    <FormWidget
                        component={'Number'}
                        name="showAttributes"
                        label={<I18n token="production.form.field.episodeNo" />}
                        mapTo="episodeNo"
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        component="MetadataAutocomplete"
                        metadataName="ProductionStatus"
                        name="productionStatus"
                        label={<I18n token="production.form.field.status" />}
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        component="Location"
                        name="locations"
                        label={<I18n token="production.form.field.locations" />}
                        multiple
                        limitTags={1}
                    />
                </FormItem>
            </FormContainer>
            <FormItem>
                <FormWidget
                    component="SectionTitle"
                    i18nToken="production.form.schedule"
                    containerSx={{ textTransform: 'uppercase' }}
                />
            </FormItem>
            <FormContainer>
                <ScheduleWidget
                    showFullSchedule={true}
                    name="productionDates.dateRange"
                    allowedUnits={['day', 'week', 'month']}
                />
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        component="Date"
                        name="releaseDate"
                        label={<I18n token="production.form.field.releaseDate" />}
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget name="productionYears" label={<I18n token="production.form.field.productionYears" />} />
                </FormItem>
                <TrackSectionTitle />
            </FormContainer>
            <ProductionTrackFormItems onCreateHandle={onCreateHandle} />
            <FormItem>
                <FormWidget
                    component="SectionTitle"
                    i18nToken="production.form.about"
                    containerSx={{ textTransform: 'uppercase' }}
                />
            </FormItem>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="productionGroup"
                        component="ReferenceAutocomplete"
                        entity="Production"
                        label={<I18n token="production.form.field.associatedTitle" />}
                    />
                </FormItem>
                <FormItem>
                    <FormWidget
                        name="about"
                        component={'TextArea'}
                        label={<I18n token="production.form.field.summary" />}
                        minRows={2}
                    />
                </FormItem>
                <FormItem>
                    {/* TODO: fix Formwidget->Autocomplete so the i18 tag can be used in label */}
                    <FormWidget
                        component="Autocomplete"
                        name="showAttributes"
                        label="Runtime"
                        options={arrayToSelectOptions(runtimes)}
                        mapTo="runtime"
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        component="Currency"
                        name="budget.currencyCode"
                        label={<I18n token="production.form.field.currency" />}
                        disableClearable
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget component="Money" name="budget" label={<I18n token="production.form.field.budget" />} />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
