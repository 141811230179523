import {
    calculateDifference,
    destructureSchedule,
    mergeDateAndTime,
    splitDateAndTime
} from '../../common/utils/dateTime';
import { get, set } from 'lodash';
import { add, differenceInSeconds, isBefore, isEqual, isValid, sub } from 'date-fns';
import Validator from '../../common/form/Validator';
import { formatNumber } from '../../common/utils/numbers';
import { Listener } from '../../common/form/FormContext';

const DECIMALS_PER_UNIT = {
    hour: 2
};
const showTimeForUnits = ['hour'];
const defaultEndTimeDiff = 1;

export const getDecimalsForDurationUnit = (unit) => {
    return DECIMALS_PER_UNIT[unit];
};

const durationToSeconds = (unit, value) => {
    if (unit === 'minute') {
        return value * 60;
    } else if (unit === 'hour') {
        return value * 3600;
    } else if (unit === 'day') {
        return value * 86400;
    } else if (unit === 'week') {
        return value * 604800;
    }
};

const secondsToDuration = (seconds, unit) => {
    let duration = 0;
    if (unit === 'minute') {
        duration = seconds / 60;
    } else if (unit === 'hour') {
        duration = seconds / 3600;
    } else if (unit === 'day') {
        duration = seconds / 86400;
    } else if (unit === 'week') {
        duration = seconds / 604800;
    }
    const decimals = DECIMALS_PER_UNIT[unit];
    return parseFloat(formatNumber(duration, { decimals }));
};

const addDuration = (mergedStart, unit, duration) => {
    if (unit === 'week') {
        return add(mergedStart, { days: duration * 7 - 1 });
    }
    return add(mergedStart, { [`${unit}s`]: unit === 'day' ? duration - 1 : duration });
};

export const roundUpByDurationUnit = (unit, value: number) => {
    let roundedUp;
    switch (unit) {
        case 'week':
            const decimalPart = value - Math.floor(value);
            roundedUp =
                decimalPart > 0.88
                    ? Math.ceil(value)
                    : parseFloat(formatNumber(value, { decimals: 2, useGrouping: false }));
            break;
        case 'day':
            roundedUp = Math.ceil(value);
            break;
        default:
            roundedUp = parseFloat(formatNumber(value, { decimals: 2, useGrouping: false }));
            break;
    }
    return roundedUp;
};

export const recalculateDuration = (prop, value, newState) => {
    const dateRange = get(newState, prop);
    if (dateRange) {
        const { start, startTime, end, endTime, durationSpecified, dateTimeDuration } = destructureSchedule(dateRange);
        const unit = dateTimeDuration.unit;
        const hasTime = showTimeForUnits.includes(dateTimeDuration.unit);
        const mergedStart = start && startTime ? mergeDateAndTime(start, startTime, !hasTime) : undefined;

        if (!durationSpecified && unit) {
            let mergedEnd = end && endTime ? mergeDateAndTime(end, endTime, !hasTime) : undefined;

            if (isValid(mergedEnd) && (isBefore(mergedStart, mergedEnd) || isEqual(mergedStart, mergedEnd))) {
                const seconds = differenceInSeconds(mergedEnd, mergedStart, { roundingMethod: 'ceil' });
                let diff = secondsToDuration(seconds, unit);
                if (dateTimeDuration.unit === 'day') {
                    diff++;
                }
                set(newState, `${prop}.dateTimeDuration.value`, roundUpByDurationUnit(unit, diff));
            }
        }
    }
};

export const recalculateProductionYears = (prop, _value, newState) => {
    const dateRange = get(newState, prop);
    if (dateRange) {
        const { start, end } = destructureSchedule(dateRange);
        set(newState, `productionYears`, calculateDifference(start, end, 'year'));
    }
};

/**
 * Date range change listeners. If multipile are defined for a property, they will be executed in the order in which they are defined.
 * @param prop Name of the parent date range property.
 */
export const getDateRangeListeners = (prop): Listener[] => [
    {
        prop: [`${prop}.start`, `${prop}.startTime`, `${prop}.end`, `${prop}.endTime`, `${prop}.dateTimeDuration.unit`],
        func: (name, newValue, newState) => recalculateDuration(prop, newValue, newState)
    },
    {
        prop: `${prop}.start`,
        func: (name, newValue, newState) => {
            const dateRange = get(newState, prop);
            if (dateRange) {
                const { durationSpecified, dateTimeDuration, mergedStart, mergedEnd } = destructureSchedule(dateRange);

                const unit = dateTimeDuration.unit;
                // If duration is not specified set the default.
                const duration = dateTimeDuration.value || defaultEndTimeDiff;
                const isDay = dateTimeDuration.unit === 'day';

                if (isValid(mergedStart)) {
                    // If end date is not specified or unit is hour, we set end date to start date value.
                    // if (unit === 'hour') {
                    //     const start = get(newState, `${prop}.start`);
                    //     set(newState, `${prop}.end`, start);
                    // } else {
                    // If start time is changing and there is no end time or end is before start,
                    // or duration is specified -> set end to start + duration.
                    if (!isValid(mergedEnd) || isBefore(mergedEnd, mergedStart) || durationSpecified) {
                        const adjustedEnd = addDuration(mergedStart, unit, duration);

                        const [newEnd, newEndTime] = splitDateAndTime(adjustedEnd);

                        set(newState, `${prop}.end`, newEnd);
                        if (unit == 'hour') {
                            set(newState, `${prop}.endTime`, newEndTime);
                        }
                        // set(newState, `${prop}.dateTimeDuration.value`, duration);
                    }
                    // }
                } else if (!isValid(mergedStart) && durationSpecified) {
                    // as per scheduler calculation, if duration is specified, end date should be calculated
                    // if start dates is not valid or is deleted, end date should also be set to ''
                    if (newValue === null) {
                        // if start date is deleted, schedulerWidget, sets it to null, however, it should be passed as '';
                        set(newState, `${prop}.start`, '');
                    }
                    set(newState, `${prop}.end`, '');
                }
            }
        }
    },
    {
        prop: `${prop}.startTime`,
        func: (name, newValue, newState) => {
            const dateRange = get(newState, prop);
            if (dateRange) {
                const { durationSpecified, dateTimeDuration, mergedStart, mergedEnd } = destructureSchedule(dateRange);

                const unit = dateTimeDuration.unit;
                // If duration is not specified set the default.
                const duration = dateTimeDuration.value || defaultEndTimeDiff;
                const endTime = get(newState, `${prop}.endTime`);

                // If start time is changing and there is no end time or end is before start, set end to start + duration.
                if (isValid(mergedStart) && (!endTime || isBefore(mergedEnd, mergedStart) || durationSpecified)) {
                    const adjustedEnd = add(mergedStart, { [`${unit}s`]: duration });
                    const [newEnd, newEndTime] = splitDateAndTime(adjustedEnd);

                    set(newState, `${prop}.end`, newEnd);
                    set(newState, `${prop}.endTime`, newEndTime);
                    //set(newState, `${prop}.dateTimeDuration.value`, duration);
                }
            }
        }
    },
    {
        prop: `${prop}.end`,
        func: (name, newValue, newState) => {
            const dateRange = get(newState, prop);
            if (dateRange) {
                const { dateTimeDuration, mergedStart, mergedEnd } = destructureSchedule(dateRange);
                const unit = dateTimeDuration.unit;
                const duration = dateTimeDuration.value;
                const isDay = unit === 'day';

                // If it's hours and end is before start, move start to end - duration
                if (isBefore(mergedEnd, mergedStart)) {
                    const start = sub(mergedEnd, { [`${unit}s`]: isDay ? duration - 1 : duration });
                    const [newStart, newStartTime] = splitDateAndTime(start);
                    set(newState, `${prop}.start`, newStart);
                    set(newState, `${prop}.startTime`, newStartTime);
                }
            }
        }
    },
    {
        prop: `${prop}.endTime`,
        func: (name, newValue, newState) => {
            const dateRange = get(newState, prop);
            if (dateRange) {
                const { dateTimeDuration, mergedStart, mergedEnd } = destructureSchedule(dateRange);
                const unit = dateTimeDuration.unit;
                const duration = dateTimeDuration.value || defaultEndTimeDiff;

                // If end time is changing and end is before start, adjust start to end - duration.
                if ((isValid(mergedEnd) && isBefore(mergedEnd, mergedStart)) || isEqual(mergedEnd, mergedStart)) {
                    const adjustedStart = sub(mergedEnd, { [`${unit}s`]: duration });
                    const [newStart, newStartTime] = splitDateAndTime(adjustedStart);
                    set(newState, `${prop}.start`, newStart);
                    set(newState, `${prop}.startTime`, newStartTime);
                }
            }
        }
    },
    {
        prop: `${prop}.dateTimeDuration.unit`,
        func: (name, newValue, newState) => {
            const showTime = showTimeForUnits.includes(newValue);
            if (showTime) {
                set(newState, `${prop}.startTime`, '090000');
                set(newState, `${prop}.endTime`, '000000');
            } else {
                set(newState, `${prop}.startTime`, '');
                set(newState, `${prop}.endTime`, '');
            }

            const dateRange = get(newState, prop);
            if (dateRange) {
                const { durationSpecified, dateTimeDuration, mergedStart, mergedEnd } = destructureSchedule(dateRange);

                const unit = dateTimeDuration.unit;
                // If duration is not specified set the default.
                const duration = dateTimeDuration.value || defaultEndTimeDiff;
                const endTime = get(newState, `${prop}.endTime`);

                if (isValid(mergedStart)) {
                    // If start time is changing and there is no end time or end is before start, set end to start + duration.
                    if ((!endTime || isBefore(mergedEnd, mergedStart)) && durationSpecified) {
                        const adjustedEnd = addDuration(mergedStart, unit, duration);
                        const [newEnd, newEndTime] = splitDateAndTime(adjustedEnd);

                        set(newState, `${prop}.end`, newEnd);
                        if (unit == 'hour') {
                            set(newState, `${prop}.endTime`, newEndTime);
                        }
                        set(newState, `${prop}.dateTimeDuration.value`, roundUpByDurationUnit(unit, duration));
                    }
                }
            }
        }
    },
    {
        prop: `${prop}.dateTimeDuration.value`,
        func: (name, newValue, newState) => {
            const dateRange = get(newState, prop);
            if (dateRange) {
                const { dateTimeDuration, mergedStart } = destructureSchedule(dateRange);
                const unit = dateTimeDuration.unit;
                const duration = newValue || defaultEndTimeDiff;
                const isDay = dateTimeDuration.unit === 'day';

                if (isValid(mergedStart)) {
                    const adjustedEnd = addDuration(mergedStart, unit, duration);
                    const [newEnd, newEndTime] = splitDateAndTime(adjustedEnd);

                    set(newState, `${prop}.end`, newEnd);
                    if (unit == 'hour') {
                        set(newState, `${prop}.endTime`, newEndTime);
                    }
                }
            }
        }
    }
];

export const isStartBeforeEnd = (prop, state, isModified) => {
    const dateRange = get(state, prop);
    if (dateRange) {
        const { mergedStart, mergedEnd } = destructureSchedule(dateRange);
        const isValid = isBefore(mergedStart, mergedEnd) || isEqual(mergedStart, mergedEnd);
        return !isModified || isValid;
    }
};

export const getDateRangeValidators = (prop) => {
    return {
        [`${prop}.start`]: [
            Validator.RULES.isRequired,
            Validator.RULES.isValidDate,
            Validator.Custom((name, state, isModified) => {
                return isStartBeforeEnd(prop, state, isModified);
            }, 'Start is after end.')
        ],
        [`${prop}.startTime`]: [
            Validator.Custom((name, state, isModified) => {
                return isStartBeforeEnd(prop, state, isModified);
            }, 'Start is after end.')
        ],
        [`${prop}.end`]: [
            Validator.RULES.isValidDate,
            Validator.Custom((name, state, isModified) => {
                return isStartBeforeEnd(prop, state, isModified);
            }, 'End is before start.')
        ],
        [`${prop}.endTime`]: [
            Validator.Custom((name, state, isModified) => {
                return isStartBeforeEnd(prop, state, isModified);
            }, 'End is before start.')
        ]
    };
};
