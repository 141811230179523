import React from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import I18n from '../../common/i18n/I18n';
import PersonOptionRenderer from './PersonOptionRenderer';

import InquiryProductionFormItems from './inquiry/InquiryProductionFormItems';
import InquiryDateRangeFormItem from './inquiry/InquiryDateRangeFormItem';

export default ({ productionName, productionOptions, productionDateRange }) => {
    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        disabled
                        name="people"
                        label={<I18n token="shortlist.lineItem.person.invitee" />}
                        component="ReferenceAutocomplete"
                        addOption
                        multiple
                        entity="Person"
                        OptionRenderer={PersonOptionRenderer}
                        getOption={(item) => {
                            return {
                                id: item.id,
                                name: item.name,
                                role: item.role,
                                label: item.name,
                                ref: { ...item.reference, label: item.name },
                                professionalRoles: item.professionalRoles
                            };
                        }}
                        preload={false}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <InquiryProductionFormItems productionOptions={productionOptions} productionName={productionName} />
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        name="role"
                        label={<I18n token="form.resource.role" />}
                        component="MetadataAutocomplete"
                        metadataName="Role"
                        disabled
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        component="Location"
                        name="locations"
                        label={<I18n token="form.resource.locations" />}
                        multiple
                        limitTags={1}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="productionSummary"
                        component={'TextArea'}
                        label={<I18n token="shortlist.lineItem.person.production.summary" />}
                        minRows={3}
                    />
                </FormItem>
            </FormContainer>
            <InquiryDateRangeFormItem dateRange={productionDateRange} />
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="personalizedMessage"
                        component={'TextArea'}
                        label={<I18n token="shortlist.lineItem.person.personalizedMessage" />}
                        minRows={3}
                    />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
