import React, { useContext } from 'react';
import FormPanel from '../../common/form/FormPanel';
import ScheduleWidget from '../../common/form/widgets/ScheduleWidget';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import { FormContainer } from '../../common/form/FormContainer';
import { FormContext } from '../../common/form/FormContext';

export default () => {
    const { state } = useContext(FormContext);
    return (
        <FormPanel>
            <FormContainer>
                <FormItem span={12} sx={{ mb: 2 }}>
                    <FormWidget hideHelperText component={'Switch'} name="dateRange.durationSpecified" label={'Specify duration'} />
                    <FormWidget hideHelperText component={'Switch'} name="transient.scheduleDisabled" label={'Disable'} />
                    <FormWidget hideHelperText component={'Switch'} name="transient.showFullSchedule" label={'Show full'} />
                    <FormWidget hideHelperText component={'Switch'} name="transient.disableWeekends" label={'Disable weekends'} />
                    <FormWidget hideHelperText component={'Switch'} name="transient.isMilestone" label={'Milestone'} />
                </FormItem>
                <ScheduleWidget
                    disabled={state.transient.scheduleDisabled}
                    disableWeekends={state.transient.disableWeekends}
                    showFullSchedule={state.transient.showFullSchedule}
                    isMilestone={state.transient.isMilestone}
                    name="dateRange"
                    allowedUnits={['hour', 'day', 'week']}
                />
            </FormContainer>
        </FormPanel>
    );
};
