import React from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#F9ECEB',
        borderRadius: 1,
        alignItems: 'center',
        padding: '6px 16px',
        gap: '12px',
        color: '#4D1619',
        fontWeight: '400',
        fontSize: '14px'
    }
}));
