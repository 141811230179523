import { TablePagination } from '@mui/material';
import React from 'react';
import { usePersistedSearchkitVariables } from '../../../common/components/filtering/filters';
import { useStyling } from '../../../common/Theme';
import { constants } from '../../../common/constants';

export type PaginationConfig = {
    from: number;
    pageNumber: number;
    size: number;
    total: number;
    totalPages: number;
};

export type PaginationProps = {
    id: string;
    pagination: PaginationConfig;
};

export default (props: PaginationProps) => {
    const { id, pagination } = props;
    const { theme } = useStyling();
    const { pageNumber, setPageNumber, pageSize, setPageSize } = usePersistedSearchkitVariables(id);

    return (
        <TablePagination
            sx={{ alignSelf: 'flex-end', overflow: 'hidden', minHeight: '52px', width: '100%', borderTop: `1px solid ${theme.palette.divider}` }}
            component="div"
            rowsPerPageOptions={constants.rowsPerPageOptions}
            count={pagination ? pagination.total : 0}
            page={pageNumber}
            onPageChange={(event, page) => {
                setPageNumber(page);
            }}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(event) => {
                setPageSize(parseInt(event.target.value));
                setPageNumber(0);
            }}
        />
    );
};
