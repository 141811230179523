import { Box, SvgIcon, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import React from 'react';
import { constants } from '../constants';
import useHistory from '../utils/useHistory';

export type View = {
    icon: typeof SvgIcon;
    label: string;
    Component: any;
    default?: boolean;
    showFacets?: boolean;
    disableAddActions?: boolean;
};

type ViewTypeSelectorProps = {
    views: View[];
    selectedViewIndex: number;
    setSelectedViewIndex: (number) => void;
    id: string;
};

export default (props: ViewTypeSelectorProps) => {
    const { views, selectedViewIndex, setSelectedViewIndex, id } = props;
    const { changeSearch, searchParams } = useHistory();
    const currentView = searchParams.get(`${id}_${constants.viewKey}`);

    return (
        <ToggleButtonGroup
            sx={{ ml: 1, cursor: 'not-allowed' }}
            value={selectedViewIndex}
            exclusive={true}
            onChange={(e, newValue) => {
                setSelectedViewIndex(newValue);
                changeSearch({ [`${id}_${constants.viewKey}`]: views[newValue]?.label });
            }}
        >
            {views.map((view, index) => (
                <Tooltip key={index} title={view.label}>
                    <Box>
                        <ToggleButton key={index} value={index} disabled={view.label === currentView}>
                            <view.icon />
                        </ToggleButton>
                    </Box>
                </Tooltip>
            ))}
        </ToggleButtonGroup>
    );
};
