import React from 'react';
import { Box, Typography } from '@mui/material';
import { Icons } from '../common/icons/Icons';
import CreateAccount from '../common/auth/CreateAccount';
import { V } from '../common/Layout';
import I18n from '../common/i18n/I18n';

export default ({ name }: { name: string }) => {
    return (
        <CreateAccount
            message={
                <V sx={{ alignItems: 'center' }}>
                    <Typography fontSize="large" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Icons.Success color="success" />{' '}
                        <I18n token="form.inquiryResponse.success.thankYou" values={{ name: name }} />
                    </Typography>
                    <Typography fontSize="small">
                        <I18n token="form.inquiryResponse.success.message" values={{ name: name }} />
                    </Typography>
                </V>
            }
        />
    );
};
