import { ClassNameMap } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { useStyling } from '../Theme';

export const useDataGridStyles = (): ClassNameMap<string> => {
    const { theme, isDarkMode } = useStyling();
    return makeStyles({
        tableRow: {
            '&.MuiDataGrid-row--editing  > * > [class|="MuiDataGrid-actionsCell"]': {
                '&:not(:empty)': {
                    visibility: 'visible'
                }
            },
            '&.Mui-hovered  > * > [class|="MuiDataGrid-actionsCell"]': {
                '&:not(:empty)': {
                    visibility: 'visible'
                },
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                color: theme.palette.text.primary,
                borderRadius: '18px',
                padding: '0 8px',
                height: '42px'
            }
        },
        rightActionsCell: {
            '& .MuiDataGrid-actionsCell': {
                gridGap: 0,
                visibility: 'hidden'
            }
        },
        container: {
            height: '100%',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            '& .MuiDataGrid-root': {
                backgroundColor: theme.palette.background.paper
            },
            '& .MuiDataGrid-row:hover': {
                backgroundColor: isDarkMode ? grey['700'] : grey['100']
            },
            '& .MuiDataGrid-row.Mui-hovered': {
                backgroundColor: isDarkMode ? grey['700'] : grey['100']
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
                p: 0
            },
            '& .MuiInputBase-input': {
                pl: 1,
                pr: 1
            },
            '& .MuiDataGrid-pinnedColumns': {
                boxShadow: 'none',
                backgroundImage: 'none',
                backgroundColor: 'unset'
            },
            '& .MuiDataGrid-pinnedColumnHeaders': {
                boxShadow: 'none',
                backgroundImage: 'none',
                backgroundColor: 'unset'
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'break-spaces',
                lineHeight: 1.25
            },
            // Editing row has different styling
            '& .MuiDataGrid-row--editing': {
                backgroundColor: `darken(${theme.palette.background.default},10) !important`,
                // And cells are transparent
                '& .MuiDataGrid-cell': {
                    backgroundColor: 'transparent'
                }
            },
            '& .MuiDataGrid-cell--editing': {
                backgroundColor: theme.palette.background.default
            },
            '& .MuiDataGrid-cell--errors': {
                background: `linear-gradient(to top right,transparent 50%,${theme.palette.error.main} 50.1%) top right/10px 10px no-repeat,transparent;`
            }
        }
    })();
};
