import { useMutation } from '@apollo/client';
import { TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { formatISO } from 'date-fns';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NoteInput } from 'sr-types/lib/note/v1/graphql';
import { Note, Reference } from 'sr-types/lib/search/v1/graphql';
import LexicaljsEditor from '../../lab/form/lexical/LexicaljsEditor';
import { ScrollableLayout, V } from '../Layout';
import { useStyling } from '../Theme';
import { UserContext } from '../auth/UserContext';
import { AllPermissions, PermissionsType } from '../auth/api';
import Error from '../components/Error';
import Loading from '../components/Loading';
import NotFound from '../components/NotFound';
import { enqueueSnackbar } from '../components/Toast';
import SidePanel from '../components/layout/SidePanel';
import I18n, { useI18n } from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import EmptyPromise from '../utils/EmptyPromise';
import { toISOFromJSDate } from '../utils/dateTime';
import NoteRenderer from './NoteRenderer';
import { client, removeNote, saveNote, useGetNote } from './notesApi';
import Confirm from '../components/Confirm';

const SORTING_ENABLED = true;

const NotesSearchInput = ({ id, value, onChange }) => {
    const searchLabel = useI18n('notes.search');
    const { theme } = useStyling();
    const buttonSx: SxProps = { ml: 1, '&:hover': { cursor: 'pointer' }, fontColor: theme.palette.text.primary };

    return (
        <TextField
            id={`${id}-input`}
            sx={{ mb: 1, mt: 0.5 }}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            variant="outlined"
            fullWidth
            size={'small'}
            InputProps={{
                endAdornment: (
                    <>
                        {value && value.length && (
                            <Icons.Close
                                sx={buttonSx}
                                onClick={(e) => {
                                    onChange('');
                                }}
                            />
                        )}
                        <Icons.Search sx={buttonSx} />
                    </>
                ),
                // autoFocus: true,
                inputProps: {
                    placeholder: searchLabel
                }
            }}
        />
    );
};

const notesSorter = (a: Note, b: Note, sortAsc: boolean) => {
    return sortAsc
        ? a.updatedOn < b.updatedOn
            ? -1
            : a.updatedOn > b.updatedOn
              ? 1
              : 0
        : a.updatedOn < b.updatedOn
          ? 1
          : a.updatedOn > b.updatedOn
            ? -1
            : 0;
};

type NotesRendererType = {
    notes: Note[];
    collapsed: boolean;
    doSave: (modified: NoteInput, original: Note) => Promise<any>;
    doDelete: (noteId: string) => Promise<any>;
    featurePermissions: PermissionsType;
    entityPermissions: PermissionsType;
};

type NotesProps = {
    id: string;
    title?: string | ReactElement;
    entityReference: Reference;
    onClose: () => void;
    featurePermissions: object;
    entityPermissions: object;
};

export default (props: NotesProps) => {
    const { id, title = 'Notes', entityReference, onClose, featurePermissions, entityPermissions } = props;
    const collapsedKey = `${id}-collapsed`;
    const [collapsed, setCollapsed] = useState(localStorage.getItem(collapsedKey) === 'true');
    const sortKey = `${id}-sort`;
    const [sortAsc, setSortAsc] = useState(localStorage.getItem(sortKey) === 'true');
    const { userProfile, activeOrganizationAccount } = useContext(UserContext);
    const [term, setTerm] = useState<string>('');
    const [notes, setNotes] = useState<Note[]>([]);
    const [saveMutation] = useMutation(saveNote, { client: client });
    const [deleteMutation] = useMutation(removeNote, { client: client });
    const [isEditing, setIsEditing] = useState(false);
    const hasPermission = featurePermissions[AllPermissions.Manage] && entityPermissions[AllPermissions.Comment];
    const { noteData, isNoteLoading, noteFetchError } = useGetNote(entityReference.id, !entityReference.id);
    const saveSuccess = useI18n('notes.toast.save.success');
    const saveFailure = useI18n('notes.toast.save.failure');
    const contentPlaceholder = useI18n('notes.placeholder.content');
    const [deleteNoteId, setDeleteNoteId] = useState(undefined);
    const sidePanelActions = [
        {
            icon: collapsed ? Icons.UnfoldMore : Icons.UnfoldLess,
            label: collapsed ? 'Expand' : 'Collapse',
            onClick: () =>
                setCollapsed((prevCollapsed) => {
                    localStorage.setItem(collapsedKey, (!collapsed).toString());
                    return !collapsed;
                })
        },
        {
            icon: Icons.Close,
            label: 'Close',
            onClick: onClose
        }
    ];
    if (SORTING_ENABLED) {
        sidePanelActions.unshift({
            icon: sortAsc ? Icons.ArrowUpward : Icons.ArrowDownward,
            label: sortAsc ? 'Sort descending' : 'Sort ascending',
            onClick: () =>
                setSortAsc((prevSortAsc) => {
                    localStorage.setItem(sortKey, (!prevSortAsc).toString());
                    return !prevSortAsc;
                })
        });
    }

    useEffect(() => {
        if (notes && notes.length) {
            console.log('sorting notes:', sortAsc ? 'asc' : 'desc');
            setNotes((prevNotes) => {
                const clone = [...notes];
                clone.sort((a, b) => notesSorter(a, b, sortAsc));
                return clone;
            });
        }
    }, [sortAsc]);

    useEffect(() => {
        if (noteFetchError) {
            console.error('Failed to fetch notes:', noteFetchError);
        } else if (noteData && noteData.results.hits.items) {
            if (SORTING_ENABLED) {
                noteData.results.hits.items.sort((a, b) => notesSorter(a, b, sortAsc));
            }
            setNotes(noteData.results.hits.items);
        }
    }, [noteData]);

    const doSave = useCallback(
        (modified: NoteInput, original: Note) => {
            return saveMutation({
                variables: {
                    input: modified
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((res) => {
                    if (res.data.saveNote.errors) {
                        enqueueSnackbar(saveFailure, { variant: 'error' });
                    } else {
                        enqueueSnackbar(saveSuccess, { variant: 'success' });

                        const newNote: Note = {
                            id: res.data.saveNote.id,
                            content: modified.content,
                            title: modified.title,
                            name: modified.title,
                            entityReference: modified.entityReference,
                            createdOn: original.createdOn,
                            createdByName: original.createdByName,
                            updatedOn: formatISO(new Date()), // This could be different from service, but we don't care.
                            headline: '',
                            uri: ''
                        };
                        setNotes((prevNotes) => {
                            const existing: boolean = notes.some((note) => note.id === newNote.id);
                            const clone: Note[] = existing
                                ? notes.map((note) => (note.id === newNote.id ? newNote : note))
                                : [...notes, newNote];
                            if (SORTING_ENABLED) {
                                clone.sort((a, b) => notesSorter(a, b, sortAsc));
                            }
                            return clone;
                        });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(saveFailure, { variant: 'error' });
                });
        },
        [notes]
    );

    const doDelete = useCallback(
        (noteId: string) => {
            return deleteMutation({
                variables: {
                    id: noteId
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((res) => {
                    if (res.data.deleteNote.errors) {
                        console.error('Delete note error:', res.data.deleteNote.errors);
                    } else {
                        setNotes((prevNotes) => {
                            return prevNotes.filter((note) => note.id !== noteId);
                        });
                    }
                    setDeleteNoteId(undefined);
                })
                .catch((err) => {
                    console.warn(err);
                });
        },
        [notes]
    );

    const onDeleteHandle = (noteId: string) => {
        return new Promise((resolve, reject) => {
            setDeleteNoteId(noteId);
        });
    };

    const filteredNotes: Note[] = useMemo(() => {
        return notes.filter((n) => {
            return (
                !term.length ||
                n.content.toLowerCase().includes(term.toLowerCase()) ||
                n.title.toLowerCase().includes(term.toLowerCase())
            );
        });
    }, [notes, term]);

    const emptyNote: Note = {
        title: '',
        content: '',
        entityReference: entityReference,
        createdOn: toISOFromJSDate(new Date()),
        createdByName: `${userProfile.name.firstName} ${userProfile.name.lastName}`,
        headline: '',
        id: '',
        name: '',
        uri: ''
    };

    return (
        <SidePanel id={'notes-contaner'} title={title} actions={sidePanelActions}>
            {isNoteLoading ? (
                <Loading />
            ) : noteFetchError ? (
                <Error error={noteFetchError} />
            ) : (
                <V sx={{ height: '100%', gap: 1, overflow: 'hidden' }}>
                    <V sx={{ gap: 1, px: 1 }}>
                        <NotesSearchInput id={'notes-search-input'} value={term} onChange={setTerm} />
                        {hasPermission && (
                            <LexicaljsEditor
                                id={'new-note'}
                                contentPlaceholder={contentPlaceholder}
                                readOnly={false}
                                clearOnSave={true}
                                clearOnCancel={true}
                                content={emptyNote}
                                onSave={(title: string, content: string) => {
                                    const modified: NoteInput = {
                                        contentType: 'text/enriched',
                                        title: title,
                                        content: content,
                                        entityReference: entityReference
                                    };
                                    return doSave(modified, emptyNote);
                                }}
                                onCancel={() => {
                                    return EmptyPromise;
                                }}
                            />
                        )}
                    </V>
                    {filteredNotes && filteredNotes.length ? (
                        <ScrollableLayout sx={{ pr: 1 }}>
                            {filteredNotes.map((n, index) => (
                                <NoteRenderer
                                    key={`${index}_${n.id}`}
                                    note={n}
                                    collapsed={collapsed}
                                    isAnyEditing={isEditing}
                                    setIsAnyEditing={setIsEditing}
                                    onSave={(title: string, content: string) => {
                                        const modified: NoteInput = {
                                            identity: {
                                                id: n.id
                                            },
                                            contentType: 'text/enriched',
                                            title: title,
                                            content: content,
                                            // labels: 'test-richText-note',
                                            entityReference: n.entityReference
                                        };
                                        return doSave(modified, n);
                                    }}
                                    onDelete={(noteId: string) => {
                                        return onDeleteHandle(noteId);
                                    }}
                                    featurePermissions={featurePermissions}
                                    entityPermissions={entityPermissions}
                                />
                            ))}
                        </ScrollableLayout>
                    ) : (
                        <NotFound text={'No notes found'} />
                    )}
                    <Confirm
                        open={Boolean(deleteNoteId)}
                        title={<I18n token={'notes.delete'} />}
                        body={<I18n token={'notes.delete.message'} />}
                        onConfirm={() => doDelete(deleteNoteId)}
                        onCancel={() => setDeleteNoteId(undefined)}
                    />
                </V>
            )}
        </SidePanel>
    );
};
