import { Box, Popover, Typography } from '@mui/material';
import React from 'react';

export default ({ text, sx = undefined }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Box className="clickable clamp" onClick={handleClick} sx={{ wordBreak: 'break-word', ...sx }}>
                {text}
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Typography sx={{ maxWidth: '400px', p: 2, wordBreak: 'break-word' }}>{text}</Typography>
            </Popover>
        </>
    );
};
