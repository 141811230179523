import React from 'react';
import { FormContainer } from '../../../common/form/FormContainer';
import FormItem from '../../../common/form/FormItem';
import FormWidget from '../../../common/form/FormWidget';
import I18n from '../../../common/i18n/I18n';
import PersonOptionRenderer from '../../../shortlists/form/PersonOptionRenderer';

export const TrackSectionTitle = () => {
    return (
        <FormItem>
            <FormWidget
                component="SectionTitle"
                i18nToken="production.form.title.track"
                containerSx={{ textTransform: 'uppercase' }}
            />
        </FormItem>
    );
};

export default ({ onCreateHandle }) => {
    return (
        <FormContainer>
            {/* We may use it later on */}
            {/* <FormItem half>
                <FormWidget
                    component="ReferenceAutocomplete"
                    entity="Person"
                    name="projectOwner"
                    label={<I18n token="production.form.field.owner" />}
                    addOption
                    OptionRenderer={PersonOptionRenderer}
                    getOption={(item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            role: item.role,
                            label: item.name,
                            ref: { ...item.reference, label: item.name }
                        };
                    }}
                    onCreateHandle={onCreateHandle}
                    preload={false}
                />
            </FormItem> */}
            <FormItem half>
                <FormWidget
                    component="MetadataAutocomplete"
                    metadataName="Termset"
                    filters={[{ identifier: 'name', value: 'ProductionProjectStatus' }]}
                    termset="ProductionProjectStatus"
                    name="projectStatus"
                    label={<I18n token="production.form.field.status" />}
                    allowCreate
                />
            </FormItem>
        </FormContainer>
    );
};
