import useResizeObserver from '@react-hook/resize-observer';
import { MutableRefObject, useLayoutEffect, useState } from 'react';

const setNewWidth = (newSize: DOMRect, width: number, setWidth: (w) => void) => {
    if (newSize?.width) {
        const newWidth = Math.floor(newSize?.width);
        if (newWidth !== width) {
            setWidth(newWidth);
        }
    }
};

export default (target: MutableRefObject<HTMLElement>): number => {
    const [width, setWidth] = useState<number>();

    useLayoutEffect(() => {
        if (typeof target?.current?.getBoundingClientRect === 'function') {
            setNewWidth(target.current.getBoundingClientRect(), width, setWidth);
        }
    }, [target]);

    // Where the magic happens
    useResizeObserver(target, (entry) => {
        setNewWidth(entry?.contentRect, width, setWidth);
    });
    return width;
};
