import { isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { FormContainer } from '../../../common/form/FormContainer';
import FormItem from '../../../common/form/FormItem';
import FormWidget from '../../../common/form/FormWidget';
import ScheduleWidget, { EMPTY_SCHEDULE } from '../../../common/form/widgets/ScheduleWidget';
import I18n from '../../../common/i18n/I18n';

export default ({ dateRange }) => {
    const isDataRangeDisabled = useMemo(() => !isEqual(dateRange, EMPTY_SCHEDULE), [dateRange]);

    return (
        <>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        hideHelperText
                        component={'Switch'}
                        name="dateRange.durationSpecified"
                        label={<I18n token="form.shortlistLineItem.inquiry.durationSpecified" />}
                        disabled={isDataRangeDisabled}
                    />
                </FormItem>
                <ScheduleWidget
                    disabled={isDataRangeDisabled}
                    showFullSchedule={true}
                    name="dateRange"
                    allowedUnits={['hour', 'day', 'week']}
                />
            </FormContainer>
        </>
    );
};
