import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { cloneDeep, isNil } from 'lodash';
import React, { Dispatch, SetStateAction, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { NameInput, Reference } from 'sr-types/lib/production/v1/graphql';
import { ALL_PERMISSIONS, AllPermissions } from '../common/auth/api';
import { useFeaturePermissions, UserContext } from '../common/auth/UserContext';
import BreadCrumbs from '../common/components/BreadCrumbs';
import WithSidePanel from '../common/components/layout/WithSidePanel';
import Loading from '../common/components/Loading';
import { client, detailsQuery, saveQuery } from '../common/components/person/person';
import { enqueueSnackbar } from '../common/components/Toast';
import { constants } from '../common/constants';
import FormButtons from '../common/form/FormButtons';
import { FormProvider } from '../common/form/FormContext';
import FormSection from '../common/form/FormSection';
import Validator from '../common/form/Validator';
import I18n from '../common/i18n/I18n';
import { Icons } from '../common/icons/Icons';
import { H, V } from '../common/Layout';
import { SUPPLIER } from '../common/nav/apps';
import Notes from '../common/notes/Notes';
import useHistory from '../common/utils/useHistory';
import GuestContactInfo, { DisabledFieldsType } from './GuestContactInfo';

const validationRules = {
    'name.firstName': [Validator.RULES.isRequired],
    'name.lastName': [Validator.RULES.isRequired],
    'contactInfo.email.address': [Validator.RULES.email]
};

const EMPTY_STATE = {
    name: {
        firstName: '',
        lastName: ''
    },
    contactInfo: {
        email: '',
        phone: ''
    },
    organizationRole: '',
    experiences: [
        {
            experienceName: '',
            title: '',
            department: '',
            professionRoles: []
        }
    ],
    productionRole: '',
    creativeCredits: [
        {
            showName: '',
            department: '',
            professionRoles: [],
            showReference: undefined
        }
    ]
};

interface PrepopulateForm {
    name?: NameInput;
    organizationRole?: String;
}
interface GuestEditorProps {
    entityIdFromModal?: string;
    isInModal?: boolean;
    setIsSaveDisabled?: Dispatch<SetStateAction<boolean>>;
    productionId?: string;
    productionRef?: Reference;
    prePopulateName?: PrepopulateForm;
    disabledFields?: DisabledFieldsType;
}

export default React.forwardRef((props: GuestEditorProps, ref) => {
    const {
        entityIdFromModal = undefined,
        isInModal = false,
        setIsSaveDisabled = undefined,
        productionRef = undefined,
        prePopulateName = undefined,
        disabledFields = undefined
    } = props;
    const { routeParams, changeRoute } = useHistory();
    const { entityId: entId } = routeParams;
    const featurePermissions = useFeaturePermissions('People Resources');
    const entityPermissions = ALL_PERMISSIONS;

    const [isSaving, setIsSaving] = useState(false);
    const entityId = useMemo(() => {
        return entityIdFromModal || entId;
    }, [entId, entityIdFromModal]);

    const [isCreate, setIsCreate] = useState(entityId === constants.createId);
    const { activeOrganizationAccount, application } = useContext(UserContext);
    const [values, setValues] = useState<any>({
        ...cloneDeep(EMPTY_STATE),
        name: prePopulateName?.name || { firstName: '', lastName: '' },
        organizationRole: prePopulateName?.organizationRole || '',
        contactExists: false,
        entityId: entityId,
        isUpdate: false,
        isOrganizationLoading: false,
        isProductionLoading: false
    });

    const [save] = useMutation(saveQuery, {
        client: client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });

    const { loading, data } = useQuery(detailsQuery, {
        client: client,
        variables: { id: entityId },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        skip: !entityId || entityId === constants.createId,
        fetchPolicy: constants.apolloFetchPolicy
    });

    useEffect(() => {
        setIsCreate(values.entityId === constants.createId);
    }, [values]);

    useEffect(() => {
        if (data) {
            data.person?.creativeCredits?.map((c) => {
                if (c.showReference) {
                    c.showReference.label = c.showName || '';
                }
            });
            const { experiences, creativeCredits } = data.person;
            const newValue = {
                ...data.person,
                organizationRole:
                    experiences && experiences.length && experiences[0].professionalRoles?.length
                        ? experiences[0].professionalRoles[0]
                        : '',
                productionRole:
                    creativeCredits && creativeCredits.length && creativeCredits[0].professionalRoles?.length
                        ? creativeCredits[0].professionalRoles[0]
                        : ''
            };
            setValues(newValue);
        }
    }, [data]);

    const onPersonSave = (onSuccess = undefined, onError = undefined, isSaveAndClose) => {
        setIsSaving(true);
        const name = values.name.firstName + ' ' + values.name.lastName;
        const savedEntity = {
            identity: {
                id: isCreate ? '' : values.entityId || entityId
            },
            profileImageReference: values.profileImageReference,
            name: {
                firstName: values.name.firstName,
                lastName: values.name.lastName
            },
            summary: {
                headline: values.summary?.headline || '',
                about: values.summary?.about || ''
            },
            contactInfo: {
                email: !values?.contactInfo?.email?.address
                    ? null
                    : {
                          address: values?.contactInfo?.email?.address || '',
                          typeString: 'Business',
                          verified: false
                      },
                phone: !values?.contactInfo?.phone?.number
                    ? null
                    : {
                          number: values?.contactInfo?.phone?.number || '',
                          typeString: 'Mobile'
                      }
            },
            professionalRoles: isNil(values.organizationRole) ? [] : [values.organizationRole],
            location: values.location,
            externalData: values.externalData,
            experiences: [
                {
                    experienceName: !isNil(values.experiences)
                        ? values.experiences[0]?.organizationReference?.label
                        : '',
                    professionalRoles: isNil(values.organizationRole) ? [] : [values.organizationRole],
                    organizationReference: !isNil(values.experiences)
                        ? values.experiences[0]?.organizationReference
                        : null,
                    title: values.experiences[0]?.title || '',
                    department: values.experiences[0]?.department || ''
                }
            ],
            creativeCredits: [
                {
                    showName:
                        productionRef && Object.keys(productionRef).length
                            ? productionRef.label
                            : !isNil(values.creativeCredits)
                              ? values.creativeCredits[0]?.showReference?.label
                              : '',
                    showReference:
                        productionRef && Object.keys(productionRef).length
                            ? productionRef
                            : !isNil(values.creativeCredits)
                              ? values.creativeCredits[0]?.showReference
                              : undefined,
                    professionalRoles: isNil(values.productionRole) ? [] : [values.productionRole],
                    department: values.creativeCredits[0]?.department || ''
                }
            ],
            skills: values.skills,
            certifications: values.certifications,
            profileAttributes: values.profileAttributes,
            public: false
        };
        save({
            variables: {
                input: savedEntity
            }
        })
            .then((res) => {
                setIsSaving(false);
                if (res.data.savePerson.errors) {
                    enqueueSnackbar(<I18n token="form.save.failure" values={{ name: name }} />, { variant: 'error' });
                    onError?.();
                } else {
                    enqueueSnackbar(<I18n token="form.save.success" values={{ name: name }} />, { variant: 'success' });
                    onSuccess?.(savedEntity, res?.data?.savePerson?.id);
                    if (!isInModal) {
                        if (isSaveAndClose) {
                            changeRoute(`${application.home}/people`);
                        } else {
                            changeRoute(`${application.home}/people/${res.data.savePerson.id}`);
                        }
                    }
                }
            })
            .catch((err) => {
                setIsSaving(false);
                console.log('error', err);
                enqueueSnackbar(<I18n token="form.save.failure" values={{ name: name }} />, { variant: 'error' });
                onError?.();
            });
    };

    const onClickCancelHandle = () => {
        changeRoute(`${SUPPLIER.home}/people`);
    };

    const onClickSaveHandle = (isSaveAndClose) => {
        onPersonSave(undefined, undefined, isSaveAndClose);
    };

    useImperativeHandle(ref, () => ({
        handleSave: (onSuccess, onError, isSaveAndClose) => {
            onPersonSave(onSuccess, onError, isSaveAndClose);
        }
    }));

    const SaveAndCloseButton = (isValid) => {
        return (
            entityId === constants.createId && (
                <LoadingButton
                    variant={'contained'}
                    onClick={() => onPersonSave(undefined, undefined, true)}
                    disabled={!isValid}
                    loading={isSaving}
                >
                    <I18n token="form.button.save.and.close" />
                </LoadingButton>
            )
        );
    };

    return (
        entityId &&
        (loading ? (
            <Loading />
        ) : (
            <FormProvider
                validationRules={validationRules}
                state={values}
                setState={setValues}
                expandedSections={['guest-contact-info']}
            >
                <V sx={{ height: '100%', p: 1 }}>
                    {isInModal ? (
                        <GuestContactInfo
                            isInModal={isInModal}
                            setIsSaveDisabled={setIsSaveDisabled}
                            productionRef={productionRef}
                            disabledFields={disabledFields}
                        />
                    ) : (
                        <>
                            <WithSidePanel
                                panels={
                                    featurePermissions[AllPermissions.Read]
                                        ? [
                                              {
                                                  id: 'notes',
                                                  icon: Icons.Notes,
                                                  label: <I18n token="notes.title" />,
                                                  disabled: entityId === constants.createId || loading,
                                                  component: (onClose) => (
                                                      <Notes
                                                          id={'notes-person'}
                                                          title={
                                                              <I18n
                                                                  token="notes.add.contact"
                                                                  values={{
                                                                      firstName: data?.person?.name?.firstName,
                                                                      lastName: data?.person?.name?.lastName
                                                                  }}
                                                              />
                                                          }
                                                          entityReference={data?.person.reference}
                                                          onClose={() => {
                                                              onClose();
                                                          }}
                                                          featurePermissions={featurePermissions}
                                                          entityPermissions={entityPermissions}
                                                      />
                                                  )
                                              }
                                          ]
                                        : []
                                }
                                leftToolbar={
                                    <BreadCrumbs
                                        grow
                                        custom={[
                                            {
                                                uri: `${application.home}`,
                                                label: <I18n token="Home" />,
                                                icon: Icons.Home
                                            },
                                            {
                                                uri: `${application.home}/${
                                                    application.home === '/admin' ? 'peopleresources' : 'people'
                                                }`,
                                                label: application.home === '/admin' ? 'Peopleresources' : 'People'
                                            },
                                            { label: `${values.name.firstName} ${values.name.lastName}` || '' }
                                        ]}
                                    />
                                }
                            >
                                <V sx={{ overflow: 'hidden', height: '100%', flexGrow: 2 }}>
                                    <H sx={{ pb: 2, gap: 2 }}>
                                        <Typography fontSize={'medium'} fontWeight={'bold'} whiteSpace={'nowrap'}>
                                            {isCreate ? (
                                                <I18n token="contact.create" />
                                            ) : (
                                                <I18n
                                                    token="contact.edit"
                                                    values={{
                                                        firstName: values.name.firstName,
                                                        lastName: values.name.lastName
                                                    }}
                                                />
                                            )}
                                        </Typography>
                                        <FormButtons
                                            isSaving={
                                                isSaving || values.isOrganizationLoading || values.isProductionLoading
                                            }
                                            onClickCancel={onClickCancelHandle}
                                            onClickSave={() => onClickSaveHandle(false)}
                                            extraButtons={[SaveAndCloseButton]}
                                            disableSave={!values.isUpdate && values?.contactExists}
                                        />
                                    </H>
                                    <V sx={{ overflowY: 'auto', gap: 2, pt: 0 }}>
                                        <FormSection
                                            id="guest-contact-info"
                                            fields={Object.keys(EMPTY_STATE)}
                                            Component={GuestContactInfo}
                                        />
                                    </V>
                                </V>
                            </WithSidePanel>
                        </>
                    )}
                </V>
            </FormProvider>
        ))
    );
});
