import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { auto } from '@popperjs/core';
import I18n from '../../common/i18n/I18n';
import { H, V } from '../../common/Layout';
import { useStyling } from '../../common/Theme';
import { getStringFromDateRange } from '../helpers/activityUtils';

const ListActionButtons = ({ isKeyDate, activityId, markActiivtyId, unmarkActivityId, isLoading, isDisabled }) => {
    const { theme } = useStyling();

    return (
        <H sx={{ width: auto, gap: 2 }}>
            {isKeyDate && (
                <Typography sx={{ color: theme.palette.text.disabled }}>
                    <I18n token="form.added" />
                </Typography>
            )}
            <LoadingButton
                variant="text"
                loading={isLoading.current.id === activityId && isLoading.current.isLoading}
                disabled={isDisabled}
                onClick={(e) => {
                    isKeyDate ? unmarkActivityId(activityId) : markActiivtyId(activityId);
                    e.stopPropagation();
                }}
            >
                {isKeyDate ? <I18n token="form.remove" /> : <I18n token="form.add" />}
            </LoadingButton>
        </H>
    );
};

interface ActivityListTileType {
    item: Record<string, any>;
    markActiivtyId: (id: string) => void;
    unmarkActivityId: (id: string) => void;
    isLoading: boolean;
    isDisabled?: boolean;
}

export default (props: ActivityListTileType) => {
    const { item: activity, markActiivtyId, unmarkActivityId, isLoading, isDisabled = false } = props;
    const { theme } = useStyling();

    return (
        activity && (
            <H className="clickable" sx={{ h: 7 }}>
                <V sx={{ alignSelf: 'stretch', gap: 0.5 }}>
                    <Typography>{activity?.name}</Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>
                        {activity?.dateRange && `${getStringFromDateRange(activity.dateRange) || '-'}`}
                    </Typography>
                </V>
                <ListActionButtons
                    isKeyDate={activity?.isKeyDate}
                    activityId={activity.id}
                    markActiivtyId={markActiivtyId}
                    unmarkActivityId={unmarkActivityId}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                />
            </H>
        )
    );
};
