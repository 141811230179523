import { Button, Typography } from '@mui/material';
import React from 'react';
import I18n from '../common/i18n/I18n';
import { V } from '../common/Layout';

interface KeyPeopleActionsType {
    firstActionChildren: React.ReactNode;
    secondActionChildren: React.ReactNode;
    onFirstAction: () => void;
    onSecondAction: () => void;
}

export default ({ firstActionChildren, secondActionChildren, onFirstAction, onSecondAction }: KeyPeopleActionsType) => {
    return (
        <V sx={{ alignItems: 'center' }}>
            <Button onClick={onFirstAction}>{firstActionChildren}</Button>
            <Typography component="span">OR</Typography>
            <Button onClick={onSecondAction}>{secondActionChildren}</Button>
        </V>
    );
};
