import { createSvgIcon } from '@mui/material';
import React from 'react';

export const ImportFile = createSvgIcon(
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M29.9998 25V30H9.99984V25H6.6665V30C6.6665 31.8333 8.1665 33.3333 9.99984 33.3333H29.9998C31.8332 33.3333 33.3332 31.8333 33.3332 30V25H29.9998ZM11.6665 15L14.0165 17.35L18.3332 13.05V26.6666H21.6665V13.05L25.9832 17.35L28.3332 15L19.9998 6.66663L11.6665 15Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>,
    'ImportFile'
);
