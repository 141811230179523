import React from 'react';
import { Chip, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { Reference } from 'sr-types/lib/shortlist/v1/graphql';
import { H, V } from '../Layout';
import I18n from '../i18n/I18n';

export default ({ data }: { data: Reference[] }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTooltipOpen = () => {
        setIsTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    let displayData = data;

    if (data.length > 4) {
        displayData = data.slice(0, 4);
    }

    return (
        <H sx={{ gap: 1, flexWrap: 'wrap' }}>
            {displayData.map((info, index) => (
                <Chip label={info?.label} variant="outlined" key={info?.label} />
            ))}
            {data.length > 4 && (
                <Tooltip
                    title={
                        <V>
                            {data.map((info, index) => (
                                <Typography key={index}>{info.label}</Typography>
                            ))}
                        </V>
                    }
                    open={isTooltipOpen}
                    onClose={handleTooltipClose}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <Chip
                        label={<I18n token="multiple.chip.more.label" values={{ value: data.length - 4 }} />}
                        variant="outlined"
                        onMouseEnter={handleTooltipOpen}
                        onMouseLeave={handleTooltipClose}
                    />
                </Tooltip>
            )}
        </H>
    );
};
