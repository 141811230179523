import { ListItemText, MenuItem, Tooltip, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import React, { useContext, useState } from 'react';
import { H } from '../Layout';
import { useStyling } from '../Theme';
import { UserContext } from '../auth/UserContext';
import { Membership } from '../auth/api';
import Loading from '../components/Loading';
import SecureImage from '../components/SecureImage';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import showrunnrLogoDark from '../logo/logo_dark.svg';
import showrunnrLogoLight from '../logo/logo_light.svg';
import useHistory from '../utils/useHistory';

const useOrgMenuItems = (onMenuItemClick, isLoading) => {
    const [selectedOrgId, setSelectedOrgId] = useState(null);
    const { userProfile, activeOrganizationAccount } = useContext(UserContext);
    const menuItems = [];
    let brand = <Typography fontSize={20}>Showrunnr</Typography>;
    if (userProfile) {
        userProfile.memberships.forEach((membership: Membership, idx: number) => {
            const isCurrent = membership.id === activeOrganizationAccount;
            const preferredAccountKey = 'preferredAccount.' + userProfile.username;
            const preferedAccount = localStorage.getItem(preferredAccountKey);
            const onStarClick = (membershipId: string) => {
                localStorage.setItem(preferredAccountKey, membershipId);
            };
            if (isCurrent) {
                brand = <Typography fontSize={20}>{membership.name}</Typography>;
                // TODO temp hardwire to show disney logo for dev org
                if (membership.branding && membership.branding.useCustom) {
                    if (membership.branding.bigLogo) {
                        brand = (
                            <SecureImage
                                imageId={membership.branding.bigLogo.id}
                                style={{ width: 'unset', height: '32px' }}
                            />
                        );
                    } else if (membership.branding.brandNameOrText) {
                        brand = <Typography fontSize={20}>{membership.branding.brandNameOrText}</Typography>;
                    } else if (!membership.name) {
                        brand = <Typography fontSize={20}>{membership.id}</Typography>;
                    }
                }
            }
            if (!membership.personal) {
                menuItems.push(
                    <MenuItem
                        key={idx}
                        onClick={() => {
                            setSelectedOrgId(membership.id);
                            onMenuItemClick(membership.id);
                        }}
                        // disabled={isCurrent}
                        selected={isCurrent}
                    >
                        <ListItemText>{membership.name ?? membership.id}</ListItemText>
                        {isLoading && membership.id === selectedOrgId ? (
                            <Loading size={'21px'} sx={{ width: 'unset' }} />
                        ) : membership.id === preferedAccount ? (
                            <Tooltip title={<I18n token={'account.preferredAccount.start.tooltip'} />}>
                                <Icons.Star color={'primary'} sx={{ ml: 1 }} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={<I18n token={'account.preferredAccount.start.tooltip'} />}>
                                <Icons.StarOutline
                                    color={'disabled'}
                                    onClick={() => onStarClick(membership.id)}
                                    sx={{ ml: 1 }}
                                />
                            </Tooltip>
                        )}
                    </MenuItem>
                );
            }
        });
    }
    return { menuItems, brand };
};

export default () => {
    const { setActiveOrganizationAccount, activeOrganizationAccount } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const { changeSearch } = useHistory();
    const { isDarkMode } = useStyling();
    const [isLoading, setIsLoading] = useState(false);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (newOrgId: string, pathOverride: string) => {
        setIsLoading(true);
        setActiveOrganizationAccount(newOrgId).then(() => {
            changeSearch({ orgId: newOrgId });
            setIsLoading(false);
            handleMenuClose();
        });
    };

    const { menuItems, brand } = useOrgMenuItems(onMenuItemClick, isLoading);
    const renderMenu = (
        <Menu
            id={'org-selection-menu'}
            keepMounted
            open={isMenuOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            onClose={handleMenuClose}
            slotProps={{
                paper: {
                    sx: { minWidth: '200px' }
                }
            }}
        >
            {menuItems}
        </Menu>
    );
    return (
        <>
            <H
                className="clickable"
                onClick={(event) => {
                    if (activeOrganizationAccount) {
                        setAnchorEl(event.currentTarget);
                    }
                }}
            >
                {activeOrganizationAccount ? (
                    <>
                        {brand}
                        <Icons.ArrowDropDownOutlined />
                    </>
                ) : (
                    <img
                        alt="Logo"
                        style={{ width: 'unset', height: '20px' }}
                        src={isDarkMode ? showrunnrLogoDark : showrunnrLogoLight}
                    />
                )}
            </H>
            {activeOrganizationAccount && renderMenu}
        </>
    );
};
