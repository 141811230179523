import { setContext } from '@apollo/client/link/context';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { find, remove } from 'lodash';
import { AppDefinition } from '../nav/apps';
import { Reference } from '../reference/reference';

const baseApi = '/iam/v1';
const baseAdminApi = '/iam/admin/v1';
const userProfileApi = '/user/v1';

export type Name = {
    firstName: string;
    lastName: string;
};

export enum AllPermissions {
    Read = 'Read',
    Manage = 'Manage',
    Edit = 'Edit',
    Delete = 'Delete',
    Comment = 'Comment',
    Share = 'Share',
    Inactivate = 'Inactivate',
    Activate = 'Activate'
}
const AllPermissionsKeys = Object.keys(AllPermissions);

export type PermissionType =
    | AllPermissions.Read
    | AllPermissions.Manage
    | AllPermissions.Edit
    | AllPermissions.Delete
    | AllPermissions.Comment
    | AllPermissions.Share;

export type PermissionsType = {
    [key in AllPermissions]?: boolean;
};

export type MembershipAccess = {
    feature: string;
    application: string;
    permissions: PermissionType[];
};

export type Membership = {
    id: string;
    name: string;
    access: MembershipAccess[];
    personal?: boolean;
    roles?: string[];
    branding?: {
        useCustom: boolean;
        bigLogo: {
            id: string;
            type: string;
            urlPrefix: string;
        };
        smallLogo: {
            id: string;
            type: string;
            urlPrefix: string;
        };
        brandNameOrText: string;
    };
    ownerOrganizationId?: string;
};

export type UserProfile = {
    accountId: string;
    username: string;
    name: Name;
    roles: string[];
    memberships: Membership[];
    personReference: Reference;
    profileImageReference: Reference;
};

export type LoginResponse = {
    accessToken: string;
    challengeParams: any; // What is this?
    idToken: string;
    refreshToken: string;
};

export type PreLoginResponse = {
    userFound: boolean;
    organizations: Organization[];
    action: number;
};

export type Organization = {
    id: string;
    name: string;
    organizationDomain: Domain;
};

type Domain = {
    pattern: string;
    verified: boolean;
    idpIdentifier: string;
};

let refreshCountdownTimer;

type AuthToken = {
    token: string;
    expiry: number;
};

export const getToken = (): AuthToken => {
    const json = window.localStorage.getItem('auth-token');
    return json ? JSON.parse(json) : undefined;
};

export const setToken = (token: string) => {
    if (token) {
        const decoded = jwtDecode<JwtPayload>(token);
        const expirationTime = decoded.exp * 1000;
        window.localStorage.setItem(
            'auth-token',
            JSON.stringify({
                token: token,
                expiry: expirationTime
            })
        );
        const refreshTokenAfter = expirationTime - Date.now() - 60 * 1000;
        refreshCountdownTimer = setTimeout(getNewToken, refreshTokenAfter);
    }
};

export const resetToken = () => {
    window.localStorage.removeItem('auth-token');
    clearTimeout(refreshCountdownTimer);
};

export const isTokenExpired = () => {
    const token = getToken();
    if (token) {
        const expirationTime = token.expiry;
        return Date.now() >= expirationTime;
    }
    return true;
};

export const getNewToken = () => {
    return refreshToken()
        .then((data) => {
            setToken(data['idToken']);
        })
        .catch((err) => {
            console.error('Refresh token error:', err);
            resetToken();
        });
};

export const signOut = () => {
    return new Promise((resolve, reject) => {
        fetch(routes.signOut, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            }
        })
            .then((/*res*/) => {})
            .catch((err) => {
                console.warn('Logout error:', err);
                reject(err);
            })
            .finally(() => {
                resetToken();
                resolve(true);
            });
    });
};

export const routes = {
    preSignIn: `${baseApi}/presignin`,
    signIn: `${baseApi}/signin`,
    respondToChallenge: `${baseApi}/auth`,
    refreshToken: `${baseApi}/tokenrefresh`,
    signOut: `${baseApi}/signout`,
    getUserProfile: `${userProfileApi}/profile`,
    signUp: `${baseApi}/registration`,
    verify: `${baseApi}/verification`,
    save: `${baseApi}/save`,
    addUser: `${baseAdminApi}/user`,
    saveIdp: `${baseAdminApi}/idp/save`,
    passwordRecovery: `${baseApi}/password/recovery`,
    confirmPassword: `${baseApi}/password/verify`,
    resetPassword: `${baseAdminApi}/password/reset`,
    verifyEmail: `${baseApi}/user/verification/reconcile`
};

interface RequestInitInterface extends RequestInit {
    url: string | Request | URL;
}

const DEFAULT_API_HEADERS = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json'
};
const DEFAULT_METHOD = 'POST';
const DEFAULT_MODE = window.DEBUG ? 'no-cors' : 'same-origin';

function fetchAndResolveRoutes({
    url,
    body,
    method = DEFAULT_METHOD,
    headers = DEFAULT_API_HEADERS,
    mode,
    credentials
}: RequestInitInterface) {
    const init: RequestInit = {
        body,
        method,
        headers,
        mode,
        credentials
    };
    return new Promise((resolve, reject) => {
        fetch(url, init)
            .then((res) => {
                return res.json().then((data) => {
                    res.ok ? resolve(data) : reject(data);
                });
            })
            .catch((err) => {
                return reject(err);
            });
    });
}

const stringify = (object) => {
    const formBody = [];
    Object.getOwnPropertyNames(object).forEach((key) => {
        formBody.push(key + '=' + encodeURIComponent(object[key]));
    });
    return formBody.join('&');
};

export interface LoginRequest {
    username: string;
    password?: string;
    isCustom?: boolean;
}

export function logIn(payload: LoginRequest) {
    return fetchAndResolveRoutes({
        url: routes.signIn,
        body: stringify(payload),
        mode: DEFAULT_MODE
    });
}

export function preLogin(username: String) {
    return fetchAndResolveRoutes({
        url: routes.preSignIn,
        body: stringify({ username: username })
    });
}

export function respondToChallenge(request) {
    return fetchAndResolveRoutes({
        url: routes.respondToChallenge,
        body: stringify(request)
    });
}

export type orgSubscription = {
    roles: string[];
    organization: Reference;
    department: string;
};

export type AddUserPayload = {
    username: string;
    email: string;
    password: string;
    mobile: string;
    firstName: string;
    lastName: string;
    memberOf: string;
    roles: string[];
    organization: Reference;
    department: string;
    orgSubscriptions: orgSubscription[];
};

export function addUser(payload: AddUserPayload, ownerId: string) {
    const token = getToken();
    return fetchAndResolveRoutes({
        url: routes.addUser,
        body: JSON.stringify(payload),
        headers: {
            ...DEFAULT_API_HEADERS,
            Authorization: token ? 'Bearer ' + token.token : '',
            OwnerId: ownerId,
            'Content-Type': 'application/json'
        }
    });
}

export type SaveIdpPayload = {
    organizationAccountId: string;
    idpIdentifier: string;
    idpName: string;
    idpType: string;
    oidcClientId: string;
    oidcClientSecret: string;
    oidcIssuer: string;
    samlMetadataUrl: string;
    enable: boolean;
};

export function saveIdp(payload: SaveIdpPayload, ownerId: string) {
    const token = getToken();
    return fetchAndResolveRoutes({
        url: routes.saveIdp,
        body: stringify(payload),
        headers: {
            ...DEFAULT_API_HEADERS,
            Authorization: token ? 'Bearer ' + token.token : '',
            OwnerId: ownerId
        }
    });
}

export function refreshToken(token = undefined) {
    return fetchAndResolveRoutes({
        url: routes.refreshToken,
        headers: { ...DEFAULT_API_HEADERS, refreshtoken: token ?? '' },
        credentials: 'include'
    });
}

export type SignupPayload = {
    username: string;
    email: string;
    password: string;
    passwordConfirm: string;
    mobile: string;
    firstName: string;
    lastName: string;
};

export function signUp(payload: SignupPayload) {
    return fetchAndResolveRoutes({
        url: routes.signUp,
        body: stringify(payload),
        mode: DEFAULT_MODE
    });
}

export type VerifyRequest = {
    username: string;
    code: string;
};

export function verify(payload: VerifyRequest) {
    return fetchAndResolveRoutes({
        url: routes.verify,
        body: stringify(payload),
        mode: DEFAULT_MODE
    });
}

export type ConfirmPasswordRequest = {
    username: string;
    code: string;
    password: string;
};

export function confirmPassword(payload: ConfirmPasswordRequest) {
    return fetchAndResolveRoutes({
        url: routes.confirmPassword,
        body: stringify(payload),
        mode: DEFAULT_MODE
    });
}

export function passwordRecovery(username: string) {
    return fetchAndResolveRoutes({
        url: routes.passwordRecovery,
        body: stringify({ username: username })
    });
}

export const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token.token}` : ''
        }
    };
});

export const fetchUserProfile = async (activeOrganizationAccount: string, cb: (UserProfile) => void) => {
    const token = getToken();
    try {
        const res = await fetch(routes.getUserProfile, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                Authorization: token ? 'Bearer ' + token.token : '',
                ownerId: activeOrganizationAccount
            }
        });
        if (res.ok) {
            const data: UserProfile = await res.json();
            // TODO BEGIN HACK remove planning app for now since it's not part of the main app and is showing up first.
            data.memberships.forEach((m) => {
                remove(m.access, (a: MembershipAccess) => a.application === 'Planning');
            });
            // TODO END HACK
            cb(data);
        } else {
            console.warn('Failed to load user profile:', res);
        }
    } catch (err) {
        console.warn('Failed to load user profile:', err);
    }
};

export type ResetPasswordPayload = {
    email: string;
    accountId: string;
    temporaryPassword: string;
    notifyUser: boolean;
};

export function resetPassword(payload: ResetPasswordPayload, ownerId: string) {
    const token = getToken();
    return fetchAndResolveRoutes({
        url: routes.resetPassword,
        body: stringify(payload),
        headers: {
            ...DEFAULT_API_HEADERS,
            Authorization: token ? 'Bearer ' + token.token : '',
            OwnerId: ownerId
        }
    });
}

export function registerFlow(email: string) {
    const token = getToken().token;
    const decoded = jwtDecode<JwtPayload>(token);

    const formData = new FormData();
    formData.append('email', email);
    formData.append('accountId', decoded['custom:account_id']);
    formData.append('notifyUser', 'true');

    return fetchAndResolveRoutes({
        url: routes.resetPassword,
        body: formData,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    });
}

export function verifyEmail(bearer: string) {
    return fetchAndResolveRoutes({
        url: routes.verifyEmail,
        headers: {
            ...DEFAULT_API_HEADERS,
            Authorization: bearer ? `Bearer ${bearer}` : ''
        }
    });
}

export const findPermissions = (obj, isPublic = false): PermissionsType => {
    const perms: PermissionsType = {};
    for (const element of AllPermissionsKeys) {
        const key = element;
        perms[key] = isPublic || obj.includes(key);
    }
    return perms;
};

export const getFeaturePermissions = (
    userProfile: UserProfile,
    application: AppDefinition,
    orgId: string,
    feature: string
): PermissionsType => {
    let ret: PermissionsType;
    if (application && feature && orgId && userProfile?.memberships) {
        const features: Membership = find(userProfile.memberships, { id: orgId });
        if (features?.access) {
            const access: MembershipAccess = find(features.access, { application: application.name, feature: feature });
            if (access) {
                ret = findPermissions(access.permissions);
            }
        }
    }
    return ret || ({} as PermissionsType);
};

export const ALL_PERMISSIONS = {
    [AllPermissions.Read]: true,
    [AllPermissions.Edit]: true,
    [AllPermissions.Delete]: true,
    [AllPermissions.Manage]: true,
    [AllPermissions.Comment]: true,
    [AllPermissions.Share]: true
};

export const getRowPermissions = (rowSecurity) => {
    // TODO only temp until row-level security is implemented, then set to empty object.
    const NO_SECURITY = {
        [AllPermissions.Read]: true,
        [AllPermissions.Edit]: true,
        [AllPermissions.Delete]: true,
        [AllPermissions.Manage]: true,
        [AllPermissions.Comment]: true,
        [AllPermissions.Share]: true
    };
    return rowSecurity?.allowedActions ? findPermissions(rowSecurity.allowedActions) : NO_SECURITY;
};
