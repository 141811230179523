import { createSvgIcon } from '@mui/material';
import React from 'react';

export const LinkedIn = createSvgIcon(
    <>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="4" fill="#0A66C2" />
            <g clipPath="url(#clip0_1973_19978)">
                <path
                    d="M4.1665 5.72278C4.1665 5.3474 4.29789 5.03771 4.56065 4.79373C4.82341 4.54973 5.16501 4.42773 5.58542 4.42773C5.99834 4.42773 6.33242 4.54785 6.58767 4.78809C6.85043 5.03584 6.98182 5.35866 6.98182 5.75656C6.98182 6.11692 6.8542 6.41722 6.59894 6.65746C6.33618 6.90521 5.99083 7.02909 5.5629 7.02909H5.55164C5.13872 7.02909 4.80465 6.90521 4.54939 6.65746C4.29413 6.40972 4.1665 6.09815 4.1665 5.72278ZM4.3129 15.5764V8.05386H6.8129V15.5764H4.3129ZM8.19804 15.5764H10.698V11.3759C10.698 11.1132 10.7281 10.9105 10.7881 10.7678C10.8932 10.5126 11.0528 10.2967 11.2667 10.1203C11.4807 9.94387 11.7491 9.85566 12.0719 9.85566C12.9128 9.85566 13.3332 10.4225 13.3332 11.5561V15.5764H15.8332V11.2633C15.8332 10.1522 15.5704 9.30949 15.0449 8.73517C14.5194 8.16084 13.8249 7.87368 12.9615 7.87368C11.9931 7.87368 11.2386 8.29035 10.698 9.12368V9.1462H10.6868L10.698 9.12368V8.05386H8.19804C8.21305 8.2941 8.22056 9.04109 8.22056 10.2949C8.22056 11.5486 8.21305 13.3091 8.19804 15.5764Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1973_19978">
                    <rect x="4.1665" y="4.16602" width="11.6667" height="11.6667" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </>,
    'LinkedIn'
);
