import React, { useContext, useState } from 'react';
import { FormContext } from '../../../common/form/FormContext';
import FormItem from '../../../common/form/FormItem';
import FormWidget from '../../../common/form/FormWidget';
import { useI18n } from '../../../common/i18n/I18n';
import ProductionTitleForm from '../ProductionTitleForm';

export default ({ productionOptions, productionName }) => {
    const { state, handleChange } = useContext(FormContext);

    const [isOpen, toggleOpen] = useState(false);
    const [otherTitleValue, setOtherTitleValue] = useState('');
    const [productionTitles, setProductionTitles] = useState([...(productionOptions || [])]);

    return (
        <>
            <FormItem>
                <FormWidget
                    data-testid={'production'}
                    name="production.name"
                    label={useI18n('production.breadcrumbs.label')}
                    component="Autocomplete"
                    options={productionTitles}
                    value={state?.production.name}
                    onChange={(name: string, value: string) => handleChange(name, value)}
                    setSelectedValue={setOtherTitleValue}
                    isCustomEelementOpen={isOpen}
                    toggleOpen={() => {
                        toggleOpen(true);
                    }}
                    disabled={isOpen}
                    onBlur={() => {
                        if (!state?.production.name) {
                            handleChange('production.name', productionName);
                        }
                    }}
                    editInputValue
                    selectOnFocus
                    allowFreeForm
                    renderOption
                    isAddEnabled
                />
            </FormItem>
            {isOpen && (
                <FormItem>
                    <ProductionTitleForm
                        selectedValue={otherTitleValue}
                        setSelectedValue={setOtherTitleValue}
                        toggleOpen={toggleOpen}
                        setProductionOptions={setProductionTitles}
                    />
                </FormItem>
            )}
        </>
    );
};
