import { Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { InquiryStatusInfo, Opportunity } from 'sr-types/lib/opportunity/v1/graphql';
import { FormContext } from '../../../common/form/FormContext';
import FormWidget from '../../../common/form/FormWidget';
import I18n from '../../../common/i18n/I18n';
import { V } from '../../../common/Layout';
import { useStyling } from '../../../common/Theme';
import { getCurrentResponseFromStatus, inquiryResponseStatuses, useGetResponseNoteLabels } from '../opportunityInquiry';

export default ({
    inquiryData,
    inquiryResponseStatus,
    requestNoteLabel,
    setRequestNoteLabel,
    inviteeName
}: {
    inquiryData: InquiryStatusInfo | Opportunity;
    inquiryResponseStatus: string;
    requestNoteLabel: string;
    setRequestNoteLabel: Dispatch<SetStateAction<string>>;
    inviteeName: string;
}) => {
    const { theme } = useStyling();
    const { state, setState } = useContext(FormContext);
    const { noteLabels } = useGetResponseNoteLabels();

    const isClosedTransaction =
        inquiryResponseStatus === inquiryResponseStatuses.no || inquiryResponseStatus === inquiryResponseStatuses.yes;

    useEffect(() => {
        if (inquiryResponseStatus) {
            setCurrentState(inquiryResponseStatus);
        }
    }, [inquiryResponseStatus]);

    const getRequestKeyNoteLabel = (status: string) => {
        const responseKey = getCurrentResponseFromStatus(status);
        const requestNoteLabel = noteLabels[responseKey && responseKey[0]];

        return {
            responseKey,
            requestNoteLabel
        };
    };

    const setCurrentState = (status: string) => {
        const { responseKey, requestNoteLabel } = getRequestKeyNoteLabel(status);
        setState({
            ...inquiryData,
            status: { id: status, label: <I18n token={`dialog.${responseKey}`} /> }
        });
        setRequestNoteLabel(requestNoteLabel);
    };

    const handleOnChange = (status: string) => {
        const { responseKey, requestNoteLabel } = getRequestKeyNoteLabel(status);
        setRequestNoteLabel(requestNoteLabel);
        setState({
            ...inquiryData,
            requestNote: '',
            status: { id: status, label: <I18n token={`dialog.${responseKey}`} /> }
        });
    };

    return (
        <V gap={2}>
            <Typography fontSize={'medium'} color={theme.palette.text.primary}>
                <I18n token={'shortlist.line.item.availability'} />
            </Typography>

            <V gap={2.5}>
                <FormWidget
                    component="Chips"
                    name={'status'}
                    value={state?.status}
                    options={[
                        {
                            id: inquiryResponseStatuses.yes,
                            label: <I18n token="dialog.yes" />,
                            disabled: isClosedTransaction && inquiryResponseStatus !== inquiryResponseStatuses.yes
                        },
                        {
                            id: inquiryResponseStatuses.no,
                            label: <I18n token="dialog.no" />,
                            disabled: isClosedTransaction && inquiryResponseStatus !== inquiryResponseStatuses.no
                        },
                        {
                            id: inquiryResponseStatuses.requestInfo,
                            label: <I18n token="dialog.requestInfo" />,
                            disabled: isClosedTransaction
                        }
                    ]}
                    onChange={(name: string, value) => {
                        handleOnChange(value.id);
                    }}
                    containerSx={{ mb: 0 }}
                />
                {requestNoteLabel ? (
                    <FormWidget
                        name="requestNote"
                        label={requestNoteLabel}
                        component={'TextArea'}
                        disabled={isClosedTransaction}
                    />
                ) : (
                    <></>
                )}
            </V>

            <Typography fontSize={'small'} color={theme.palette.text.primary}>
                <I18n token={'form.respond.note'} values={{ name: inviteeName }} />
            </Typography>
        </V>
    );
};
