import React, { useEffect, useState } from 'react';
import CustomAuth from './CustomAuth';
import useHistory from '../utils/useHistory';
import { passwordRecovery, verifyEmail } from './api';
import Nav from '../nav/Nav';
import { authLinks } from './AccountLinks';

export default () => {
    const [bearer, setBearer] = useState('');
    const { searchParams, changeRoute } = useHistory();
    const username = searchParams.get('username');

    const doVerifyEmail = () => {
        verifyEmail(bearer)
            .then((res: any) => {
                if (username) {
                    passwordRecovery(username)
                        .then((res: any) => {
                            if (res.userFound) {
                                changeRoute(authLinks.passwordRecovery.path, { emailSent: 'true' }, true);
                            }
                        })
                        .catch((err) => {
                            console.error('login error:', err);
                        });
                } else {
                    changeRoute(authLinks.login.path, undefined, true);
                }
            })
            .catch((err) => {
                console.error('login error:', err);
            });
    };

    useEffect(() => {
        if (bearer) {
            doVerifyEmail();
        }
    }, [bearer]);

    return (
        <>
            <Nav />
            <CustomAuth
                username={username}
                setBearer={(bearer) => {
                    if (bearer) {
                        setBearer(bearer);
                    }
                }}
            />
        </>
    );
};
