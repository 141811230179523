import { Typography } from '@mui/material';
import React from 'react';
import { AttachmentInput } from 'sr-types/lib/production/v1/graphql';
import I18n from '../i18n/I18n';
import { V } from '../Layout';
import AttachmentsRenderer from './AttachmentsRenderer';

export default ({
    saveFiles,
    onDelete
}: {
    saveFiles: AttachmentInput[];
    onDelete: (file: AttachmentInput, index: number) => void;
}) => {
    return (
        <>
            {saveFiles.length != 0 && (
                <V sx={{ gap: 1, overflow: 'auto' }}>
                    <Typography fontSize={'medium'} sx={{ flexGrow: 2 }}>
                        <I18n token={'attachments.preview'} />
                    </Typography>
                    {saveFiles.map((file, index) => (
                        <AttachmentsRenderer
                            key={index}
                            file={file}
                            isPreview={true}
                            onDelete={() => onDelete(file, index)}
                        />
                    ))}
                </V>
            )}
        </>
    );
};
