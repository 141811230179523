import React, {useEffect, useState} from 'react';
import FormPanel from '../../common/form/FormPanel';
import {FormContainer} from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import {MeasurementField} from '../attributes';
import {useUnitsDataLazy} from '../attributesApi';
// import { private_excludeVariablesFromRoot } from '@mui/material';

type TermsetValueEditorProps = {
    field: MeasurementField;
};

export default (props: TermsetValueEditorProps) => {
    const { field } = props;
    const [options, setOptions] = useState([]);
    const { getUnits, unitsData } = useUnitsDataLazy(field?.measurementType);

    useEffect(() => {
        if (field?.measurementType) {
            getUnits();
        }
    }, []);

    useEffect(() => {
        if (unitsData?.results?.hits?.items) {
            setOptions(
                unitsData.results.hits.items.map((item) => {
                    return { ...item, label: item.name };
                })
            );
        }
    }, [unitsData]);

    return (
        <FormPanel>
            <FormContainer>
                <FormItem half>
                    <FormWidget name={field.name} label={field.label} />
                </FormItem>

                <FormItem half>
                    <FormWidget name={field.name + 'unit'} component="Autocomplete" label="Unit" options={options} allowFreeForm={true} />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
