import { useIsWithinBreakpoints } from './useIsWithinBreakpoints';

export type BreakpointSize = 'xs' | 's' | 'm' | 'l' | 'xl';

export const BREAKPOINT_ARRAY: Array<BreakpointSize> = ['xs', 's', 'm', 'l', 'xl'];

export type Breakpoints = {
    /**
     * Set the minimum window width at which to start to the breakpoint
     */
    [key in BreakpointSize]: number;
};

export const BREAKPOINTS: Breakpoints = {
    xl: 1536, // > 1536
    l: 1200, // 1200-1535
    m: 900, // 900-1199
    s: 600, // 600-899
    xs: 0 // 0-599
};

export function keysOf<T, K extends keyof T>(obj: T): K[] {
    return Object.keys(obj) as K[];
}

export const BREAKPOINT_KEYS = keysOf(BREAKPOINTS);

export const getBreakpoint = (width: number, breakpoints: Breakpoints = BREAKPOINTS): BreakpointSize | undefined => {
    // Find the breakpoint (key) whose value is <= windowWidth starting with largest first
    return keysOf(BREAKPOINTS).find((key) => breakpoints[key] <= width);
};

export const isWithinMaxBreakpoint = (
    width: number,
    max: BreakpointSize | number,
    breakpoints: Breakpoints = BREAKPOINTS
): boolean => {
    if (typeof max === 'number') {
        return width <= max;
    } else {
        const currentBreakpoint = getBreakpoint(width, breakpoints);
        return currentBreakpoint ? breakpoints[currentBreakpoint] <= breakpoints[max] : false;
    }
};

export const isWithinMinBreakpoint = (
    width: number,
    min: BreakpointSize | number,
    breakpoints: Breakpoints = BREAKPOINTS
): boolean => {
    if (typeof min === 'number') {
        return width >= min;
    } else {
        const currentBreakpoint = getBreakpoint(width, breakpoints);
        return currentBreakpoint ? breakpoints[currentBreakpoint] >= breakpoints[min] : false;
    }
};

export const isWithinBreakpoints = (
    width: number,
    sizes: BreakpointSize[],
    breakpoints: Breakpoints = BREAKPOINTS
): boolean => {
    const currentBreakpoint = getBreakpoint(width, breakpoints);
    return currentBreakpoint ? sizes.includes(currentBreakpoint) : false;
};

export const useOnMobile = () => {
    return useIsWithinBreakpoints(['xs']);
};