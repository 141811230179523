import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import { Paper, PaperProps } from '@mui/material';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function DraggableDialog() {

    useEffect(() => {
        // handleClickOpen();  Disabling the Pop-up being shown on the page for now
    }, []);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        if (localStorage.getItem("posthogSessionRecordingConsent") == null) {
            localStorage.setItem("posthogSessionRecordingConsent", "false");
            setOpen(true);
        }

        if (localStorage.getItem("posthogSessionRecordingConsent").includes("false")) {
            setOpen(true);
        }
    };


    const handleReject = () => {
        setOpen(false);
        //@ts-ignore
        window.posthog.disable_session_recording = true;
        localStorage.setItem("posthogSessionRecordingConsent", "false");
    };

    const handleAccept = () => {
        setOpen(false);
        //@ts-ignore
        window.posthog.disable_session_recording = false;
        localStorage.setItem("posthogSessionRecordingConsent", "true");
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleReject}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Session Recording Consent
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To Understand the usage better and to serve you better, we would like to do session recording.
                        In layman worlds - understand how you use the application.
                        Could you please provide consent for the same?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleReject}>
                        Reject
                    </Button>
                    <Button onClick={handleAccept}>Accept</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
