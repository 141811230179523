import React, { useMemo, useState } from 'react';
import { H, V } from '../../common/Layout';
import { useStyling } from '../../common/Theme';
import DelimitedLabels from '../../common/components/DelimitedLabels';
import EditablePanel from '../../common/components/EditablePanel';
import I18n from '../../common/i18n/I18n';
import { Icons } from '../../common/icons/Icons';
import AddKeyPeopleModal from '../AddKeyPeopleModal';
import { ProductionTypeSuperset } from '../helpers/productionApi';
import AddKeyDatesModal from '../schedule/AddKeyDatesModal';
import AkaTile from './AkaLabel';
import AssociatedOrgsLabel from './AssociatedOrgsLabel';
import AssociatedPeopleTile from './AssociatedPeopleTile';
import DescriptionLabel from './DescriptionLabel';
import HeaderLabel from './HeaderLabel';
import KeyDates from './KeyDates';
import RoundAvatar, { AvatarSizes } from './RoundAvatar';
import TitleLabel from './TitleLabel';
import { useOnMobile } from '../../common/hooks/breakpoints';

type ProductionViewProps = {
    viewState: ProductionTypeSuperset;
    onEdit?: () => void;
    setViewState?: (production: ProductionTypeSuperset) => void;
    isSaving?: boolean;
    isReadOnly?: boolean;
};

const ProductionHeaderInfo = ({ viewState, infoTiles }) => {
    const isMobile = useOnMobile();
    return (
        <H sx={{ gap: 1, justifyContent: 'start', alignSelf: 'flex-start', minWidth: isMobile && 0 }}>
            <RoundAvatar imageId={undefined} title={viewState.name} size={AvatarSizes.md} />
            <V sx={{ width: `calc(100% - ${AvatarSizes.sm.width}px - 70px)` }}>
                <H sx={{ gap: 1, alignItems: 'baseline' }}>
                    <TitleLabel title={viewState.name} width={900} />
                    <AkaTile otherTitles={viewState.otherTitles} width={250} />
                </H>
                <H sx={{ gap: 0.5 }}>
                    <DelimitedLabels>{infoTiles}</DelimitedLabels>
                </H>
            </V>
        </H>
    );
};

export default ({ viewState, onEdit, setViewState, isSaving = false, isReadOnly = false }: ProductionViewProps) => {
    const [isHover, setIsHover] = useState(false);
    const [isKeyDatesModalOpen, setIsKeyDatesModalOpen] = useState(false);
    const [isKeyPeopleModalOpen, setIsKeyPeopleModalOpen] = useState(false);
    const [activityId, setActivityId] = useState('');
    const { theme } = useStyling();
    const infoTiles = [];

    const getKeyActivities = useMemo(() => {
        if (viewState?.plans?.[0]?.keyActivities && viewState.plans[0]?.activities && !isReadOnly) {
            return viewState.plans[0].keyActivities.map((keyActivity) =>
                viewState.plans[0].activities.find((a) => a?.identity.id === keyActivity.id)
            );
        } else {
            return viewState.keyActivities || [];
        }
    }, [viewState?.plans?.[0]]);

    if (viewState?.productionStatus) {
        infoTiles.push(<HeaderLabel key={1} text={viewState.productionStatus} />);
    }
    if (viewState?.productionType) {
        infoTiles.push(<HeaderLabel key={2} text={viewState.productionType} width={150} />);
    }
    if (viewState?.associatedOrgs && Array.isArray(viewState?.associatedOrgs) && viewState?.associatedOrgs.length > 0) {
        infoTiles.push(<AssociatedOrgsLabel key={3} orgs={viewState.associatedOrgs} />);
    }
    if (viewState?.genre) {
        infoTiles.push(<HeaderLabel key={4} text={viewState.genre} width={300} />);
    }
    return (
        viewState && (
            <>
                <V
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    data-testid="production-header-section"
                >
                    <EditablePanel
                        Header={<ProductionHeaderInfo viewState={viewState} infoTiles={infoTiles} />}
                        sx={{
                            position: 'relative',
                            bgcolor: theme.palette.background.paper,
                            borderRadius: 1,
                            overflow: 'hidden',
                            gap: 1.5,
                            px: 2,
                            py: 1,
                            width: '100%'
                        }}
                        isCollapsable={!isReadOnly}
                        actions={
                            isReadOnly
                                ? []
                                : [
                                      {
                                          label: <I18n token="form.title.edit" />,
                                          icon: Icons.Edit,
                                          onClick: () => {
                                              onEdit();
                                          },
                                          disabled: isSaving
                                      }
                                  ]
                        }
                    >
                        <V sx={{ gap: 1.5 }}>
                            {/* <ProjectOwner projectOwner={viewState.projectOwner?.label} /> we may use it later on*/}
                            <AssociatedPeopleTile
                                contacts={viewState.keyPeople || []}
                                onAdd={() => {
                                    setIsKeyPeopleModalOpen(true);
                                }}
                                isReadOnly={isReadOnly}
                                isSaving={isSaving}
                            />

                            <KeyDates
                                activities={getKeyActivities}
                                onAdd={() => {
                                    setIsKeyDatesModalOpen(true);
                                }}
                                onEdit={(id) => {
                                    setIsKeyDatesModalOpen(true);
                                    setActivityId(id);
                                }}
                                isReadOnly={isReadOnly}
                                isSaving={isSaving}
                            />

                            <DescriptionLabel description={viewState.about} />
                        </V>
                    </EditablePanel>
                </V>
                {isKeyDatesModalOpen && (
                    <AddKeyDatesModal
                        open={isKeyDatesModalOpen}
                        onClose={() => {
                            setIsKeyDatesModalOpen(false);
                            setActivityId('');
                        }}
                        productionId={viewState?.identity?.id}
                        planId={viewState?.plans?.[0]?.identity?.id}
                        activityId={activityId}
                        setViewState={setViewState}
                        viewState={viewState}
                    />
                )}
                {isKeyPeopleModalOpen && (
                    <AddKeyPeopleModal
                        open={isKeyPeopleModalOpen}
                        onClose={() => {
                            setIsKeyPeopleModalOpen(false);
                        }}
                        productionId={viewState?.identity?.id}
                        peopleData={viewState?.associatedPeople}
                        keyPeople={viewState?.keyPeople}
                        productionRef={{ ...viewState?.reference, label: viewState?.name || '' }}
                    />
                )}
            </>
        )
    );
};
