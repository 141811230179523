import React from 'react';
import { Attribute } from '../attributes';
import { LabelValueDisplay } from '../AttributeValue';

type TimeAttributePreviewProps = {
    attribute: Attribute;
};

const getTimeFormatFromTimeString = (input) => {
    const hours = input.slice(0, 2);
    const minutes = input.slice(2, 4);

    return `${hours}:${minutes}`;
};

export default (props: TimeAttributePreviewProps) => {
    const { attribute } = props;

    return (
        attribute && (
            <LabelValueDisplay
                label={attribute.label}
                value={attribute.value ? getTimeFormatFromTimeString(attribute.value) : '-'}
            />
        )
    );
};
