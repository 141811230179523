import React from 'react';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';

export default () => {
    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget name="full" label="Full" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="half1" label="Half" />
                </FormItem>
                <FormItem half>
                    <FormWidget name="half2" label="Half" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem quarter>
                    <FormWidget name="quarter1" label="Quarter" />
                </FormItem>
                <FormItem quarter>
                    <FormWidget name="quarter2" label="Quarter" />
                </FormItem>
                <FormItem quarter>
                    <FormWidget name="quarter3" label="Quarter" />
                </FormItem>
                <FormItem quarter>
                    <FormWidget name="quarter4" label="Quarter" />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
