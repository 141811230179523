import React, { Dispatch, SetStateAction } from 'react';
import { V } from '../../../common/Layout';
import OpportunityResponseFormItems from './OpportunityResponseFormItems';
import OpportunityEmailPreview from './OpportunityEmailPreview';
import { InquiryStatusInfo } from 'sr-types/lib/opportunity/v1/graphql';

export default ({
    inquiryStatusData,
    numberOfProducts,
    requestNoteLabel,
    setRequestNoteLabel
}: {
    inquiryStatusData: InquiryStatusInfo;
    numberOfProducts: number;
    requestNoteLabel: string;
    setRequestNoteLabel: Dispatch<SetStateAction<string>>;
}) => {
    return (
        <V gap={2}>
            <OpportunityEmailPreview
                content={inquiryStatusData?.inquiryMessage?.content}
                numberOfProducts={numberOfProducts}
                inquiryFor={inquiryStatusData?.inquiryFor}
            />

            <OpportunityResponseFormItems
                inquiryData={inquiryStatusData as InquiryStatusInfo}
                inquiryResponseStatus={inquiryStatusData?.status}
                requestNoteLabel={requestNoteLabel}
                setRequestNoteLabel={setRequestNoteLabel}
                inviteeName={inquiryStatusData?.inquiryMessage?.senderInfo?.name}
            />
        </V>
    );
};
