import { atom, selector } from 'recoil';

export const isSchedulerProductionRefetchNeededAtom = atom({
    key: 'isSchedulerProductionRefetchNeeded',
    default: localStorage.getItem('isSchedulerProductionRefetchNeeded') === 'true'
});

export const isSchedulerProductionRefetchNeededValue = selector({
    key: 'isSchedulerProductionRefetchNeededValue',
    get: ({ get }) => {
        return get(isSchedulerProductionRefetchNeededAtom);
    }
});
