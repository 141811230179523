import React, { useContext, useEffect, useMemo } from 'react';
import TextFieldEditor from './text/TextFieldEditor';
import NumberFieldEditor from './number/NumberFieldEditor';
import TextFieldPreview from './text/TextFieldPreview';
import NumberFieldPreview from './number/NumberFieldPreview';
import Validator, { ValidatorType } from '../common/form/Validator';
import { Listener } from '../common/form/FormContext';
import TermsetFieldEditor from './termset/TermsetFieldEditor';
import TermsetFieldPreview from './termset/TermsetFieldPreview';
import TermsetAttributeEditor from './termset/TermsetAttributeEditor';
import NumberTypePreview from './number/NumberTypePreview';
import TextTypePreview from './text/TextTypePreview';
import TermsetTypePreview from './termset/TermsetTypePreview';
import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache, useLazyQuery } from '@apollo/client';
import { authLink } from '../common/auth/api';
import DefaultAttributePreview from './default/DefaultAttributePreview';
import BooleanAttributeEditor from './boolean/BooleanAttributeEditor';
import BooleanAttributePreview from './boolean/BooleanAttributePreview';
import DateAttributeEditor from './date/DateAttributeEditor';
import DefaultTypePreview from './default/DefaultTypePreview';
import DefaultFieldPreview from './default/DefaultFieldPreview';
import DefaultFieldEditor from './default/DefaultFieldEditor';
import TimeAttributeEditor from './time/TimeAttributeEditor';
import TimeAttributePreview from './time/TimeAttributePreview';
import DateAttributePreview from './date/DateAttributePreview';
import TermsetAttributePreview from './termset/TermsetAttributePreview';
import DecimalFieldEditor from './decimal/DecimalFieldEditor';
import DecimalAttributePreview from './decimal/DecimalAttributePreview';
import DecimalFieldPreview from './decimal/DecimalFieldPreview';
import DefaultAttributeEditor from './default/DefaultAttributeEditor';
import { useI18n } from '../common/i18n/I18n';
import MeasurementFieldEditor from './measurement/MeasurementFieldEditor';
import MeasurementAttributeEditor from './measurement/MeasurementAttributeEditor';
import MeasurementAttributePreview from './measurement/MeasurementAttributePreview';
import MeasurementFieldPreview from './measurement/MeasurementFieldPreview';
import { UserContext } from '../common/auth/UserContext';
import { useFieldDefinitionData } from './attributesApi';
import { client as termsetClient, termsetListQuery } from '../supply/termset/termset';
import { constants } from '../common/constants';
import { searchClient } from '../common/list/slimQuery';
import { areAllFiltersInList } from '../common/utils/commonUtils';
import { ValidationRulesType } from '../production/helpers/productionUtils';
import { isEmpty } from 'lodash';
import { FieldDefinition, Reference } from 'sr-types/lib/search/v1/graphql';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/fielddefinition/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const saveFieldDefinition: DocumentNode = gql`
    mutation FieldDefinitions($input: FieldDefinitionInput) {
        saveFieldDefinition(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const addFieldDefinitionAssociation: DocumentNode = gql`
    mutation FieldDefinitions($id: ID!, $entity: String!, $entityId: String) {
        addFieldDefinitionAssociation(id: $id, entity: $entity, entityId: $entityId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const removeFieldDefinitionAssociation: DocumentNode = gql`
    mutation FieldDefinitions($id: ID!, $entity: String!, $entityId: String) {
        removeFieldDefinitionAssociation(id: $id, entity: $entity, entityId: $entityId) {
            id
            errors {
                field
                message
            }
        }
    }
`;
export const deleteFieldDefinition: DocumentNode = gql`
    mutation FieldDefinitions($id: ID!) {
        deleteFieldDefinition(id: $id) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const getFieldDefinition: DocumentNode = gql`
    query Preference {
        fieldDefinition {
            name
            fieldType
            label
        }
    }
`;

export const inactivateFieldDefinition: DocumentNode = gql`
    mutation InactivateFieldDefinition($id: ID!) {
        inactivateFieldDefinition(id: $id) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const activateFieldDefinition: DocumentNode = gql`
    mutation ActivateFieldDefinition($id: ID!) {
        activateFieldDefinition(id: $id) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

const camelizeKey = (text) => {
    const a = text.toLowerCase().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
    return a.substring(0, 1).toLowerCase() + a.substring(1);
};

export enum AvailableTypeEnum {
    number = 'number',
    text = 'text',
    termset = 'termset',
    time = 'time',
    date = 'date',
    boolean = 'boolean',
    decimal = 'decimal',
    measurement = 'measurement'
}

export enum TermsetSelectTypeEnum {
    single = 'singleSelect',
    multi = 'multiSelect'
}

type TermsetSelectType = (typeof TermsetSelectTypeEnum)[keyof typeof TermsetSelectTypeEnum];
type AvailableType = (typeof AvailableTypeEnum)[keyof typeof AvailableTypeEnum];

/**
 * Type is like a class, Field is like an instance of that class, Attribute is a Field with a value.
 */
export interface Type {
    id?: string;
    typeName: string;
    fieldType: AvailableType;
    description: string;
}

/**
 * Field definition extends the type with name and label + type specific properties
 */
export interface Field extends Type {
    name: string;
    label: string;
    group: string;
}

export interface TextField extends Field {}

export interface NumberField extends Field {
    min: number;
    max: number;
}

export interface TermsetField extends Field {
    termset: string;
    selectType?: TermsetSelectType;
    newTermset?: string[];
    isMultiSelect?: boolean;
    allowCreate?: boolean;
}
export interface DecimalField extends Field {
    min: number;
    max: number;
    decimalsNumber: number;
}
export interface MeasurementField extends Field {
    min: number;
    max: number;
    decimalsNumber: number;
    unit?: string;
    measurementType?: string;
}
/**
 * Attribute extends the Field with value
 */
export interface Attribute extends Field {
    value: any;
    metadata?: string;
    fieldDefinitionReference?: Reference;
}
export interface TextAttribute extends Attribute {
    value: string;
}
export interface NumberAttribute extends Attribute {
    value: number;
}
export interface TermsetAttribute extends Attribute {
    value: Reference;
}
export interface DateAttribute extends Attribute {
    value: string;
}
export interface TimeAttribute extends Attribute {
    value: string;
}
export interface MeasurementAttribute extends Attribute {
    value: string;
}

type FieldTypesType = {
    [key in AvailableType]: Type;
};
export const fieldTypes: FieldTypesType = {
    [AvailableTypeEnum.number]: {
        typeName: 'Number',
        fieldType: AvailableTypeEnum.number,
        description: 'Number field description goes here'
    },
    [AvailableTypeEnum.text]: {
        typeName: 'Text',
        fieldType: AvailableTypeEnum.text,
        description: 'Text field description goes here'
    },
    [AvailableTypeEnum.termset]: {
        typeName: 'Termset',
        fieldType: AvailableTypeEnum.termset,
        description: 'Termset field description goes here'
    },
    [AvailableTypeEnum.time]: {
        typeName: 'Time',
        fieldType: AvailableTypeEnum.time,
        description: 'Time field description goes here'
    },
    [AvailableTypeEnum.date]: {
        typeName: 'Date',
        fieldType: AvailableTypeEnum.date,
        description: 'Date field description goes here'
    },
    [AvailableTypeEnum.boolean]: {
        typeName: 'Boolean',
        fieldType: AvailableTypeEnum.boolean,
        description: 'Boolean field description goes here'
    },
    [AvailableTypeEnum.decimal]: {
        typeName: 'Decimal',
        fieldType: AvailableTypeEnum.decimal,
        description: 'Decimal field description goes here'
    },
    [AvailableTypeEnum.measurement]: {
        typeName: 'Measurement',
        fieldType: AvailableTypeEnum.measurement,
        description: 'Measurement field description goes here'
    }
};

type ReactElementType = {
    [key in AvailableType]: (props) => JSX.Element;
};

export const typePreview: ReactElementType = {
    [AvailableTypeEnum.number]: NumberTypePreview,
    [AvailableTypeEnum.text]: TextTypePreview,
    [AvailableTypeEnum.termset]: TermsetTypePreview,
    [AvailableTypeEnum.time]: DefaultTypePreview,
    [AvailableTypeEnum.date]: DefaultTypePreview,
    [AvailableTypeEnum.boolean]: DefaultTypePreview,
    [AvailableTypeEnum.decimal]: DefaultTypePreview,
    [AvailableTypeEnum.measurement]: DefaultTypePreview
};

export const fieldEditors: ReactElementType = {
    [AvailableTypeEnum.number]: NumberFieldEditor,
    [AvailableTypeEnum.text]: TextFieldEditor,
    [AvailableTypeEnum.termset]: TermsetFieldEditor,
    [AvailableTypeEnum.time]: DefaultFieldEditor,
    [AvailableTypeEnum.date]: DefaultFieldEditor,
    [AvailableTypeEnum.boolean]: DefaultFieldEditor,
    [AvailableTypeEnum.decimal]: DecimalFieldEditor,
    [AvailableTypeEnum.measurement]: MeasurementFieldEditor
};

export const fieldPreview: ReactElementType = {
    [AvailableTypeEnum.text]: TextFieldPreview,
    [AvailableTypeEnum.number]: NumberFieldPreview,
    [AvailableTypeEnum.termset]: TermsetFieldPreview,
    [AvailableTypeEnum.time]: DefaultFieldPreview,
    [AvailableTypeEnum.date]: DefaultFieldPreview,
    [AvailableTypeEnum.boolean]: DefaultFieldPreview,
    [AvailableTypeEnum.decimal]: DecimalFieldPreview,
    [AvailableTypeEnum.measurement]: MeasurementFieldPreview
};

export const attributeEditors: ReactElementType = {
    [AvailableTypeEnum.text]: DefaultAttributeEditor,
    [AvailableTypeEnum.number]: DefaultAttributeEditor,
    [AvailableTypeEnum.termset]: TermsetAttributeEditor,
    [AvailableTypeEnum.time]: TimeAttributeEditor,
    [AvailableTypeEnum.date]: DateAttributeEditor,
    [AvailableTypeEnum.boolean]: BooleanAttributeEditor,
    [AvailableTypeEnum.decimal]: DefaultAttributeEditor,
    [AvailableTypeEnum.measurement]: MeasurementAttributeEditor
};

export const attributePreview: ReactElementType = {
    [AvailableTypeEnum.text]: DefaultAttributePreview,
    [AvailableTypeEnum.number]: DefaultAttributePreview,
    [AvailableTypeEnum.termset]: TermsetAttributePreview,
    [AvailableTypeEnum.time]: TimeAttributePreview,
    [AvailableTypeEnum.date]: DateAttributePreview,
    [AvailableTypeEnum.boolean]: BooleanAttributePreview,
    [AvailableTypeEnum.decimal]: DecimalAttributePreview,
    [AvailableTypeEnum.measurement]: MeasurementAttributePreview
};

export const useTypeValidationRules = (selectedType, value) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { fieldDefinitionData } = useFieldDefinitionData(undefined, 'active');

    const [getData, { data: termsetsData }] = useLazyQuery(termsetListQuery, {
        client: termsetClient,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: false
    });

    useEffect(() => {
        if (selectedType?.fieldType === AvailableTypeEnum.termset) {
            getData();
        }
    }, [selectedType, getData]);

    /* Validation rules for Types */
    type TypeValidationRulesType = {
        [key in AvailableType]: {
            [field: string]: ValidatorType[];
        };
    };
    const termsetValidationMsg = useI18n('termset.fieldDefinition.validationMsg');
    const labelValidationMsg = useI18n('fieldDefinition.labelvalidationMsg');
    const minValidationMsg = useI18n('fieldDefinition.minValidation');
    const maxValidationMsg = useI18n('fieldDefinition.maxValidation');

    const typeValidationRules = useMemo(() => {
        const existingFieldDefByGroup = fieldDefinitionData?.results?.hits?.items
            ? fieldDefinitionData.results.hits.items.reduce((accumulator, currentVal) => {
                  const name = currentVal.name.toLowerCase().replace(/\s+/g, '');
                  const group = currentVal.group.toLowerCase().replace(/\s+/g, '');
                  if (accumulator[group]) {
                      accumulator[group].push(name);
                  } else {
                      accumulator[group] = [name];
                  }
                  return accumulator;
              }, {})
            : [];

        const commonLabelValidator: ValidatorType = {
            isValid: (prop, state, isModified) => {
                const group = state.group.toLowerCase().replace(/\s+/g, '');
                const label = state.label.toLowerCase().replace(/\s+/g, '');
                return existingFieldDefByGroup?.[group] ? !existingFieldDefByGroup[group].includes(label) : true;
            },
            messageKey: labelValidationMsg
        };

        const minMaxValidator: ValidatorType = {
            isValid: (prop, state, isModified) => {
                return !(parseFloat(state.min) > parseFloat(state.max));
            }
        };
        const commonMaxValidator: ValidatorType = { ...minMaxValidator, messageKey: maxValidationMsg };
        const commonMinValidator: ValidatorType = { ...minMaxValidator, messageKey: minValidationMsg };

        const staticRules: TypeValidationRulesType = {
            [AvailableTypeEnum.text]: {
                label: [Validator.RULES.isRequired, commonLabelValidator]
            },
            [AvailableTypeEnum.number]: {
                label: [Validator.RULES.isRequired, commonLabelValidator],
                min: [Validator.RULES.isNumber, commonMinValidator],
                max: [Validator.RULES.isNumber, commonMaxValidator]
            },
            [AvailableTypeEnum.termset]: {
                label: [Validator.RULES.isRequired, commonLabelValidator],
                termset: value?.newTermset ? [] : [Validator.RULES.isRequired],
                newTermset: value?.termset ? [] : [Validator.RULES.isRequired]
            },
            [AvailableTypeEnum.time]: {
                label: [Validator.RULES.isRequired, commonLabelValidator]
            },
            [AvailableTypeEnum.date]: {
                label: [Validator.RULES.isRequired, commonLabelValidator]
            },
            [AvailableTypeEnum.boolean]: {
                label: [Validator.RULES.isRequired, commonLabelValidator]
            },
            [AvailableTypeEnum.decimal]: {
                label: [Validator.RULES.isRequired, commonLabelValidator],
                min: [Validator.RULES.isNumber, commonMinValidator],
                max: [Validator.RULES.isNumber, commonMaxValidator],
                decimalsNumber: [Validator.RULES.isNumber]
            },
            [AvailableTypeEnum.measurement]: {
                label: [Validator.RULES.isRequired, commonLabelValidator],
                min: [Validator.RULES.isNumber, commonMinValidator],
                max: [Validator.RULES.isNumber, commonMaxValidator],
                decimalsNumber: [Validator.RULES.isNumber],
                measurementType: [Validator.RULES.isRequired],
                unit: [Validator.RULES.isRequired]
            }
        };

        if (termsetsData?.termsets && selectedType?.fieldType === AvailableTypeEnum.termset) {
            staticRules.termset.label.push({
                isValid: (prop, state, isModified) => {
                    const termsetLabels = termsetsData.termsets.map((termset) => termset.label);
                    return !state.newTermset.length || !termsetLabels.includes(state.label);
                },
                messageKey: termsetValidationMsg
            });
        }
        return selectedType?.fieldType ? staticRules[selectedType.fieldType] : {};
    }, [
        fieldDefinitionData?.results?.hits?.items,
        labelValidationMsg,
        maxValidationMsg,
        minValidationMsg,
        selectedType?.fieldType,
        termsetValidationMsg,
        termsetsData?.termsets,
        value?.newTermset,
        value?.termset
    ]);

    return { typeValidationRules };
};

/* Listeners for Types */
type TypeListenersType = {
    [key in AvailableType]: Listener[];
};
export const typeListeners: TypeListenersType = {
    [AvailableTypeEnum.text]: [
        {
            prop: 'label',
            func: (name, newValue, newState: Field) => {
                newState.name = camelizeKey(newValue);
            }
        }
    ],
    [AvailableTypeEnum.number]: [
        {
            prop: 'label',
            func: (name, newValue, newState: Field) => {
                newState.name = camelizeKey(newValue);
            }
        }
    ],
    [AvailableTypeEnum.termset]: [
        {
            prop: 'label',
            func: (name, newValue, newState: Field) => {
                newState.name = camelizeKey(newValue);
            }
        },
        {
            prop: 'newTermset',
            func: (name, newValue, newState: TermsetField) => {
                newState.termset = '';
            }
        },
        {
            prop: 'termset',
            func: (name, newValue, newState: TermsetField) => {
                newState.newTermset = [];
            }
        },
        {
            prop: 'selectType',
            func: (name, newValue, newState: TermsetField) => {
                if (newValue.id === TermsetSelectTypeEnum.multi) {
                    newState.isMultiSelect = true;
                } else {
                    newState.isMultiSelect = false;
                }
            }
        }
    ],
    [AvailableTypeEnum.time]: [
        {
            prop: 'label',
            func: (name, newValue, newState: Field) => {
                newState.name = camelizeKey(newValue);
            }
        }
    ],
    [AvailableTypeEnum.date]: [
        {
            prop: 'label',
            func: (name, newValue, newState: Field) => {
                newState.name = camelizeKey(newValue);
            }
        }
    ],
    [AvailableTypeEnum.boolean]: [
        {
            prop: 'label',
            func: (name, newValue, newState: Field) => {
                newState.name = camelizeKey(newValue);
            }
        }
    ],
    [AvailableTypeEnum.decimal]: [
        {
            prop: 'label',
            func: (name, newValue, newState: Field) => {
                newState.name = camelizeKey(newValue);
            }
        }
    ],
    [AvailableTypeEnum.measurement]: [
        {
            prop: 'label',
            func: (name, newValue, newState: Field) => {
                newState.name = camelizeKey(newValue);
            }
        }
    ]
};

/* Validation rules for Attributes */
type AttributeValidationRulesType = {
    [key in AvailableType]: (field?: Field) => ValidatorType[];
};
export const attributeValidationRules: AttributeValidationRulesType = {
    [AvailableTypeEnum.text]: (/*field: Field*/): ValidatorType[] => {
        return [Validator.RULES.isRequired];
    },
    [AvailableTypeEnum.number]: (field: Field): ValidatorType[] => {
        return [
            Validator.RULES.isNumber,
            {
                isValid: (prop, state, isModified, i18n) => {
                    if (state) {
                        const number = field as NumberField;
                        const value: number = parseInt(state[prop]);
                        const min: number = parseInt(String(number.min));
                        const max: number = parseInt(String(number.max));
                        let msg;
                        if (isFinite(min) && isFinite(max)) {
                            if (value < min || value > max) {
                                msg = i18n('validation.number.between', { min: number.min, max: number.max });
                            }
                        } else if (isFinite(min)) {
                            if (value < min) {
                                msg = i18n('validation.number.larger', { min: number.min });
                            }
                        } else if (isFinite(max)) {
                            if (value > max) {
                                msg = i18n('validation.number.smaller', { max: number.max });
                            }
                        }
                        return msg ? msg : true;
                    } else {
                        return true;
                    }
                }
            }
        ];
    },
    [AvailableTypeEnum.termset]: (/*field: Field*/): ValidatorType[] => {
        return [Validator.RULES.isRequired];
    },
    [AvailableTypeEnum.time]: (/*field: Field*/): ValidatorType[] => {
        return [Validator.RULES.isRequired];
    },
    [AvailableTypeEnum.date]: (/*field: Field*/): ValidatorType[] => {
        return [Validator.RULES.isRequired];
    },
    [AvailableTypeEnum.boolean]: (/*field: Field*/): ValidatorType[] => {
        return [];
    },
    [AvailableTypeEnum.decimal]: (field: Field): ValidatorType[] => {
        return [
            Validator.RULES.isNumber,
            {
                isValid: (prop, state, isModified, i18n) => {
                    if (state) {
                        const number = field as NumberField;
                        const value: number = parseInt(state[prop]);
                        const min: number = parseInt(String(number.min));
                        const max: number = parseInt(String(number.max));
                        let msg;
                        if (isFinite(min) && isFinite(max)) {
                            if (value < min || value > max) {
                                msg = i18n('validation.number.between', { min: number.min, max: number.max });
                            }
                        } else if (isFinite(min)) {
                            if (value < min) {
                                msg = i18n('validation.number.larger', { min: number.min });
                            }
                        } else if (isFinite(max)) {
                            if (value > max) {
                                msg = i18n('validation.number.smaller', { max: number.max });
                            }
                        }
                        return msg ? msg : true;
                    } else {
                        return true;
                    }
                }
            }
        ];
    },
    [AvailableTypeEnum.measurement]: (field: Field): ValidatorType[] => {
        return [
            Validator.RULES.isNumber,
            {
                isValid: (prop, state, isModified, i18n) => {
                    if (state) {
                        const number = field as NumberField;
                        const value: number = parseInt(state[prop]);
                        const min: number = parseInt(String(number.min));
                        const max: number = parseInt(String(number.max));
                        let msg;
                        if (isFinite(min) && isFinite(max)) {
                            if (value < min || value > max) {
                                msg = i18n('validation.number.between', { min: number.min, max: number.max });
                            }
                        } else if (isFinite(min)) {
                            if (value < min) {
                                msg = i18n('validation.number.larger', { min: number.min });
                            }
                        } else if (isFinite(max)) {
                            if (value > max) {
                                msg = i18n('validation.number.smaller', { max: number.max });
                            }
                        }
                        return msg ? msg : true;
                    } else {
                        return true;
                    }
                }
            }
        ];
    }
};

export const generateAttributeValidationRules = (fields) => {
    const rules: ValidationRulesType = {};
    fields.forEach((field) => {
        // TODO right now it's only 1 validator per field.
        const generator = attributeValidationRules[field.fieldType];
        if (generator && typeof generator === 'function') {
            const fieldRules = generator(field);
            if (!isEmpty(fieldRules)) {
                rules[field.name] = fieldRules;
            }
        }
    });
    return rules;
};

export const getAttributesByGroups = (attributes) => {
    const result = {};
    if (Array.isArray(attributes)) {
        attributes.forEach((attr) => {
            result[attr.group] = result?.[attr.group] ? [...result[attr.group], attr] : [attr];
        });
    }

    Object.keys(result).forEach((key) => {
        result[key] = result[key].sort((a, b) => a.name.localeCompare(b.name));
    });

    return result;
};

export const getObjectSortedByKeys = (unsorted) => {
    const ordered = Object.keys(unsorted)
        .sort()
        .reduce((obj, key) => {
            obj[key] = unsorted[key];
            return obj;
        }, {});
    return ordered;
};

export const parseMetadataToFields = (attributes) => {
    if (attributes) {
        const parsedAttributes = attributes.map((attribute) => {
            const { metadata, ...otherFields } = attribute;
            const commonMetadataFields: any = {};
            if (metadata) {
                const metaDataJSON = JSON.parse(metadata);
                commonMetadataFields.required = metaDataJSON.required;
                commonMetadataFields.keyInformation = metaDataJSON.keyInformation;

                if (otherFields.fieldType === AvailableTypeEnum.number) {
                    return { ...otherFields, ...commonMetadataFields, min: metaDataJSON?.min, max: metaDataJSON?.max };
                }
                if (otherFields.fieldType === AvailableTypeEnum.termset) {
                    return {
                        ...otherFields,
                        ...commonMetadataFields,
                        termset: metaDataJSON?.termset,
                        allowCreate: metaDataJSON?.allowCreate,
                        isMultiSelect: metaDataJSON?.isMultiSelect
                    };
                }
                if (otherFields.fieldType === AvailableTypeEnum.decimal) {
                    return {
                        ...otherFields,
                        ...commonMetadataFields,
                        min: metaDataJSON?.min,
                        max: metaDataJSON?.max,
                        decimalsNumber: metaDataJSON?.decimalsNumber
                    };
                }
                if (otherFields.fieldType === AvailableTypeEnum.measurement) {
                    return {
                        ...otherFields,
                        ...commonMetadataFields,
                        min: metaDataJSON?.min,
                        max: metaDataJSON?.max,
                        decimalsNumber: metaDataJSON?.decimalsNumber,
                        measurementType: metaDataJSON?.measurementType,
                        unit: metaDataJSON?.unit
                    };
                }
            }
            return { ...otherFields, ...commonMetadataFields };
        });
        return parsedAttributes;
    }
    return attributes;
};

export const parseFieldsToMetadata = (fieldDefinition) => {
    const {
        min,
        max,
        typeName,
        decimalsNumber,
        required,
        keyInformation,
        termset,
        newTermset,
        allowCreate,
        selectType,
        isMultiSelect,
        measurementType,
        unit,
        ...otherFields
    } = fieldDefinition;
    let metadata = '';
    if (fieldDefinition.fieldType === AvailableTypeEnum.number) {
        metadata = JSON.stringify({ min, max });
    }
    if (fieldDefinition.fieldType === AvailableTypeEnum.termset) {
        metadata = JSON.stringify({ required, keyInformation, termset, newTermset, allowCreate, isMultiSelect });
    }
    if (fieldDefinition.fieldType === AvailableTypeEnum.decimal) {
        metadata = JSON.stringify({ min, max, decimalsNumber });
    }
    if (fieldDefinition.fieldType === AvailableTypeEnum.measurement) {
        metadata = JSON.stringify({ min, max, decimalsNumber, required, keyInformation, measurementType, unit });
    }
    return { ...otherFields, metadata };
};

export const getSupportedItems = (items) => {
    return items.filter((item) => Object.values(AvailableTypeEnum).includes(item.fieldType));
};

export const getNonSavedFieldDefinitions = (fieldDef, associatedFieldDefs) => {
    const existingIds = associatedFieldDefs?.map((field) => field.id);
    const nonExistingFields = fieldDef.filter((field) => !existingIds?.includes(field.id));
    return nonExistingFields;
};
export const arrayToSelectOptionsLocalized = (arr) => {
    return arr && arr.length > 0
        ? arr.map((item) => {
              return { id: item, label: useI18n(`termset.selectType.${item}`) };
          })
        : [];
};

export const refetchFieldDefinitions = () =>
    searchClient.refetchQueries({
        include: ['slimResultsQuery'],
        onQueryUpdated(observableQuery) {
            return areAllFiltersInList(observableQuery.options.variables.filters, [
                {
                    identifier: 'entity',
                    value: 'Activity'
                },
                {
                    identifier: 'entityType',
                    value: 'Resource'
                }
            ]);
        }
    });

export function processAttributes(attributes: Attribute[], attr: any) {
    return attributes
        .map((attribute) => {
            const { value, metadata, ...otherAttributeFields } = attribute;
            if (attribute.fieldType === AvailableTypeEnum.measurement) {
                return {
                    ...otherAttributeFields,
                    value: JSON.stringify({
                        value: attr[attribute.name],
                        unit: attr[attribute.name + 'unit']
                    })
                };
            }
            if (attr[attribute?.name] || attribute.fieldType === AvailableTypeEnum.boolean) {
                return { ...otherAttributeFields, value: attr[attribute.name] };
            }
        })
        .filter(Boolean);
}
