import React from 'react';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';

export default () => {
    return (
        <FormPanel>
            <FormContainer>
                <FormItem quarter>
                    <FormWidget component="Date" name="date" label="Date" />
                </FormItem>
                <FormItem quarter>
                    <FormWidget component="Time" name="time" label="Time" />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
