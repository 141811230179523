import { Typography } from '@mui/material';
import React from 'react';
import { search } from 'sr-types';
import { V } from '../../../common/Layout';
import Loading from '../../../common/components/Loading';
import I18n from '../../../common/i18n/I18n';
import { CustomComboBoxFacet } from './CustomComboBoxFacet';
import { CustomListFacet } from './CustomListFacet';
import { DateRangeFacet } from './DateRangeFacet';
import { RangeSliderFacet } from './RangeSliderFacet';
import { TextFacet } from './TextFacet';
import { SingleSelectFacet } from './SingleSelectFacet';
import { BooleanFacet } from './BooleanFacet';

type FacetsListProps = {
    facets: search.SkFacetSet[];
    loading: boolean;
    promote: string[];
    showCount: boolean;
    isLocal: boolean;
    requiredFilters: string[];
};
export const FacetsList = (components = [], loading = false) => {
    const componentTypeMap = [
        ...components,
        TextFacet,
        CustomListFacet,
        CustomComboBoxFacet,
        DateRangeFacet,
        RangeSliderFacet,
        SingleSelectFacet,
        BooleanFacet
    ].reduce((sum, component) => {
        sum[component.DISPLAY] = component;
        return sum;
    }, {});
    return (props: FacetsListProps) => {
        const { facets, loading, promote, showCount, isLocal, requiredFilters = []} = props;
        return loading ? (
            <Loading size={'16px'} sx={{ py: 2 }} />
        ) : facets && facets.length ? (
            facets.map((facet, index) => {
                const isPromote = promote && promote.length && promote.includes(facet.identifier);
                if (!isPromote) {
                    const Component = componentTypeMap[facet.display];
                    if (!Component) {
                        throw new Error(facet.display + ' not available');
                    }
                    return (
                        <V
                            key={index}
                            sx={{
                                p: 1,
                                pt: 2
                            }}
                        >
                            <Component
                                facet={facet}
                                requiredFilters={requiredFilters}
                                loading={loading}
                                showCount={showCount}
                                isLocal={isLocal}
                            />
                        </V>
                    );
                }
            })
        ) : (
            <Typography sx={{ m: 1, fontWeight: 300 }}>
                <I18n token={'error.facets.missing.message'} />
            </Typography>
        );
    };
};
