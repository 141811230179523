import { MARKETPLACE, PLANNING, PROFILES, SUPPLIER } from '../../common/nav/apps';
import { useContext, useMemo } from 'react';
import { UserContext } from '../../common/auth/UserContext';

export default () => {
    const { application } = useContext(UserContext);

    const preFilterByApp = useMemo(() => {
        return {
            [MARKETPLACE.id]: [{ identifier: 'public', value: 'false' }],
            [SUPPLIER.id]: [
                { identifier: 'public', value: 'false' },
                { identifier: 'origin', value: 'noop' }
            ],
            [PLANNING.id]: [{ identifier: 'public', value: 'false' }],
            [PROFILES.id]: [
                { identifier: 'public', value: 'false' },
                { identifier: 'origin', value: 'noop' }
            ]
        };
    }, []);

    return preFilterByApp[application.id];
};
