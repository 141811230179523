import {
    addDays,
    addHours,
    addMinutes,
    addMonths,
    addQuarters,
    addWeeks,
    addYears,
    differenceInDays,
    differenceInHours,
    differenceInMonths,
    differenceInQuarters,
    differenceInWeeks,
    differenceInYears,
    format,
    isValid,
    parse,
    parseISO,
    startOfMonth,
    subDays,
    subHours,
    subMonths,
    subQuarters,
    subWeeks,
    subYears,
    toDate
} from 'date-fns';
import { Measurement } from '../form/widgets/Measurement';
import { getBrowserLocale } from '../i18n/localization';
import { RecurrenceRule } from 'sr-types/lib/production/v1/graphql';

const testDate = new Date(2000, 10, 12, 14, 5, 6);
export const isAMPM = testDate.toLocaleTimeString(getBrowserLocale()).includes('PM');
// export const DISPLAY_DATE_FORMAT = testDate.toLocaleDateString().replace('11', 'MM').replace('12', 'dd').replace('2000', 'yyyy');
export const DISPLAY_TIME_FORMAT = isAMPM ? 'hh:mm a' : 'HH:mm';
export const DISPLAY_TIME_PLACEHOLDER = isAMPM ? 'h:m a|p(m)' : 'h:m';
export const API_DATE_FORMAT = 'yyyyMMdd';
export const API_TIME_FORMAT = 'HHmmss';
export const API_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";
export const SHORT_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric'
};

export const toApiDateTimeString = (date: Date) => {
    return format(date, API_DATE_TIME_FORMAT);
};

export const toDisplay = (isoDate: string, short: boolean = false) => {
    return isoDate && isoDate !== '' ? toLocaleDateString(parseISO(isoDate), short) : '';
};

export const toLocaleDateString = (date: string | Date, short: boolean = false) => {
    if (date === '') {
        return '';
    }
    const d: Date = typeof date === 'string' ? parseISO(date) : date;
    return d.toLocaleDateString(getBrowserLocale(), short ? SHORT_DATE_OPTIONS : {});
};

export const toDisplayDateAndTime = (date, time, showTime) => {
    if (showTime) {
        return toLocaleDateString(date) + ' ' + toDisplayLocaleTime(time);
    }
    return toLocaleDateString(date);
};

export const toDisplayLocaleDateAndTime = (isoDateTime, showTime) => {
    if (showTime) {
        return isoDateTime.toLocaleString();
    } else {
        return toLocaleDateString(isoDateTime);
    }
};

export const toDisplayDateTimeRange = (dateRange: DateRange) => {
    if (dateRange.dateTimeDuration && dateRange.dateTimeDuration.unit == 'hour') {
        if (dateRange.start === dateRange.end) {
            return [
                toLocaleDateString(dateRange.start) + ' ' + toDisplayLocaleTime(dateRange.startTime),
                toDisplayLocaleTime(dateRange.endTime)
            ].join('-');
        }
        return [
            toLocaleDateString(dateRange.start) + ' ' + toDisplayLocaleTime(dateRange.startTime),
            toLocaleDateString(dateRange.end) + ' ' + toDisplayLocaleTime(dateRange.endTime)
        ].join('-');
    }
    if (dateRange.start === dateRange.end) {
        return toLocaleDateString(dateRange.start);
    }
    return toLocaleDateString(dateRange.start) + '-' + toLocaleDateString(dateRange.end);
};

export const toDisplayLocaleTime = (time: string) => {
    return time && time !== '' ? format(parse(time, API_TIME_FORMAT, new Date()), DISPLAY_TIME_FORMAT) : '';
};

export const toJSDate = (isoDate) => {
    return isoDate && isoDate !== '' ? parseISO(isoDate) : undefined;
};
export const toDisplayTime = (time) => {
    return time && time !== '' ? format(parse(time, API_TIME_FORMAT, new Date()), 'hh:mm a') : '';
};

export const toYearMonthFromISOString = (isoDate: string) => {
    return parseISO(isoDate).toLocaleString(getBrowserLocale(), { month: 'long', year: 'numeric' }); //=> 'April 2021';
};

export const toYearFromISOString = (isoDate: string) => {
    return parseISO(isoDate).toLocaleString(getBrowserLocale(), { year: 'numeric' });
};

export const toISOFromJSDate = (date: Date) => {
    return format(date, API_DATE_FORMAT);
};

export const toSimpleTimeFromJSDate = (date: Date) => {
    return format(date, API_TIME_FORMAT);
};

export const toDateTimeFromJSDate = (date: Date) => {
    return toDate(date);
};

export const toDateTimeISOWithoutOffset = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss'z'");
};

export const toDateTimeInUTC = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss'+00:00'");
};

export const isoDateToDisplayTime = (date: string) => {
    return format(parseISO(date), DISPLAY_TIME_FORMAT);
};

export const calculatePeriod = (start: string, end: string, type) => {
    let dur = '-';
    let startDate = type === TimePeriod.Hours ? parse(start, API_TIME_FORMAT, new Date()) : parseISO(start);
    let endDate = type === TimePeriod.Hours ? parse(end, API_TIME_FORMAT, new Date()) : parseISO(end);
    if (startDate !== undefined && endDate != undefined) {
        switch (type) {
            case TimePeriod.Hours:
                dur = differenceInHours(endDate, startDate, { roundingMethod: 'ceil' }).toString();
                break;
            case TimePeriod.Days:
                dur = differenceInDays(endDate, startDate).toString();
                break;
            case TimePeriod.Weeks:
                dur = differenceInWeeks(endDate, startDate, { roundingMethod: 'ceil' }).toString();
                break;
            case TimePeriod.Months:
                dur = differenceInMonths(endDate, startDate).toString();
                break;
            case TimePeriod.Quarters:
                dur = differenceInQuarters(endDate, startDate, { roundingMethod: 'ceil' }).toString();
                break;
            case TimePeriod.Years:
                dur = differenceInYears(endDate, startDate).toString();
                break;
            default:
                dur = differenceInDays(endDate, startDate).toString();
                break;
        }
    }
    return dur;
};

export const addPeriod = (start, period, type) => {
    let startDate = type === TimePeriod.Hours ? parse(start, API_TIME_FORMAT, new Date()) : parseISO(start);
    let endDate = startDate;
    if (startDate !== undefined && period != undefined) {
        switch (type) {
            case TimePeriod.Hours:
                endDate = addHours(startDate, parseInt(period));
                break;
            case TimePeriod.Days:
                endDate = addDays(startDate, parseInt(period));
                break;
            case TimePeriod.Weeks:
                endDate = addWeeks(startDate, parseInt(period));
                break;
            case TimePeriod.Months:
                endDate = addMonths(startDate, parseInt(period));
                break;
            case TimePeriod.Quarters:
                endDate = addQuarters(startDate, parseInt(period));
                break;
            case TimePeriod.Years:
                endDate = addYears(startDate, parseInt(period));
                break;
            default:
                endDate = addDays(startDate, parseInt(period));
                break;
        }
    }
    return format(endDate, API_DATE_FORMAT);
};

export const subtractPeriod = (end, period, type) => {
    let endDate = type === TimePeriod.Hours ? parse(end, API_TIME_FORMAT, new Date()) : parseISO(end);
    let startDate = endDate;
    if (startDate !== undefined && period != undefined) {
        switch (type) {
            case TimePeriod.Hours:
                startDate = subHours(endDate, parseInt(period));
                break;
            case TimePeriod.Days:
                startDate = subDays(endDate, parseInt(period));
                break;
            case TimePeriod.Weeks:
                startDate = subWeeks(endDate, parseInt(period));
                break;
            case TimePeriod.Months:
                startDate = subMonths(endDate, parseInt(period));
                break;
            case TimePeriod.Quarters:
                startDate = subQuarters(endDate, parseInt(period));
                break;
            case TimePeriod.Years:
                startDate = subYears(endDate, parseInt(period));
                break;
            default:
                startDate = subDays(endDate, parseInt(period));
                break;
        }
    }
    return format(startDate, API_DATE_FORMAT);
};

export const durationToHoursMinutes = (dur) => {
    return format(addMinutes(new Date(0), dur), 'hh:mm');
};

export const calculateDifference = (start, end, unit) => {
    let dur = 0;
    if (start && isValid(start) && end && isValid(end) && unit) {
        if (unit === 'day') {
            end = addDays(end, 1);
        }
        switch (unit) {
            case 'hour':
                dur = differenceInHours(end, start, { roundingMethod: 'ceil' });
                break;
            case 'day':
                dur = differenceInDays(end, start);
                break;
            case 'week':
                dur = differenceInWeeks(end, start, { roundingMethod: 'ceil' });
                break;
            case 'month':
                dur = differenceInMonths(end, start);
                break;
            case 'quarter':
                dur = differenceInQuarters(end, start, { roundingMethod: 'ceil' });
                break;
            case 'year':
                dur = differenceInYears(end, start);
                break;
            default:
                dur = differenceInDays(end, start);
        }
    }
    return dur;
};

export enum TimePeriod {
    Hours = 'Hours',
    Days = 'Days',
    Weeks = 'Weeks',
    Months = 'Months',
    Quarters = 'Quarters',
    Years = 'Years'
}

export const mergeDateAndTime = (date, time, zeroTime = false) => {
    return toDate(
        new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            zeroTime ? 0 : isValid(time) ? time.getHours() : date.getHours(),
            zeroTime ? 0 : isValid(time) ? time.getMinutes() : date.getMinutes(),
            zeroTime ? 0 : isValid(time) ? time.getSeconds() : date.getSeconds(),
            zeroTime ? 0 : isValid(time) ? time.getMilliseconds() : date.getMilliseconds()
        )
    );
};

export const splitDateAndTime = (date) => {
    return [format(date, API_DATE_FORMAT), format(date, API_TIME_FORMAT)];
};

export interface DateRange {
    start: string;
    startTime?: string;
    end: string;
    endTime?: string;
    duration: string;
    durationType?: string;
    recurrenceRule?: RecurrenceRule;
    dateTimeDuration?: Measurement;
    durationSpecified?: boolean;
}

export const destructureSchedule = (value: DateRange) => {
    if (value) {
        const start: Date = parse(value.start, API_DATE_FORMAT, new Date());
        const startTime: Date = parse(value.startTime, API_TIME_FORMAT, new Date());
        const end: Date = parse(value.end, API_DATE_FORMAT, new Date());
        const endTime: Date = parse(value.endTime, API_TIME_FORMAT, new Date());
        const durationSpecified = value.durationSpecified;
        const dateTimeDuration = value.dateTimeDuration;
        const mergedStart: Date = mergeDateAndTime(start, startTime);
        const mergedEnd: Date = mergeDateAndTime(end, endTime);
        return { start, startTime, end, endTime, durationSpecified, dateTimeDuration, mergedStart, mergedEnd };
    }
};

// return the date of the first of the current month in string format
export const getFirstDayOftheMonth = () => {
    const currentDate = new Date();
    const firstDayOfMonth = startOfMonth(currentDate);
    const formattedDate = format(firstDayOfMonth, "yyyy-MM-dd'T'HH:mm:ss");
    return formattedDate;
};

export const toBryntumCalTime = (time) => {
    const parsedTime = parse(time, 'HHmmss', new Date());
    const formattedTime = format(parsedTime, 'HH:mm');
    return formattedTime;
};

export const breakDateRangeIntoMonths = (start, end, unit) => {
    const buckets = [];
    let currentMonthStart = new Date(start);
    while (currentMonthStart <= end) {
        // Calculate the last day of the current month
        const currentMonthEnd = new Date(currentMonthStart);
        currentMonthEnd.setMonth(currentMonthStart.getMonth() + 1, 0); // Set to last day of the month
        unit == 'hour' && currentMonthEnd.setHours(23, 59);

        const bucketEnd = currentMonthEnd < end ? currentMonthEnd : end;

        buckets.push([new Date(currentMonthStart), bucketEnd]);

        // Move to the start of the next month
        currentMonthStart.setMonth(currentMonthStart.getMonth() + 1, 1);
        unit == 'hour' && currentMonthStart.setHours(0, 0);
    }

    // end date of the last bucket should be equal to dateRange end
    buckets[buckets.length - 1][1] = end;

    return buckets;
};
