import { Grid, SxProps, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import FormItem from './FormItem';

type FormContainerProps = {
    title?: string | ReactElement;
    titleSx?: SxProps;
    children: ReactElement | ReactElement[];
    containerSx?: SxProps;
};

export const FormContainer = ({ title = undefined, children, titleSx = undefined, containerSx = undefined }: FormContainerProps) => {
    return (
        <Grid className="_formContainer" sx={containerSx} flexWrap={'wrap'} container mt={0} columnSpacing={5.5} rowSpacing={1.5}>
            {title && (
                <FormItem sx={{ mt: 0, pt: 0, ...titleSx }}>
                    <Typography fontSize={16}>{title}</Typography>
                </FormItem>
            )}
            {children}
        </Grid>
    );
};
