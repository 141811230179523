import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache, useQuery } from '@apollo/client';
import { authLink } from '../../common/auth/api';
import { LOCATION_FIELDS } from '../../common/components/location';
import { convertToReferenceFieldObject, Reference, ReferenceField } from '../../common/reference/reference';
import { ReferenceFragment } from '../../common/list/fragments';
import { Product } from 'sr-types/lib/product/v1/graphql';
import { searchClient, slimResultsQuery } from '../../common/list/slimQuery';
import { useMemo } from 'react';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/product/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const useSearchClientData = (entity: string, activeOrganizationAccount: string) => {
    const filters = [{ identifier: 'entity', value: entity }];
    if ((entity  === 'Organization')) {
        const Orgfilters = [{ identifier: 'public', value: 'false' }, { identifier: 'inactive', value: 'false' }];
        filters.push(...Orgfilters);
    }
        const { loading, error, data } = useQuery(slimResultsQuery(), {
            client: searchClient,
            variables: {
                filters: filters,
                page: {
                    from: 0,
                    size: 1000
                },
                sortBy: 'name_ASC'
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            },
            notifyOnNetworkStatusChange: false
        });

    const optionData = useMemo(() => {
        if (data?.results?.hits?.items) {
            const items = data.results.hits.items;
            if (items.length) {
                const data = items.map((item) => {
                    return {
                       id: item.id, 
                       name:  item.name
                    };
                });
                return data;
            }
        }
    }, [data]);

    return { loading, error, optionData };
};

export enum GroupType {
    Item = 'Item',
    Group = 'Group',
    Child = 'Child'
}

export enum PackageType {
    Standard = 'Standard',
    Custom = 'Custom',
    Pool = 'Pool',
    Bundle = 'Bundle'
}

export type AssociatedProduct = {
    sequence: number;
    product: ReferenceField;
};

export const EMPTY_STATE: Product = {
    identity: {
        id: ''
    },
    name: '',
    type: '',
    subType: '',
    categories: [],
    availableLocations: [],
    offerTypes: [],
    description: '',
    isBundle: false,
    leaseDetails: [],
    sellable: true,
    packagingType: PackageType.Standard,
    baseProduct: undefined,
    definedProductPool: true,
    productPool: [],
    reservable: true,
    shouldSequenceSchedule: false,
    needsDetailedSchedule: false,
    associatedProducts: [],
    ownerOrganizationName: undefined,
    security: undefined,
    associatedFieldDefinitionReferences: [],
    profile: [],
    calendar: undefined
};

export const detailsQuery: DocumentNode = gql`
    query Product($id: ID!) {
        product(id: $id) {
            identity {
                id
            }
            name
            type
            subType
            categories {
                value
                lineage {
                    value
                }
            }
            calendar {
                ...Reference
            }
            offerTypes
            description
            isBundle
            leaseDetails {
                blockSize {
                    unit
                    value
                }
                minLease
                maxLease
            }
            reference {
                ...Reference
            }
            packagingType
            sellable
            reservable
            baseProduct {
                ...Reference
            }
            definedProductPool
            productPool {
                label
                reference {
                    ...Reference
                }
            }
            availableLocations {
                ...LocationFields
                timezone {
                    id
                    name
                }
            }
            ownerOrganizationId
            ownerOrganizationName
            profile {
                name
                fieldType
                value
                fieldDefinitionReference {
                    ...Reference
                }
            }
            associatedFieldDefinitionReferences {
                ...Reference
            }
            security {
                allowedActions
            }
            shouldSequenceSchedule
            needsDetailedSchedule
            associatedProducts {
                sequence
                product {
                    label
                    reference {
                        ...Reference
                    }
                }
            }
        }
    }
    ${ReferenceFragment}
    ${LOCATION_FIELDS}
`;

export const saveQuery: DocumentNode = gql`
    mutation SaveProduct($input: ProductInput!) {
        saveProduct(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const deleteQuery: DocumentNode = gql`
    mutation DeleteProduct($id: ID!) {
        deleteProduct(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const inactivateMutation: DocumentNode = gql`
    mutation InactivateProduct($id: ID!) {
        inactivateProduct(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const activateMutation: DocumentNode = gql`
    mutation ActivateProduct($id: ID!) {
        activateProduct(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export type ownerOrganizationRef = {
    ownerOrganizationRef?: Reference;
};

export const productToState = (product): Product & ownerOrganizationRef => {
    return {
        identity: product.identity,
        name: product.name,
        type: product.type,
        subType: product.subType,
        categories: product.categories,
        availableLocations: product.availableLocations || [],
        leaseDetails: product.leaseDetails,
        isBundle: product.isBundle,
        description: product.description,
        offerTypes: product.offerTypes,
        sellable: product.sellable,
        packagingType: product.packagingType,
        baseProduct: product.baseProduct,
        definedProductPool: product.definedProductPool,
        productPool: product.productPool,
        reservable: product.reservable,
        shouldSequenceSchedule: product.shouldSequenceSchedule,
        needsDetailedSchedule: product.needsDetailedSchedule,
        associatedProducts: product.associatedProducts,
        ownerOrganizationName: product.ownerOrganizationName,
        ownerOrganizationId: product.ownerOrganizationId,
        security: product.security,
        associatedFieldDefinitionReferences: product.associatedFieldDefinitionReferences,
        profile: product.profile,
        calendar: product.calendar
    };
};

export const rowsToproduct = (rows, colDefs, activeOrganizationAccount) => {
    return rows.map((row) => {
        const productEntity = {
            name: row.name,
            type: row.type,
            subType: row.subtype ? row.subtype: null,
            packagingType: row.packagingType,
            offerTypes: [row.offerTypes],
            availableLocations: row.availableLocations,
            description: row.description ? row.description : null,
            categories: [
                {
                    value: row.categories ? row.categories : null,
                    lineage: {
                        value: row.categories ? row.categories : null
                    }
                }
            ],
            leaseDetails: [],
            sellable: true,
            definedProductPool: false,
            productPool: [],
            reservable: true,
            shouldSequenceSchedule: false,
            needsDetailedSchedule: false,
            associatedProducts: [],
            ownerOrganizationId: row.ownerOrganizationId,
            associatedFieldDefinitionReferences: [],
            profile: []
        };
        return productEntity;
    });
}; 

export const bulkSaveQuery: DocumentNode = gql`
    mutation BulkSaveProduct($input: BulkSaveProductRequest!) {
        bulkSaveProduct(input: $input) {
            createdCount
            updatedCount
            unprocessedCount
            results {
                id
                errors {
                    field
                    message
                }
                reference {
                    id
                }
            }
            logFile {
                id
                uriPrefix
            }
        }
    }
`;

export const stateToProduct = (values) => {
    const associatedProducts = values?.associatedProducts?.map((associatedProduct) => {
        return {
            sequence: associatedProduct.sequence,
            product: convertToReferenceFieldObject(associatedProduct.product)
        };
    });

    return {
        identity: values.identity,
        name: values.name,
        type: values.type,
        subType: values.subType,
        categories: values.categories,
        isBundle: values.bundle,
        description: values.description,
        offerTypes: values.offerTypes,
        availableLocations: values.availableLocations,
        leaseDetails:
            (values?.offerTypes && values?.offerTypes?.includes('Lease')) || values?.offerTypes?.includes('Book')
                ? values?.leaseDetails
                : [],
        sellable: values.sellable,
        packagingType: values.packagingType,
        baseProduct: convertToReferenceFieldObject(values.baseProduct),
        definedProductPool: values.definedProductPool,
        productPool: convertToReferenceFieldObject(values.productPool),
        reservable: values.reservable,
        shouldSequenceSchedule: values.shouldSequenceSchedule,
        needsDetailedSchedule: values.needsDetailedSchedule,
        associatedProducts: associatedProducts,
        ownerOrganizationId: values.ownerOrganizationRef ? values.ownerOrganizationRef.id : '',
        associatedFieldDefinitionReferences: values.associatedFieldDefinitionReferences,
        profile: values.profile,
        calendar: values.calendar
    };
};
