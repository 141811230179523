import { Button, SvgIcon, SxProps } from '@mui/material';
import React, { ReactElement } from 'react';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import { H, V } from '../Layout';
import { useStyling } from '../Theme';
import EllipsisText from './EllipsisText';

export default ({
    text = undefined,
    ImageIcon = undefined,
    title = undefined,
    sx = {},
    allowedEdit,
    button = false,
    onClick = undefined,
    buttonText = 'button.add'
}: {
    text?: string | ReactElement;
    ImageIcon?: ReactElement;
    title?: string | ReactElement;
    sx?: SxProps;
    allowedEdit?: boolean;
    button?: boolean;
    onClick?: () => void;
    buttonText?: string;
}) => {
    const { theme } = useStyling();
    return (
        <V fill sx={{ alignItems: 'center', justifyContent: 'center', ...sx }}>
            {ImageIcon && ImageIcon}
            {title && (
                <H sx={{ gap: 1, color: theme.palette.text.secondary, justifyContent: 'center' }}>
                    <SvgIcon component={Icons.Info} />
                    <EllipsisText text={title} sx={{ fontSize: 16, fontWeight: 600 }} />
                </H>
            )}
            {text && <EllipsisText text={text} sx={{ fontSize: 'small' }} />}
            {allowedEdit ? (
                button && (
                    <Button
                        variant="outlined"
                        startIcon={<Icons.AddOutlined />}
                        sx={{ textTransform: 'capitalize' }}
                        onClick={onClick}
                    >
                        <I18n token={buttonText} />
                    </Button>
                )
            ) : (
                <></>
            )}
        </V>
    );
};
