import { useStyling } from '../common/Theme';
import { Typography } from '@mui/material';
import React from 'react';

export default ({ type }) => {
    const { theme } = useStyling();

    return (
        <Typography
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                p: 0.5,
                fontSize: 'smaller',
                borderBottomLeftRadius: '8px',
                borderBottom: `1px solid ${theme.palette.divider}`,
                borderLeft: `1px solid ${theme.palette.divider}`,
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: theme.palette.text.disabled
            }}
        >
            {type}
        </Typography>
    );
};
