import React, { useEffect } from 'react';
import MeasurementInput, { Measure } from './MeasurementInput';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import { API_DATE_FORMAT, API_TIME_FORMAT, calculateDifference, DateRange } from '../utils/dateTime';
import { set } from 'lodash';
import { hasErrors } from '../form/forms';
import { add, format, isValid, parse, sub, toDate } from 'date-fns';

export const EMPTY_DATE_RANGE: DateRange = {
    dateTimeDuration: undefined,
    durationSpecified: false,
    end: '',
    endTime: '',
    start: '',
    startTime: ''
};

export const recalculateDuration = (value: DateRange, defaultDurationType = undefined) => {
    const start: Date = parse(value.start, API_DATE_FORMAT, new Date());
    const startTime: Date = parse(value.startTime, API_TIME_FORMAT, new Date());
    const end: Date = parse(value.end, API_DATE_FORMAT, new Date());
    const endTime: Date = parse(value.endTime, API_TIME_FORMAT, new Date());
    if (isValid(start) && isValid(end) && value.dateTimeDuration?.unit) {
        const mergedStart = toDate(
            new Date(
                start.getFullYear(),
                start.getMonth(),
                start.getDate(),
                isValid(startTime) ? startTime.getHours() : start.getHours(),
                isValid(startTime) ? startTime.getMinutes() : start.getMinutes(),
                isValid(startTime) ? startTime.getSeconds() : start.getSeconds(),
                isValid(startTime) ? startTime.getMilliseconds() : start.getMilliseconds()
            )
        );
        const mergedEnd = toDate(
            new Date(
                end.getFullYear(),
                end.getMonth(),
                end.getDate(),
                isValid(endTime) ? endTime.getHours() : end.getHours(),
                isValid(endTime) ? endTime.getMinutes() : end.getMinutes(),
                isValid(endTime) ? endTime.getSeconds() : end.getSeconds(),
                isValid(endTime) ? endTime.getMilliseconds() : end.getMilliseconds()
            )
        );
        const dateTimeUnit = value.dateTimeDuration && value.dateTimeDuration.unit ? value.dateTimeDuration.unit : defaultDurationType;
        if (dateTimeUnit) {
            return {
                ...value,
                dateTimeDuration: {
                    value: calculateDifference(mergedStart, mergedEnd, dateTimeUnit),
                    unit: dateTimeUnit
                },
                durationSpecified: false
            };
        } else {
            return value;
        }
    }
    return value;
};

export default (props) => {
    const {
        allowedUnits,
        name,
        value = EMPTY_DATE_RANGE,
        valid,
        onChange,
        durationLabel = 'Duration',
        durationTypeLabel = 'Duration Type',
        defaultDurationType = undefined,
        hideDurationFields = false,
        hideTimeFields = false,
        disabled = false,
        unitsLoading = false
    } = props;

    const handleChange = (propName, propVal) => {
        if (propName === `${name}.dateTimeDuration`) {
            const v = { ...value };
            set(v, 'durationSpecified', propVal.value !== value.dateTimeDuration?.value);
            set(v, 'dateTimeDuration', propVal);
            onChange(name, v);
        } else {
            const v = { ...value };
            set(v, propName, propVal);
            if (propName === 'start') {
                if (value.dateTimeDuration.unit == 'hour') {
                    // Start date change, then set end date to start date.
                    set(v, 'end', propVal);
                } else {
                    // Start date Change, then set End Date = Start + Duration - 1
                    const end: Date = parse(propVal, API_DATE_FORMAT, new Date());
                    set(v, 'end', format(add(end, { [value.dateTimeDuration?.unit + 's']: value.dateTimeDuration?.value - 1 }), API_DATE_FORMAT));
                }
                const start: Date = parse(propVal, API_DATE_FORMAT, new Date());
                const end: Date = parse(value.end, API_DATE_FORMAT, new Date());
                if (start > end) {
                    if (value.dateTimeDuration?.unit == 'day') {
                        set(v, 'end', format(add(start, { [value.dateTimeDuration?.unit + 's']: value.dateTimeDuration?.value - 1 }), API_DATE_FORMAT));
                    } else {
                        set(v, 'end', format(add(start, { [value.dateTimeDuration?.unit + 's']: value.dateTimeDuration?.value }), API_DATE_FORMAT));
                    }
                }
            } else if (propName === 'end') {
                const start: Date = parse(value.start, API_DATE_FORMAT, new Date());
                let parseEndVal = value.dateTimeDuration?.unit == 'hour' ? propVal : propVal + value.endTime;
                let endformat = value.dateTimeDuration?.unit == 'hour' ? API_DATE_FORMAT + API_TIME_FORMAT : API_DATE_FORMAT;
                const end: Date = parse(parseEndVal, endformat, new Date());
                if (start > end) {
                    if (value.dateTimeDuration?.unit == 'day') {
                        set(v, 'start', format(sub(end, { [value.dateTimeDuration?.unit + 's']: value.dateTimeDuration?.value - 1 }), API_DATE_FORMAT));
                    } else {
                        set(v, 'start', format(sub(end, { [value.dateTimeDuration?.unit + 's']: value.dateTimeDuration?.value }), API_DATE_FORMAT));
                    }
                }
            } else if (propName === 'startTime') {
                // If Start time is changed, set end time = Start Time + Duration
                let newEndTime = parse(propVal, API_TIME_FORMAT, new Date());
                if (isValid(newEndTime)) {
                    newEndTime = add(newEndTime, { hours: value.dateTimeDuration.value });
                    set(v, 'endTime', format(newEndTime, API_TIME_FORMAT));
                }
            } else if (propName === 'endTime') {
                if (value.start === value.end) {
                    // If end before start, then set start to end - 1
                    if (parse(propVal, API_TIME_FORMAT, new Date()) < parse(value.startTime, API_TIME_FORMAT, new Date())) {
                        let newStartTime = sub(parse(propVal, API_TIME_FORMAT, parse(v.start + v.startTime, API_DATE_FORMAT + API_TIME_FORMAT, new Date())), { hours: 1 });
                        set(v, 'startTime', format(newStartTime, API_TIME_FORMAT));
                        set(v, 'start', format(newStartTime, API_DATE_FORMAT));
                    }
                }
            }
            onChange(name, v);
        }
    };

    useEffect(() => {
        if (value) {
            const v = recalculateDuration(value, defaultDurationType);
            if (v && v.dateTimeDuration && (v.dateTimeDuration.unit !== value.dateTimeDuration?.unit || v.dateTimeDuration.value !== value.dateTimeDuration?.value)) {
                onChange(name, v);
            }
        }
    }, [value, defaultDurationType, onChange, name]);

    return (
        <React.Fragment>
            <FormItem half>
                <FormWidget
                    component="Date"
                    name="start"
                    label="Start date"
                    value={value.start ?? ''}
                    errors={valid.errors[`${name}.start`]}
                    hasErrors={hasErrors(valid, `${name}.start`)}
                    onChange={handleChange}
                    disabled={disabled}
                />
                {!hideTimeFields && (
                    <FormWidget
                        component="Time"
                        name="startTime"
                        label="Start time"
                        value={value.startTime ?? ''}
                        errors={valid.errors[`${name}.startTime`]}
                        hasErrors={hasErrors(valid, `${name}.startTime`)}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                )}
            </FormItem>
            <FormItem half>
                <FormWidget
                    component="Date"
                    name="end"
                    label="End date"
                    value={value.end ?? ''}
                    errors={valid.errors[`${name}.end`]}
                    hasErrors={hasErrors(valid, `${name}.end`)}
                    onChange={handleChange}
                    disabled={disabled}
                />
                {!hideTimeFields && (
                    <FormWidget
                        component="Time"
                        name="endTime"
                        label="End time"
                        value={value.endTime ?? ''}
                        errors={valid.errors[`${name}.endTime`]}
                        hasErrors={hasErrors(valid, `${name}.endTime`)}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                )}
            </FormItem>
            {!hideDurationFields && (
                <MeasurementInput
                    name={`${name}.dateTimeDuration`}
                    label={durationLabel}
                    unitLabel={durationTypeLabel}
                    defaultUnit={defaultDurationType}
                    value={value.dateTimeDuration}
                    measure={Measure.Time}
                    allowedUnits={allowedUnits}
                    disabledUnits={['second', 'minute']}
                    setDefaultToFirstValue
                    disabled={disabled}
                    unitsLoading={unitsLoading}
                    valueDisabled
                    valid={valid}
                    onChange={handleChange}
                />
            )}
        </React.Fragment>
    );
};
