import _ from 'lodash';
import pkceChallenge from 'pkce-challenge';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { I18nContext, I18nContextType, useI18n } from '../../common/i18n/I18n';
import Loading from '../components/Loading';
import { enqueueSnackbar } from '../components/Toast';
import Validator, { ValidationState } from '../form/Validator';
import { handleAndCommitChange } from '../form/forms';
import Nav from '../nav/Nav';
import useDefaultRoute from '../nav/useDefaultRoute';
import useHistory from '../utils/useHistory';
import { authLinks } from './AccountLinks';
import Login from './Login';
import { UserContext } from './UserContext';
import { LoginRequest, Organization, PreLoginResponse, preLogin, refreshToken, registerFlow, setToken } from './api';
import {
    CODE_CHALLENGE,
    CODE_VERIFIER,
    CURRENT_IDP_IDENTIFIER,
    LINKED_USER_ERROR,
    USER_EXISTS_ERROR,
    buildSSORedirectURI,
    getTokensFromAuthCode
} from './ssoAuth';
import { useLocation } from 'react-router-dom';
import CustomAuth from './CustomAuth';
import UsernameLoginForm from './UsernameLoginForm';
import ChooseAccountForm from './ChooseAccountForm';
import { V } from '../Layout';

const NEEDS_PASSWORD = 'NEEDS_PASSWORD';
const NEEDS_USERNAME = 'NEEDS_USERNAME';
const CHOOSE_ACCOUNT = 'CHOOSE_ACCOUNT';
const ACTIVE_ORG_ID = 'orgId';
const CUSTOM_AUTH = 'CUSTOM_AUTH';

const validationRules = {
    username: [Validator.RULES.isRequired, Validator.RULES.email]
};

export default () => {
    const { changeRoute, searchParams, logOut, goBack, changeSearch } = useHistory();
    const { isAuthenticated, setIsAuthenticated, userProfile, setActiveOrganizationAccount } = useContext(UserContext);
    const [selectedOrgId, setSelectedOrgId] = useState<string>();
    const [loginState, setLoginState] = useState(NEEDS_USERNAME);
    const [busy, setBusy] = useState(false);
    const [orgAccounts, setOrgAccounts] = useState<Organization[]>([]);
    const i18nContext: I18nContextType = useContext(I18nContext);
    const { pathname, orgId } = useDefaultRoute(selectedOrgId);
    const [newToken, setNewToken] = useState('');
    const location = useLocation();
    const email = location.state?.email || '';
    const username = location.state?.username || '';

    // Default route will be available once user is logged in and user profile is loaded.
    useEffect(() => {
        if (userProfile) {
            const ref = localStorage.getItem('referrer.' + userProfile.username) || localStorage.getItem('referrer');
            if (ref) {
                const newRoute = JSON.parse(ref);
                if (newRoute) {
                    localStorage.removeItem('referrer.' + userProfile.username);
                    localStorage.removeItem('referrer');
                    console.log('PreLogin: User is authenticated, redirecting to referrer route:', ref);
                    changeRoute(newRoute.pathname, new URLSearchParams(newRoute.search));
                }
            } else if (pathname) {
                console.log('PreLogin: User is authenticated, redirecting to default route:', pathname);
                changeRoute(pathname, { orgId: orgId });
            } else {
                throw new Error('Default route not found, please check permissions.');
            }
        }
    }, [changeRoute, orgId, pathname, userProfile]);

    const validator = new Validator(validationRules, {
        // If true validator will keep going even after first field fails validation.
        runAll: false,
        i18nContext: i18nContext
    });

    const [values, setValues] = useState<LoginRequest>({
        username: email || username || '',
        password: ''
    });

    const [valid, setValid] = useState<ValidationState>({
        isValid: email || username || false,
        errors: {}
    });

    const [isBusy, setIsBusy] = useState(false);

    const onChange = (name, value) => {
        handleAndCommitChange(values, name, value, validator, setValues, setValid);
    };

    const redirectToSSO = async (idpIdentifier: string, orgId: string) => {
        const { code_challenge, code_verifier } = await pkceChallenge();
        localStorage.setItem(CODE_CHALLENGE, code_challenge);
        localStorage.setItem(CODE_VERIFIER, code_verifier);
        localStorage.setItem(CURRENT_IDP_IDENTIFIER, idpIdentifier);
        localStorage.setItem(ACTIVE_ORG_ID, orgId);
        const uri = buildSSORedirectURI(idpIdentifier, code_challenge, values.username);
        window.location.replace(uri);
    };

    const handleSelectAccount = (orgAcc: Organization) => {
        const idpIdentifier = orgAcc?.organizationDomain?.idpIdentifier;
        if (idpIdentifier) {
            redirectToSSO(idpIdentifier, orgAcc.id);
        } else {
            setLoginState(NEEDS_PASSWORD);
            setSelectedOrgId(orgAcc.id);
        }
    };

    const onContinue = () => {
        setIsBusy(true);
        preLogin(values.username)
            .then((res: PreLoginResponse) => {
                if (res.userFound) {
                    if (res.action == 0) {
                        const ref = localStorage.getItem('referrer.' + values.username);
                        const preferredAccountKey = 'preferredAccount.' + values.username;
                        const preferredAccount = localStorage.getItem(preferredAccountKey);
                        if (preferredAccount) setSelectedOrgId(preferredAccount);
                        if (res.organizations && res.organizations.length > 1 && !ref && !preferredAccount) {
                            setOrgAccounts(res.organizations);
                            setLoginState(CHOOSE_ACCOUNT);
                            setIsBusy(false);
                        } else {
                            if (res.organizations?.length) {
                                const org = res.organizations[0];
                                if (org.organizationDomain && org.organizationDomain.idpIdentifier) {
                                    redirectToSSO(org.organizationDomain.idpIdentifier, org.id);
                                } else {
                                    setSelectedOrgId(org.id);
                                    setLoginState(NEEDS_PASSWORD);
                                    setIsBusy(false);
                                }
                            } else {
                                setLoginState(NEEDS_PASSWORD);
                                setIsBusy(false);
                            }
                        }
                    }
                    if (res.action == 1) {
                        changeRoute(authLinks.verify.path, {}, true, { username: values.username });
                    }
                    if (email && res.action == 2) {
                        registerFlow(values.username);
                        setLoginState(NEEDS_PASSWORD);
                        setIsBusy(false);
                        enqueueSnackbar('Email sent', { variant: 'success' });
                        return;
                    }
                    if (res.action == 2) {
                        changeRoute(authLinks.login.path, {}, true, { email: values.username });
                        setLoginState(CUSTOM_AUTH);
                        setIsBusy(false);
                        if (newToken) {
                            registerFlow(values.username);
                            setLoginState(NEEDS_PASSWORD);
                        }
                    }
                } else if (values.username) {
                    changeRoute(authLinks.signup.path, {}, true, { username: values.username });
                    setValid({ errors: validator.errors, isValid: _.isEmpty(validator.errors) });
                    setIsBusy(false);
                }
            })
            .catch((err) => {
                setIsBusy(false);
                enqueueSnackbar(`Unable to proceed with Login: ${err.message}`, { variant: 'error' });
            });
    };

    const cancel = () => {
        setLoginState(NEEDS_USERNAME);
        setOrgAccounts([]);
        goBack();
    };

    const hasErrors = (fieldName) => {
        return Array.isArray(valid.errors[fieldName]) && valid.errors[fieldName].length > 0;
    };

    let formErrors = [];
    if (valid.errors.length) {
        Object.keys(valid.errors).map((key) => {
            if (Array.isArray(valid.errors[key])) {
                formErrors = formErrors.concat(valid.errors[key]);
            }
        });
        console.warn('Form errors:', formErrors);
    }

    const keyboardEventHandler = (event) => {
        if (event.key === 'Enter' && valid.isValid) {
            onContinue();
        }
    };

    useEffect(() => {
        // for first time linked user we need to redirect again
        if (searchParams && searchParams.get('error_description')) {
            setBusy(true);
            const description = searchParams.get('error_description');
            if (description.includes(LINKED_USER_ERROR) || description.includes(USER_EXISTS_ERROR)) {
                const idpIdentifier = localStorage.getItem(CURRENT_IDP_IDENTIFIER);
                const codeChallenge = localStorage.getItem(CODE_CHALLENGE);
                if (idpIdentifier && codeChallenge) {
                    const uri = buildSSORedirectURI(idpIdentifier, codeChallenge, '');
                    window.location.replace(uri);
                }
            } else {
                setBusy(false);
            }
        }
    }, []);

    useEffect(() => {
        if (searchParams && searchParams.get('code')) {
            const code = searchParams.get('code');
            const verifier = localStorage.getItem(CODE_VERIFIER);
            const orgId = localStorage.getItem(ACTIVE_ORG_ID);
            setBusy(true);
            getTokensFromAuthCode(code, verifier)
                .then((data) => {
                    refreshToken(data['refresh_token'])
                        .then((data) => {
                            changeSearch({ code: undefined });
                            handleSuccessfulLogin(data['idToken'], orgId);
                        })
                        .catch((err) => {
                            setBusy(false);
                            logOut();
                        });
                })
                .catch((err) => {
                    setBusy(false);
                    logOut();
                })
                .finally(() => {
                    localStorage.removeItem(CODE_CHALLENGE);
                    localStorage.removeItem(CODE_VERIFIER);
                    localStorage.removeItem(CURRENT_IDP_IDENTIFIER);
                    localStorage.removeItem(ACTIVE_ORG_ID);
                });
        }
    }, []);

    const handleSuccessfulLogin = (idToken: string, orgId: string) => {
        setToken(idToken);
        setActiveOrganizationAccount(orgId).then(() => {
            changeSearch({ orgId: orgId });
            setIsAuthenticated(true);
        });
    };

    const title = useI18n('dialog.login');
    const chooseAccountTitle = useI18n('dialog.choose.account');
    return isAuthenticated && userProfile ? (
        <React.Fragment />
    ) : (
        <>
            <Nav />
            {busy ? (
                <Loading />
            ) : (
                <React.Fragment>
                    {loginState === NEEDS_PASSWORD && (
                        <Login
                            username={values.username}
                            onLogin={(token: string) => {
                                handleSuccessfulLogin(token, selectedOrgId);
                            }}
                            cancel={cancel}
                        />
                    )}
                    {loginState === CUSTOM_AUTH &&
                        (!newToken ? (
                            <CustomAuth
                                username={values.username}
                                setBearer={(token) => {
                                    if (token) {
                                        setToken(token);
                                        setNewToken(token);
                                    }
                                }}
                            />
                        ) : (
                            <UsernameLoginForm
                                keyboardEventHandler={keyboardEventHandler}
                                title={title}
                                values={values}
                                onChange={onChange}
                                hasErrors={hasErrors}
                                isBusy={isBusy}
                                onContinue={onContinue}
                                valid={valid}
                            />
                        ))}

                    {loginState === NEEDS_USERNAME && (
                        <UsernameLoginForm
                            keyboardEventHandler={keyboardEventHandler}
                            title={title}
                            values={values}
                            onChange={onChange}
                            hasErrors={hasErrors}
                            isBusy={isBusy}
                            onContinue={onContinue}
                            valid={valid}
                        />
                    )}
                    {loginState === CHOOSE_ACCOUNT && (
                        <ChooseAccountForm
                            chooseAccountTitle={chooseAccountTitle}
                            values={values}
                            valid={valid}
                            hasErrors={hasErrors}
                            onChange={onChange}
                            orgAccounts={orgAccounts}
                            handleSelectAccount={handleSelectAccount}
                            cancel={cancel}
                        />
                    )}
                </React.Fragment>
            )}
        </>
    );
};
