import React, { useEffect } from 'react';
import useHistory from '../utils/useHistory';

export default () => {
    const { logOut } = useHistory();
    useEffect(() => {
        logOut(false);
    }, [logOut]);
    return <></>;
};
