import React, { useContext, useEffect, useState } from 'react';
import { doSave } from './helpers/BulkSaveProductions';
import { bulkSaveQuery, client, rowsToProduction } from './helpers/production';
import { useQuery } from '@apollo/client';
import { GridColDef } from '@mui/x-data-grid-premium';
import { valueGetter } from '../supply/opportunity/details/ContactListUtils';
import { useMutationWithContext } from '../common/hooks/useMutationWithContext';
import { UserContext } from '../common/auth/UserContext';
import { constants } from '../common/constants';
import SRDataImport from '../common/SRDataImport/SRDataImport';
import SRDataImportDetails from '../common/SRDataImport/SRDataImportDetails';
import { searchClient, slimSearchQuery } from '../common/list/slimQuery';

const id: GridColDef = {
    field: 'ID',
    headerName: 'ID',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter
};
const associatedTitle: GridColDef = {
    field: 'productionGroup',
    headerName: 'Associated Title',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
const summary: GridColDef = {
    field: 'summary',
    headerName: 'Summary',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
const noOfEpisodes: GridColDef = {
    field: 'noOfEpisodes',
    headerName: 'No. of Episodes',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
const seasonNo: GridColDef = {
    field: 'seasonNo',
    headerName: 'Season Number',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
const episodeNo: GridColDef = {
    field: 'episodeNo',
    headerName: 'Episode Number',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
const name: GridColDef = {
    field: 'publicTitle',
    headerName: 'Title',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
const AKA: GridColDef = {
    field: 'otherTitles',
    headerName: 'AKA',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
const productionType: GridColDef = {
    field: 'productionType',
    headerName: 'Type',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};

const productionStatus: GridColDef = {
    field: 'productionStatus',
    headerName: 'Status',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};

const releaseDate: GridColDef = {
    field: 'releaseDate',
    headerName: 'Release Date',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};

const releaseYear: GridColDef = {
    field: 'releaseYear',
    headerName: 'Release Year',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};

const productionYears: GridColDef = {
    field: 'productionYears',
    headerName: 'Production Years',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};

const genre: GridColDef = {
    field: 'genre',
    headerName: 'Genre',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
const runtime: GridColDef = {
    field: 'runtime',
    headerName: 'Runtime',
    flex: 1,
    sortable: true,
    type: 'string',
    valueGetter,
    editable: true
};
export const productionImportColDefs: GridColDef[] = [
    id,
    name,
    AKA,
    productionType,
    productionStatus,
    associatedTitle,
    releaseDate,
    releaseYear,
    productionYears,
    genre,
    runtime,
    summary,
    noOfEpisodes,
    seasonNo,
    episodeNo
];

const placeholder = `To enter data:
Simply type in this area, use Commas to separate production attributes and each production on a new line
Copy paste from Excel or other documents
Or drop an Excel or CSV file here
Rearrange the headers above to match the order of the data you entered`;

export default ({ onSave, importPanelOpen, onClose }) => {
    const [bulkSave] = useMutationWithContext(bulkSaveQuery, client);
    const [ImportDetails, setImportDetails] = useState(undefined);
    const [open, setOpen] = useState(true);
    const { activeOrganizationAccount } = useContext(UserContext);
    const [colDefs, setColDefs] = useState(productionImportColDefs);
    const { loading, data } = useQuery(slimSearchQuery('AlternateIdLabel'), {
        client: searchClient,
        variables: {
            entity: 'AlternateIdLabel',
            page: { from: 0, size: 50 },
            tokenize: true,
            sortBy: undefined
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy
    });

    const onConfirm = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (data) {
            const altIDColdDefs = data?.search.hits.items?.map((i) => {
                const col: GridColDef = {
                    field: 'AltId' + i.name,
                    headerName: i.name,
                    flex: 1,
                    sortable: true,
                    type: 'string',
                    valueGetter: valueGetter
                };
                return col;
            });
            setColDefs([...productionImportColDefs, ...altIDColdDefs]);
        }
    }, [data]);

    return (
        <>
            {importPanelOpen && (
                <SRDataImport
                    feature={'Production'}
                    primaryColDefs={colDefs}
                    altColDefs={colDefs}
                    rowsToEntities={rowsToProduction}
                    onSave={onSave}
                    isUpload={importPanelOpen}
                    onClose={onClose}
                    doSave={doSave}
                    bulkSave={bulkSave}
                    title={'Import'}
                    saveLabel={'Save'}
                    editLabel={'Previous'}
                    setImportDetails={setImportDetails}
                    setShowImportDetailsDialog={setOpen}
                    placeholder={placeholder}
                    disableTextBasedSelector={false}
                    category={'Productions'}
                />
            )}

            {ImportDetails && <SRDataImportDetails open={open} onConfirm={onConfirm} details={ImportDetails} />}
        </>
    );
};
