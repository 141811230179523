import { SvgIcon } from '@mui/material';
import { Icons } from './Icons';

type IconMap = {
    [key in string]: typeof SvgIcon;
};

export const TYPE_TO_ICON: IconMap = {
    Animal: Icons.Animal,
    Cast: Icons.Cast,
    Crew: Icons.Crew,
    Default: Icons.BuildCircleOutlined,
    Equipment: Icons.Equipement,
    Facility: Icons.Facility,
    Place: Icons.OutlinedPlaceIcon,
    Product: Icons.Product,
    Prop: Icons.Prop,
    Service: Icons.Services,
    Vehicle: Icons.Vehicle,
    'Creative Work': Icons.CreativeWork,
    'Digital Assets': Icons.DigitalAssets,
    Wardrobe: Icons.WardrobeIcon
};
