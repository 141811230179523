import { getToken } from '../auth/api';

export const DOWNLOAD_URL = '/storage/v1/secure/download';

export default (documentId: string, fileName: string, activeOrganizationAccount: string, target?: string) => {
    const token = getToken();
    const url = `${DOWNLOAD_URL}/${documentId}`;
    const headers = new Headers();
    headers.append('Authorization', token ? 'Bearer ' + token.token : '');
    headers.append('ownerId', activeOrganizationAccount);

    fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            if (target) {
                link.target = target;
            } else {
                link.setAttribute('download', fileName);
            }
            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
};
