import { Autocomplete, createFilterOptions, ListItemText, TextField } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { getLocation, locationLabels, locationToString } from './location';
import { forEach, get, isArray, isEmpty, isEqual } from 'lodash';
import { constants } from '../constants';
import LocationQuickAdd from './LocationQuickAdd';
import { FormContext } from '../form/FormContext';

const valueToOption = (multiple, value) => {
    if (multiple) {
        const opts = [];
        if (isArray(value)) {
            forEach(value, (loc) => {
                const label = loc && loc.label && loc.label.length ? loc.label : locationToString(loc);
                if (label) {
                    opts.push({ label: label, loc: loc });
                }
            });
        }
        return opts;
    } else {
        const label = value && value.label && value.label.length ? value.label : locationToString(value);
        if (label) {
            return { label: label, loc: value };
        }
        return '';
    }
};

export default (props) => {
    const {
        id,
        name,
        inputLabel,
        value,
        onChange,
        required,
        disabled,
        multiple,
        limitTags = 2,
        appendTimeZone = false,
        defaultOptions = [],
        allowAdditionalLocations = true,
        hasErrors = false,
        errorText = '',
        size = constants.defaultWidgetSize,
        variant = constants.defaultWidgetVariant,
        ...rest
    } = props;
    const [searchText, setSearchText] = useState('');
    const { state, handleChange } = useContext(FormContext);
    const [options, setOptions] = useState([]);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: window._env_.GOOGLE_MAPS_LOCATION_API_KEY,
        debounce: 500
    });

    useEffect(() => {
        if (value && value.label && value.label !== searchText) {
            setSearchText(value.label);
        }
    }, [value]);

    useEffect(() => {
        if (searchText !== '' && triggerSearch && allowAdditionalLocations) {
            setOptions([]);
            const types = rest.restrictToCountry ? ['country'] : [];
            getPlacePredictions({ input: searchText, types: types });
        }
    }, [searchText]);

    useEffect(() => {
        const labels = locationLabels(multiple ? value : [value]);
        if (placePredictions.length) {
            const opts = placePredictions
                .filter((place) => {
                    return !labels.includes(place.description);
                })
                .map((place) => {
                    return {
                        key: place.place_id,
                        label: place.description
                    };
                });
            setOptions([{ label: `Add "${searchText}"`, loc: { label: searchText, primary: false } }, ...opts]);
        } else {
            if (!isEmpty(searchText)) {
                setOptions([{ label: `Add "${searchText}"`, loc: { label: searchText, primary: false } }]);
            }
        }
    }, [placePredictions]);

    const onSelect = (event, option) => {
        if (multiple) {
            const locs = option
                .filter((opt) => {
                    return opt.loc;
                })
                .map((opt) => {
                    return opt.loc;
                });
            option.forEach((o) => {
                if (!o.loc) {
                    placesService?.getDetails(
                        {
                            placeId: o.key
                        },
                        (placeDetails) => {
                            getLocation(placeDetails, appendTimeZone).then((data) => {
                                let location = data;
                                location.label = o.label;
                                location.primary = locs.length === 0;
                                onChange(name, [...locs, location]);
                            });
                        }
                    );
                }
            });
            onChange(name, locs);
        } else {
            if (option && option.loc) {
                setShowAddForm(true);
                onChange(name, option.loc);
            } else {
                if (option) {
                    placesService?.getDetails(
                        {
                            placeId: option.key
                        },
                        (placeDetails) => {
                            getLocation(placeDetails, appendTimeZone).then((data) => {
                                let location = data;
                                location.label = option.label;
                                location.primary = true;
                                onChange(name, location);
                            });
                        }
                    );
                } else {
                    onChange(name, undefined);
                }
            }
        }
    };

    const filter = createFilterOptions();
    const filterOptions = (options, state) => {
        const results = filter(options, state);
        defaultOptions.map((option) => {
            const isExisting = results.some((existingOption) => {
                return isEqual(existingOption, option);
            });
            if (!isExisting) {
                results.unshift({
                    loc: option,
                    label: option.label
                });
            }
        });

        return results;
    };

    const handleAdd = () => {
        const location = get(state, name);
        const label = locationToString(location, true);
        handleChange(`${name}.label`, label);
        setSearchText(label);
        setShowAddForm(false);
    };

    const handleCancel = () => {
        handleChange(`${name}.label`, '');
        setSearchText('');
        setShowAddForm(false);
    };

    const val = useMemo(() => {
        return valueToOption(multiple, value);
    }, [multiple, value]);

    return (
        <React.Fragment>
            <Autocomplete
                id={id}
                disabled={disabled}
                multiple={multiple}
                fullWidth
                freeSolo
                size={size}
                loading={isPlacePredictionsLoading}
                options={options}
                filterOptions={filterOptions}
                getOptionLabel={(option) => {
                    return option ? option.label : '';
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={props.id}>
                        <ListItemText primary={option.label} />
                    </li>
                )}
                value={val}
                isOptionEqualToValue={(option, value) => {
                    return option && value && option.label === value.label;
                }}
                renderInput={(inputParams) => (
                    <TextField
                        size={size}
                        required={required}
                        disabled={disabled}
                        variant={variant}
                        label={inputLabel}
                        error={hasErrors}
                        helperText={errorText}
                        InputProps={{
                            ...inputParams.InputProps,
                            size: size
                        }}
                        {...inputParams}
                    />
                )}
                limitTags={limitTags}
                onChange={onSelect}
                inputValue={searchText}
                onInputChange={(event, value) => {
                    if (event) {
                        setSearchText(value);
                        setTriggerSearch(true);
                    }
                }}
                noOptionsText={'Search for locations'}
            />
            <LocationQuickAdd name={name} isOpen={showAddForm} onCancel={handleCancel} onAdd={handleAdd} />
        </React.Fragment>
    );
};
