import { Button, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { Filter, useSearchkit } from '@searchkit/client';
import { find, filter } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { H, V } from '../../../common/Layout';
import { useStyling } from '../../../common/Theme';
import { usePersistedSearchkitVariables } from '../../../common/components/filtering/filters';
import I18n, { useI18n } from '../../../common/i18n/I18n';
import { Icons } from '../../../common/icons/Icons';
import PromotedFacet from './PromotedFacet';
import SearchPanel from './SearchPanel';
import { StyledBadge } from './StyledBadge';
import QuickFilterFacets from './QuickFilterFacets';
import { UserContext } from '../../../common/auth/UserContext';

type FooterProps = {
    loading?: boolean;
    count?: number;
    immutableFilters?: Filter[];
    requiredFilters?: string[];
    isReporting?: boolean;
};

// We want to exclude these filter keys from counting if user has filters or not.
const EXCLUDE_STATIC = ['entity', 'primary', 'public'];

const PromotedFacets = ({ facets, promote }) => {
    if (promote && promote.length) {
        const pf = promote.map((p) => find(facets, (f) => f.identifier === p)).filter((f) => f);
        return pf.map((f, index) => <PromotedFacet key={index} facet={f} />);
    } else {
        return <></>;
    }
};

const Header = ({ onClose }) => {
    const { theme } = useStyling();
    const tooltip = useI18n('dialog.close');
    return (
        <V sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <H sx={{ p: 1, pl: 2, justifyContent: 'space-between', height: 'fit-content' }}>
                <Typography variant={'h6'}>Filters</Typography>
                <IconButton size={'small'} onClick={onClose}>
                    <Tooltip title={tooltip}>
                        <Icons.Close />
                    </Tooltip>
                </IconButton>
            </H>
        </V>
    );
};

const Footer = ({ loading = false, count, immutableFilters = [], requiredFilters = [], isReporting }: FooterProps) => {
    const label = useI18n(count === 0 ? 'error.emptyResult' : 'list.results', { count: count });
    const { theme } = useStyling();
    const tooltip = useI18n('facets.reset.button');
    const api = useSearchkit();
    return (
        <V sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
            <H sx={{ p: 1, justifyContent: 'space-between' }}>
                {loading || isReporting ? <p></p> : <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>}

                <IconButton
                    size={'small'}
                    disabled={!api.canResetSearch()}
                    onClick={() => {
                        const entityFilter = find(api.getFilters(), (f) => f.identifier === 'entity');
                        const publicFilter = find(api.getFilters(), (f) => f.identifier === 'public');
                        const filterRequired = filter(api.getFilters(), (f) => requiredFilters.includes(f.identifier));

                        const filters = [];
                        if (Array.isArray(immutableFilters) && immutableFilters.length > 0) {
                            immutableFilters.forEach((filter) => {
                                filters.push(filter);
                            });
                        }
                        //  SHOW-3265: adding the filter which are marked as required and shouldn't be cleared.
                        if (Array.isArray(filterRequired) && filterRequired.length > 0) {
                            filterRequired.forEach((filter) => {
                                filters.push(filter);
                            });
                        }

                        if (entityFilter) {
                            filters.push(entityFilter);
                        }
                        if (publicFilter) {
                            filters.push(publicFilter);
                        }

                        api.setSearchState({
                            ...api.searchState,
                            query: '',
                            filters: filters,
                            page: { from: 0, size: api.searchState.page.size }
                        });
                        api.search();
                    }}
                >
                    <Tooltip title={tooltip}>
                        <Icons.ClearAll />
                    </Tooltip>
                </IconButton>
            </H>
        </V>
    );
};

export default ({
    id,
    Facets,
    data,
    requiredFilters = [],
    loading,
    searching = false,
    onSearch = undefined,
    promote = undefined,
    hideBadgeCount = false,
    hideAllFilterLabel = false,
    showCount = true,
    isLocal = false,
    isReporting = false,
    labelToken = 'filter.allFilter.label',
    immutableFilters = [],
    facetsVisibility = { filters: true, searchPanel: true, promotedFacets: true, quickFilter: true },
    quickFilters = [],
    searchPlaceholder = undefined,
    resetOnLogout = false
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const { variables, setPageNumber } = usePersistedSearchkitVariables(id);
    const { theme, isMobile } = useStyling();
    const count = data && data?.results ? data?.results.hits.page.total : 0;
    const { addOnLogoutListener } = useContext(UserContext);

    const excludeDynamic = useMemo(() => {
        return immutableFilters.map((filter) => {
            return filter.identifier;
        });
    }, [immutableFilters]);
    const filters = variables.filters.filter((f) => {
        return (
            !EXCLUDE_STATIC.includes(f.identifier) &&
            (!promote || !promote.includes(f.identifier)) &&
            (!excludeDynamic || !excludeDynamic.includes(f.identifier))
        );
    });

    const showOnlySearchPanel = useMemo(() => {
        return facetsVisibility.searchPanel && !facetsVisibility.filters && !facetsVisibility.promotedFacets;
    }, [facetsVisibility]);
    const onClose = () => {
        setAnchorEl(null);
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    //clear searchkit variables on logout
    useEffect(() => {
        const clearFiltersFromCache = () => {
            resetOnLogout && window.localStorage.removeItem(`filters_${id}`);
        };

        addOnLogoutListener(clearFiltersFromCache);
    }, [addOnLogoutListener, resetOnLogout, id]);

    return (
        <H className="_facetsPanel" sx={{ gap: 2, width: showOnlySearchPanel ? '100%' : 'unset' }}>
            {facetsVisibility.filters && (
                <StyledBadge badgeContent={hideBadgeCount ? undefined : filters.length}>
                    {!hideAllFilterLabel && !isMobile ? (
                        <Button
                            sx={{
                                whiteSpace: 'nowrap',
                                color: filters.length ? theme.palette.success.main : theme.palette.primary.main
                            }}
                            onClick={onClick}
                            variant={'text'}
                            startIcon={<Icons.Tune />}
                        >
                            <I18n token={labelToken} />
                        </Button>
                    ) : (
                        <IconButton
                            sx={{
                                color: filters.length ? theme.palette.success.main : theme.palette.primary.main,
                                px: 0.5,
                                py: 0
                            }}
                            onClick={onClick}
                        >
                            <Tooltip
                                title={<I18n token={labelToken} />}
                                slotProps={{
                                    popper: {
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -6]
                                                }
                                            }
                                        ]
                                    }
                                }}
                            >
                                <Icons.Tune />
                            </Tooltip>
                        </IconButton>
                    )}
                </StyledBadge>
            )}
            {facetsVisibility.searchPanel && (
                <SearchPanel
                    id={`${id}-search`}
                    loading={loading}
                    searching={searching}
                    query={variables.query}
                    setQuery={onSearch}
                    sx={showOnlySearchPanel ? { width: '100%', p: 1 } : {}}
                    searchPlaceholder={searchPlaceholder}
                />
            )}
            {facetsVisibility.promotedFacets && <PromotedFacets facets={data?.results?.facets} promote={promote} />}
            {facetsVisibility.quickFilter && (
                <QuickFilterFacets facets={data?.results?.facets} quickFilters={quickFilters} />
            )}
            {anchorEl && facetsVisibility.filters && (
                <Popover
                    id={`filters-popover-${id}`}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                borderRadius: 1,
                                boxSizing: 'border-box',
                                maxWidth: '300px',
                                minWidth: '300px',
                                overflowX: 'hidden',
                                bottom: '10%',
                                display: 'flex',
                                flexDirection: 'column'
                            }
                        }
                    }}
                >
                    <Header onClose={onClose} />
                    <V sx={{ overflowX: 'hidden', px: 1, flexGrow: 2 }}>
                        <Facets
                            facets={data?.results?.facets}
                            loading={loading}
                            promote={[...(promote || []), ...(excludeDynamic || [])]}
                            showCount={showCount}
                            isLocal={isLocal}
                            requiredFilters={requiredFilters}
                        />
                    </V>
                    <Footer
                        loading={loading}
                        requiredFilters={requiredFilters}
                        count={count}
                        isReporting={isReporting}
                        immutableFilters={immutableFilters}
                    />
                </Popover>
            )}
        </H>
    );
};
