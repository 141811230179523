import React from 'react';
import { createSvgIcon } from '@mui/material';

export const Success = createSvgIcon(
    <svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2439_20928)">
            <path
                d="M59.7801 0.642334C92.7728 0.642334 119.56 27.4298 119.56 60.4225C119.56 93.4151 92.7728 120.203 59.7801 120.203C26.7875 120.203 0 93.4151 0 60.4225C0 27.4298 26.7875 0.642334 59.7801 0.642334Z"
                fill="currentColor"
            />
            <path
                d="M27.5781 66.2229L50.5252 85.9523L91.4843 38.5682"
                stroke="white"
                strokeWidth="9.07696"
                strokeLinecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_2439_20928">
                <rect width="120" height="120" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>,
    'Success'
);
