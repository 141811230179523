import React, { ReactElement, useContext } from 'react';
import { Box, ButtonProps, Modal, Typography } from '@mui/material';
import Loading from '../components/Loading';
import { LoadingButton } from '@mui/lab';
import ModalHeader from './ModalHeader';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { ActionType } from '../components/ActionType';
import { FormContext } from './FormContext';
import { useStyling } from '../Theme';
import { C, H } from '../Layout';
import I18n from '../i18n/I18n';

type FormModalProps = {
    id: string;
    title: string | ReactElement;
    isOpen: boolean;
    width?: string;
    isLoading?: boolean;
    isSaving?: boolean;
    isSaveDisabled?: boolean;
    saveLabel?: string | ReactElement;
    onSave?: () => void;
    onClose?: () => void;
    expanded?: boolean;
    hideButtons?: boolean;
    hideDefaultButtons?: boolean;
    children;
    additonalLeftsideActions?: ActionType[];
    additionalActions?: ActionType[];
    sx?: SxProps<Theme>;
    loadingButtonVariant?: ButtonProps['variant'];
    titleSx?: SxProps<Theme>;
    modalBodySx?: SxProps<Theme>;
    isReqFields?: boolean;
};
export default (props: FormModalProps) => {
    const {
        id,
        width = '600px',
        title,
        isOpen = false,
        isLoading = false,
        isSaving = false,
        isSaveDisabled = false,
        saveLabel = 'Save',
        onSave,
        onClose,
        expanded = true,
        hideButtons = false,
        hideDefaultButtons = false,
        children,
        additonalLeftsideActions = [] as ActionType[],
        additionalActions = [] as ActionType[],
        sx = undefined,
        loadingButtonVariant,
        modalBodySx = undefined,
        titleSx = undefined,
        isReqFields = false
    } = props;

    const { validation } = useContext(FormContext);
    const { theme } = useStyling();

    const reqStyles = {
        pt: 2,
        pb: 2,
        pr: 2,
        width: '100%',
        justifyContent: 'space-between'
    };

    const getActions = (actions: ActionType[]) => {
        return actions.map((action, index) => {
            return (
                <LoadingButton
                    variant={action.variant}
                    disabled={action.disabled}
                    key={`form-action-${index}`}
                    onClick={(e) => action.onClick?.()}
                    loading={action?.loading || false}
                >
                    {action.icon ? <action.icon /> : <React.Fragment />} {action.label}
                </LoadingButton>
            );
        });
    };

    const leftActions = getActions(additonalLeftsideActions);
    const actions = (additionalActions.length && getActions(additionalActions)) || [];
    return (
        isOpen && (
            <Modal open={isOpen} onClose={onClose} id={id}>
                <Box
                    className="form-modal-container"
                    sx={{
                        position: 'absolute',
                        flexDirection: 'column',
                        width: { xs: '100%', sm: width },
                        height: expanded ? { xs: '100%', sm: '640px' } : 'initial',
                        maxHeight: { xs: '100%', sm: '90%' },
                        bgcolor: 'background.paper',
                        boxShadow: { xs: 0, sm: 24 },
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        boxSizing: 'border-box',
                        borderRadius: { xs: 0, sm: 1 },
                        zIndex: 10,
                        ...sx
                    }}
                >
                    <ModalHeader title={title} onClose={onClose} sx={titleSx} />
                    <Box
                        className="form-modal-body"
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 2,
                            ...modalBodySx
                        }}
                    >
                        {isLoading ? <Loading /> : typeof children === 'function' ? children() : children}
                    </Box>
                    {hideButtons ? (
                        <React.Fragment />
                    ) : (
                        <C
                            sx={{
                                display: 'flex',
                                ...(leftActions
                                    ? { justifyContent: 'space-between' }
                                    : { alignSelf: 'flex-end', alignItems: 'flex-end' })
                            }}
                        >
                            <C sx={{ p: 2 }} className="form-modal-buttons">
                                {...leftActions}
                            </C>
                            <H
                                className="form-modal-buttons"
                                sx={isReqFields ? reqStyles : { p: 2, flexDirection: 'row', justifyContent: 'end' }}
                            >
                                {isReqFields && (
                                    <Typography sx={{ display: 'flex', color: theme.palette.required.main }}>
                                        <Typography>*</Typography>
                                        <I18n token="modal.required.fields.missing" />
                                    </Typography>
                                )}

                                {...actions}
                                {!hideDefaultButtons && (
                                    <LoadingButton
                                        variant={loadingButtonVariant || 'text'}
                                        loading={isSaving}
                                        disabled={isSaveDisabled || (validation ? !validation.isValid : false)}
                                        onClick={onSave}
                                    >
                                        {saveLabel}
                                    </LoadingButton>
                                )}
                            </H>
                        </C>
                    )}
                </Box>
                {/*</Draggable>*/}
            </Modal>
        )
    );
};
