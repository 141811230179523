import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useI18n } from '../i18n/I18n';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';
import { LoadingButton } from '@mui/lab';

export default ({
    open,
    title,
    body,
    okLabelI18n = 'dialog.ok',
    cancelLabelI18n = 'dialog.cancel',
    onConfirm,
    onCancel,
    maxWidth = 'xs' as Breakpoint,
    isLoading = false
}) => {
    const okLabel = useI18n(okLabelI18n);
    const cancelLabel = useI18n(cancelLabelI18n);
    return (
        <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth={maxWidth} open={open}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent dividers>{body}</DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onCancel}>
                    {cancelLabel}
                </Button>
                <LoadingButton onClick={onConfirm} loading={isLoading}>
                    {okLabel}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
