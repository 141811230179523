import type { Spread } from 'lexical';
import { DOMConversionMap, DOMConversionOutput, DOMExportOutput, EditorConfig, LexicalNode, SerializedTextNode, TextNode } from 'lexical';
import { css } from '@emotion/react';
import React from 'react';

export type SerializedMentionsNode = Spread<
    {
        trigger: string;
        mentionId: string;
        mentionLabel: string;
        mentionDesc: string;
        mentionEntity: string;
        type: 'mention';
        version: 1;
    },
    SerializedTextNode
>;

const mentionStyle = (theme = undefined) => css`
    background-color: rgba(24, 119, 232, 0.2);
    padding: 0 4px;
    border: 1px solid grey;
    border-radius: 4px;
    &::before {
        content: '@';
        margin-right: 4px;
    }
    // popup
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const popupCss = (theme = undefined) => css`
    /* The actual popup */
    visibility: hidden;
    width: fit-content;
    max-width: 320px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    //left: 50%;
    margin-left: -80px;
`;

export class MentionsNode extends TextNode {
    __trigger: string;
    __mentionId: string;
    __mentionLabel: string;
    __mentionDesc: string;
    __mentionEntity: string;

    static getType(): string {
        return 'mention';
    }

    static clone(node: MentionsNode): MentionsNode {
        return new MentionsNode(node.__trigger, node.__mentionId, node.__mentionLabel, node.__mentionDesc, node.__mentionEntity);
    }

    static importJSON(serializedNode: SerializedMentionsNode): MentionsNode {
        const node = $createMentionsNode(serializedNode.trigger, serializedNode.mentionId, serializedNode.mentionLabel, serializedNode.mentionDesc, serializedNode.mentionEntity);
        node.setTextContent(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }

    constructor(trigger: string, mentionId: string, mentionName: string, mentionDesc: string, mentionEntity: string) {
        super(trigger + mentionName);
        this.__trigger = trigger;
        this.__mentionId = mentionId;
        this.__mentionLabel = mentionName;
        this.__mentionDesc = mentionDesc;
        this.__mentionEntity = mentionEntity;
    }

    onClick = (e: MouseEvent) => {
        const parent: HTMLElement = e.currentTarget as HTMLElement;
        const child: HTMLElement = parent.firstElementChild as HTMLElement;
        const classes: DOMTokenList = child.classList;
        const pW = parent.offsetWidth;
        const cW = child.offsetWidth;
        // We want to move the popup, so it's center aligns with parent's center.
        const newOffset = 0 - pW - cW / 2 + pW / 2 + 8; // 8 = padding
        child.style.marginLeft = `${newOffset}px`;
        classes.toggle('popup-show');
    };

    isSegmented(): false {
        return false;
    }

    exportJSON(): SerializedMentionsNode {
        return {
            ...super.exportJSON(),
            trigger: this.__trigger,
            mentionId: this.__mentionId,
            mentionLabel: this.__mentionLabel,
            mentionDesc: this.__mentionDesc,
            mentionEntity: this.__mentionEntity,
            type: 'mention',
            version: 1
        };
    }

    createDOM(config: EditorConfig): HTMLElement {
        const dom = super.createDOM(config);
        dom.style.cssText = mentionStyle().styles;
        dom.className = 'mention';
        const child = document.createElement('span');
        child.className = 'popup';
        child.style.cssText = popupCss().styles;
        child.textContent = this.__mentionDesc;
        dom.appendChild(child);
        dom.onclick = (e) => this.onClick(e);
        return dom;
    }

    exportDOM(): DOMExportOutput {
        const element = document.createElement('span');
        element.setAttribute('data-showrunnr-mention', 'true');
        element.setAttribute('data-showrunnr-trigger', this.__trigger);
        element.setAttribute('data-showrunnr-mentionId', this.__mentionId);
        element.setAttribute('data-showrunnr-mentionEntity', this.__mentionEntity);
        element.setAttribute('data-showrunnr-mentionDesc', this.__mentionDesc);
        element.textContent = this.__text;
        return { element };
    }

    static importDOM(): DOMConversionMap | null {
        return {
            span: (domNode: HTMLElement) => {
                if (!domNode.hasAttribute('data-showrunnr-mention')) {
                    return null;
                }
                return {
                    conversion: (domNode: HTMLElement): DOMConversionOutput | null => {
                        const mLabel = domNode.textContent;
                        if (mLabel !== null) {
                            const mTrigger = domNode.getAttribute('data-showrunnr-trigger');
                            const mId = domNode.getAttribute('data-showrunnr-mentionId');
                            const mEntity = domNode.getAttribute('data-showrunnr-mentionEntity');
                            const mDesc = domNode.getAttribute('data-showrunnr-mentionDesc');
                            const node = $createMentionsNode(mTrigger, mId, mLabel, mDesc, mEntity);
                            return {
                                node
                            };
                        }

                        return null;
                    },
                    priority: 1
                };
            }
        };
    }

    isTextEntity(): true {
        return true;
    }

    isToken(): true {
        return true;
    }
}

export const $createMentionsNode = (trigger: string, mentionId: string, mentionName: string, mentionDesc: string, mentionEntity: string): MentionsNode => {
    const mentionsNode = new MentionsNode(trigger, mentionId, mentionName, mentionDesc, mentionEntity);
    mentionsNode.setMode('segmented').toggleDirectionless();
    return mentionsNode;
};

export const $isMentionsNode = (node: LexicalNode | null | undefined): node is MentionsNode => {
    return node instanceof MentionsNode;
};
