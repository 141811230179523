import { Box } from '@mui/material';
import React from 'react';

export default ({ sx = {}, children }) => {
    return (
        <Box className="_center" sx={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center', ...sx }}>
            {children}
        </Box>
    );
};
