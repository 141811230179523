import { createSvgIcon } from '@mui/material';
import React from 'react';

export const IMDb = createSvgIcon(
    <>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2037_5046)">
                <path d="M0 0H20V20H0" fill="#F5C518" />
                <path
                    d="M4.0625 12.8125V7.1875H2.5V12.8125H4.0625ZM7.38281 7.1875L7.03125 9.80469L6.83594 8.39844L6.64062 7.1875H4.6875V12.8125H6.01562V9.10156L6.5625 12.8125H7.53906L8.04688 9.02344V12.8125H9.375V7.1875H7.38281ZM10 12.8125V7.1875H12.4219C13.0078 7.1875 13.4375 7.61719 13.4375 8.16406V11.8359C13.4375 12.3828 13.0078 12.8125 12.4219 12.8125H10ZM11.8359 8.20312L11.4844 8.16406V11.8359C11.6797 11.8359 11.8359 11.7969 11.875 11.7188C11.9531 11.6406 11.9531 11.4062 11.9531 11.0156V8.82812V8.35938L11.8359 8.20312ZM16.3672 8.59375H16.4844C17.0312 8.59375 17.5 9.02344 17.5 9.57031V11.8359C17.5 12.3828 17.0312 12.8125 16.4844 12.8125H16.3672C16.0547 12.8125 15.7422 12.6562 15.5469 12.3828L15.4688 12.7344H14.0625V7.1875H15.5469V8.98438C15.7422 8.75 16.0547 8.59375 16.3672 8.59375ZM16.0547 11.3281V10L16.0156 9.57031C15.9766 9.49219 15.8594 9.45312 15.7812 9.45312C15.7031 9.45312 15.5859 9.49219 15.5469 9.57031V11.7969C15.5859 11.875 15.7031 11.9141 15.7812 11.9141C15.8594 11.9141 16.0156 11.875 16.0156 11.7969L16.0547 11.3281Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_2037_5046">
                    <rect width="20" height="20" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </>,
    'IMDb'
);
