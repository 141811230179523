import React, { useEffect, useState } from 'react';
import { Autocomplete, ListItemText, TextField } from '@mui/material';
import { getTimeZones } from '@vvo/tzdb';
import { constants } from '../constants';
import { forEach } from 'lodash';

export default (props) => {
    const {
        id,
        name,
        inputLabel,
        value,
        multiple,
        hasError,
        errorText,
        onChange,
        required,
        disabled,
        limitTags = 2,
        size = constants.defaultWidgetSize,
        variant = constants.defaultWidgetVariant,
        ...rest
    } = props;
    const [options, setOptions] = useState([]);
    const timeZones = getTimeZones();

    useEffect(() => {
        const opts = timeZones.map((tz) => {
            return {
                id: tz.name,
                name: tz.alternativeName,
                group: tz.group
            };
        });
        setOptions(opts);
    }, []);

    const valueToOption = (tzValue) => {
        if (multiple) {
            const opts = [];
            forEach(tzValue, (value) => {
                if (value && value.id) {
                    const group = timeZones.find((tz) => tz.name == value.id)?.group;
                    opts.push({
                        id: value.id,
                        name: value.name,
                        group: group
                    });
                }
            });
            return opts;
        } else {
            if (tzValue && tzValue.id) {
                const group = timeZones.find((tz) => tz.name == tzValue.id)?.group;
                return {
                    id: tzValue.id,
                    name: tzValue.name,
                    group: group
                };
            } else {
                return null;
            }
        }
    };

    return (
        <Autocomplete
            id={id}
            disabled={disabled}
            fullWidth
            multiple={multiple}
            size={size}
            options={options}
            getOptionLabel={(option) => {
                return option ? option.id : '';
            }}
            renderOption={(props, option) => (
                <li {...props} key={props.id}>
                    <ListItemText primary={option.id} secondary={option.name} />
                </li>
            )}
            value={valueToOption(value)}
            isOptionEqualToValue={(option, value) => {
                return option && value && (option.id === value.id || option.group.includes(value.id));
            }}
            renderInput={(inputParams) => (
                <TextField
                    size={size}
                    required={required}
                    disabled={disabled}
                    variant={variant}
                    error={hasError}
                    helperText={errorText}
                    label={inputLabel}
                    InputProps={{
                        ...inputParams.InputProps,
                        size: size
                    }}
                    {...inputParams}
                />
            )}
            limitTags={limitTags}
            onChange={onChange}
        />
    );
};
