import { $isCodeNode, CODE_LANGUAGE_MAP } from '@lexical/code';
import { $isLinkNode } from '@lexical/link';
import { $isListNode, INSERT_CHECK_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode, REMOVE_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { $createHeadingNode, $createQuoteNode, $isHeadingNode, $isQuoteNode } from '@lexical/rich-text';
import { $isParentElementRTL, $setBlocksType } from '@lexical/selection';
import { $isTableNode } from '@lexical/table';
import { $findMatchingParent, $getNearestBlockElementAncestorOrThrow, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { Divider } from '@mui/material';
import type { NodeKey } from 'lexical';
import { $createParagraphNode, $getSelection, $isRangeSelection, $isRootOrShadowRoot, $isTextNode, DEPRECATED_$isGridSelection } from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';
import { H } from '../../../common/Layout';
import AlignmentMenuItem from './AlignmentMenuItem';
import ListMenuItem from './ListMenuItem';
import ParagraphMenuItem, { paragraphTypes } from './ParagraphMenuItem';
import TextStyleMenuItem from './TextStyleMenuItem';
import UndoRedoMenuItem from './UndoRedoMenuItem';
import { getSelectedNode } from './getSelectedNode';

const rootTypeToRootName = {
    root: 'Root',
    table: 'Table'
};

const ToolbarDivider = () => {
    return <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />;
};

type LexicalTopToolbarProps = {
    disabled?: boolean;
    isSmall?: boolean;
};

export default (props: LexicalTopToolbarProps) => {
    const { disabled, isSmall } = props;
    const [editor] = useLexicalComposerContext();
    const [blockType, setBlockType] = useState<keyof typeof paragraphTypes>('paragraph');
    const [rootType, setRootType] = useState<keyof typeof rootTypeToRootName>('root');
    const [selectedElementKey, setSelectedElementKey] = useState<NodeKey | null>(null);
    const [fontSize, setFontSize] = useState<string>('15px');
    const [fontColor, setFontColor] = useState<string>('#000');
    const [bgColor, setBgColor] = useState<string>('#fff');
    const [fontFamily, setFontFamily] = useState<string>('Arial');
    const [isLink, setIsLink] = useState(false);

    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);

    const [isCode, setIsCode] = useState(false);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isRTL, setIsRTL] = useState(false);
    const [codeLanguage, setCodeLanguage] = useState<string>('');
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    const [isAlignLeft, setIsAlignLeft] = useState(false);
    const [isAlignCenter, setIsAlignCenter] = useState(false);
    const [isAlignRight, setIsAlignRight] = useState(false);
    const [isAlignJustify, setIsAlignJustify] = useState(false);

    if (!editor) {
        return null;
    }

    const clearFormatting = useCallback(() => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const anchor = selection.anchor;
                const focus = selection.focus;
                const nodes = selection.getNodes();

                if (anchor.key === focus.key && anchor.offset === focus.offset) {
                    return;
                }

                nodes.forEach((node, idx) => {
                    // We split the first and last node by the selection
                    // So that we don't format unselected text inside those nodes
                    if ($isTextNode(node)) {
                        if (idx === 0 && anchor.offset !== 0) {
                            node = node.splitText(anchor.offset)[1] || node;
                        }
                        if (idx === nodes.length - 1) {
                            node = node.splitText(focus.offset)[0] || node;
                        }

                        if (node.__style !== '') {
                            node.setStyle('');
                        }
                        if (node.__format !== 0) {
                            node.setFormat(0);
                            $getNearestBlockElementAncestorOrThrow(node).setFormat('');
                        }
                    } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
                        node.replace($createParagraphNode(), true);
                    } else if ($isDecoratorBlockNode(node)) {
                        node.setFormat('');
                    }
                });
            }
        });
    }, [editor]);

    const updateToolbar = useCallback(() => {
        if (editor) {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const anchorNode = selection.anchor.getNode();
                const format = anchorNode.getFormat();
                setIsAlignLeft(format === 1);
                setIsAlignCenter(format === 2);
                setIsAlignRight(format === 3);
                setIsAlignJustify(format === 4);

                let element =
                    anchorNode.getKey() === 'root'
                        ? anchorNode
                        : $findMatchingParent(anchorNode, (e) => {
                              const parent = e.getParent();
                              return parent !== null && $isRootOrShadowRoot(parent);
                          });

                if (element === null) {
                    element = anchorNode.getTopLevelElementOrThrow();
                }

                const elementKey = element.getKey();
                const elementDOM = editor.getElementByKey(elementKey);

                // Update text format
                setIsBold(selection.hasFormat('bold'));
                setIsItalic(selection.hasFormat('italic'));
                setIsUnderline(selection.hasFormat('underline'));
                setIsStrikethrough(selection.hasFormat('strikethrough'));
                setIsSubscript(selection.hasFormat('subscript'));
                setIsSuperscript(selection.hasFormat('superscript'));
                setIsCode(selection.hasFormat('code'));
                setIsRTL($isParentElementRTL(selection));

                // Update links
                const node = getSelectedNode(selection);
                const parent = node.getParent();
                if ($isLinkNode(parent) || $isLinkNode(node)) {
                    setIsLink(true);
                } else {
                    setIsLink(false);
                }

                const tableNode = $findMatchingParent(node, $isTableNode);
                if ($isTableNode(tableNode)) {
                    setRootType('table');
                } else {
                    setRootType('root');
                }

                if (elementDOM !== null) {
                    setSelectedElementKey(elementKey);
                    if ($isListNode(element)) {
                        const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
                        const type = parentList ? parentList.getListType() : element.getListType();
                        setBlockType(type);
                    } else {
                        const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                        if (type in paragraphTypes) {
                            setBlockType(type as keyof typeof paragraphTypes);
                        }
                        if ($isCodeNode(element)) {
                            const language = element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
                            setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : '');
                            return;
                        }
                    }
                }
                // Handle buttons
                // setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'));
                // setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
                // setBgColor($getSelectionStyleValueForProperty(selection, 'background-color', '#fff'));
                // setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'));
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            })
        );
    }, [editor, updateToolbar]);

    // const handleSize = (event: React.MouseEvent<HTMLElement>, newH: string | null) => {
    //     const level = parseInt(newH) as Level;
    //     editor.chain().focus().toggleHeading({ level: level }).run();
    // };

    const handleBlockType = (bt: keyof typeof paragraphTypes) => {
        if (blockType !== bt) {
            editor.update(() => {
                const selection = $getSelection();
                if (bt === 'paragraph') {
                    if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
                        $setBlocksType(selection, () => $createParagraphNode());
                    }
                } else if (bt === 'h1' || bt === 'h2' || bt === 'h3' || bt === 'h4' || bt === 'h5' || bt === 'h6') {
                    if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
                        $setBlocksType(selection, () => $createHeadingNode(bt));
                    }
                } else if (bt === 'bullet') {
                    if (blockType !== 'bullet') {
                        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
                    } else {
                        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
                    }
                } else if (bt === 'number') {
                    if (blockType !== 'number') {
                        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
                    } else {
                        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
                    }
                } else if (bt === 'check') {
                    if (blockType !== 'check') {
                        editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
                    } else {
                        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
                    }
                } else if (bt === 'quote') {
                    if (blockType !== 'quote') {
                        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
                            $setBlocksType(selection, () => $createQuoteNode());
                        }
                    }
                }
            });
        }
    };

    // breakpoint is the size of the expanded toolbar with all the components at their maximum size.
    return (
        <H>
            <UndoRedoMenuItem editor={editor} disabled={disabled} />
            <ToolbarDivider />
            <ParagraphMenuItem blockType={blockType} setBlockType={handleBlockType} disabled={disabled} />
            <ToolbarDivider />
            <ListMenuItem isSmall={isSmall} blockType={blockType} setBlockType={handleBlockType} disabled={disabled} />
            <ToolbarDivider />
            <TextStyleMenuItem
                isSmall={isSmall}
                editor={editor}
                isBold={isBold}
                isItalic={isItalic}
                isStrikethrough={isStrikethrough}
                isUnderline={isUnderline}
                disabled={disabled}
            />
            <ToolbarDivider />
            <AlignmentMenuItem
                editor={editor}
                isSmall={isSmall}
                isAlignLeft={isAlignLeft}
                isAlignCenter={isAlignCenter}
                isAlignRight={isAlignRight}
                isAlignJustify={isAlignJustify}
                disabled={disabled}
            />
        </H>
    );
};
