import { createSvgIcon } from '@mui/material';
import React from 'react';

export const Order = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.25 2C7.25 1.30964 7.80964 0.75 8.5 0.75H13.5C14.1904 0.75 14.75 1.30964 14.75 2H18.5C19.3284 2 20 2.67157 20 3.5V8.00032L18 8.00054V4H14.75C14.75 4.69036 14.1904 5.25 13.5 5.25H8.5C7.80964 5.25 7.25 4.69036 7.25 4H4V21H10V23H3.5C2.67157 23 2 22.3284 2 21.5V3.5C2 2.67157 2.67157 2 3.5 2H7.25ZM8.75 2.25V3.75H13.25V2.25H8.75Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 8C7 7.44772 7.44772 7 8 7H10.596C11.1467 7 11.6266 7.37476 11.7602 7.90896L12.1645 9.52632H22.5581C23.4265 9.52632 24.0074 10.4201 23.6547 11.2137L21.3061 16.4979C21.1135 16.9312 20.6838 17.2105 20.2096 17.2105H13.9992L13.4027 19H20.8574C21.4097 19 21.8574 19.4477 21.8574 20C21.8574 20.5523 21.4097 21 20.8574 21H19.5H13.5H12.2928C11.4737 21 10.8954 20.1976 11.1544 19.4205L12.2244 16.2105L10.4229 10.8061L9.97139 9H8C7.44772 9 7 8.55229 7 8ZM13.9992 15.2105H19.6897L21.3271 11.5263H12.7712L13.9992 15.2105Z"
            fill="currentColor"
        />
        <path
            d="M13.5 21C14.3284 21 15 21.6716 15 22.5C15 23.3284 14.3284 24 13.5 24C12.6716 24 12 23.3284 12 22.5C12 21.6716 12.6716 21 13.5 21Z"
            fill="currentColor"
        />
        <path
            d="M19.5 21C20.3284 21 21 21.6716 21 22.5C21 23.3284 20.3284 24 19.5 24C18.6716 24 18 23.3284 18 22.5C18 21.6716 18.6716 21 19.5 21Z"
            fill="currentColor"
        />
    </svg>,
    'Order'
);
