import React, { useContext, useEffect, useState } from 'react';
import { autoCreateQuery, client, detailsQuery, EMPTY_PERSON, Person, stateToPerson } from './person';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { Alert, Box, Button, Typography } from '@mui/material';
import Validator, { ValidationState } from '../../form/Validator';
import { handleAndCommitChange, hasErrors, processServiceErrors } from '../../form/forms';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UserContext } from '../../auth/UserContext';
import I18n, { I18nContext, I18nContextType, useI18n } from '../../i18n/I18n';
import { LoadingButton } from '@mui/lab';
import { FormContainer } from '../../form/FormContainer';
import FormItem from '../../form/FormItem';
import FormWidget from '../../form/FormWidget';
import { ProductionOptionRenderer } from '../../../supply/booking/ProductionOptionRenderer';
import { getOption as getProductionOption, quickAddProduction } from '../../../production/helpers/QuickAddProduction';
import {
    autoCreateQuery as productionAutoCreateQuery,
    client as productionClient
} from '../../../production/helpers/production';
import OrganizationOptionRenderer from '../../../organization/OrganizationOptionRenderer';
import { getOption, quickAddOrganization } from '../../../organization/QuickAddOrganization';
import {
    autoCreateQuery as autoCreateQueryOrganization,
    client as organizationClient
} from '../../../organization/organization';
import { V } from '../../Layout';
import { getReferenceId } from '../../reference/reference';
import { useMutationWithContext } from '../../hooks/useMutationWithContext';
import { enqueueSnackbar } from '../Toast';
import { constants } from '../../constants';
import { searchClient, slimResultsQuery } from '../../list/slimQuery';

type PersonQuickAddProps = {
    id?: string;
    newContactData: Map<string, string>;
    existingContactData?: Person;
    onCancel: Function;
    onAdd: (person: Person) => void;
};

const EMPTY_PERSON_FOR_ORG: Person = {
    name: {
        firstName: '',
        lastName: ''
    },
    contactInfo: {
        phone: {
            number: '',
            typeString: 'Mobile'
        },
        email: {
            address: '',
            typeString: 'Business',
            verified: false
        }
    },
    creativeCredits: undefined,
    experiences: [{ experienceName: undefined, professionalRoles: [] }],
    reference: undefined
};

export default (props: PersonQuickAddProps) => {
    const isQuickAddOrgFlow = props.id === 'org-quick-person-add';
    const [values, setValues] = useState<Person>(
        isQuickAddOrgFlow ? cloneDeep(EMPTY_PERSON_FOR_ORG) : cloneDeep(EMPTY_PERSON)
    );
    const [save] = useMutation(autoCreateQuery, { client: client });
    const [saveProduction] = useMutationWithContext(productionAutoCreateQuery, productionClient);
    const [saveOrganization] = useMutationWithContext(autoCreateQueryOrganization, organizationClient);
    const { activeOrganizationAccount } = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const [personExists, setPersonExists] = useState(false);
    const i18nContext: I18nContextType = useContext(I18nContext);
    let validationRules = {};
    if (isQuickAddOrgFlow) {
        validationRules = {
            'name.firstName': [Validator.RULES.isRequired],
            'name.lastName': [Validator.RULES.isRequired],
            'contactInfo.email.address': [Validator.RULES.isRequired, Validator.RULES.email]
        };
    } else {
        validationRules = {
            'contactInfo.email.address': [Validator.RULES.isRequired, Validator.RULES.email],
            'contactInfo.phone.number': [Validator.RULES.phoneNumber]
        };
    }
    const query = slimResultsQuery('Person');
    const [findUserDetails, { loading, data }] = useLazyQuery(query, {
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });

    useEffect(() => {
        if (data?.results?.hits?.items?.length > 0) {
            const id = getReferenceId(data.results.hits.items[0]);
            if (id !== values?.reference?.id) {
                setPersonExists(true);
            }
        } else {
            setPersonExists(false);
        }
    }, [data]);

    useEffect(() => {
        const data = isQuickAddOrgFlow ? cloneDeep(EMPTY_PERSON_FOR_ORG) : cloneDeep(EMPTY_PERSON);
        if (props.newContactData.size > 0) {
            set(
                data,
                'name.firstName',
                props.newContactData.has('firstName') ? props.newContactData.get('firstName') : ''
            );
            set(
                data,
                'name.lastName',
                props.newContactData.has('lastName') ? props.newContactData.get('lastName') : ''
            );
        }
        setValues(data);
    }, [props.newContactData]);

    useEffect(() => {
        if (props.existingContactData && !isQuickAddOrgFlow) {
            setValues(props.existingContactData);
        }
    }, [props.existingContactData]);

    const validator = new Validator(validationRules, {
        runAll: false,
        i18nContext: i18nContext
    });

    const [valid, setValid] = useState<ValidationState>({
        isValid: false,
        errors: {}
    });

    const onChange = (name, value) => {
        handleAndCommitChange(values, name, value, validator, setValues, setValid);
    };

    const doSave = () => {
        setSaving(true);
        const savedEntity = stateToPerson(values);
        save({
            variables: {
                input: savedEntity
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
            .then((res) => {
                if (res.data.autoCreatePerson.errors) {
                    enqueueSnackbar(
                        <I18n
                            token="form.save.failure"
                            values={{ name: `${savedEntity.name.firstName} ${savedEntity.name.lastName}` }}
                        />,
                        {
                            variant: 'error'
                        }
                    );
                    processServiceErrors(res.data.autoCreatePerson.errors, valid, setValid, validator);
                    setSaving(false);
                } else {
                    client
                        .query({
                            query: detailsQuery,
                            variables: { id: res.data.autoCreatePerson.id },
                            context: { headers: { ownerId: activeOrganizationAccount } },
                            fetchPolicy: constants.apolloFetchPolicy
                        })
                        .then((result) => {
                            const person = stateToPerson(values);
                            person.reference = result.data.person.reference;
                            props.onAdd(person);
                            setSaving(false);
                        })
                        .catch((err) => {
                            props.onAdd(undefined);
                            setSaving(false);
                        });
                }
            })
            .catch((err) => {
                console.warn(err);
                enqueueSnackbar(
                    <I18n
                        token="form.save.failure"
                        values={{ name: `${savedEntity.name.firstName} ${savedEntity.name.lastName}` }}
                    />,
                    {
                        variant: 'error'
                    }
                );
                setSaving(false);
            });
    };

    useEffect(() => {
        if (!hasErrors(valid, 'contactInfo.email.address') && !isEmpty(values.contactInfo?.email?.address)) {
            const { address } = values.contactInfo?.email;
            findUserDetails({
                variables: {
                    filters: [
                        { identifier: 'entity', value: 'Person' },
                        { identifier: 'email', value: address }
                    ]
                }
            });
        }
    }, [values.contactInfo?.email?.address]);

    return (
        <Box
            sx={{
                mt: isQuickAddOrgFlow ? 0 : 2,
                boxShadow:
                    'rgb(0 0 0 / 5%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 5%) 0px 3px 14px 2px',
                br: '4px',
                p: 2
            }}
        >
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="name.firstName"
                        label={useI18n('person.add.first.name')}
                        value={values.name?.firstName}
                        errors={get(valid.errors, 'name.firstName')}
                        hasErrors={hasErrors(valid, 'name.firstName')}
                        onChange={onChange}
                    />
                </FormItem>
                <FormItem>
                    <FormWidget
                        name="name.lastName"
                        label={useI18n('person.add.last.name')}
                        value={values.name?.lastName}
                        errors={get(valid.errors, 'name.lastName')}
                        hasErrors={hasErrors(valid, 'name.lastName')}
                        onChange={onChange}
                    />
                </FormItem>
                {personExists && (
                    <FormItem>
                        <Alert severity="info" sx={{ width: '100%' }}>
                            <V>
                                <Typography>
                                    <I18n token="person.add.email.exist.message" />
                                </Typography>
                            </V>
                        </Alert>
                    </FormItem>
                )}
                <FormItem children={undefined} />
                <FormItem>
                    <FormWidget
                        name="contactInfo.email.address"
                        label={useI18n('person.add.email')}
                        value={values.contactInfo?.email?.address}
                        errors={get(valid.errors, 'contactInfo.email.address')}
                        hasErrors={hasErrors(valid, 'contactInfo.email.address')}
                        onChange={onChange}
                    />
                </FormItem>
                <FormItem>
                    <FormWidget
                        component="PhoneNumber"
                        name="contactInfo.phone.number"
                        label={useI18n('person.add.phone')}
                        value={values.contactInfo?.phone?.number}
                        errors={get(valid.errors, 'contactInfo.phone.number')}
                        hasErrors={hasErrors(valid, 'contactInfo.phone.number')}
                        onChange={onChange}
                    />
                </FormItem>
                {values?.experiences?.map((exp, index) => {
                    return (
                        <React.Fragment key={index}>
                            {!isQuickAddOrgFlow && (
                                <FormItem>
                                    <FormWidget
                                        component="ReferenceAutocomplete"
                                        entity="Organization"
                                        name={`experiences[${index}].organizationReference`}
                                        label={<I18n token={'person.experiences.organization'} />}
                                        value={values.experiences[index].organizationReference}
                                        errors={get(valid.errors, `experiences[${index}].organizationReference`)}
                                        hasErrors={hasErrors(valid, `experiences[${index}].organizationReference`)}
                                        onChange={onChange}
                                        OptionRenderer={OrganizationOptionRenderer}
                                        onCreateHandle={quickAddOrganization}
                                        getOption={getOption}
                                        saveMutation={saveOrganization}
                                        addOption
                                        preload={false}
                                        filter={{ expressions: [{ field: 'inactive', value: { values: ['false'] } }] }}
                                    />
                                    <FormWidget
                                        allowCreate
                                        component="MetadataAutocomplete"
                                        metadataName="Title"
                                        name={`experiences[${index}].title`}
                                        label={<I18n token={'person.experiences.title'} />}
                                        value={values.experiences[index].title}
                                        errors={valid.errors.role}
                                        hasErrors={hasErrors(valid, `experiences[${index}].title`)}
                                        onChange={onChange}
                                    />
                                </FormItem>
                            )}
                            <FormItem>
                                {!isQuickAddOrgFlow && (
                                    <FormWidget
                                        allowCreate
                                        component="MetadataAutocomplete"
                                        metadataName="Department"
                                        name={`experiences[${index}].department`}
                                        label={<I18n token={'person.experiences.department'} />}
                                        value={values.experiences[index].department}
                                        errors={valid.errors.department}
                                        hasErrors={hasErrors(valid, `experiences[${index}].department`)}
                                        onChange={onChange}
                                    />
                                )}
                                <FormWidget
                                    allowCreate
                                    component="MetadataAutocomplete"
                                    metadataName="Role"
                                    multiple
                                    name={`experiences[${index}].professionalRoles`}
                                    label={<I18n token={'person.add.role'} />}
                                    value={values.experiences[index].professionalRoles}
                                    errors={valid.errors.role}
                                    hasErrors={hasErrors(valid, `experiences[${index}].professionalRoles`)}
                                    onChange={onChange}
                                />
                            </FormItem>
                        </React.Fragment>
                    );
                })}
                {values?.creativeCredits?.map((credit, index) => {
                    return (
                        <React.Fragment key={index}>
                            <FormItem>
                                <FormWidget
                                    name={`creativeCredits[${index}].showReference`}
                                    addOption
                                    component="ReferenceAutocomplete"
                                    value={values.creativeCredits[index].showReference}
                                    entity="Production"
                                    label={<I18n token={'person.creative.credits.production'} />}
                                    OptionRenderer={ProductionOptionRenderer}
                                    onCreateHandle={quickAddProduction}
                                    getOption={getProductionOption}
                                    errors={get(valid.errors, `creativeCredits[${index}].showReference`)}
                                    hasErrors={hasErrors(valid, `creativeCredits[${index}].showReference`)}
                                    saveMutation={saveProduction}
                                    onChange={onChange}
                                />
                            </FormItem>
                            <FormItem>
                                <FormWidget
                                    allowCreate
                                    component="MetadataAutocomplete"
                                    metadataName="Department"
                                    name={`creativeCredits[${index}].department`}
                                    label={<I18n token={'person.creative.credits.production.department'} />}
                                    value={values.creativeCredits[index].department}
                                    errors={valid.errors.department}
                                    hasErrors={hasErrors(valid, `creativeCredits[${index}].department`)}
                                    onChange={onChange}
                                />
                                <FormWidget
                                    allowCreate
                                    component="MetadataAutocomplete"
                                    metadataName="Role"
                                    multiple
                                    name={`creativeCredits[${index}].professionalRoles`}
                                    label={<I18n token={'person.add.role'} />}
                                    value={values.creativeCredits[index].professionalRoles}
                                    errors={valid.errors.role}
                                    hasErrors={hasErrors(valid, `creativeCredits[${index}].professionalRoles`)}
                                    onChange={onChange}
                                />
                            </FormItem>
                        </React.Fragment>
                    );
                })}
                <FormItem>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="text" onClick={() => props.onCancel?.()}>
                            <I18n token={'dialog.cancel'} />
                        </Button>
                        <LoadingButton
                            disabled={!valid.isValid || personExists}
                            loading={saving}
                            onClick={doSave}
                            variant="text"
                        >
                            <I18n token={'button.add'} />
                        </LoadingButton>
                    </Box>
                </FormItem>
            </FormContainer>
        </Box>
    );
};
