import React from 'react';
import { createSvgIcon } from '@mui/material';

export const PlaceIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.4133 3.02667L18.5556 3C18.8044 3 19 3.19556 19 3.44444V9.17396C18.5475 9.05945 18.0774 9 17.6 9C17.4735 9 17.3475 9.00417 17.2222 9.01244V5.4L14.5556 6.43111V9.88453C13.8375 10.344 13.2237 10.9708 12.7778 11.7379V6.44L9.22222 5.19556V15.56L12.3902 16.6688C12.6748 17.4051 13.1152 18.1771 13.711 18.9851L13.6667 19L8.33333 17.1333L3.58667 18.9733L3.44444 19C3.19556 19 3 18.8044 3 18.5556V5.11556C3 4.91111 3.13333 4.75111 3.32 4.68889L8.33333 3L13.6667 4.86667L18.4133 3.02667ZM7.44444 5.17778L4.77778 6.07556V16.6089L7.44444 15.5778V5.17778Z"
            fill="currentColor"
        />
        <path
            d="M17.6 16C16.94 16 16.4 15.46 16.4 14.8C16.4 14.14 16.94 13.6 17.6 13.6C18.26 13.6 18.8 14.14 18.8 14.8C18.8 15.46 18.26 16 17.6 16ZM20.9 14.92C20.9 12.742 19.61 11.5 17.6 11.5C15.59 11.5 14.3 12.742 14.3 14.92C14.3 16.324 15.17 17.8935 17.6 20.1135C20.03 17.8935 20.9 16.324 20.9 14.92ZM17.6 10C20.12 10 22.4 11.932 22.4 14.92C22.4 16.912 20.798 19.27 17.6 22C14.402 19.27 12.8 16.912 12.8 14.92C12.8 11.932 15.08 10 17.6 10Z"
            fill="currentColor"
        />
    </svg>,
    'PlaceIcon'
);
