import { Slider, Typography } from '@mui/material';
import { useSearchkit } from '@searchkit/client';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { V } from '../../../common/Layout';

export const getMinMax = (facet): any => {
    if (facet.entries && facet.entries.length) {
        const levels = getLevels(facet.entries);
        const minBoundary = levels[0].min;
        const maxBoundary = levels[levels.length - 1].max;
        return [minBoundary, maxBoundary];
    } else {
        return [facet.min, facet.max];
    }
};

export const getLevels = (entries: Array<{ label: string; count: number }>): any =>
    entries.reduce((levels, entry, index, entries) => {
        const lastLevel = levels[levels.length - 1];
        const isLast = entries.length === index + 1;
        if (!lastLevel || lastLevel.max) {
            levels.push({
                min: lastLevel ? lastLevel.max : parseFloat(entry.label),
                hasResults: entry.count !== 0
            });
        } else if (
            lastLevel &&
            !lastLevel.max &&
            ((entry.count > 0 && !lastLevel.hasResults) ||
                (entry.count === 0 && lastLevel.hasResults) ||
                (isLast && !lastLevel.max))
        ) {
            lastLevel.max = parseFloat(entry.label);
            if (!isLast) {
                levels.push({
                    min: parseFloat(entry.label),
                    hasResults: entry.count !== 0
                });
            }
        }
        return levels;
    }, []);

const debouncedCallback = debounce((api, facet, value) => {
    const pageSize = api.getSearchState().page.size;
    api.removeFiltersByIdentifier(facet.identifier);
    api.addFilter({ identifier: facet.identifier, min: value[0], max: value[1] });
    api.setPage({
        from: 0,
        size: pageSize
    });
    api.search();
}, 400);

export const RangeSliderFacet = ({ facet }) => {
    const api = useSearchkit();
    const [value, setValue] = useState<number[]>(() => {
        const selectedOptions = api.getFiltersByIdentifier(facet.identifier);
        if (selectedOptions?.length > 0) {
            return [selectedOptions[0].min, selectedOptions[0].max];
        } else {
            return getMinMax(facet);
        }
    });

    useEffect(() => {
        const selectedOptions = api.getFiltersByIdentifier(facet.identifier);
        if (selectedOptions?.length > 0) {
            setValue([selectedOptions[0].min, selectedOptions[0].max]);
        }
    }, []);

    return (
        <V>
            <Typography sx={{ fontSize: 'small' }}>{facet.label}</Typography>
            {facet.entries && facet.entries.length ? (
                <Slider
                    size={'small'}
                    getAriaLabel={() => facet.label}
                    value={value}
                    onChange={(event: Event, newValue: number | number[]) => {
                        setValue(newValue as number[]);
                    }}
                    onChangeCommitted={(event: Event, newValue: number | number[]) => {
                        debouncedCallback(api, facet, newValue);
                    }}
                    valueLabelDisplay="auto"
                />
            ) : (
                <Slider
                    size={'small'}
                    getAriaLabel={() => facet.label}
                    min={facet.min}
                    max={facet.max}
                    step={facet.interval}
                    value={value}
                    onChange={(event: Event, newValue: number | number[]) => {
                        setValue(newValue as number[]);
                    }}
                    onChangeCommitted={(event: Event, newValue: number | number[]) => {
                        debouncedCallback(api, facet, newValue);
                    }}
                    valueLabelDisplay="auto"
                />
            )}
        </V>
    );
};

RangeSliderFacet.DISPLAY = 'RangeSliderFacet';
