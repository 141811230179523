import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { BreakpointSize, getBreakpoint } from './breakpoints';


export const useCurrentBreakpoint = () => {
    const [currentBreakpoint, setCurrentBreakpoint] = useState<BreakpointSize>();

    const handleResize = useMemo(
        () => debounce(() => {
            const bp = getBreakpoint(window.innerWidth);
            if (bp !== currentBreakpoint) {
                setCurrentBreakpoint(bp);
            }
        }, 100),
        [currentBreakpoint, setCurrentBreakpoint]
    );

    useEffect(() => {
        window.removeEventListener('resize', handleResize);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return currentBreakpoint;
};
