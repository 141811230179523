import { Box, Typography } from '@mui/material';
import React from 'react';
import { useStyling } from '../../common/Theme';
import EllipsisText from '../../common/components/EllipsisText';

export default ({ option }) => {
    const { theme } = useStyling();
    const createOption = option && option.createOption;
    const label = createOption ? `Add ${option.label}` : option.label;

    return (
        <Box key={option.id}>
            {createOption ? (
                <Typography sx={{ fontStyle: createOption ? 'italic' : 'normal' }}>{label}</Typography>
            ) : (
                <>
                    <Typography sx={{ fontWeight: 'medium' }} variant="subtitle1">
                        {option?.name}
                    </Typography>
                    <Typography sx={{ fontWeight: '300' }} color={theme.palette.text.disabled} variant="subtitle2">
                        <EllipsisText text={option?.professionalRoles?.join(', ') || option.role || ' '} />
                    </Typography>
                </>
            )}
        </Box>
    );
};
