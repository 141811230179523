import React from 'react';
import { Attribute } from '../attributes';
import { LabelValueDisplay } from '../AttributeValue';

type DefaultAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: DefaultAttributePreviewProps) => {
    const { attribute } = props;
    return attribute && <LabelValueDisplay label={attribute.label} value={attribute.value || '-'} />;
};
