import React, { useContext } from 'react';
import I18n from '../i18n/I18n';
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useOnMobile } from '../hooks/breakpoints';
import { FormContext } from '../form/FormContext';
import { H } from '../Layout';

export default function OkCancel({
    isBusy = false,
    isOkDisabled,
    onOk,
    onCancel,
    okLabelI18n = 'dialog.ok',
    cancelLabelI18n = 'dialog.cancel'
}) {
    const { validation } = useContext(FormContext);
    const isOkValid = validation ? !validation.isValid : isOkDisabled || isBusy;
    const isMobile = useOnMobile();
    return (
        <H
            sx={{
                flexDirection: isMobile ? 'column-reverse' : 'row',
                gap: 2,
                marginTop: '20px',
                width: '100%'
            }}
        >
            {onCancel && (
                <Button
                    id="cancelButton"
                    onClick={() => {
                        onCancel();
                    }}
                    variant={'outlined'}
                    sx={{ width: '100%' }}
                >
                    <I18n token={cancelLabelI18n} />
                </Button>
            )}

            <LoadingButton
                sx={{ width: '100%' }}
                variant={'contained'}
                loading={isBusy}
                id="okButton"
                onClick={onOk}
                disabled={isOkValid}
            >
                <I18n token={okLabelI18n} />
            </LoadingButton>
        </H>
    );
}
