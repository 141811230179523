import { Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { Attachment } from 'sr-types/lib/production/v1/graphql';
import RoundAvatar, { AvatarSizes } from '../../production/header/RoundAvatar';
import { H, V } from '../Layout';
import { useStyling } from '../Theme';
import { ActionType } from '../components/ActionType';
import EllipsisText from '../components/EllipsisText';
import ResponsiveActions from '../components/ResponsiveActions';
import { Icons } from '../icons/Icons';
import { toDisplay } from '../utils/dateTime';
import download from '../utils/download';
import { convertBytesToMbsOrKbs, imageExtensions } from './attachmentsApi';
import { UserContext } from '../auth/UserContext';
import AttachmentsImagePreview from './AttachmentsImagePreview';
import useLoadAttachment from '../components/useLoadAttachment';

type AttachmentsProps = {
    file: Attachment;
    onDelete: () => void;
    isPreview?: boolean;
};

export default (props: AttachmentsProps) => {
    const { file, onDelete, isPreview = false } = props;
    const { theme, isDarkMode } = useStyling();
    const { activeOrganizationAccount } = useContext(UserContext);
    const [isHover, setIsHover] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const { attachment } = useLoadAttachment(file?.entityReference?.id);

    const actions = useMemo(() => {
        const actionsArray: ActionType[] = [
            {
                icon: Icons.Delete,
                onClick: onDelete,
                label: 'Delete'
            }
        ];

        actionsArray.push({
            icon: Icons.Visibility,
            onClick: () => {
                if (!imageExtensions.includes(file?.type) && attachment) {
                    window.open(attachment, '_blank');
                } else {
                    setIsPreviewOpen(true);
                }
            },
            label: 'Preview'
        });

        if (!isPreview) {
            actionsArray.push({
                icon: Icons.Download,
                label: 'Download',
                onClick: () => {
                    download(file.entityReference.id, file.name, activeOrganizationAccount);
                }
            });
        }
        return actionsArray;
    }, [onDelete, isPreview, attachment, file]);

    return (
        <H
            fill
            sx={{
                p: 1,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                '&:hover': {
                    backgroundColor: theme.palette.grey[isDarkMode ? 700 : 100]
                }
            }}
        >
            <H
                fill
                sx={{ gap: 2, flexGrow: 1, position: 'relative', cursor: 'pointer' }}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => setIsPreviewOpen(true)}
            >
                <RoundAvatar
                    imageId={imageExtensions.includes(file.type) ? file.entityReference.id : undefined}
                    size={AvatarSizes.md}
                    title={file.name}
                />
                <V sx={{ flexGrow: 1 }}>
                    <EllipsisText text={file.name} />
                    <H sx={{ gap: 1, mt: 1, width: 'fit-content' }}>
                        <Typography fontSize={'x-small'} fontWeight={'normal'} color={theme.palette.text.disabled}>
                            {convertBytesToMbsOrKbs(file.size)}
                        </Typography>
                        {file.createdOn && (
                            <Typography fontSize={'x-small'} fontWeight={'normal'} color={theme.palette.text.disabled}>
                                {toDisplay(file.createdOn)}
                            </Typography>
                        )}

                        <Typography
                            fontSize={'x-small'}
                            fontWeight={'normal'}
                            className={'ellipsis'}
                            sx={{ flexGrow: 2 }}
                            color={theme.palette.text.disabled}
                        >
                            .{file.type}
                        </Typography>
                    </H>
                </V>

                <H
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    sx={{ justifyContent: 'flex-end' }}
                >
                    <ResponsiveActions actions={actions} showBorder isActivated={isHover} />
                </H>
            </H>
            {imageExtensions.includes(file?.type) && isPreviewOpen && (
                <AttachmentsImagePreview
                    isPreviewOpen={isPreviewOpen}
                    setIsPreviewOpen={setIsPreviewOpen}
                    name={file?.name}
                    id={file?.entityReference?.id}
                />
            )}
        </H>
    );
};
