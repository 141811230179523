/** @jsxImportSource @emotion/react */

import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { css, List, ListItem, ListItemButton, Paper, useAutocomplete } from '@mui/material';
import { UserContext } from '../auth/UserContext';
import { constants } from '../constants';
import Loading from '../components/Loading';
import { cloneDeep, debounce, find, isEmpty } from 'lodash';
import { useStyling } from '../Theme';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { usePrevious } from '../hooks/usePrevious';
import { StyledTooltip } from '../components/StyledTooltip';
import { searchClient, slimSearchQuery } from './slimQuery';

export default (props: GridRenderEditCellParams) => {
    const {
        id,
        value,
        field,
        OptionRenderer,
        toOption,
        entityToUpdate,
        columns,
        sortBy = 'name',
        isDesc = false,
        afterSearch = undefined,
        getNonFuzzyQueryParams = undefined,
        filters,
        filter,
        setIsRowUpdated = undefined,
        onAutoCreate = undefined,
        autoCreate = false
    } = props;
    const { theme } = useStyling();
    const apiRef = useGridApiContext();
    const [inputValue, setInputValue] = useState<string>(value);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const prevValue = usePrevious(value);
    const { activeOrganizationAccount } = useContext(UserContext);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const isInitialMount = useRef(true);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        strategy: 'fixed',
        modifiers: [
            {
                name: 'flip',
                enabled: true
            }
        ]
    });

    useEffect(() => {
        if (value !== prevValue) {
            setInputValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (value !== prevValue) {
                if (setIsRowUpdated) {
                    setIsRowUpdated(true);
                }
            }
        }
    }, [value]);

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, focused } = useAutocomplete({
        options,
        getOptionLabel: (option) => {
            return 'Unused';
        }
    });

    const updateOptions = (term: string) => {
        if (!loading) {
            apiRef.current.setEditCellValue({
                id: id,
                field: field,
                value: term
            });
            setLoading(true);
            setOptions([]);
            let queryTerm = term;
            const queryFilter = cloneDeep(filter);
            if (getNonFuzzyQueryParams && typeof getNonFuzzyQueryParams === 'function') {
                const query = getNonFuzzyQueryParams(term);
                if (!isEmpty(query)) {
                    queryTerm = '';
                    queryFilter.expressions.push({ field: query.field, value: { values: [query.value] } });
                }
            }
            const entity = find(filters, { identifier: 'entity' })['value']; // TODO type this
            const variables = {
                entity: entity,
                query: queryTerm,
                page: { from: 0, size: 50 },
                tokenize: true,
                sortBy: undefined as string,
                filter: queryFilter
            };
            if (sortBy) {
                variables.sortBy = sortBy + '_' + (isDesc ? 'DESC' : 'ASC');
            }
            searchClient
                .query({
                    query: slimSearchQuery(entity),
                    variables: variables,
                    context: {
                        headers: {
                            ownerId: activeOrganizationAccount
                        }
                    },
                    fetchPolicy: constants.apolloFetchPolicy
                })
                .then((result) => {
                    const options = result.data?.search.hits.items.map((item, index) => toOption(item, index));
                    const createOption = toOption({ name: `Create ${term}` });
                    createOption.createOption = true;
                    const isExisting = options.some((option) => Object.values(option).includes(term));
                    if (term && autoCreate && !isExisting) {
                        options.push(createOption);
                    }
                    setOptions(options);
                    if (afterSearch) {
                        const currentEditRows = apiRef.current.state.editRows;
                        const errorMessage = afterSearch(result.data?.search.hits.items, term, currentEditRows, id);
                        setErrorMessage(errorMessage);
                    }
                })
                .catch((err) => {
                    console.error('Update options failed:', err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const debouncedUpdateOptions = useCallback(debounce((term: string) => updateOptions(term), 500) as Function, []);

    useEffect(() => {
        debouncedUpdateOptions(inputValue);
    }, [inputValue]);

    const setField = (option) => {
        const updateArr = entityToUpdate(columns, field, option);
        updateArr.forEach((update) => {
            apiRef.current.setEditCellValue({
                id: id,
                field: update.field,
                value: update.value
            });
        });
    };

    const makeAutoCreateCall = () => {
        const entity = find(filters, { identifier: 'entity' })['value']; // TODO type this
        onAutoCreate(inputValue, entity)
            .then((result) => {
                const autoCreatedOption = toOption(result);
                const isExisting = options.some((option) => Object.values(option).includes(inputValue));
                const updatedOptions = isExisting && options.filter((option) => !option.createOption);
                let newOptions = [];
                if (updatedOptions) {
                    newOptions = [...updatedOptions, autoCreatedOption];
                } else {
                    newOptions = [autoCreatedOption];
                }
                newOptions.sort();
                setOptions(newOptions);
                setField(autoCreatedOption);
            })
            .catch((err) => console.log(err));
    };

    const updateField = (option) => () => {
        if (option.createOption) {
            makeAutoCreateCall();
        } else {
            setField(option);
        }
        if (afterSearch) {
            const currentEditRows = apiRef.current.state.editRows;
            const errorMessage = afterSearch(options, inputValue, currentEditRows, id);
            setErrorMessage(errorMessage);
        }
    };

    useEffect(() => {
        if (loading) {
            setErrorMessage('');
        }
    }, [loading]);

    return (
        <div style={{ width: '100%', height: '100%' }} ref={setReferenceElement} {...getRootProps()}>
            <StyledTooltip open={!focused && !isEmpty(errorMessage)} title={errorMessage}>
                <input
                    {...getInputProps()}
                    css={css`
                        height: 100%;
                        width: 100%;
                        border: none;
                        padding-left: 8px;
                        padding-right: 8px;
                        font-size: inherit;
                        background-color: transparent;
                        // background-color: ${theme.palette.background.paper};
                        color: ${theme.palette.text.primary};
                    `}
                    className={!focused && !isEmpty(errorMessage) ? 'Mui-error' : ''}
                    value={inputValue || ''}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                />
            </StyledTooltip>
            {focused ? (
                createPortal(
                    <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 8000 }} {...attributes.popper}>
                        <Paper
                            sx={{
                                marginTop: '4px',
                                width: '200px',
                                position: 'absolute',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                minHeight: '36px',
                                maxHeight: loading ? '100px' : '200px',
                                display: 'flex',
                                alignItems: loading ? 'center' : 'flex-start'
                            }}
                        >
                            {loading ? (
                                <Loading size={'16px'} />
                            ) : options.length > 0 ? (
                                <List sx={{ height: '100%', width: '100%' }} {...getListboxProps()}>
                                    {options.map((option, index) => (
                                        <ListItem sx={{ width: '100%' }} disablePadding {...getOptionProps({ option, index })} key={index} onClick={updateField(option)}>
                                            <ListItemButton sx={{ width: '100%' }}>
                                                <OptionRenderer option={option} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <React.Fragment />
                            )}
                        </Paper>
                    </div>,
                    document.querySelector('#popper')
                )
            ) : (
                <React.Fragment />
            )}
        </div>
    );
};
