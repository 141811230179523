import React from 'react';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';

export default () => {
    return (
        <FormPanel>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="status" component="MetadataAutocomplete" label="Autocomplete" metadataName="OpportunityStatus" />
                </FormItem>
                <FormItem half>
                    <FormWidget multiple name="statusMulti" component="MetadataAutocomplete" label="Autocomplete multi" metadataName="OpportunityStatus" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget showRadio name="statusRadio" label="Radio" component="MetadataAutocomplete" metadataName="OpportunityStatus" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget showChips name="statusChips" label="Chips" component="MetadataAutocomplete" metadataName="OpportunityStatus" />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
