import { Alert, Button, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FormContainer } from '../common/form/FormContainer';
import { FormContext } from '../common/form/FormContext';
import FormItem from '../common/form/FormItem';
import FormPanel from '../common/form/FormPanel';
import FormWidget from '../common/form/FormWidget';
import { UserContext } from '../common/auth/UserContext';
import { useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { constants } from '../common/constants';
import { H, V } from '../common/Layout';
import { useStyling } from '../common/Theme';
import ParentOrganizationRenderer from '../organization/OrganizationOptionRenderer';
import { autoCreateQuery, client } from '../organization/organization';
import { getOption, quickAddOrganization } from '../organization/QuickAddOrganization';
import { ProductionOptionRenderer } from '../supply/booking/ProductionOptionRenderer';
import { getOption as getProductionOption, quickAddProduction } from '../production/helpers/QuickAddProduction';
import {
    autoCreateQuery as autoCreateProductionQuery,
    client as productionClient
} from '../production/helpers/production';
import useHistory from '../common/utils/useHistory';
import { useMutationWithContext } from '../common/hooks/useMutationWithContext';
import { searchClient, slimResultsQuery } from '../common/list/slimQuery';
import { Reference } from 'sr-types/lib/production/v1/graphql';
import I18n from '../common/i18n/I18n';

const EMPTY_CONTACT = {
    Name: '',
    Organization: '',
    Role: '',
    Production: '',
    ProductionRole: ''
};

export type DisabledFieldsType = {
    creativeCredits?: { department?: boolean; showReference?: boolean };
    productionRole?: boolean;
    organizationRole?: boolean;
    experiences?: { deparment?: boolean; title?: boolean; organizationReference?: boolean };
    contactInfo?: { phone?: boolean; email?: boolean };
};

interface GuestContactInfoProps {
    pageSize?: number;
    isInModal?: boolean;
    setIsSaveDisabled?: Dispatch<SetStateAction<boolean>>;
    productionRef?: Reference;
    disabledFields?: DisabledFieldsType;
}

export default ({
    pageSize = 10,
    isInModal = false,
    setIsSaveDisabled = undefined,
    productionRef = undefined,
    disabledFields = undefined
}: GuestContactInfoProps) => {
    const { theme } = useStyling();
    const { routeParams } = useHistory();
    const { entityId } = routeParams;
    const { validation, state, setState, handleChange } = useContext(FormContext);
    const { activeOrganizationAccount } = useContext(UserContext);
    const [contact, setContact] = useState<any>();
    const [updatedContact, setUpdatedContact] = useState(EMPTY_CONTACT);
    const isCreate = entityId === constants.createId;
    const [save, { loading: saveLoading }] = useMutationWithContext(autoCreateQuery, client);
    const [saveProduction, { loading: saveProductionLoading }] = useMutationWithContext(
        autoCreateProductionQuery,
        productionClient
    );
    const [findUserDetails, { loading, data }] = useLazyQuery(slimResultsQuery('Person'), {
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });

    useEffect(() => {
        if (data?.results?.hits?.items?.length > 0) {
            const { id } = data?.results?.hits?.items[0];
            if (id !== state?.reference?.id) {
                const { id, name, organization, role, production, productionRole } = data?.results?.hits?.items[0];
                setContact(data?.results?.hits?.items[0]);
                setState({ ...state, contactExists: true, entityId: id });
                setUpdatedContact({
                    Name: name,
                    Organization: organization,
                    Role: role,
                    Production: production,
                    ProductionRole: productionRole
                });
            }
        } else {
            setState({ ...state, contactExists: false });
        }
    }, [data]);

    const hasErrors = () => {
        const email = validation && validation?.errors?.contactInfo?.email;
        return email?.address && email.address.length > 0;
    };

    useEffect(() => {
        if (!hasErrors() && !isEmpty(state?.contactInfo?.email?.address)) {
            findUserDetails({
                variables: {
                    page: { from: 0, size: pageSize },
                    filters: [
                        { identifier: 'entity', value: 'Person' },
                        { identifier: 'email', value: state?.contactInfo?.email?.address }
                    ]
                }
            });
        }
    }, [state?.contactInfo?.email?.address]);

    const onClickUpdate = () => {
        const { name } = contact;
        const nameArray = name.split(' ');
        const newContact = {
            ...state,
            isUpdate: true,
            id: contact.id,
            name: {
                firstName: nameArray[0],
                lastName: nameArray[1]
            },
            contactInfo: contact.contactInfo,
            experiences: [
                {
                    experienceName: contact.organization || '',
                    professionalRoles: [contact.role] || []
                }
            ],
            creativeCredits: [
                {
                    showName: contact.production || '',
                    professionalRoles: [contact.productionRole] || []
                }
            ]
        };
        setState(newContact);
    };

    useEffect(() => {
        handleChange('isOrganizationLoading', saveLoading);
    }, [saveLoading]);

    useEffect(() => {
        handleChange('isProductionLoading', saveProductionLoading);
    }, [saveProductionLoading]);

    useEffect(() => {
        setIsSaveDisabled?.((validation && !validation.isValid) || saveLoading);
    }, [setIsSaveDisabled, validation, saveLoading]);
    return (
        <FormPanel>
            <FormContainer>
                <FormItem half={isInModal} quarter={!isInModal}>
                    <FormWidget name="name.firstName" label={<I18n token="guestEditor.label.firstName" />} />
                </FormItem>
                <FormItem half={isInModal} quarter={!isInModal}>
                    <FormWidget name="name.lastName" label={<I18n token="guestEditor.label.lastName" />} />
                </FormItem>
            </FormContainer>
            <FormContainer>
                {state?.contactExists && !state.isUpdate && (
                    <FormItem half={!isInModal}>
                        <Alert
                            severity="info"
                            sx={{ zIndex: '9999', width: '100%' }}
                            action={
                                isCreate && (
                                    <Button color="inherit" size="large" onClick={() => onClickUpdate()}>
                                        <I18n token="guestEditor.label.update" />
                                    </Button>
                                )
                            }
                        >
                            {updatedContact && (
                                <V>
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        <I18n token="guestEditor.emailValidation.matching" />
                                    </Typography>
                                    <Typography>
                                        <I18n token="guestEditor.emailValidation.sameEmail" />
                                    </Typography>
                                    {Object.keys(updatedContact).map((key, index) => (
                                        <H key={index}>
                                            <Typography color={theme.palette.text.disabled}>{`${key} : `}</Typography>{' '}
                                            <Typography>{` ${updatedContact[key]} `}</Typography>
                                        </H>
                                    ))}
                                </V>
                            )}
                        </Alert>
                    </FormItem>
                )}
            </FormContainer>
            {disabledFields?.contactInfo?.email ? (
                <></>
            ) : (
                <FormContainer>
                    <FormItem half={!isInModal}>
                        <FormWidget
                            name="contactInfo.email.address"
                            label={<I18n token="guestEditor.label.email" />}
                            hasErrors={hasErrors()}
                        />
                    </FormItem>
                </FormContainer>
            )}
            {disabledFields?.contactInfo?.email ? (
                <></>
            ) : (
                <FormContainer>
                    <FormItem half={!isInModal}>
                        <FormWidget
                            component="PhoneNumber"
                            name="contactInfo.phone.number"
                            label={<I18n token="guestEditor.label.phone" />}
                        />
                    </FormItem>
                    <FormItem children={null} />
                </FormContainer>
            )}
            {disabledFields?.experiences?.organizationReference ? (
                <></>
            ) : (
                <FormContainer>
                    <FormItem half={!isInModal}>
                        <FormWidget
                            component="ReferenceAutocomplete"
                            name="experiences[0].organizationReference"
                            label={<I18n token="guestEditor.label.organization" />}
                            entity="Organization"
                            OptionRenderer={ParentOrganizationRenderer}
                            getOption={getOption}
                            addOption
                            onCreateHandle={quickAddOrganization}
                            saveMutation={save}
                            preload={false}
                            filter={{ expressions: [{ field: 'inactive', value: { values: ['false'] } }] }}
                        />
                    </FormItem>
                </FormContainer>
            )}
            {disabledFields?.experiences?.title && disabledFields?.experiences?.title ? (
                <></>
            ) : disabledFields?.experiences?.title ? (
                <></>
            ) : (
                <FormContainer>
                    <FormItem half={isInModal} quarter={!isInModal}>
                        <FormWidget
                            name="experiences[0].title"
                            component="MetadataAutocomplete"
                            metadataName="Title"
                            label={<I18n token="guestEditor.label.title" />}
                            allowCreate
                        />
                    </FormItem>
                    {disabledFields?.experiences?.deparment ? (
                        <></>
                    ) : (
                        <FormItem half={isInModal} quarter={!isInModal}>
                            <FormWidget
                                name="experiences[0].department"
                                component="MetadataAutocomplete"
                                metadataName="Department"
                                label={<I18n token="guestEditor.label.department" />}
                                allowCreate
                            />
                        </FormItem>
                    )}
                </FormContainer>
            )}
            {disabledFields?.organizationRole ? (
                <></>
            ) : (
                <FormContainer>
                    <FormItem half={isInModal} quarter={!isInModal}>
                        <FormWidget
                            component="MetadataAutocomplete"
                            name="organizationRole"
                            label={<I18n token="guestEditor.label.organizationRole" />}
                            metadataName="Role"
                            allowCreate
                        />
                    </FormItem>
                </FormContainer>
            )}
            {disabledFields?.creativeCredits ? (
                <></>
            ) : (
                !(productionRef && Object.keys(productionRef).length) && (
                    <FormContainer>
                        <FormItem half={isInModal} quarter={!isInModal}>
                            <FormWidget
                                name="creativeCredits[0].showReference"
                                addOption
                                component="ReferenceAutocomplete"
                                entity="Production"
                                label={<I18n token="guestEditor.label.production" />}
                                OptionRenderer={ProductionOptionRenderer}
                                onCreateHandle={quickAddProduction}
                                getOption={getProductionOption}
                                saveMutation={saveProduction}
                            />
                        </FormItem>
                    </FormContainer>
                )
            )}

            {disabledFields?.productionRole && disabledFields?.creativeCredits?.department ? (
                <></>
            ) : (
                <FormContainer>
                    {disabledFields?.productionRole ? (
                        <></>
                    ) : (
                        <FormItem half={isInModal} quarter={!isInModal}>
                            <FormWidget
                                component="MetadataAutocomplete"
                                name="productionRole"
                                label={<I18n token="guestEditor.label.productionRole" />}
                                metadataName="Role"
                                allowCreate
                            />
                        </FormItem>
                    )}
                    {disabledFields?.creativeCredits?.department ? (
                        <></>
                    ) : (
                        <FormItem half={isInModal} quarter={!isInModal}>
                            <FormWidget
                                name="creativeCredits[0].department"
                                component="MetadataAutocomplete"
                                metadataName="Department"
                                label={<I18n token="guestEditor.label.productionDepartment" />}
                                allowCreate
                            />
                        </FormItem>
                    )}
                </FormContainer>
            )}
        </FormPanel>
    );
};
