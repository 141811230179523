import React from 'react';
import { createSvgIcon } from '@mui/material';

export const RequestSent = createSvgIcon(
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2048_10559)">
            <path
                d="M3.79102 4.23481C3.79102 2.66893 5.26848 1.39954 7.09102 1.39954H21.491C23.3136 1.39954 24.791 2.66893 24.791 4.23481V17.0328H22.991V4.23481C22.991 3.52305 22.3194 3.2167 21.491 3.2167H7.09102C6.26259 3.2167 5.59102 3.52305 5.59102 4.23481V20.7309C5.59102 21.4427 6.26259 21.8446 7.09102 21.8446H17.1107V23.5662H7.09102C5.26848 23.5662 3.79102 22.2968 3.79102 20.7309V4.23481Z"
                fill="currentColor"
                fillOpacity="0.54"
            />
            <path
                d="M17.2077 8.98287C17.2077 10.587 15.8952 11.8995 14.291 11.8995C12.6868 11.8995 11.3743 10.587 11.3743 8.98287C11.3743 7.3787 12.6868 6.0662 14.291 6.0662C15.8952 6.0662 17.2077 7.3787 17.2077 8.98287Z"
                fill="currentColor"
                fillOpacity="0.54"
            />
            <path
                d="M18.3452 14.2037C19.4243 14.6704 20.1243 15.7204 20.1243 16.9016V17.7329H8.45768V16.9016C8.45768 15.7204 9.15768 14.6704 10.2368 14.2037C11.4764 13.6641 12.8473 13.3579 14.291 13.3579C15.7348 13.3579 17.1056 13.6641 18.3452 14.2037Z"
                fill="currentColor"
                fillOpacity="0.54"
            />
            <path
                d="M23.3853 17.7524C20.6805 17.7524 18.4902 19.9476 18.4902 22.6524C18.4902 25.3572 20.6805 27.5524 23.3853 27.5524C26.095 27.5524 28.2902 25.3572 28.2902 22.6524C28.2902 19.9476 26.095 17.7524 23.3853 17.7524ZM23.3902 26.3393C21.2244 26.3393 19.6864 24.8182 19.6864 22.6524C19.6864 20.4866 21.2244 18.9488 23.3902 18.9488C25.556 18.9488 27.096 20.4866 27.096 22.6524C27.096 24.8182 25.556 26.3393 23.3902 26.3393Z"
                fill="currentColor"
                fillOpacity="0.54"
            />
            <path
                d="M23.7425 19.7705H22.9043V23.1233L25.838 24.8835L26.2571 24.1962L23.7425 22.7042V19.7705Z"
                fill="currentColor"
                fillOpacity="0.54"
            />
        </g>
        <defs>
            <clipPath id="clip0_2048_10559">
                <rect width="28" height="28" fill="white" transform="translate(0.291016 0.232788)" />
            </clipPath>
        </defs>
    </svg>,
    'RequestSent'
);
