import React, { useContext, useEffect, useState } from 'react';
import FormWidget from '../../common/form/FormWidget';
import I18n from '../../common/i18n/I18n';
import { Button, Typography } from '@mui/material';
import { H } from '../../common/Layout';
import { useMutation } from '@apollo/client';
import { client, saveQuery } from '../../production/helpers/production';
import { UserContext } from '../../common/auth/UserContext';
import useHistory from '../../common/utils/useHistory';
import { toInput, useProductionDetailsData } from '../../production/helpers/productionApi';
import { FormContext, FormProvider } from '../../common/form/FormContext';
import { useStyling } from '../../common/Theme';
import { LoadingButton } from '@mui/lab';
import { alternateProductionNameValidations } from './inquiry/inquiry';

export default ({ toggleOpen, selectedValue, setSelectedValue, setProductionOptions }) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { routeParams } = useHistory();
    const { productionId } = routeParams;
    const { productionDetailsData } = useProductionDetailsData(productionId);
    const { theme } = useStyling();
    const { state, handleChange } = useContext(FormContext);
    const [alternateNameState, setAlternateNameState] = useState({
        alternateName: selectedValue,
        alternateLabel: undefined
    });
    const [inputState, setInputState] = useState(selectedValue || '');
    const [loading, setLoading] = useState(false);

    const [save] = useMutation(saveQuery, {
        client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    useEffect(() => {
        setSelectedValue(alternateNameState?.alternateName);
    }, [alternateNameState?.alternateName]);

    const handleConfirm = () => {
        const newTitle = {
            key: alternateNameState.alternateLabel,
            value: selectedValue
        };

        setProductionOptions((previous) => [
            ...previous,
            { key: alternateNameState.alternateLabel, label: alternateNameState.alternateName }
        ]);

        const updatedOptions = [...state.productionOptions, newTitle];
        handleChange('productionOptions', { updatedOptions });

        if (productionDetailsData) {
            const productionDetails = toInput(productionId, productionDetailsData.production);
            const otherTitles = [];
            if (productionDetailsData.production.otherTitles?.length) {
                otherTitles.push(...productionDetailsData.production.otherTitles);
            }
            otherTitles.push(newTitle);
            save({
                variables: {
                    input: {
                        ...productionDetails,
                        otherTitles
                    }
                }
            }).then(() => {
                toggleOpen(false);
                setLoading(false);
                handleChange('production.name', selectedValue);
            });
        }
    };

    return (
        <H
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                border: `1px solid ${theme.palette.focus.dark}`,
                borderRadius: '12px',
                backgroundColor: theme.palette.focus.main,
                padding: 2,
                '& .MuiInputBase-root': {
                    backgroundColor: theme.palette.background.paper
                }
            }}
        >
            <Typography fontWeight={'bold'} textAlign={'start'} width={'100%'}>
                <I18n token="add.alternate.name" />
            </Typography>

            <H sx={{ width: '100%', gap: 2, marginTop: 2 }}>
                <FormProvider
                    state={alternateNameState}
                    setState={setAlternateNameState}
                    validationRules={alternateProductionNameValidations}
                >
                    <FormWidget
                        component="Autocomplete"
                        allowFreeForm
                        value={inputState || alternateNameState}
                        onChange={(name, value: string) => setInputState(value)}
                        name="alternateName"
                        label={<I18n token="form.name" />}
                    />

                    <FormWidget
                        sx={{
                            '& input': {
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: '12px'
                            }
                        }}
                        name="alternateLabel"
                        label={<I18n token="form.label" />}
                    />
                </FormProvider>
            </H>

            <H sx={{ gap: 2, justifyContent: 'flex-end' }}>
                <Button
                    variant="text"
                    onClick={() => {
                        toggleOpen(false);
                    }}
                >
                    <I18n token={'dialog.cancel'} />
                </Button>

                <LoadingButton
                    onClick={() => {
                        setLoading(true);
                        handleConfirm();
                    }}
                    loading={loading}
                    disabled={!alternateNameState.alternateName || !alternateNameState.alternateLabel}
                    variant={'contained'}
                >
                    <I18n token={'form.button.save.and.add'} />
                </LoadingButton>
            </H>
        </H>
    );
};
