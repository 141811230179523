import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    LexicalTypeaheadMenuPlugin,
    QueryMatch,
    TypeaheadOption,
    useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { Menu, MenuItem } from '@mui/material';
import { TextNode } from 'lexical';
import * as React from 'react';
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../common/auth/UserContext';
import { constants } from '../../../common/constants';
import { OptionRendererProps } from '../../../common/form/widgets/OptionRendererProps';
import { searchClient, slimResultsQuery } from '../../../common/list/slimQuery';
import { $createMentionsNode } from './MentionsNode';

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const DocumentMentionsRegex = {
    NAME,
    PUNCTUATION
};

const CapitalizedNameMentionsRegex = new RegExp('(^|[^#])((?:' + DocumentMentionsRegex.NAME + '{' + 1 + ',})$)');

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['@'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNC + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by a valid char.
const VALID_JOINS =
    '(?:' +
    '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
    ' |' + // E.g. " " in "Josh Duck"
    '[' +
    PUNC +
    ']|' + // E.g. "-' in "Salier-Hellendag"
    ')';

const LENGTH_LIMIT = 75;

const getTriggerRegex = (trigger) => {
    return new RegExp(
        '(^|\\s|\\()(' + '[' + trigger + ']' + '((?:' + VALID_CHARS + VALID_JOINS + '){0,' + LENGTH_LIMIT + '})' + ')$'
    );
};

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
    '(^|\\s|\\()(' + '[' + TRIGGERS + ']' + '((?:' + VALID_CHARS + '){0,' + ALIAS_LENGTH_LIMIT + '})' + ')$'
);

const mentionsCache = new Map();

function search<T>(
    string: string,
    activeOrganizationAccount: string,
    entity,
    rowToOption,
    callback: (results: MentionOption<T>[]) => void
): void {
    searchClient
        .query({
            query: slimResultsQuery(entity),
            variables: {
                query: string,
                page: { from: 0, size: 50 },
                filters: [{ identifier: 'entity', value: entity }],
                tokenize: true
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            },
            fetchPolicy: constants.apolloFetchPolicy
        })
        .then((result) => {
            callback(result.data?.results.hits.items.map(rowToOption));
        })
        .catch((err) => {
            console.error('Error in entity lookup:', err);
        });
}

function useMentionLookupService<T>(
    mentionString: string | null,
    entity: string,
    rowToOption: (row, index: number) => MentionOption<T>
) {
    const [results, setResults] = useState<Array<MentionOption<T>>>([]);
    const { activeOrganizationAccount } = useContext(UserContext);

    useEffect(() => {
        const cachedResults = mentionsCache.get(mentionString);

        if (mentionString == null || mentionString.length === 0) {
            setResults([]);
            mentionsCache.clear();
            return;
        }

        if (cachedResults === null) {
            return;
        } else if (cachedResults !== undefined) {
            setResults(cachedResults);
            return;
        }

        mentionsCache.set(mentionString, null);
        setResults([]);
        search<T>(mentionString, activeOrganizationAccount, entity, rowToOption, (newResults) => {
            mentionsCache.set(mentionString, newResults);
            setResults(newResults);
        });
    }, [mentionString]);

    return results;
}

const checkForCapitalizedNameMentions = (text: string, minMatchLength: number): QueryMatch | null => {
    const match = CapitalizedNameMentionsRegex.exec(text);
    if (match !== null) {
        // The strategy ignores leading whitespace but we need to know it's
        // length to add it to the leadOffset
        const maybeLeadingWhitespace = match[1];

        const matchingString = match[2];
        if (matchingString != null && matchingString.length >= minMatchLength) {
            return {
                leadOffset: match.index + maybeLeadingWhitespace.length,
                matchingString,
                replaceableString: matchingString
            };
        }
    }
    return null;
};

const checkForTriggerChar = (trigger: string, text: string, minMatchLength: number): QueryMatch | null => {
    let match = getTriggerRegex(trigger).exec(text);

    if (match === null) {
        match = AtSignMentionsRegexAliasRegex.exec(text);
    }
    if (match !== null) {
        // The strategy ignores leading whitespace but we need to know it's
        // length to add it to the leadOffset
        const maybeLeadingWhitespace = match[1];

        const matchingString = match[3];
        if (matchingString.length >= minMatchLength) {
            return {
                leadOffset: match.index + maybeLeadingWhitespace.length,
                matchingString,
                replaceableString: match[2]
            };
        }
    }
    return null;
};

export class MentionOption<T> extends TypeaheadOption {
    id: string;
    label: string;
    desc: string;
    entity: T;
    picture: ReactElement;

    constructor(id: string, label: string, desc: string, entity: T, picture: ReactElement) {
        super(id);
        this.id = id;
        this.label = label;
        this.desc = desc;
        this.entity = entity;
        this.picture = picture;
    }
}

type MentionsPluginProps<T> = {
    id: string;
    trigger: string;
    entity: string;
    rowToOption: (row, index: number) => MentionOption<T>;
    OptionRenderer: (props: OptionRendererProps<T>) => ReactElement;
};

export default function MentionsPlugin<T>(props: MentionsPluginProps<T>): ReactElement {
    const { id, trigger, entity, rowToOption, OptionRenderer } = props;
    const [editor] = useLexicalComposerContext();
    const [queryString, setQueryString] = useState<string | null>(null);
    const results: MentionOption<T>[] = useMentionLookupService(queryString, entity, rowToOption);

    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
        minLength: 0
    });

    const onSelectOption = useCallback(
        (selectedOption: MentionOption<T>, nodeToReplace: TextNode | null, closeMenu: () => void) => {
            editor.update(() => {
                if (selectedOption) {
                    const mentionNode = $createMentionsNode(
                        trigger,
                        selectedOption.id,
                        selectedOption.label,
                        selectedOption.desc,
                        entity
                    );
                    if (nodeToReplace) {
                        nodeToReplace.replace(mentionNode);
                    }
                    mentionNode.select();
                }
                closeMenu();
            });
        },
        [editor]
    );

    return (
        <LexicalTypeaheadMenuPlugin<MentionOption<T>>
            onQueryChange={setQueryString}
            onSelectOption={onSelectOption}
            triggerFn={(text: string) => {
                return isMenuOpened && checkForTriggerChar(trigger, text, 1);
            }}
            onOpen={() => {
                setIsMenuOpened(true);
            }}
            options={results}
            menuRenderFn={(anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => (
                <Menu
                    id={`${id}-menu`}
                    sx={{ maxHeight: '480px', maxWidth: '320px' }}
                    anchorEl={anchorElementRef.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    open={anchorElementRef && results.length > 0}
                    onClose={() => {
                        selectOptionAndCleanUp(undefined);
                        setIsMenuOpened(false);
                    }}
                >
                    {results.map((option, i: number) => (
                        <MenuItem
                            key={i}
                            // selected={selectedIndex === i}
                            onClick={() => {
                                // setHighlightedIndex(i);
                                selectOptionAndCleanUp(option);
                            }}
                            // onMouseEnter={() => {
                            //     setHighlightedIndex(i);
                            // }}
                        >
                            <OptionRenderer option={option.entity} sx={{ width: '100%' }} />
                        </MenuItem>
                    ))}
                </Menu>
            )}
        />
    );
}
