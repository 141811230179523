import Dexie from 'dexie';

const db = new Dexie('Showrunnr');
db.version(1).stores({
    schedulerStores: '++id,name'
});

db.open();

export default db;
