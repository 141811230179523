import React, { useEffect, useState } from 'react';
import { constants } from '../constants';
import { Autocomplete, FormControl, FormHelperText, ListItemText, TextField } from '@mui/material';
import { find } from 'lodash';
import FormItem from '../form/FormItem';
import { useUnitsData } from '../../attributes/attributesApi';

export enum Measure {
    Time = 'Time',
    Area = 'Area',
    Length = 'Length',
    Volume = 'Volume',
    Power = 'Power'
}

export type Measurement = {
    value: number;
    unit: string;
};

const EMPTY_MEASUREMENT: Measurement = {
    value: undefined,
    unit: undefined
};

export default (props) => {
    const {
        measure = Measure.Time,
        allowedUnits,
        disabledUnits,
        name,
        label,
        unitLabel,
        value: measurement = EMPTY_MEASUREMENT as Measurement,
        valid,
        onChange,
        setDefaultToFirstValue = false,
        defaultUnit = undefined,
        disabled = false,
        unitsLoading = false,
        valueDisabled = false,
        invert = false,
        size = constants.defaultWidgetSize,
        variant = constants.defaultWidgetVariant,
        ...rest
    } = props;
    const [unitOptions, setUnitOptions] = useState([]);
    const [defaultUnitValue, setDefaultUnitValue] = useState(defaultUnit);
    const [unSupportedUnitValue, setUnSupportedUnitValue] = useState(undefined);
    const { unitsData: data, unitsLoading: loading } = useUnitsData(measure.toString());

    useEffect(() => {
        const allowed = allowedUnits ?? [];
        const restricted = disabledUnits ?? [];
        if (data && data.results.hits && data.results.hits.items) {
            const items = data.results.hits.items.filter((item) => (allowed.length === 0 || allowed.includes(item.name)) && !restricted.includes(item.name));
            const unitOpts = items.map((item, index) => {
                return { label: item.label, value: item.name };
            });
            if (items.length) {
                if (!measurement?.unit && setDefaultToFirstValue && !unitsLoading && !disabled) {
                    setDefaultUnitValue(unitOpts[0].value);
                    onChange(name, {
                        value: measurement?.value,
                        unit: measurement?.unit ?? unitOpts[0].value
                    });
                }
                setUnSupportedUnitValue(!unitOpts.map((u) => u.value).includes(measurement?.unit) ? measurement?.unit : undefined);
                setUnitOptions(unitOpts);
            }
        }
    }, [data, allowedUnits, unitsLoading]);

    useEffect(() => {
        if (disabled) {
            setDefaultUnitValue(undefined);
            if (measurement?.unit) {
                onChange(name, {
                    value: undefined,
                    unit: undefined
                });
            }
        }
    }, [disabled]);

    const valueErrs = valid.errors[`${name}.value`] && valid.errors[`${name}.value`].length ? valid.errors[`${name}.value`] : [];
    const unitErrs = valid.errors[`${name}.unit`] && valid.errors[`${name}.unit`].length ? valid.errors[`${name}.unit`] : [];
    const Quantity = (
        <FormItem half>
            <TextField
                disabled={valueDisabled || disabled}
                error={valueErrs.length > 0}
                helperText={Array.isArray(valueErrs) ? valueErrs.join(', ') : ' '}
                label={label}
                variant={variant}
                fullWidth
                size={size}
                id="quantity"
                name="quantity"
                value={measurement?.value || ''}
                onChange={(event) => {
                    onChange(name, {
                        value: event.target.value,
                        unit: measurement?.unit ?? defaultUnitValue
                    });
                }}
            />
        </FormItem>
    );

    const Unit = (
        <FormItem half>
            <FormControl disabled={disabled} error={unitErrs.length > 0} fullWidth>
                <Autocomplete
                    size={size}
                    id="unit"
                    fullWidth
                    loading={loading || unitsLoading}
                    options={unitOptions}
                    disableClearable
                    disabled={disabled}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={props.id}>
                            <ListItemText primary={option.label} />
                        </li>
                    )}
                    value={(measurement?.unit ?? defaultUnitValue) || null}
                    getOptionLabel={(option) => {
                        if (option) {
                            if (typeof option === 'object' && option.label) {
                                return option.label;
                            } else if (typeof option === 'string') {
                                const found = find(unitOptions, { value: option });
                                return found ? found.label : unSupportedUnitValue || '';
                            }
                        }
                        return '';
                    }}
                    isOptionEqualToValue={(option, selectedValue) => {
                        return (option && option.value === selectedValue) || unSupportedUnitValue;
                    }}
                    renderInput={(params) => (
                        <TextField
                            error={unitErrs.length > 0 || unSupportedUnitValue != undefined}
                            variant={variant}
                            size={size}
                            label={unitErrs.length > 0 ? unitErrs.join('') : unSupportedUnitValue ? 'Invalid Lease Unit' : unitLabel}
                            {...params}
                        />
                    )}
                    onChange={(event, selectedOption) => {
                        // @ts-ignore
                        onChange(name, { value: measurement?.value, unit: selectedOption.value });
                        // @ts-ignore
                        setUnSupportedUnitValue(!unitOptions.map((u) => u.value).includes(selectedOption.value) ? selectedOption.value : undefined);
                    }}
                />
                <FormHelperText id={`unit-helper-text`}>{Array.isArray(unitErrs) ? unitErrs.join(', ') : ''}</FormHelperText>
            </FormControl>
        </FormItem>
    );
    return invert ? (
        <React.Fragment>
            {Unit}
            {Quantity}
        </React.Fragment>
    ) : (
        <React.Fragment>
            {Quantity}
            {Unit}
        </React.Fragment>
    );
};
