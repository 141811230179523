import React from 'react';
import { H } from '../../../common/Layout';
import { ToggleButton, Tooltip } from '@mui/material';
import StyledToggleButtonGroup from './StyledToggleButtonGroup';
import I18n from '../../../common/i18n/I18n';
import CircularProgress from '@mui/material/CircularProgress';
import { Icons } from '../../../common/icons/Icons';

const SaveCancelDeleteMenuItem = ({
    isSaveDisabled,
    isSaving,
    onSave,
    isDeleteDisabled,
    isDeleting,
    onDelete,
    onCancel
}) => {
    const buttons = [];
    if (typeof onSave === 'function') {
        const saveDisabled = isSaveDisabled || isSaving || isDeleting;
        buttons.push(
            <Tooltip key={'save'} title={<I18n token={isSaveDisabled ? 'notes.empty.error' : 'notes.save'} />}>
                <ToggleButton
                    sx={{ cursor: saveDisabled ? 'not-allowed' : 'pointer' }}
                    value="save"
                    aria-label="save"
                    onClick={() => {
                        if (!saveDisabled) {
                            onSave();
                        }
                    }}
                >
                    {isSaving ? (
                        <CircularProgress size={12} />
                    ) : (
                        <Icons.Done color={saveDisabled ? 'disabled' : 'success'} fontSize={'small'} />
                    )}
                </ToggleButton>
            </Tooltip>
        );
    }
    if (typeof onCancel === 'function') {
        const cancelDisabled = isSaving || isDeleting;
        buttons.push(
            <Tooltip key={'cancel'} title={<I18n token={'notes.cancel.editing'} />}>
                <ToggleButton
                    sx={{ cursor: cancelDisabled ? 'not-allowed' : 'pointer' }}
                    value="cancel"
                    aria-label="cancel"
                    onClick={() => {
                        if (!cancelDisabled) {
                            onCancel();
                        }
                    }}
                >
                    <Icons.Close fontSize={'small'} />
                </ToggleButton>
            </Tooltip>
        );
    }
    if (typeof onDelete === 'function') {
        const deleteDisabled = isDeleteDisabled || isSaving || isDeleting;
        buttons.push(
            <Tooltip
                key={'delete'}
                title={<I18n token={isDeleteDisabled ? 'notes.delete.disabled' : 'notes.delete'} />}
            >
                <ToggleButton
                    sx={{ cursor: deleteDisabled ? 'not-allowed' : 'pointer' }}
                    value="delete"
                    aria-label="delete"
                    onClick={() => {
                        if (!deleteDisabled) {
                            onDelete();
                        }
                    }}
                >
                    {isDeleting ? <CircularProgress size={12} /> : <Icons.Delete fontSize={'small'} />}
                </ToggleButton>
            </Tooltip>
        );
    }
    return (
        <StyledToggleButtonGroup exclusive size={'small'} sx={{ mr: 1 }}>
            {buttons}
        </StyledToggleButtonGroup>
    );
};

export default ({ isSaveDisabled, isSaving, onSave, isDeleteDisabled, isDeleting, onDelete, onCancel }) => {
    return (
        <H passthroughProps={{ breakpoint: 600 }}>
            <SaveCancelDeleteMenuItem
                isSaveDisabled={isSaveDisabled}
                isSaving={isSaving}
                onSave={onSave}
                onDelete={onDelete}
                isDeleteDisabled={isDeleteDisabled}
                isDeleting={isDeleting}
                onCancel={onCancel}
            />
        </H>
    );
};
