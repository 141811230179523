import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, ListItemText, MenuItem, TextField } from '@mui/material';
import { format, isValid, parse } from 'date-fns';
import { API_TIME_FORMAT, DISPLAY_TIME_FORMAT, isAMPM } from '../utils/dateTime';
import Menu from '@mui/material/Menu';
import { constants } from '../constants';
import { Icons } from '../icons/Icons';

type TimeInputProps = {
    required?: boolean;
    disabled?: boolean;
    variant?: 'standard' | 'filled' | 'outlined';
    autoFocus?: boolean;
    step?: 'HOUR' | 'HALF' | 'QUARTER';
    label?: string;
    placeholder?: string;
    value?: string;
    onChange: (value) => void;
    error?: string;
    size?: 'small' | 'medium';
};

// TODO Not i18n!
const generateOptions = (start, step, suffix) => {
    const ret = [];
    for (let i = start; i < start + 12; i++) {
        const h = isAMPM && i === 0 ? '12' : i.toString();
        ret.push(h + ':00' + suffix);
        if (step === 'HALF') {
            ret.push(h + ':30' + suffix);
        } else if (step === 'QUARTER') {
            ret.push(h + ':15' + suffix);
            ret.push(h + ':30' + suffix);
            ret.push(h + ':45' + suffix);
        }
    }
    return ret;
};

const parseFormats = ['h:ma', 'ha', 'hma', 'HH:m', 'Hmm', 'HH'];
const fromDisplay = (str) => {
    let clean = str.replace(/\s/g, '');

    // If term is a 3 digit number prepend a zero to avoid ambiguities.
    if (clean.match(/^\d{3}$/)) {
        clean = '0' + clean;
    }

    for (let i = 0; i < parseFormats.length; i++) {
        const f = parseFormats[i];
        const date = parse(clean, f, new Date());
        // Return first format that produces valid string.
        if (isValid(date)) {
            console.log('Date', str, 'in pattern', f);
            return format(date, API_TIME_FORMAT);
        }
    }
    // If no formats work return the original string.
    return str;
};

const toDisplay = (str) => {
    const date = parse(str, API_TIME_FORMAT, new Date());
    const valid = isValid(date);
    return [valid, valid ? format(date, DISPLAY_TIME_FORMAT) : str];
};

export default (props: TimeInputProps) => {
    const [input, setInput] = useState<string>('');
    const {
        required,
        disabled,
        autoFocus,
        step = 'HALF',
        label,
        value = undefined,
        onChange,
        placeholder,
        error,
        variant = constants.defaultWidgetVariant,
        size = constants.defaultWidgetSize,
        ...rest
    } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuOpened, setMenuOpened] = useState(false);

    // TODO Temp
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (value && value.length) {
            const [valid, display] = toDisplay(value);
            setInput(display);
            setHasError(!valid);
        } else {
            setInput('');
            setHasError(false);
        }
    }, [value]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpened(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuOpened(false);
    };

    const times = useMemo(() => {
        return isAMPM
            ? generateOptions(0, step, ' AM').concat(generateOptions(0, step, ' PM'))
            : generateOptions(0, step, '').concat(generateOptions(12, step, ''));
    }, []);

    return (
        <>
            <TextField
                className="_TimeInput"
                fullWidth
                size={size}
                required={required}
                disabled={disabled}
                variant={variant}
                value={input}
                autoFocus={autoFocus}
                hiddenLabel={!label}
                label={label}
                error={hasError}
                helperText={hasError ? 'Invalid time' : ' '}
                placeholder={placeholder}
                onFocus={(e) => {
                    e.target.select();
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton disabled={disabled} size={'small'} onClick={handleMenuOpen}>
                            <Icons.AccessTime fontSize={'small'} />
                        </IconButton>
                    )
                }}
                onChange={(e) => {
                    setInput(e.target.value);
                }}
                onBlur={(e) => {
                    onChange(fromDisplay(e.target.value));
                }}
            />

            {menuOpened ? (
                <Menu
                    sx={{ maxHeight: '200px' }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    keepMounted={false}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    open={menuOpened}
                    onClose={handleMenuClose}
                >
                    {times.map((time, index) => (
                        <MenuItem
                            key={`time-${index}`}
                            onClick={() => {
                                handleMenuClose();
                                onChange(fromDisplay(time));
                            }}
                        >
                            <ListItemText>{time}</ListItemText>
                        </MenuItem>
                    ))}
                </Menu>
            ) : (
                <React.Fragment />
            )}
        </>
    );
};
