import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useRouteError } from 'react-router-dom';
import InquiryResponse from '../../shortlists/InquiryResponse';
import { ErrorFallback } from '../ErrorBoundary';
import ConfirmPassword from '../auth/ConfirmPassword';
import CustomAuthentication from '../auth/CustomAuthentication';
import Logout from '../auth/Logout';
import PreLogin from '../auth/PreLogin';
import Signup from '../auth/Signup';
import { UserContext } from '../auth/UserContext';
import Verify from '../auth/Verify';
import VerifyUser from '../auth/VerifyUser';
import { Membership, UserProfile, fetchUserProfile, getNewToken, isTokenExpired } from '../auth/api';
import Loading from '../components/Loading';
import { log } from '../utils/commonUtils';
import useHistory, { ChangeRoute } from '../utils/useHistory';
import InvitationResponse from '../workflow/InvitationResponse';
import AppRoute, { RouteType } from './AppRoute';
import useDefaultRoute from './useDefaultRoute';
import { find } from 'lodash';
import { PROFILES } from './apps';

const ErrorBoundary = () => {
    const error = useRouteError();
    return <ErrorFallback error={error as Error} />;
};

const addRoutes = (allRoutes, children = undefined) => {
    let routesAndRedirects = [];
    (children || allRoutes).forEach((route: RouteType) => {
        if (route.path) {
            // If component is defined it will be rendererd, otherwise if redirect is defined we will redirect to it.
            if (route.component) {
                routesAndRedirects.push(
                    <Route
                        errorElement={<ErrorBoundary />}
                        path={route.path}
                        element={<AppRoute routes={allRoutes} {...route} />}
                    />
                );
            } else if (route.redirect) {
                routesAndRedirects.push(<Route path={route.path} element={<ChangeRoute path={route.redirect} />} />);
            }
        }
        if (route.items) {
            const childrenRoutes = addRoutes(allRoutes, route.items);
            routesAndRedirects = routesAndRedirects.concat(childrenRoutes);
        }
    });
    return routesAndRedirects;
};

const getDefaultOrgId = (userProfile: UserProfile) => {
    // User not sunscribed to any accounts
    if (userProfile.memberships?.length === 1 && userProfile.memberships[0].personal === true) {
        return userProfile.memberships[0].id;
    } else {
        // Find membership by id or first non-personal one.
        const membership = find(userProfile.memberships, (m: Membership) => {
            return !m.personal;
        });
        return membership?.id;
    }
};

const DefaultRoute = () => {
    const { isAuthenticated, userProfile } = useContext(UserContext);
    const { searchParams } = useHistory();
    const orgId = searchParams.get('orgId');
    const { url } = useDefaultRoute();

    if (!isAuthenticated) {
        log('DefaultRoute: User not authenticated, redirecting to /login');
        return <ChangeRoute path={'/login'} />;
    } else if (isAuthenticated && userProfile && !orgId) {
        log('DefaultRoute: User profile present, redirecting to default url', url);
        return <ChangeRoute path={url} />;
    } else if (!userProfile) {
        log('DefaultRoute: User profile missing, redirecting to /login');
        return <ChangeRoute path={'/login'} />;
    } else if (!orgId) {
        log('DefaultRoute: orgId missing in the url, redirecting to /login');
        return <ChangeRoute path={'/login'} />;
    } else if (!url) {
        log('DefaultRoute: Failed to find default route, redirecting to /login');
        return <ChangeRoute path={'/login'} />;
    } else {
        log('DefaultRoute: User profile present, redirecting to default url', url);
        return <ChangeRoute path={url} />;
    }
};

export default ({ routes }) => {
    const [refreshDone, setRefreshDone] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setIsAuthenticated, setActiveOrganizationAccount, setUserProfile } = useContext(UserContext);

    /**
     * Fetch user profile and set it in UserContext, also set the activeOrganizationAccount
     * to personal account. If the user is authenticated redirect to profile
     * page.
     */
    const getUserProfile = () => {
        fetchUserProfile('', (data) => {
            setUserProfile(data);
            setActiveOrganizationAccount(data.memberships[0].id, true);
        }).then(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (!refreshDone) {
            getNewToken()
                .then(() => {
                    const expired = isTokenExpired();
                    log('AppRouter: Token refresh done, is token expired?', expired);
                    if (!expired) {
                        setIsLoading(true);
                        getUserProfile();
                    }
                })
                .finally(() => {
                    const expired = isTokenExpired();
                    setRefreshDone(true);
                    setIsAuthenticated(!expired);
                });
        }
    }, []);

    const router = useMemo(() => {
        const r = [
            <Route path={`/login`} element={<PreLogin />} />,
            <Route path={`/assessavails`} element={<InquiryResponse />} />,
            <Route path={`/logout`} element={<Logout />} />,
            <Route path={`/signup`} element={<Signup />} />,
            <Route path={`/verify`} element={<Verify />} />,
            <Route path={`/invitations/:invitationId`} element={<InvitationResponse />} />,
            <Route path={`/password/recovery`} element={<VerifyUser />} />,
            <Route path={`/password/recovery/authentication`} element={<CustomAuthentication />} />,
            <Route path={`/password/recovery/confirm`} element={<ConfirmPassword />} />,
            ...addRoutes(routes),
            <Route path="*" element={<DefaultRoute />} />
        ];
        return createBrowserRouter(createRoutesFromElements(r));
    }, [routes]);

    if (isLoading) {
        return <Loading />;
    }
    return refreshDone ? <RouterProvider router={router} fallbackElement={<Loading />} /> : <Loading />;
};
