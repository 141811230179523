import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import { isWeekend } from 'date-fns';
import { Measurement } from './Measurement';
import { DateRange } from '../../utils/dateTime';
import FormItem from '../FormItem';
import FormWidget from '../FormWidget';
import { constants } from '../../constants';
import { FormContext } from '../FormContext';
import { useQuery } from '@apollo/client';
import { AutocompleteOption } from './OptionRendererProps';
import { getDecimalsForDurationUnit } from '../../../supply/booking/ScheduleCalculator';
import { searchClient, slimResultsQuery } from '../../list/slimQuery';

const showTimeForUnits = ['hour'];

export const DEFAULT_DURATION: Measurement = {
    unit: 'day',
    value: 1
};

export const EMPTY_SCHEDULE: DateRange = {
    dateTimeDuration: DEFAULT_DURATION,
    duration: '',
    durationSpecified: true,
    durationType: '',
    end: '',
    endTime: '',
    recurrenceRule: null,
    start: '',
    startTime: ''
};

type SchedulerProps = {
    name: string;
    allowedUnits?: string[];
    showFullSchedule?: boolean;
    isMilestone?: boolean;
    disableWeekends?: boolean;
    disabled?: boolean;
    prefix?: React.ReactElement;
    disableDates?: boolean;
    disableUnits?: boolean;
    locale?: string;
    showDateRange?: boolean;
    takeFullWidth?: boolean;
};

export default (props: SchedulerProps) => {
    const {
        name,
        allowedUnits,
        disableWeekends,
        showFullSchedule,
        disabled,
        prefix,
        disableDates,
        disableUnits,
        isMilestone = false,
        locale,
        showDateRange,
        takeFullWidth = false
    } = props;
    const [unitOptions, setUnitOptions] = useState([]);
    const { state, handleChange } = useContext(FormContext);
    const disabledUnits = ['second', 'minute'];
    const dateRange: DateRange = get(state, name);
    const durationSpecified = dateRange && dateRange.durationSpecified;
    const timeDisabled = !showTimeForUnits.includes(dateRange?.dateTimeDuration?.unit);

    const shouldDisableDate = (day: Date): boolean => {
        return disableWeekends ? isWeekend(day) : false;
    };

    const { data, loading } = useQuery(slimResultsQuery('Unit'), {
        client: searchClient,
        variables: {
            filters: [
                { identifier: 'entity', value: 'Unit' },
                { identifier: 'measure', value: 'Time' }
            ]
        },
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true,
        canonizeResults: true
    });

    useEffect(() => {
        if (data && data.results.hits && data.results.hits.items) {
            const unitName = `${name}.dateTimeDuration.unit`;
            const currentUnitType = get(state, unitName);
            let isCurrentValid = false;
            const allowed = allowedUnits ?? [];
            const restricted = disabledUnits ?? [];

            const items = data.results.hits.items.filter(
                (item) => (allowed.length === 0 || allowed.includes(item.name)) && !restricted.includes(item.name)
            );
            const unitOpts: Array<AutocompleteOption> = items.map((item) => {
                // If current unit type is found in allowed list we won't be setting a default.
                if (currentUnitType === item.name) {
                    isCurrentValid = true;
                }
                return { id: item.name, label: item.label };
            });
            setUnitOptions(unitOpts);

            if (unitOpts.length && !isCurrentValid) {
                handleChange(unitName, unitOpts[0].id);
            }
        }
    }, [data, allowedUnits]);

    const currentUnit = dateRange.dateTimeDuration?.unit;
    const decimals = getDecimalsForDurationUnit(currentUnit);
    const unitComponent = (
        <FormWidget
            disabled={disabled || disableUnits}
            label={'Unit'}
            name={`${name}.dateTimeDuration.unit`}
            component="Autocomplete"
            loading={loading}
            options={unitOptions}
            disableClearable
            locale={locale}
        />
    );
    const startDateComponent = (
        <FormWidget
            disabled={disabled || disableDates}
            component="Date"
            name={`${name}.start`}
            label="Start date"
            shouldDisableDate={shouldDisableDate}
            locale={locale}
        />
    );
    const startTimeComponent = (
        <FormWidget
            disabled={timeDisabled || disabled || disableDates}
            component="Time"
            name={`${name}.startTime`}
            label="Start time"
            locale={locale}
        />
    );
    const durationComponent = (
        <FormWidget
            disabled={!durationSpecified || disabled}
            component={'Number'}
            decimals={decimals}
            name={`${name}.dateTimeDuration.value`}
            label="Duration"
            forcePositive={true}
            locale={locale}
            useGrouping={false}
        />
    );
    const endDateComponent = (
        <FormWidget
            disabled={durationSpecified || disabled || disableDates}
            component="Date"
            name={`${name}.end`}
            label="End date"
            shouldDisableDate={shouldDisableDate}
            locale={locale}
        />
    );
    const endTimeComponent = (
        <FormWidget
            disabled={durationSpecified || timeDisabled || disabled || disableDates}
            component="Time"
            name={`${name}.endTime`}
            label="End time"
            locale={locale}
        />
    );

    /*
        dur=true
        unit, duration, startDate (2, 2, 2)
        dur=true, unit=hour
        unit, duration, startDate, startTime (2, 2, 2, 2)

        dur=false
        unit, startDate, endDate (2, 2, 3)
        dur=false, unit=hour
        unit, startDate, startTime, endDate, endTime (2, 2, 2, 2, 2)
    */
    const hasPrefix = !!prefix;
    return isMilestone ? (
        <FormItem span={12}>{startDateComponent}</FormItem>
    ) : showDateRange ? (
        takeFullWidth ? (
            <FormItem span={12} sx={{ width: '100%' }}>
                {startDateComponent}
                {endDateComponent}
            </FormItem>
        ) : (
            <>
                <FormItem span={6}>{startDateComponent}</FormItem>
                <FormItem span={6}>{endDateComponent}</FormItem>
            </>
        )
    ) : showFullSchedule ? (
        <>
            <FormItem span={6}>{durationComponent}</FormItem>
            <FormItem span={6}>{unitComponent}</FormItem>

            <FormItem span={6}>
                {startDateComponent}
                {timeDisabled ? <></> : startTimeComponent}
            </FormItem>

            <FormItem span={6}>
                {endDateComponent}
                {timeDisabled ? <></> : endTimeComponent}
            </FormItem>
        </>
    ) : (
        <>
            {prefix && <FormItem span={2}>{prefix}</FormItem>}
            <FormItem span={2}>{unitComponent}</FormItem>

            <FormItem span={hasPrefix ? 4 : 5}>
                {startDateComponent}
                {timeDisabled ? <></> : startTimeComponent}
            </FormItem>

            {durationSpecified ? (
                <FormItem span={2}>{durationComponent}</FormItem>
            ) : (
                <FormItem span={hasPrefix ? 4 : 5}>
                    {endDateComponent}
                    {timeDisabled ? <></> : endTimeComponent}
                </FormItem>
            )}
        </>
    );
};
