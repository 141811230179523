import React from 'react';
import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Icons } from '../icons/Icons';
import EllipsisText from '../components/EllipsisText';

export default ({ title, onClose, sx }) => {
    return (
        <Box
            className="form-modal-header handle"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: 2,
                flexGrow: 0,
                ...sx
            }}
        >
            {title ? (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 2 }}>
                    <EllipsisText sx={{ fontSize: 'large', textTransform: 'none' }} text={title} />
                </Box>
            ) : (
                <></>
            )}
            {typeof onClose === 'function' ? (
                <Box sx={{ flexGrow: 2, textAlign: 'end' }}>
                    <IconButton size="small" color="inherit" onClick={onClose}>
                        <Icons.Close />
                    </IconButton>
                </Box>
            ) : (
                <></>
            )}
        </Box>
    );
};
