import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import React from 'react';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';

export default () => {
    return (
        <FormPanel>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="noValidation" label="No validation" />
                </FormItem>
                <FormItem half>
                    <FormWidget name="isRequired" label="Is required" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="mustBeNumber" label="Must be number" />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        component="Autocomplete"
                        name="wrongValue"
                        label="Autocomplete"
                        options={[
                            { id: 'one', label: 'One' },
                            { id: 'wrong', label: 'Wrong value' },
                            { id: 'three', label: 'Three' }
                        ]}
                    />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
