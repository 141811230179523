import { useMutation, useQuery } from '@apollo/client';
import { cloneDeep, find, get, isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useFeaturePermissions, UserContext } from '../../common/auth/UserContext';
import { constants } from '../../common/constants';
import Validator, { ValidationState } from '../../common/form/Validator';
import { Button, Typography } from '@mui/material';
import { H, ScrollableLayout, V } from '../../common/Layout';
import { FormProvider } from '../../common/form/FormContext';
import FormSection from '../../common/form/FormSection';
import { enqueueSnackbar } from '../../common/components/Toast';
import I18n from '../../common/i18n/I18n';
import InfoEditor from './ProductionInfoEditor';
import AboutEditor from './ProductionAboutEditor';
import AltIdEditor from './ProductionAltIdEditor';
import {
    client,
    detailsQuery,
    preSaveQuery,
    ProductionAboutInfo,
    ProductionInfo,
    saveQuery,
    toAssociatedOrgs,
    toAssociatedPeople
} from '../helpers/production';
import { toYearFromISOString } from '../../common/utils/dateTime';
import Loading from '../../common/components/Loading';
import GuestList from '../../supply/booking/GuestList';
import { Identity } from '../../common/components/types';
import { columnVisibility, productionEditorColDefs } from '../helpers/ContactListUtils';
import useHistory from '../../common/utils/useHistory';
import WithSidePanel from '../../common/components/layout/WithSidePanel';
import BreadCrumbs from '../../common/components/BreadCrumbs';
import UserList from '../UserList';
import { AllPermissions } from '../../common/auth/api';
import { Production } from 'sr-types/lib/production/v1/graphql';
import { Icons } from '../../common/icons/Icons';
import { getL10nDef } from '../../common/i18n/localization';
import AssociatedOrganizationsEditor from './AssociatedOrganizationsEditor';

export const EMPTY_PROD_INFO: ProductionInfo = {
    name: '',
    aka: '',
    productionType: null,
    productionStatus: '',
    productionGroup: undefined,
    genre: [],
    managedProduction: false,
    keyPeople: []
};

export const EMPTY_ABOUT_INFO: ProductionAboutInfo = {
    summary: {},
    releaseDate: '',
    releaseYear: '',
    productionYears: '',
    showAttributes: [{ key: 'runtime', value: null }],
    locations: [],
    budget: {
        currencyCode: getL10nDef().defaultCurrency,
        units: 0
    }
};

export const EMPTY_ALT_IDS = {
    alternateIds: []
};
export const EMPTY_ASSOCIATED_PPL = {
    guests: []
};

export const EMPTY_ASSOCIATED_ORGS = {
    associatedOrganizations: []
};

export const EMPTY_PROJECT_TRACK = {
   // projectOwner : undefined, /* We may use it later on */
    projectStatus : ''
}

const Buttons = ({
    isSaving,
    valid,
    disableSave,
    onProductionSave
}: {
    isSaving: boolean;
    disableSave: boolean;
    valid: ValidationState;
    onProductionSave: () => void;
}) => {
    const { application } = useContext(UserContext);
    const { changeRoute } = useHistory();

    return (
        <H sx={{ gap: 1, justifyContent: 'flex-end' }}>
            <Button
                variant={'outlined'}
                disabled={isSaving}
                onClick={() => {
                    changeRoute(`${application.home}/production`);
                }}
            >
                <I18n token="dialog.cancel" />
            </Button>
            <Button
                variant={'contained'}
                disabled={isSaving || !valid.isValid || disableSave}
                onClick={() => {
                    onProductionSave();
                }}
            >
                <I18n token="dialog.save" />
            </Button>
        </H>
    );
};

export default () => {
    const { changeRoute, routeParams, location } = useHistory();
    const { entityId } = routeParams;
    const [values, setValues] = useState<Production>({
        headline: '',
        otherTitles: [],
        ...cloneDeep(EMPTY_PROD_INFO),
        ...cloneDeep(EMPTY_ABOUT_INFO),
        ...cloneDeep(EMPTY_ALT_IDS),
        ...cloneDeep(EMPTY_ASSOCIATED_PPL),
        ...cloneDeep(EMPTY_ASSOCIATED_ORGS)
    });
    const [validationRules, setValidationRules] = useState({});
    const [altIdFields, setAltIdFields] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [productionId, setProductionId] = useState();
    const { application, activeOrganizationAccount } = useContext(UserContext);
    const isCreate = entityId === constants.createId;
    const featurePermissions = useFeaturePermissions('Related Entities');
    const isReadOnly = get(location, 'state.isReadOnly', false);

    const [save] = useMutation(saveQuery, {
        client: client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });

    const [preSave] = useMutation(preSaveQuery, {
        client: client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });

    const { loading, data } = useQuery(detailsQuery, {
        client: client,
        variables: { id: entityId },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        skip: isCreate || !entityId,
        fetchPolicy: constants.apolloFetchPolicy
    });

    const [valid, setValid] = useState<ValidationState>({
        isValid: false,
        errors: {}
    });

    useEffect(() => {
        const aka = find(values['otherTitles'], { key: 'AKA' });
        const rules = {
            name: aka && aka.value != '' ? [] : [Validator.RULES.isRequired],
            aka: values.name != '' ? [] : [Validator.RULES.isRequired]
        };
        const altIdFields = [];
        values.alternateIds.forEach((id, index) => {
            const key = `alternateIds[${index}].url`;
            rules[key] = [Validator.RULES.url];
            altIdFields.push(key);
            setAltIdFields(altIdFields);
        });
        setValid((prevVal) => ({ ...prevVal, isValid: !(aka && aka.value == '' && values.name == '') }));
        setValidationRules(rules);
    }, [values]);

    useEffect(() => {
        if (isCreate) {
            preSave().then((res) => {
                setProductionId(res.data.preSaveProduction.id);
            });
        }
    }, []);

    useEffect(() => {
        if (data && data.production) {
            let showAttributes = {};
            data.production.showAttributes?.map((attr) => {
                showAttributes[attr.key] = attr.value;
            });
            let associatedOrganizations = [];
            data.production.associatedOrgs?.map((orgs) => {
                associatedOrganizations.push({
                    organizationReference: orgs.organizationReference,
                    organization: orgs.name,
                    organizationTypes: orgs.organizationTypes,
                    organizationCategories: orgs.organizationCategories
                });
            });
            setValues({
                ...data.production,
                releaseYear: data.production.releaseDate ? toYearFromISOString(data.production.releaseDate) : null,
                productionType: data.production.productionType || '',
                summary: {
                    about: data.production.about
                },
                showAttributes: showAttributes,
                productionGroup: {
                    label: data.production.productionGroup.label || '',
                    ...(data.production.productionGroup.productionReference || {})
                },
                alternateIds: data.production.alternateIds || [],
                genre: data.production.genre || [],
                productionStatus: data.production.productionStatus,
                guests: data.production.associatedPeople || [],
                associatedOrgs: associatedOrganizations,
                productionYears: data.production.productionYears
            });
        }
    }, [data]);

    const onProductionSave = () => {
        setIsSaving(true);
        let identity: Identity = {
            id: entityId
        };
        if (isCreate) {
            identity = {
                id: productionId,
                preSave: true
            };
        }
        onSave(identity);
    };

    const onSave = (identity) => {
        const name = values.name;
        const productionGroup = {
            label: values.productionGroup?.label,
            productionReference: values.productionGroup
        };
        const savedEntity = {
            identity: identity,
            publicTitle: values.name,
            showAttributes: Object.keys(values.showAttributes).map((key) => {
                if (values.showAttributes[key]) {
                    return {
                        key: key,
                        value: values.showAttributes[key].toString()
                    };
                }
            }),
            summary: {
                headline: values.headline || '',
                about: values.about || ''
            },
            productionType: values.productionType || '',
            productionGroup: productionGroup || {},
            managedProduction: values.managedProduction
        };
        if (values.releaseDate) {
            savedEntity['releaseDate'] = values.releaseDate;
        }
        if (values.productionYears) {
            savedEntity['productionYears'] = values.productionYears;
        }
        const aka = find(values['otherTitles'], { key: 'AKA' });
        if (values.otherTitles && values.otherTitles.length) {
            if (aka.value) {
                savedEntity['otherTitles'] = values.otherTitles;
            }
        }
        if (values.genre.length) {
            savedEntity['genre'] = values.genre;
        }
        if (values.productionStatus != '') {
            savedEntity['productionStatus'] = values.productionStatus;
        }
        if (values.locations && values.locations.length) {
            savedEntity['locations'] = values.locations;
        }
        if (!isEmpty(values.alternateIds)) {
            savedEntity['alternateIds'] = values.alternateIds;
        }
        if (values.associatedPeople && values.associatedPeople.length) {
            savedEntity['associatedPeople'] = toAssociatedPeople(values.associatedPeople, {
                id: identity.id,
                name: values.name || (aka && aka.value)
            });
        }
        if (values.associatedOrgs && values.associatedOrgs.length) {
            savedEntity['associatedOrgs'] = toAssociatedOrgs(values.associatedOrgs);
        }
        save({
            variables: {
                input: savedEntity
            }
        })
            .then((res) => {
                setIsSaving(false);
                if (res.data.saveProduction.errors) {
                    enqueueSnackbar(<I18n token="form.save.failure" values={{ name: name }} />, { variant: 'error' });
                } else {
                    enqueueSnackbar(<I18n token="form.save.success" values={{ name: name }} />, { variant: 'success' });
                    changeRoute(`${application.home}/production`);
                }
            })
            .catch((err) => {
                setIsSaving(false);
                enqueueSnackbar(<I18n token="form.save.failure" values={{ name: name }} />, { variant: 'error' });
            });
    };

    return loading ? (
        <Loading />
    ) : (
        entityId && (
            <V sx={{ p: 1, overflow: 'hidden' }}>
                <WithSidePanel
                    panels={
                        featurePermissions[AllPermissions.Read]
                            ? [
                                  {
                                      id: 'members',
                                      icon: Icons.DeviceHub,
                                      component: (onClose) => (
                                          <UserList productionName={data?.production.name} onClose={onClose} />
                                      )
                                  }
                              ]
                            : []
                    }
                    leftToolbar={<BreadCrumbs grow uri={`${application.home}/production`} entityName={values.name} />}
                >
                    <V sx={{ overflow: 'hidden', height: '100%', flexGrow: 2 }}>
                        <H sx={{ gap: 2, mb: 2 }}>
                            <Typography fontSize={'medium'} fontWeight={'bold'} whiteSpace={'nowrap'}>
                                {isCreate ? (
                                    <I18n token="production.form.title.create" />
                                ) : (
                                    <I18n token="production.form.title.edit" />
                                )}
                            </Typography>
                            <Buttons
                                isSaving={isSaving}
                                valid={valid}
                                disableSave={isReadOnly}
                                onProductionSave={onProductionSave}
                            />
                        </H>
                        <ScrollableLayout sx={{ p: 0 }}>
                            <FormProvider validationRules={validationRules} state={values} setState={setValues}>
                                <InfoEditor />
                                <FormSection
                                    id="production-about"
                                    title={<I18n token="production.form.section.about" />}
                                    fields={Object.keys(EMPTY_ABOUT_INFO)}
                                    Component={AboutEditor}
                                    renderCollapsed={false}
                                />
                                <FormSection
                                    id="production-alt-id"
                                    title={<I18n token="production.form.section.alternateIds" />}
                                    fields={altIdFields}
                                    Component={AltIdEditor}
                                    renderCollapsed={false}
                                />
                                <FormSection
                                    id="production-people"
                                    title={<I18n token="production.form.section.associatedPeople" />}
                                    fields={Object.keys(EMPTY_ASSOCIATED_PPL)}
                                    Component={GuestList}
                                    componentProps={{
                                        colDefs: productionEditorColDefs,
                                        columnVisibility: columnVisibility,
                                        name: 'associatedPeople'
                                    }}
                                    renderCollapsed={false}
                                />
                                <FormSection
                                    id="production-organization"
                                    title={<I18n token="production.form.section.associatedOrganizations" />}
                                    fields={Object.keys(EMPTY_ASSOCIATED_ORGS)}
                                    Component={AssociatedOrganizationsEditor}
                                    renderCollapsed={false}
                                />
                            </FormProvider>
                        </ScrollableLayout>
                    </V>
                </WithSidePanel>
            </V>
        )
    );
};
