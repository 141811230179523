import React from 'react';
import { Attribute } from '../attributes';
import { toDisplay } from '../../common/utils/dateTime';
import { LabelValueDisplay } from '../AttributeValue';

type DateAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: DateAttributePreviewProps) => {
    const { attribute } = props;
    return (
        attribute && (
            <LabelValueDisplay label={attribute.label} value={attribute.value ? toDisplay(attribute.value) : '-'} />
        )
    );
};
