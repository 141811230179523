import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import React, { Fragment, ReactElement } from 'react';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import { Toolbar, ToolbarOptions } from './DataGrid';
import { keys } from 'lodash';

export const CustomToolbar = (props: {
    id: string;
    toolbar: Toolbar;
    toolbarOptions: ToolbarOptions;
    toolbarSx?: SxProps;
    createNewRow: () => void;
    isAddDisabled: boolean;
    addTitle?: string;
    ColumnVisibilitySelector: ReactElement;
}) => {
    const { id, toolbar, toolbarOptions, toolbarSx, createNewRow, isAddDisabled, addTitle, ColumnVisibilitySelector } =
        props;

    const toolBarComponents = {
        add: (
            <Button
                onClick={createNewRow}
                disabled={isAddDisabled}
                key={'add'}
                size={'small'}
                color={'primary'}
                startIcon={<Icons.AddOutlined />}
            >
                {addTitle || <I18n token={'grid.button.add'} />}
            </Button>
        ),
        export: (
            <GridToolbarExport
                key={'export'}
                size={'small'}
                csvOptions={toolbarOptions.csvOptions}
                printOptions={{ disableToolbarButton: true }}
                excelOptions={{ disableToolbarButton: true }}
            />
        ),
        custom: toolbar.custom?.map((tb, index) => <Fragment key={`custom-${index}`}>{tb}</Fragment>),
        columns: (
            <>
                <GridToolbarColumnsButton key={'colulmns'} size={'small'} />
                {ColumnVisibilitySelector}
            </>
        )
    };

    return toolbar.columns || toolbar.export || toolbar.add || toolbar.custom ? (
        <GridToolbarContainer sx={toolbarSx}>
            {keys(toolbar)?.map((toolbarOption, index) => (
                <React.Fragment key={index}>
                    {toolbar[toolbarOption] && toolBarComponents[toolbarOption]}
                </React.Fragment>
            ))}
        </GridToolbarContainer>
    ) : (
        <React.Fragment />
    );
};
