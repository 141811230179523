import { useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { NoteInput } from 'sr-types/lib/note/v1/graphql';
import { LineItemInput, Shortlist, ShortlistInput, Summary } from 'sr-types/lib/shortlist/v1/graphql';
import { UserContext } from '../common/auth/UserContext';
import { constants } from '../common/constants';
import { searchClient, slimResultsQuery, slimSearchQuery } from '../common/list/slimQuery';
import {
    saveLineItem,
    saveLineItemWithNote,
    client,
    deleteLineItemFromShortlist,
    deleteQuery,
    detailsQuery,
    saveQuery
} from './shortlist';

export type DeleteShortlistType = {
    shortlistId: string;
    onClose?: () => void;
    onSuccess?: () => void;
    onError?: () => void;
};

export type AddLineItemToShortlistType = {
    shortlistId: string;
    itemInput: LineItemInput;
    noteInput?: NoteInput;
    onClose?: () => void;
    onSuccess?: (id: string) => void;
    onError?: () => void;
};

export type DeleteLineItemFromShortlistType = {
    shortlistId: string;
    lineItemId: string;
    onClose?: () => void;
    onSuccess?: (id: string) => void;
    onError?: () => void;
};

export const useGetOrganizationDetailsForInquiry = (filter) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const { loading: organizationLoading, data: organizationData } = useQuery(slimSearchQuery('Organization'), {
        client: searchClient,
        skip: filter.length === 0,
        variables: {
            entity: 'Organization',
            filter: {
                expressions: filter
            },
            page: { from: 0, size: 50 },
            tokenize: true,
            sortBy: undefined
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: false
    });
    return { organizationData, organizationLoading };
};

export const useGetPersonDetailsForInquiry = (filter) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const { loading: personLoading, data: personData } = useQuery(slimSearchQuery('Person'), {
        client: searchClient,
        skip: filter.length === 0,
        variables: {
            entity: 'Person',
            filter: {
                expressions: filter
            },
            page: { from: 0, size: 50 },
            tokenize: true,
            sortBy: undefined
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: false
    });
    return { personData, personLoading };
};

export const useGetResourceShortlists = (resourceId: string, skip: boolean) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const { data: resourceShortlistsData, loading: resourceShortlistsLoading } = useQuery(
        slimResultsQuery('Shortlists'),
        {
            variables: {
                filters: [
                    {
                        identifier: 'entity',
                        value: 'Shortlist'
                    },
                    { identifier: 'contextType', value: 'Resource' },
                    { identifier: 'contextId', value: resourceId }
                ],
                page: { size: 1000, from: 0 }
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            },
            client: searchClient,
            fetchPolicy: constants.apolloFetchPolicy,
            notifyOnNetworkStatusChange: true,
            skip: skip
        }
    );

    return { resourceShortlistsData, resourceShortlistsLoading };
};

export const useDeleteShortlist = (activeOrganizationAccount) => {
    const [shortlistDelete, { loading: isShortlistDeleting }] = useMutation(deleteQuery, { client });

    const deleteShortlist = ({
        shortlistId,
        onClose = undefined,
        onSuccess = undefined,
        onError = undefined
    }: DeleteShortlistType) => {
        shortlistDelete({
            variables: {
                id: shortlistId
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
            .then((res) => {
                if (res.data.deleteShortlist.errors) {
                    onError?.();
                } else {
                    onSuccess?.();
                }
            })
            .catch(() => {
                onError?.();
            })
            .finally(() => {
                onClose?.();
                setTimeout(() => {
                    searchClient.refetchQueries({
                        include: ['slimResultsQuery']
                    });
                }, 300);
            });
    };

    return { deleteShortlist, isShortlistDeleting };
};

export const useAddShortlistLineItem = (activeOrganizationAccount) => {
    const [addItem, { loading: isLineItemSaving }] = useMutation(saveLineItem, { client });

    const addShortlistLineItem = ({
        shortlistId,
        itemInput,
        onClose = undefined,
        onSuccess = undefined,
        onError = undefined
    }: AddLineItemToShortlistType) => {
        addItem({
            variables: {
                shortlistId,
                input: itemInput
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
            .then((res) => {
                if (res.data.saveLineItem.errors) {
                    onError?.();
                } else {
                    onSuccess?.(res.data.saveLineItem.id);
                }
            })
            .catch(() => {
                onError?.();
            })
            .finally(() => {
                onClose?.();
            });
    };

    return { addShortlistLineItem, isLineItemSaving };
};

export const useAddShortlistLineItemWithNote = (activeOrganizationAccount) => {
    const [addItemWithNote, { loading: isLineItemWithNotesSaving }] = useMutation(saveLineItemWithNote, { client });

    const addShortlistLineItemWithNote = ({
        shortlistId,
        itemInput,
        noteInput,
        onClose = undefined,
        onSuccess = undefined,
        onError = undefined
    }: AddLineItemToShortlistType) => {
        addItemWithNote({
            variables: {
                shortlistId,
                input: itemInput,
                note: noteInput
            },
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            }
        })
            .then((res) => {
                if (res.data.saveLineItemWithNote.errors) {
                    onError?.();
                } else {
                    onSuccess?.(res.data.saveLineItemWithNote.id);
                }
            })
            .catch(() => {
                onError?.();
            })
            .finally(() => {
                onClose?.();
            });
    };

    return { addShortlistLineItemWithNote, isLineItemWithNotesSaving };
};

export const useDeleteLineItemFromShortlist = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [deleteLineItem, { loading: isLineItemDeleting }] = useMutation(deleteLineItemFromShortlist, { client });

    const deleteShortlistLineItem = ({ shortlistId, lineItemId }: DeleteLineItemFromShortlistType) => {
        return new Promise<any>((resolve, reject) => {
            deleteLineItem({
                variables: {
                    shortlistId,
                    lineItemId
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((res) => resolve(res))
                .catch((err) => {
                    reject(err);
                });
        });
    };

    return { deleteShortlistLineItem, isLineItemDeleting };
};

export const useReadWriteGql = (entityId: string) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const create = entityId === constants.createId;

    const {
        loading: isLoading,
        data,
        error: loadError
    } = useQuery(detailsQuery, {
        variables: { id: entityId },
        client,
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        skip: create
    });

    const [saveMutation, { loading: isSaving, error: saveError }] = useMutation(saveQuery, {
        client: client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    return { isLoading, isSaving, loadError, saveError, data, saveMutation };
};

export const toInput = (id: string, entity: Shortlist): ShortlistInput => {
    return {
        ...entity,
        identity: {
            id: id === constants.createId ? '' : id
        },
        roles: entity?.roles || [],
        categories: entity?.categories?.map((category) => ({ value: category, lineage: { value: category } })) || []
    };
};

export const fromInput = (entityInput: ShortlistInput): Shortlist => {
    return {
        name: entityInput.name,
        categories: entityInput.categories,
        roles: entityInput.roles,
        type: entityInput.type,
        summary: entityInput.summary as Summary
    };
};

export type ShortlistTypeSuperset = Shortlist & ShortlistInput;
