import { Alert, Typography } from '@mui/material';
import React from 'react';
import OkCancel from '../components/OkCancel';
import FormItem from '../form/FormItem';
import { AuthFormHeader } from './AccountLinks';
import I18n from '../i18n/I18n';

export default ({ resendEmail, onLogin }) => {
    return (
        <React.Fragment>
            <FormItem>
                <AuthFormHeader title={''} />
            </FormItem>
            <FormItem>
                <Alert severity="success" sx={{ width: '100%' }}>
                    <I18n token="account.email.sent" />
                </Alert>
            </FormItem>
            <FormItem>
                <Typography fontSize={'small'}>
                    <I18n token="account.email.not.recieved" />
                </Typography>
            </FormItem>
            <FormItem>
                <OkCancel isBusy={false} okLabelI18n="account.change.password" cancelLabelI18n="dialog.resend" onOk={onLogin} onCancel={resendEmail} isOkDisabled={false} />
            </FormItem>
        </React.Fragment>
    );
};
