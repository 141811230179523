import React, { createContext, useContext, useEffect, useState } from 'react';
import Validator, { ValidationState } from './Validator';
import { find, get, isEqual, isNil, keys, remove, set } from 'lodash';
import { I18nContext, I18nContextType } from '../i18n/I18n';
import { KeyValue } from 'sr-types/lib/search/v1/graphql';

export type HandleChangeType = (prop: string, value: any, coerceChangeToArray?: boolean, map?: { prop: string; key: string }) => void;

type FormContextType = {
    loading: boolean;
    state: any;
    setState: (state: any) => void;
    validation: ValidationState;
    handleChange: HandleChangeType;
    expandedSections: string[];
    setExpandedSections: (sectionNames: string[]) => void;
    allowMultipleExpansion?: boolean;
};

export const FormContext = createContext<FormContextType>({
    validation: undefined,
    handleChange: undefined,
    state: undefined,
    setState: undefined,
    expandedSections: [],
    setExpandedSections: undefined,
    loading: false,
    allowMultipleExpansion: false
});

export type Listener = {
    prop: string | string[];
    func: (name, newValue, newState) => void | Promise<any>;
};

type FormProviderProps = {
    /** True if a loading operation is currently exectuting */
    loading?: boolean;
    /** Full state of this context */
    state: any;
    /** State setter */
    setState?: any;
    /** Validation rules for this state */
    validationRules?: any;
    /** State change listeners. Exectued after each field change but before React's render */
    listeners?: Listener[];
    /** Sections (ids) that will be initially expanded */
    expandedSections?: string[];
    /** Should multiple sections be allowed to expand? */
    allowMultipleExpansion?: boolean;
    children: any;
};

export const resolve = (state, prop, mapTo) => {
    const val = get(state, prop);
    if (mapTo) {
        const kv: KeyValue = find(val, (e) => e.key === mapTo);
        return kv ? kv.value : undefined;
    } else {
        return val;
    }
};

async function runListeners(newState, prop, value, listeners: Listener[]) {
    if (listeners && listeners.length) {
        let syncListeners = 0;
        let asyncListeners = 0;
        const promises = [];
        listeners.forEach((listener) => {
            const arr = Array.isArray(listener.prop) ? listener.prop : [listener.prop];
            arr.forEach((l) => {
                if (l === prop) {
                    const ret = listener.func(prop, value, newState);
                    // Is this function a promise.
                    if (ret && ret.then && typeof ret.then === 'function') {
                        promises.push(ret);
                        asyncListeners++;
                    } else {
                        syncListeners++;
                    }
                }
            });
        });
        if (promises.length) {
            await Promise.all(promises);
        }
        // log('Executed', syncListeners, 'sync listeners and', asyncListeners, 'async listeners');
    }
}

function setValue(state, prop, value, mapTo, coerceChangeToArray) {
    if (mapTo) {
        const map: KeyValue[] = get(state, prop) || [];
        remove(map, (e) => e.key === mapTo);
        map.push({ key: mapTo, value: value });
        set(state, prop, map);
    } else if (coerceChangeToArray && !Array.isArray(value)) {
        set(state, prop, isNil(value) ? [] : [value]);
    } else {
        set(state, prop, value);
    }
}

export const FormProvider = (props: FormProviderProps) => {
    const { loading, validationRules, state, setState, listeners, children, allowMultipleExpansion = false, expandedSections: defaultExpandedSections } = props;
    const [validator, setValidator] = useState<Validator>();
    const [validation, setValidation] = useState<ValidationState>();
    const [expandedSections, setExpandedSections] = useState<string[]>(defaultExpandedSections);
    const i18nContext: I18nContextType = useContext(I18nContext);

    useEffect(() => {
        setValidator(
            new Validator(validationRules, {
                // If true validator will keep going even after first field fails validation.
                runAll: false,
                i18nContext: i18nContext
            })
        );
    }, [validationRules]); // Run this only once on mount.

    useEffect(() => {
        if (validator) {
            const validation = validator.validate(state);
            // console.log('Initial validation:', validation, 'state:', state);
            setValidation(validation);
        }
    }, [validator]);

    const handleChange = async (prop, value, coerceChangeToArray, mapTo = undefined) => {
        const newState = { ...state };
        const currentValue = resolve(newState, prop, mapTo);
        if (!isEqual(currentValue, value)) {
            setValue(newState, prop, value, mapTo, coerceChangeToArray);
            runListeners(newState, prop, value, listeners);
            const validation = validator.validate(newState, prop);
            setState(newState);
            setValidation(validation);
            return newState;
        }
    };

    return (
        <FormContext.Provider
            value={{
                loading,
                validation,
                handleChange,
                state,
                setState,
                expandedSections,
                setExpandedSections,
                allowMultipleExpansion
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

const flattenObj = (ob) => {
    let result = {};
    for (const i in ob) {
        if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {
                result[i + '.' + j] = temp[j];
            }
        } else {
            result[i] = ob[i];
        }
    }
    return result;
};

export const getKeys = (state) => {
    return keys(flattenObj(state));
};
