import React from 'react';
import { ElementFormatType, FORMAT_ELEMENT_COMMAND } from 'lexical';
import ResponsiveToolbar from '../../../common/components/ResponsiveToolbar';
import { KeyValueMap } from '../../../common/KeyValueMap';
import { ActionType } from '../../../common/components/ActionType';
import { Icons } from '../../../common/icons/Icons';

export const alignmentStyles: KeyValueMap<string, ActionType> = {
    left: { label: 'Left', icon: Icons.FormatAlignLeft },
    center: { label: 'Center', icon: Icons.FormatAlignCenter },
    right: { label: 'Right', icon: Icons.FormatAlignRight },
    justify: { label: 'Justify', icon: Icons.FormatAlignJustify }
};

export default ({ disabled, editor, isSmall, isAlignLeft, isAlignCenter, isAlignRight, isAlignJustify }) => {
    const handleAlignment = (newAlignment: ElementFormatType | null) => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, newAlignment);
    };
    const selected = [];
    // TODO Doesn't work currently.
    // if (isAlignLeft) selected.push('left');
    // if (isAlignCenter) selected.push('center');
    // if (isAlignRight) selected.push('right');
    // if (isAlignJustify) selected.push('justify');

    return (
        <ResponsiveToolbar disabled={disabled} isSmall={isSmall} options={alignmentStyles} onSelect={handleAlignment} selected={selected} icon={Icons.FormatAlignLeft} />
    );
};
