import { Typography } from '@mui/material';
import React, { Fragment, ReactElement } from 'react';
import { useStyling } from '../Theme';

export const VerticalSeparator = () => {
    const { theme } = useStyling();
    return <Typography sx={{ color: theme.palette.text.disabled }}>|</Typography>;
};

export default ({ children }) => {
    return children.map((child: ReactElement, index: number) => {
        return (
            <Fragment key={index}>
                {child}
                {index + 1 < children.length ? <VerticalSeparator /> : <Fragment />}
            </Fragment>
        );
    });
};
