import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';
import I18n from '../../common/i18n/I18n';

export default ({ isDialogOpen, onYes, onNo }: { isDialogOpen: boolean; onYes: (e) => void; onNo: (e) => void }) => {
    return (
        <Dialog open={isDialogOpen} keepMounted>
            <DialogContent>
                <DialogContentText>
                    <I18n token="production.dialog.convertToManaged" />
                </DialogContentText>
                <DialogActions>
                    <Button onClick={onNo}>
                        <I18n token="dialog.no" />
                    </Button>
                    <Button onClick={onYes} variant="contained">
                        <I18n token="dialog.yes" />
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};
