import React from 'react';
import { createSvgIcon } from '@mui/material';

export const RequestAvailability = createSvgIcon(
    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.3333 13.333V15.9997L16 16.6663L13.3333 17.333V19.9997L20 16.6663L13.3333 13.333Z"
            fill="currentColor"
        />
        <path
            d="M2.5 2.8582C2.5 1.73972 3.55533 0.833008 4.85714 0.833008H15.1429C16.4447 0.833008 17.5 1.73972 17.5 2.8582V12.4318H16.2143V2.8582C16.2143 2.3498 15.7346 1.93766 15.1429 1.93766H4.85714C4.26541 1.93766 3.78571 2.3498 3.78571 2.8582V14.6411C3.78571 15.1495 4.26541 15.5617 4.85714 15.5617H12.5714V16.6663H4.85714C3.55533 16.6663 2.5 15.7596 2.5 14.6411V2.8582Z"
            fill="currentColor"
        />
        <path
            d="M12.0833 6.24967C12.0833 7.39551 11.1458 8.33301 10 8.33301C8.85417 8.33301 7.91667 7.39551 7.91667 6.24967C7.91667 5.10384 8.85417 4.16634 10 4.16634C11.1458 4.16634 12.0833 5.10384 12.0833 6.24967Z"
            fill="currentColor"
        />
        <path
            d="M12.8958 9.97884C13.6667 10.3122 14.1667 11.0622 14.1667 11.9059V12.4997H5.83333V11.9059C5.83333 11.0622 6.33333 10.3122 7.10417 9.97884C7.98958 9.59342 8.96875 9.37467 10 9.37467C11.0312 9.37467 12.0104 9.59342 12.8958 9.97884Z"
            fill="currentColor"
        />
    </svg>,
    'RequestAvailability'
);
