import { find } from 'lodash';
import { AllPermissions, getRowPermissions } from '../../common/auth/api';

const LAG_UNIT_MAPPING = new Map([
    ['Hours', 'h'],
    ['Days', 'd'],
    ['Weeks', 'w'],
    ['Months', 'M'],
    ['Years', 'y']
]);

const DURATION_UNIT_MAPPING = new Map([
    ['hour', 'h'],
    ['day', 'd'],
    ['week', 'w'],
    ['month', 'M'],
    ['year', 'y']
]);

const DEPENDENCY_TYPE_MAPPING = new Map([
    ['Start to Start', 0],
    ['Start to Finish', 1],
    ['Finish to Start', 2],
    ['Finish to Finish', 3]
]);

const DEFAULT_COLOR = 'orange';
const ACTIVITY_TYPE_ICONS = {
    // Milestone: 'b-fa b-fa-exclamation-triangle'
};
const DEFAULT_ICON = '';
export const STYLES = {
    border: 'border',
    plain: 'plain',
    colored: 'colored',
    dashed: 'dashed',
    line: 'line',
    minimal: 'minimal',
    rounded: 'rounded'
};

export const MODES = {
    vertical: 'vertical',
    horizontal: 'horizontal'
};

export interface Bryntum {
    resources: Array<any>;
    events: Array<any>;
    timeRanges: Array<any>;
    dependencies: Array<any>;
    assignments: Array<any>;
}

export const toBryntum = (acts, laneOrder = {}, timelineColorsByLaneId): Bryntum => {
    let from, to;
    const lanes = [];
    const dependencies = [];
    const assignments = [];
    const activities = acts ? acts.map((a) => ({ ...a })) : [];
    const addedDependencies = new Set();

    activities.forEach((activity) => {
        if (activity.assignedResources && activity.assignedResources.length > 0) {
            activity.assignedResources.forEach((ar) => {
                const laneId = ar.id;
                const laneColor = timelineColorsByLaneId[laneId];
                const r = find(lanes, { id: ar.id });
                if (!r) {
                    lanes.push({
                        id: laneId,
                        name: ar.name,
                        order: laneOrder[laneId] ? laneOrder[laneId].order : Number.MAX_VALUE,
                        eventColor: laneColor
                    });
                }
                assignments.push({ eventId: activity.id, resourceId: laneId });
            });
        }

        if (activity.dependencies && activity.dependencies.length) {
            activity.dependencies.forEach((d) => {
                const key = `${d.fromActivity.id}-${d.toActivity.id}`;
                if (!addedDependencies.has(key)) {
                    dependencies.push({
                        fromEvent: d.fromActivity.id,
                        toEvent: d.toActivity.id,
                        lag: d.lag,
                        lagUnit: LAG_UNIT_MAPPING.get(d.lagUnit),
                        type: DEPENDENCY_TYPE_MAPPING.get(d.type)
                    });
                    addedDependencies.add(key);
                }
            });
        }

        const s = activity.dateRange.start;
        if (!from || s < from) {
            from = s;
        }
        const e = activity.dateRange.end;
        if (!to || e > to) {
            to = e;
        }
    });
    lanes.sort((lane1, lane2) => lane1.order - lane2.order);
    return {
        resources: lanes,
        assignments: assignments,
        events: activities
            .filter((a) => a.activityType !== 'Non-Working')
            .map((a) => {
                const permissions = getRowPermissions(a.security);
                const activity = {
                    ...a,
                    startDate: a.dateRange.start,
                    endDate: a.dateRange.end ? a.dateRange.end : a.dateRange.start,
                    image: false,
                    resizable: permissions[AllPermissions.Edit],
                    draggable: permissions[AllPermissions.Edit],
                    permissions: permissions,
                    manuallyScheduled: true,
                    calendar: a.calendar ? a.calendar.id : undefined
                };
                // Add duration only if start and end dates don't have time component.
                // const startHasTime = activity.startDate.indexOf('00:00:00') > -1;
                // const endHasTime = activity.endDate.indexOf('00:00:00') > -1;
                activity['duration'] = a.dateRange.dateTimeDuration?.value;
                activity['durationUnit'] = a.dateRange.dateTimeDuration?.unit ? DURATION_UNIT_MAPPING.get(a.dateRange.dateTimeDuration?.unit) : undefined;
                return activity;
            }),
        dependencies: dependencies,
        timeRanges: [] /*activities
            .filter((a) => a.activityType === 'Non-Working')
            .map((a) => {
                const laneId = get(a, laneIdAttr);
                const laneName = get(a, laneNameAttr);
                return {
                    id: a.id,
                    resourceId: laneId,
                    resourceName: laneName,
                    name: a.name,
                    startDate: a.dateRange.start,
                    endDate: a.dateRange.end ? a.dateRange.end : a.dateRange.start,
                    eventStyle: STYLES.rounded,
                    attributes: a.attributes
                    // eventColor: ACTIVITY_TYPE_COLORS[a.activityType] ? ACTIVITY_TYPE_COLORS[a.activityType] : DEFAULT_COLOR,
                    // iconCls: ACTIVITY_TYPE_ICONS[a.activityType] ? ACTIVITY_TYPE_ICONS[a.activityType] : DEFAULT_ICON,
                    // cls: 'striped',
                    // eventStyle: STYLES.dashed
                };
            })*/
    };
};

export const UTILIZATION_UNITS_MAPPING = new Map([
    ['hour', 'Hours'],
    ['day', 'Days'],
    ['week', 'Weeks'],
    ['month', 'Months'],
    ['year', 'Years']
]);
