import { ApolloClient, DocumentNode, useMutation } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../auth/UserContext';

export const useMutationWithContext = (query: DocumentNode, client: ApolloClient<object> = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    return useMutation(query, {
        client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });
};
