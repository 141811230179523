import { Typography } from '@mui/material';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Note } from 'sr-types/lib/search/v1/graphql';
import LexicaljsEditor from '../../lab/form/lexical/LexicaljsEditor';
import { H, V } from '../Layout';
import { useStyling } from '../Theme';
import { AllPermissions } from '../auth/api';
import { ActionType } from '../components/ActionType';
import ResponsiveActions from '../components/ResponsiveActions';
import { useI18n } from '../i18n/I18n';
import { Icons } from '../icons/Icons';
import EmptyPromise from '../utils/EmptyPromise';
import { toDisplay } from '../utils/dateTime';
import { useOnMobile } from '../hooks/breakpoints';
import { useIsWithinBreakpoints } from '../hooks/useIsWithinBreakpoints';

const NoteHeader = ({ text }) => {
    return (
        <Typography fontSize={14} className={'ellipsis'} sx={{ flexGrow: 2 }}>
            {text}
        </Typography>
    );
};

type NoteFooterProps = {
    note: Note;
};

const NoteFooter = (props: NoteFooterProps) => {
    const { note } = props;
    const { theme } = useStyling();
    return (
        <H sx={{ gap: 1, mt: 1 }}>
            <Typography fontSize={'x-small'} fontWeight={'normal'} color={theme.palette.text.disabled}>
                {toDisplay(note.createdOn)}
            </Typography>
            <Typography
                fontSize={'x-small'}
                fontWeight={'normal'}
                className={'ellipsis'}
                sx={{ flexGrow: 2 }}
                color={theme.palette.text.disabled}
            >
                {note.createdByName}
            </Typography>
        </H>
    );
};

type NoteProps = {
    note: Note;
    collapsed?: boolean;
    onSave?: (title: string, content: string) => Promise<any>;
    onDelete?: (noteId: string) => Promise<any>;
    featurePermissions: object;
    entityPermissions: object;
    isAnyEditing: boolean;
    setIsAnyEditing: (boolean) => void;
};

export default (props: NoteProps) => {
    const {
        note,
        collapsed = false,
        onSave,
        onDelete,
        featurePermissions,
        entityPermissions,
        isAnyEditing,
        setIsAnyEditing
    } = props;
    const [isHover, setIsHover] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const contentPlaceholder = useI18n('notes.placeholder.content');

    useEffect(() => {
        setIsClicked(false);
    }, [collapsed]);

    const actions: ActionType[] = useMemo(() => {
        const ret: ActionType[] = [];
        if (featurePermissions[AllPermissions.Manage] && entityPermissions[AllPermissions.Comment]) {
            if (typeof onSave === 'function') {
                ret.push({
                    label: 'Edit',
                    icon: Icons.Edit,
                    disabled: isAnyEditing,
                    onClick: () => {
                        setIsHover(false);
                        setIsEditing(true);
                    }
                });
            }
            if (typeof onDelete === 'function') {
                ret.push({
                    label: 'Delete',
                    icon: Icons.Delete,
                    onClick: async () => {
                        await onDelete(note.id);
                    }
                });
            }
        }
        return ret;
    }, [entityPermissions, featurePermissions, isAnyEditing, note.id, onDelete, onSave]);

    useEffect(() => {
        setIsAnyEditing(isEditing);
    }, [isEditing, setIsAnyEditing]);
    const isMobile = useOnMobile();
    const isTablet = useIsWithinBreakpoints(['s', 'xs']);
    return (
        <V
            className={'_note'}
            sx={{
                position: 'relative',
                'p.LexicalEditorTheme__paragraph.LexicalEditorTheme__ltr': { paddingRight: isTablet ? '17px' : 0 }
            }}
            onClick={() => setIsClicked(!isClicked)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <LexicaljsEditor
                id={note.id}
                contentPlaceholder={contentPlaceholder}
                readOnly={!isEditing}
                collapsed={collapsed}
                isSmall={true}
                content={note.content}
                onSave={(title: string, content: string) => {
                    return onSave(title, content).then(() => {
                        setIsEditing(false);
                        setIsHover(false);
                    });
                }}
                onDelete={(id: string) => {
                    onDelete(id);
                    setIsEditing(false);
                    setIsHover(false);
                    return EmptyPromise;
                }}
                onCancel={() => {
                    setIsEditing(false);
                    setIsHover(false);
                    return EmptyPromise;
                }}
                header={!isEditing && collapsed ? <NoteHeader text={note.title} /> : <Fragment />}
                footer={!isEditing ? <NoteFooter note={note} /> : <Fragment />}
                isEditing={isEditing}
            />

            <H
                sx={{
                    '& button': isMobile
                        ? {
                              position: 'absolute',
                              right: '0px',
                              top: '0px',
                              backgroundColor: 'transparent'
                          }
                        : { position: 'static' }
                }}
            >
                {!isEditing ? <ResponsiveActions showBorder actions={actions} isActivated={isHover} /> : <Fragment />}
            </H>
        </V>
    );
};
