import React, { useState } from 'react';
import { ListItemText, MenuItem, SvgIcon, ToggleButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import { KeyValueMap } from '../KeyValueMap';
import { ActionType } from './ActionType';
import StyledToggleButtonGroup from '../../lab/form/lexical/StyledToggleButtonGroup';
import { Icons } from '../icons/Icons';

export type SelectedType = string[] | string | null;

export type ResponsiveToolbarProps = {
    disabled?: boolean;
    icon: SvgIcon;
    options: KeyValueMap<string, ActionType>;
    multi: boolean;
    selected: string | string[];
    onSelect: (selected: SelectedType) => void;
    isSmall?: boolean;
};

const Dropdown = (props: ResponsiveToolbarProps) => {
    const { disabled, multi, options, selected, icon, onSelect } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuClose = () => {
        setIsMenuOpen(false);
        setAnchorEl(null);
    };
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const DropdownIcon = multi || !selected || !options[selected] || !options[selected].icon ? icon : options[selected].icon;
    return (
        <>
            <StyledToggleButtonGroup exclusive size={'small'}>
                <ToggleButton disabled={disabled} value={selected} onClick={handleMenuOpen} sx={{ textTransform: 'unset' }}>
                    <DropdownIcon fontSize={'small'} />
                    <Icons.KeyboardArrowDown />
                </ToggleButton>
            </StyledToggleButtonGroup>
            <Menu
                id={'paragraph-style-menu'}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                {Object.keys(options).map((key, index) => {
                    const action: ActionType = options[key];
                    return (
                        <MenuItem
                            key={index}
                            disabled={action.disabled}
                            onClick={() => {
                                if (typeof onSelect === 'function') {
                                    onSelect(key);
                                } else if (typeof action.onClick === 'function') {
                                    action.onClick(key);
                                }
                                handleMenuClose();
                            }}
                            selected={selected && Array.isArray(selected) ? selected.includes(key) : key === selected}
                        >
                            {action.icon ? <action.icon fontSize={'small'} sx={{ mr: 1 }} /> : <></>}
                            <ListItemText>{action.label}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

const Toolbar = (props: ResponsiveToolbarProps) => {
    const { disabled, multi, options, selected, onSelect } = props;
    return (
        <StyledToggleButtonGroup
            exclusive={!multi}
            size={'small'}
            onChange={(e, val) => {
                if (typeof onSelect === 'function') {
                    onSelect(val);
                }
            }}
        >
            {Object.keys(options).map((key, index) => {
                const action: ActionType = options[key];
                return (
                    <ToggleButton
                        key={index}
                        value={key}
                        disabled={disabled || action.disabled}
                        selected={selected && Array.isArray(selected) ? selected.includes(key) : key === selected}
                        onClick={action.onClick}
                    >
                        <action.icon fontSize={'small'} />
                    </ToggleButton>
                );
            })}
        </StyledToggleButtonGroup>
    );
};

export default (props: ResponsiveToolbarProps) => {
    return props.isSmall ? <Dropdown {...props} /> : <Toolbar {...props} />;
};
