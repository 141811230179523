import React from 'react';
import QuickFilterItem, { QuickFilter } from './QuickFilterItem';
import { H } from '../../Layout';
import QuickFilterSingleSelectItem from './QuickFilterSingleSelectItem';

type FiltersProps = {
    filters: QuickFilter[];
    onFilterChanged: (QuickFilter) => void;
    requiredFilters?: string[];
};

export default (props: FiltersProps) => {
    const { filters, onFilterChanged, requiredFilters = [] } = props;
    return (
        <H sx={{ gap: 1, width: 'fit-content' }}>
            {filters.map((f, index) =>
                f?.displayType === 'SingleSelectFacet' ? (
                    <QuickFilterSingleSelectItem
                        key={index}
                        requiredFilters={requiredFilters}
                        filter={f}
                        onFilterChanged={onFilterChanged}
                    />
                ) : (
                    <QuickFilterItem
                        key={index}
                        filter={f}
                        requiredFilters={requiredFilters}
                        onFilterChanged={onFilterChanged}
                    />
                )
            )}
        </H>
    );
};
