import axios from 'axios';
import { getToken } from '../auth/api';
import mime from 'mime';

export const UPLOAD_URL = '/storage/v1/upload';

export default (activeOrganizationAccount: string, url: string, file: File) => {
    const token = getToken();

    const ext = file.name.substring(file.name.lastIndexOf('.'));
    const mimeType = mime.getType(ext);

    const formData = new FormData();
    formData.append('file', new Blob([file], { type: mimeType }), file.name);

    return axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            ownerId: activeOrganizationAccount,
            Authorization: token ? 'Bearer ' + token.token : ''
        }
    });
};

const blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return theBlob as File;
};
