import { SvgIcon } from '@mui/material';
import { SearchkitClient, useSearchkit } from '@searchkit/client';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { search } from 'sr-types';
import ResponsiveButtonGroup, { ResponsiveButtonGroupOption } from '../../../common/components/ResponsiveButtonGroup';
import { Icons } from '../../../common/icons/Icons';
import { TYPE_TO_ICON } from '../../../common/icons/typeToIcon';

const getFilter = (api: SearchkitClient, identifier: string) => {
    const filters = api.getFiltersByIdentifier(identifier).map((filter) => filter.value);
    return !isEmpty(filters) ? filters[0] : '';
};

const doFilter = (api: SearchkitClient, identifier: string, newValue: string) => {
    const apiFilter = getFilter(api, identifier);
    if (!isEqual(apiFilter, newValue)) {
        const pageSize = api.getSearchState().page.size;
        api.removeFiltersByIdentifier(identifier);
        api.addFilter({ identifier: identifier, value: newValue });
        api.setPage({
            from: 0,
            size: pageSize
        });
        api.search();
    }
};

export default ({ facet }) => {
    const api = useSearchkit();
    const value = useMemo(() => {
        return getFilter(api, facet.identifier);
    }, [api, facet.identifier]);

    useEffect(() => {
        doFilter(api, facet.identifier, value);
    }, [api, facet.identifier, value]);

    const options: ResponsiveButtonGroupOption[] = facet.entries.map((entry: search.SkFacetSetEntry, index) => {
        const id = entry.id.replace('type_', ''); // These ids have 'type_' prefix while others do not. We don't want to duplicate definitions.
        const icon = TYPE_TO_ICON[id] ? <SvgIcon component={TYPE_TO_ICON[id]} /> : <Icons.Default />;

        return {
            id: entry.label,
            label: entry.label,
            icon: icon
        };
    });

    return (
        <ResponsiveButtonGroup
            useMobileWithBreakpoints={['xs', 's']}
            options={options}
            selectedOption={value}
            onToggle={(newValue: string) => {
                doFilter(api, facet.identifier, newValue);
            }}
        />
    );
};
