import { Badge, BadgeProps, styled } from '@mui/material';

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    height: '29px',
    '& .MuiBadge-badge': {
        right: 0,
        top: 12,
        // border: `2px solid ${theme.palette.background.paper}`,
        // padding: '0 4px',
        backgroundColor: theme.palette.success.main,
        color: theme.palette.getContrastText(theme.palette.success.main),
        width: 14,
        minWidth: 14,
        height: 14,
        fontSize: 10,
        fontWeight: 'bold'
    }
}));
