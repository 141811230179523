import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { PreferenceInput } from 'sr-types/lib/account/v1/graphql';
import { client as accountClient } from '../../common/auth/account';
import { constants } from '../constants';

const loadPreferences: DocumentNode = gql`
    query Preferences($accountId: ID!) {
        preferences(accountId: $accountId) {
            identity {
                id
            }
            accountId
            agent
            key
            value
            view
        }
    }
`;

const savePreference: DocumentNode = gql`
    mutation SavePreference($preference: PreferenceInput!) {
        savePreference(preference: $preference) {
            id
            errors {
                field
                message
            }
        }
    }
`;
export const loadPreferencesQuery = (accountId: string) => {
    return accountClient.query({
        query: loadPreferences,
        context: {
            headers: {
                ownerId: accountId
            }
        },
        variables: {
            accountId: accountId
        },
        fetchPolicy: constants.apolloFetchPolicy
    });
};

export const savePreferenceMutation = (
    accountId: string,
    view: string,
    key: string,
    value: string,
    agent: string = ''
) => {
    const preference: PreferenceInput = {
        view: view,
        key: key,
        value: value,
        agent: agent
    };
    return accountClient
        .mutate({
            mutation: savePreference,
            context: {
                headers: {
                    ownerId: accountId
                }
            },
            variables: {
                preference: preference
            }
        })
        .catch((err) => {
            console.warn('Save preferences failed:', err);
        });
};
