import { Tooltip, Typography } from '@mui/material';
import React from 'react';

export default ({ title, width = 100 }: { title: string; width?: number }) => {
    return (
        <Tooltip title={<Typography fontSize="small">{title}</Typography>}>
            <Typography
                className="ellipsis"
                maxWidth={width}
                variant="h6"
                sx={{ fontSize: '20px', fontWeight: 'bold' }}
            >
                {title}
            </Typography>
        </Tooltip>
    );
};
