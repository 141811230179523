import { Fab, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { Icons } from '../icons/Icons';
import { ActionType } from './ActionType';

type AddActionsFabProps = {
    addActions?: ActionType[];
    defaultAddAction?: () => void;
};
export default ({ addActions, defaultAddAction }: AddActionsFabProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    return (
        <>
            <Fab
                sx={{ width: '32px', height: '32px', minWidth: '32px', minHeight: '32px', zIndex: '1' }}
                color="primary"
                aria-label="add"
                variant={'circular'}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    if (addActions?.length === 1 && typeof addActions[0].onClick === 'function') {
                        // 1 action with onClick handler.
                        addActions?.[0].onClick();
                    } else if (addActions?.length > 1) {
                        // Multiple actions, show manu.
                        setAnchorEl(event.currentTarget);
                    } else if (typeof defaultAddAction === 'function') {
                        // No actions but there is default funciton.
                        defaultAddAction();
                    } else {
                        console.warn('Failed to handle actions:', addActions);
                    }
                }}
            >
                <Icons.AddOutlined />
            </Fab>

            {isMenuOpen && (
                <Menu
                    sx={{ mt: 0.5 }}
                    anchorEl={anchorEl}
                    open
                    onClose={() => {
                        setAnchorEl(null);
                    }}
                >
                    {addActions.map((action: ActionType, index) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    action.onClick();
                                    setAnchorEl(null);
                                }}
                            >
                                {action.icon ? (
                                    <ListItemIcon>
                                        <action.icon />
                                    </ListItemIcon>
                                ) : (
                                    <></>
                                )}
                                <ListItemText>{action.label}</ListItemText>
                            </MenuItem>
                        );
                    })}
                </Menu>
            )}
        </>
    );
};
