import { constants } from '../constants';
import { getBrowserLocale } from '../i18n/localization';

class NumberParser {
    _group: string;
    _groupRegex: RegExp;
    _decimal: string;
    _decimalRegex: RegExp;
    _numeralRegex: RegExp;
    _index: any;

    constructor(locale) {
        const format = new Intl.NumberFormat(locale);
        const parts = format.formatToParts(12345.6);
        const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
        const index = new Map(numerals.map((d, i) => [d, i]));
        this._group = parts.find((d) => d.type === 'group').value;
        this._groupRegex = new RegExp(`[${this._group}]`, 'g');
        this._decimal = parts.find((d) => d.type === 'decimal').value;
        this._decimalRegex = new RegExp(`[${this._decimal}]`);
        this._numeralRegex = new RegExp(`[${numerals.join('')}]`, 'g');
        this._index = (d) => index.get(d);
    }
    parse(string) {
        if (string === undefined || string === null) {
            return NaN;
        }
        return (string = string
            .trim()
            .replace(this._groupRegex, '')
            .replace(this._decimalRegex, '.')
            .replace(this._numeralRegex, this._index))
            ? +string
            : NaN;
    }
}

type FormatNumberOptions = {
    decimals?: number;
    forcePositive?: boolean;
    locale?: string;
    /**Whether to use grouping separators, such as thousands separators or thousand/lakh/crore separators. */
    useGrouping?: boolean;
};

export const formatNumber = (value: number | string, options: FormatNumberOptions = {}) => {
    const { decimals, locale, forcePositive = false, useGrouping = true } = options;
    const loc = locale || getBrowserLocale();
    if (typeof value === 'number') {
        const factor = forcePositive && value < 0 ? -1 : 1;
        const formatOptions: Intl.NumberFormatOptions = {
            minimumFractionDigits: 0,
            notation: 'standard',
            useGrouping: useGrouping
        };
        if (typeof decimals === 'number') {
            formatOptions.maximumFractionDigits = decimals;
        }
        return new Intl.NumberFormat([loc], formatOptions).format(value * factor);
    } else if (typeof value === 'string') {
        const { parsed, endsWithDecimalPoint, numDecimals, decimalChar } = parseNumber(value, {
            decimals,
            locale,
            forcePositive
        });

        if (isNaN(parsed)) {
            // Let validation deal with it.
            return value;
        } else {
            const dec = numDecimals > decimals ? decimals : numDecimals;
            return (
                formatNumber(parsed, { decimals: dec, locale, forcePositive, useGrouping }) +
                (endsWithDecimalPoint && decimals ? decimalChar : '')
            );
        }
    }
};

export type ParsedNumber = {
    parsed: number;
    numDecimals: number;
    decimalChar: string;
    groupChar: string;
    endsWithDecimalPoint: boolean;
};

type ParseNumberOptions = {
    locale?: string;
    decimals?: number;
    forcePositive?: boolean;
};

export const parseNumber = (strNum, options: ParseNumberOptions = {}): ParsedNumber => {
    const { decimals, locale = getBrowserLocale(), forcePositive } = options;
    const parser = new NumberParser([locale, constants.defaultLang]);
    const cleaned = cleanNumber(strNum, decimals, parser._groupRegex);
    const matchDecimalPoint = cleaned.match(parser._decimalRegex);
    const hasDecimalPoint = matchDecimalPoint ? matchDecimalPoint.index > -1 : false;
    const endsWithDecimalPoint = hasDecimalPoint && matchDecimalPoint.index + 1 === cleaned.length;
    const numDecimals = hasDecimalPoint ? cleaned.length - matchDecimalPoint.index - 1 : 0;
    const parsed = parser.parse(cleaned);
    const factor = forcePositive && parsed < 0 ? -1 : 1;
    return {
        parsed: parsed * factor,
        decimalChar: parser._decimal,
        groupChar: parser._group,
        endsWithDecimalPoint,
        numDecimals
    };
};

export const cleanNumber = (strNum: string, decimals: number, groupRegex): string => {
    let cleaned = '';
    if (typeof strNum !== 'undefined') {
        cleaned = strNum.replace(/[^0-9-.,]/g, '').replace(groupRegex, '');
    }
    if (typeof decimals === 'number') {
        const regex = new RegExp(`^(?:-?\\d*\\.?\\d{0,${decimals}})?`, 'gi');
        const cropped = cleaned.match(regex);
        if (cropped) {
            cleaned = cropped[0];
        }
    }
    return cleaned;
};

export const isNumberValid = (value, locale) => {
    if (typeof value === 'number') {
        return true;
    } else if (typeof value === 'string') {
        const num = parseNumber(value, { locale });
        return !isNaN(num.parsed);
    } else {
        return false;
    }
};
