import { Typography } from '@mui/material';
import { convertBytesToMbsOrKbs } from 'mui-file-dropzone';
import React from 'react';
import I18n from '../../i18n/I18n';
import { V } from '../../Layout';
import { useStyling } from '../../Theme';

export default ({ maxFileSize, filesLimit }: { maxFileSize: number; filesLimit: number }) => {
    const { theme } = useStyling();
    return (
        <V>
            {!!maxFileSize && (
                <Typography
                    fontSize={'x-small'}
                    fontWeight={'normal'}
                    sx={{ flexGrow: 2 }}
                    color={theme.palette.text.disabled}
                >
                    <I18n
                        token={'attachments.limit.size.label'}
                        values={{ maximumFileSize: convertBytesToMbsOrKbs(maxFileSize) }}
                    />
                </Typography>
            )}
            {!!filesLimit && (
                <Typography
                    fontSize={'x-small'}
                    fontWeight={'normal'}
                    sx={{ flexGrow: 2 }}
                    color={theme.palette.text.disabled}
                >
                    <I18n token={'attachments.files.limit'} values={{ filesLimit: filesLimit }} />
                </Typography>
            )}
        </V>
    );
};
