import { atom, selector } from 'recoil';

// const [isDebug, setIsDebug] = useRecoilState(isDebugAtom);
export const isDebugAtom = atom({
    key: 'isDebug',
    default: localStorage.getItem('isDebug') === 'true'
});

// const isDebug = useRecoilValue(isDebugValue);
export const isDebugValue = selector({
    key: 'isDebugValue',
    get: ({ get }) => {
        return get(isDebugAtom);
    }
});
