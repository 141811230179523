import { Box, Collapse, IconButton, Paper, Typography } from '@mui/material';
import { cloneDeep, get, includes, remove } from 'lodash';
import React, { ReactElement, useContext } from 'react';
import { H } from '../Layout';
import { useStyling } from '../Theme';
import { Icons } from '../icons/Icons';
import { FormContext } from './FormContext';

const checkCompletness = (fields, required, errors) => {
    const initialValue = 0;
    return fields.reduce((previousValue, field) => {
        const req = get(required, field);
        const err = get(errors, field);
        return previousValue + (req || err ? 1 : 0);
    }, initialValue);
};

type FormSectionProps = {
    id: string;
    title?: string | ReactElement;
    fields?: string[];
    hide?: boolean;
    isCollapsible?: boolean;
    renderCollapsed?: boolean;
    Component: any;
    componentProps?: any;
};

const Dot = ({ complete }) => {
    const { theme } = useStyling();
    const colorSuccess = theme.palette.success.main;
    const colorError = theme.palette.error.main;

    return (
        <Box
            sx={{
                mr: 1,
                width: '8px',
                height: '8px',
                borderRadius: '4px',
                backgroundColor: complete ? colorSuccess : colorError
            }}
        />
    );
};

export default (props: FormSectionProps) => {
    const { id, title = undefined, fields = [], hide = false, isCollapsible = true, renderCollapsed = true, Component, componentProps = undefined } = props;
    const { validation, expandedSections, setExpandedSections, allowMultipleExpansion } = useContext(FormContext);
    const completness = validation ? checkCompletness(fields, validation.required, validation.errors) : 0;
    const isComplete = completness === 0;
    const containerSx = {
        pt: 1,
        pb: 1,
        pl: 2,
        pr: 2,
        width: '100%',
        '& ._formPanel': {
            '& ._formContainer:first-of-type': {
                '& ._formItem': {
                    paddingTop: title ? '8px !important' : '24px !important'
                }
            }
        }
    };
    const titleSx = {};

    const expandCollapse = () => {
        if (isCollapsible) {
            let expanded = cloneDeep(expandedSections);
            if (includes(expanded, id)) {
                remove(expanded, (section) => section === id);
            } else if (allowMultipleExpansion) {
                expanded.push(id);
            } else {
                expanded = [id];
            }
            setExpandedSections(expanded);
        }
    };

    const isExpanded = includes(expandedSections, id);
    return hide ? (
        <React.Fragment />
    ) : (
        <Paper elevation={isExpanded ? 5 : 0} sx={containerSx} data-testid="paper">
            {title ? (
                <H className={`_formSection.header${isCollapsible ? ' clickable' : ''}`} sx={titleSx} onClick={expandCollapse}>
                    <H className={'_formSection.header.title'} sx={{ flexGrow: 2 }}>
                        <Dot complete={isComplete} />
                        <Typography fontWeight={'bold'}>
                            {title}
                            {completness > 0 ? ` (${completness})` : ''}
                        </Typography>
                    </H>
                    <H className={'_formSection.header.actions'} sx={{ width: 'unset', flexGrow: 0 }}>
                        {isCollapsible ? (
                            <IconButton sx={{ ml: 1 }} size="small" color={'primary'}>
                                {isExpanded ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
                            </IconButton>
                        ) : (
                            <React.Fragment />
                        )}
                    </H>
                </H>
            ) : (
                <React.Fragment />
            )}
            <Collapse in={isExpanded} timeout={100}>
                {isExpanded || renderCollapsed ? <Component {...componentProps} /> : <React.Fragment />}
            </Collapse>
        </Paper>
    );
};
