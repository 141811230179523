import { V } from '../../common/Layout';
import { Typography } from '@mui/material';
import React from 'react';
import { Type } from '../attributes';

type NumberTypePreviewProps = {
    field: Type;
};

export default (props: NumberTypePreviewProps) => {
    const { field } = props;
    return (
        <V>
            <Typography fontSize={'medium'}>{field.typeName}</Typography>
            <Typography fontSize={'small'}>{field.description}</Typography>
        </V>
    );
};
