import React from 'react';
import { KeyValueMap } from '../../../common/KeyValueMap';
import ResponsiveToolbar from '../../../common/components/ResponsiveToolbar';
import { ActionType } from '../../../common/components/ActionType';
import { Icons } from '../../../common/icons/Icons';

export const paragraphTypes: KeyValueMap<string, ActionType> = {
    paragraph: { label: 'Normal' },
    h1: { label: 'Heading 1' },
    h2: { label: 'Heading 2' },
    h3: { label: 'Heading 3' },
    h4: { label: 'Heading 4' },
    h5: { label: 'Heading 5' },
    h6: { label: 'Heading 6' },
    // code: 'Code Block',
    quote: { label: 'Quote' }
};

export default ({ blockType, setBlockType, disabled }) => {
    return <ResponsiveToolbar disabled={disabled} isSmall={true} selected={blockType} onSelect={setBlockType} options={paragraphTypes} icon={Icons.Abc} />;
};
