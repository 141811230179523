import React from 'react';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useStyling } from '../Theme';
import I18n from '../i18n/I18n';

type SectionTitleProps = {
    i18nToken?: string;
    containerSx?: SxProps;
    children?: any;
};

export default (props: SectionTitleProps) => {
    const { i18nToken, children, containerSx } = props;
    const { theme } = useStyling();

    return <Typography sx={{ color: theme.palette.primary.main, my: 1, ...containerSx }}>{!!i18nToken && !children ? <I18n token={i18nToken} /> : children}</Typography>;
};
