import Chip from '@mui/material/Chip';
import React from 'react';
import { H, V } from '../../Layout';
import Loading from '../../components/Loading';
import FormLabel from '../FormLabel';
import { Radio, SxProps, Tooltip, Typography } from '@mui/material';
import { findIndex } from 'lodash';

export enum RendererType {
    CHIPS,
    RADIO
}

type OptionsRendererProps = {
    value: any;
    label: string;
    options: any[];
    isOptionEqualToValue: (option, value) => boolean;
    onChange: (value) => void;
    size?: 'small' | 'medium';
    rendererType?: RendererType;
    loading?: boolean;
    containerSx?: SxProps;
};
export default (props: OptionsRendererProps) => {
    const {
        value,
        label,
        options,
        onChange,
        size = 'medium',
        loading = false,
        rendererType = RendererType.CHIPS,
        isOptionEqualToValue,
        containerSx
    } = props;
    const isArray = Array.isArray(value);
    return (
        <V sx={{ width: '100%', gap: 1, mb: 1, ...containerSx }}>
            <FormLabel label={label} />
            <H
                sx={{
                    justifyContent: 'flex-start',
                    gap: rendererType === RendererType.CHIPS ? 2.5 : 0,
                    overflowX: 'hide',
                    flexWrap: 'wrap'
                }}
            >
                {loading ? (
                    <Loading size={'18px'} center={false} />
                ) : (
                    options.map((option, index) => {
                        const isChecked = isArray
                            ? findIndex(value, (v) => isOptionEqualToValue(option, v)) > -1
                            : isOptionEqualToValue(option, value);
                        return rendererType === RendererType.RADIO ? (
                            <H
                                key={index}
                                className="clickable"
                                sx={{ mr: 1, maxWidth: '150px' }}
                                onClick={() => {
                                    isChecked ? onChange(undefined) : onChange(option);
                                }}
                            >
                                <Radio checked={isChecked} />
                                <Typography className={'ellipsis'}>{option.label}</Typography>
                            </H>
                        ) : (
                            <Tooltip key={index} title={option.label}>
                                <Chip
                                    sx={{ maxWidth: '150px' }}
                                    size={size}
                                    color="primary"
                                    label={option.label}
                                    onClick={() => {
                                        isChecked ? onChange(undefined) : onChange(option);
                                    }}
                                    variant={isChecked ? 'filled' : 'outlined'}
                                    disabled={option.disabled}
                                />
                            </Tooltip>
                        );
                    })
                )}
            </H>
        </V>
    );
};
