import React, { Fragment } from 'react';
import { IconButton } from '@mui/material';
import { ActionType } from './ActionType';

export default ({ actions }: { actions: ActionType[] }) =>
    actions.map((action: ActionType, index: number) => (
        <Fragment key={index}>
            {action.component ? (
                action.component()
            ) : (
                <IconButton size={'small'} onClick={action.onClick}>
                    {action.icon ? <action.icon fontSize={'small'} /> : <></>}
                </IconButton>
            )}
        </Fragment>
    ));
