import React, {createContext, ReactElement, useContext} from 'react';

// Context
export type I18nContextType = { langBundle: any; defaultBundle: any };
export const I18nContext = createContext<I18nContextType>(undefined);
type I18nProviderProps = { langBundle: any; defaultBundle; children: React.ReactNode };
export const I18nProvider = ({ langBundle, defaultBundle, children }: I18nProviderProps) => {
    return <I18nContext.Provider value={{ langBundle, defaultBundle }}>{children}</I18nContext.Provider>;
};

type SubstitutionMapType = {
    [key: string]: string | number | boolean;
};
type I18NProps = {
    token: string;
    values?: SubstitutionMapType;
};

export default (props: I18NProps): ReactElement => {
    const { token, values = undefined } = props;
    return <>{useI18n(token, values)}</>;
};

const resolveI18nToken = (context: I18nContextType, token: string, values?: { [p: string]: string | number | boolean }) => {
    let val = context.langBundle[token] ? context.langBundle[token] : context.defaultBundle[token];
    if (val) {
        if (values) {
            for (const key in values) {
                val = val.replaceAll('{' + key + '}', values[key]);
            }
        }
    } else {
        console.warn(`Please add ${token} to language file`);
    }
    return val || token;
};

/**
 *
 * @param token string token used to look up a message from a language file.
 * @param values key/value pair map for string substitution.
 */
export const useI18n = (token, values: SubstitutionMapType = undefined): string => {
    if (typeof values === 'string') {
        console.error('useI18n signature requires 2nd argument to be object, not string');
    }
    const context: I18nContextType = useContext(I18nContext);
    return resolveI18nToken(context, token, values);
};

export const getI18nFunction = (context: I18nContextType) => {
    return (token, values) => {
        return resolveI18nToken(context, token, values);
    };
};
