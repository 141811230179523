import React, { useContext } from 'react';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';
import FormWidget from '../../common/form/FormWidget';
import FormItem from '../../common/form/FormItem';
import I18n from '../../common/i18n/I18n';
import { FormContext } from '../../common/form/FormContext';
import { isEmpty, isEqual } from 'lodash';
import { getActivityOptions } from '../../production/resource/ResourceAssociatedActivityList';
import AssociateActivityOptionRenderer from '../../production/resource/AssociateActivityOptionRenderer';
import InquiryProductionFormItems from './inquiry/InquiryProductionFormItems';
import InquiryResourceFormItems from './inquiry/InquiryResourceFormItems';
import InquiryDateRangeFormItem from './inquiry/InquiryDateRangeFormItem';

export default ({ data }) => {
    const { state } = useContext(FormContext);

    return (
        state && (
            <FormPanel>
                <FormItem>
                    <FormWidget
                        name="organizations"
                        label={<I18n token="organization.invite.form.label.organization" />}
                        component="ReferenceAutocomplete"
                        entity={'Organization'}
                        data-testid={'organization'}
                        multiple
                        disabled
                        preload={false}
                    />
                </FormItem>
                <InquiryResourceFormItems data={data} />
                <InquiryDateRangeFormItem dateRange={data?.dateRange} />
                <InquiryProductionFormItems
                    productionOptions={data?.productionOptions}
                    productionName={data?.productionName}
                />
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            name="productionSummary"
                            component={'TextArea'}
                            label={<I18n token="organization.invite.form.label.production.summary" />}
                            minRows={3}
                            disabled={!isEmpty(data.productionSummary)}
                        />
                    </FormItem>
                </FormContainer>
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            multiple
                            name="activities"
                            component="ReferenceAutocomplete"
                            entity="Activity"
                            label={<I18n token="organization.invite.form.label.production.schedule" />}
                            filter={{
                                expressions: [
                                    { field: 'production', value: { values: [data?.production?.reference.id] } }
                                ]
                            }}
                            disableIfOptionUnavailable
                            getOption={getActivityOptions}
                            OptionRenderer={AssociateActivityOptionRenderer}
                        />
                    </FormItem>
                </FormContainer>
                <FormContainer>
                    <FormItem>
                        <FormWidget
                            name="personalizedMessage"
                            component={'TextArea'}
                            label={<I18n token="organization.invite.form.label.message" />}
                            minRows={3}
                        />
                    </FormItem>
                </FormContainer>
            </FormPanel>
        )
    );
};
