import { Box } from '@mui/material';
import React from 'react';
import { grey } from '@mui/material/colors';
import { Icons } from '../icons/Icons';
import { useStyling } from '../Theme';

export default ({ Icon = undefined, bottomBorder = false, children, sx = {}, emptyIcon = false, ...rest }) => {
    const { theme } = useStyling();
    return (
        <Box
            className="_formPanel"
            sx={[
                {
                    // Decrease the top padding for the first 'line' in the form.
                    '& ._formContainer:first-of-type': {
                        '& ._formItem': {
                            paddingTop: '8px !important'
                        }
                    },
                    p: 1,
                    pr: 2,
                    gap: Icon || emptyIcon ? 0 : 2,
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: bottomBorder ? 1 : 'none',
                    borderColor: grey['800'],
                    borderRadius: 1,
                    width: '100%'
                },
                sx
            ]}
            {...rest}
        >
            {Icon ? (
                <Box sx={{ flexGrow: 0, alignSelf: 'center' }}>
                    <Icon size="large" color="disabled" />
                </Box>
            ) : (
                emptyIcon && (
                    <Box sx={{ flexGrow: 0, alignSelf: 'center' }}>
                        <Icons.Abc sx={{ visibility: 'hidden' }} color="disabled" />
                    </Box>
                )
            )}
            <Box sx={{ paddingLeft: 1, gap: 1, flexGrow: 2, width: '100%' }}>{children}</Box>
        </Box>
    );
};
