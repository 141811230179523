import React, {useContext, useMemo} from 'react';
import _, {forEach, get, isEmpty, isFunction, set} from 'lodash';
import {parsePhoneNumberFromString} from 'libphonenumber-js/max';
import {isValid, parseISO} from 'date-fns';
import {parseNumber} from '../utils/numbers';
import {getI18nFunction, I18nContext, I18nContextType} from '../i18n/I18n';

// const ISO_DATE_FORMAT_REGEX = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
// const ISO_DATE_FORMAT = 'YYYY-MM-DD';
const EMAIL_FORMAT_REGEX = /^([a-zA-Z0-9!#$%&'*+/=?^_`‘’{|}~.\-])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const NA_PHONE_NUMBER_REGEX = /^\(?\d{3}\)?-?\d{3}-?\d{4}$/;
const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

type ValidatorOptions = {
    reportUnmodified?: boolean;
    runAll?: boolean;
    i18nContext: I18nContextType;
};

const DEFAULT_OPTIONS: ValidatorOptions = {
    reportUnmodified: true,
    runAll: false, // By default, validator exists after first invalid property.
    i18nContext: undefined
};

export type ValidatorType = {
    isValid: (prop, state, isModified, resolveI18nToken) => boolean;
    isRequired?: boolean;
    messageKey?: string;
};

export type AvailableRules = 'isRequired' | 'isValidDate' | 'isNumber' | 'isNAPhoneNumber' | 'phoneNumber' | 'email' | 'url';

export type RulesType = {
    [key in AvailableRules]: ValidatorType;
};

export type Validators = {
    [key in string]: ValidatorType[];
};

const rules: RulesType = {
    isRequired: {
        isValid: (prop, state, isModified, resolveI18nToken) => {
            const value = _.get(state, prop);
            const isDate = Object.prototype.toString.call(value) === '[object Date]';
            const valid = !isEmpty(value) || (isDate && !isNaN(value.getTime())); // || isValid;
            return valid ? true : resolveI18nToken('validation.value.required');
        },
        isRequired: true
    },
    isValidDate: {
        isValid: (prop, state, isModified, resolveI18nToken) => {
            const value = _.get(state, prop);
            const empty = isEmpty(value);
            if (empty) {
                return true;
            } else {
                const date = parseISO(value);
                const valid = isValid(date);
                return valid ? true : resolveI18nToken('validation.date.invalid');
            }
        }
    },
    isNumber: {
        isValid: (prop, state, isModified, resolveI18nToken) => {
            const value = _.get(state, prop);
            const num = typeof value === 'number' ? value : parseNumber(value).parsed;
            // const NUMBER = /^[0-9|,.-]+$/;
            const valid = isEmpty(value) || !isNaN(num); // NUMBER.test(value);
            return valid ? true : resolveI18nToken('validation.number.invalid');
        }
    },
    isNAPhoneNumber: {
        isValid: (prop, state, isModified, resolveI18nToken) => {
            const value = _.get(state, prop);
            const valid = isEmpty(value) || NA_PHONE_NUMBER_REGEX.test(value);
            return valid ? true : resolveI18nToken('validation.naphone.invalid');
        }
    },
    phoneNumber: {
        isValid: (prop, state, isModified, resolveI18nToken) => {
            const value = _.get(state, prop);
            if (value) {
                const phoneNumber = parsePhoneNumberFromString(value);
                const valid = !phoneNumber || (phoneNumber.nationalNumber ? phoneNumber.isValid() : true);
                return valid ? true : resolveI18nToken('validation.phone.invalid');
            } else {
                return true;
            }
        }
    },
    email: {
        isValid: (prop, state, isModified, resolveI18nToken) => {
            const value = _.get(state, prop);
            const valid = isEmpty(value) || EMAIL_FORMAT_REGEX.test(value);
            return valid ? true : resolveI18nToken('validation.email.invalid');
        }
    },
    url: {
        isValid: (prop, state, isModified, resolveI18nToken) => {
            const value = _.get(state, prop);
            // to resolve validation error that we get at the start for default value as https
            const valid = isEmpty(value) || URL_REGEX.test(value);
            return valid ? true : resolveI18nToken('validation.url.invalid');
        }
    }
};

export interface ValidationState {
    isValid: boolean;
    errors: {[key in string]: string[]};
    modified?: {[key in string]: boolean};
    required?: {[key in string]: boolean};
}

// TODO use I18n context
const mockBundle = {
    'validation.valueRquired': 'This field is required',
    'validation.invalidEmail': 'Invalid email address'
};

export const useValidator = (rules: Validators, state = undefined) => {
    const i18nContext: I18nContextType = useContext(I18nContext);

    const validator: Validator = useMemo(() => {
        return new Validator(rules, {
            i18nContext: i18nContext
        });
    }, [i18nContext, rules]);

    const validationState = useMemo(() => {
        if (validator && state) {
            return validator.validate(state);
        }
    }, [validator, state]);

    return [validator, validationState];
};

export default class Validator {
    static RULES: RulesType = rules;
    static Custom = (func, messageKey?): ValidatorType => {
        return {
            isValid: (prop, state, isModified) => {
                return func(prop, state, isModified);
            },
            messageKey: messageKey
        };
    };

    rules: Validators;
    modified: {[key in string]: boolean};
    errors: {[key in string]: string[]};
    required: {[key in string]: boolean};
    bundle: {[key in string]: string};
    options: ValidatorOptions;
    resolve: (token:string, values:string[]) => string;

    constructor(rules: Validators, options: ValidatorOptions) {
        this.rules = rules;
        this.modified = {};
        this.errors = {};
        this.required = {};
        this.options = { ...DEFAULT_OPTIONS, ...options };
        this.resolve = options.i18nContext ? getI18nFunction(options.i18nContext) : undefined;
        this.validate = this.validate.bind(this);
        this.init();
    }

    /**
     * Here we'll check the initial values of properties.
     */
    init() {}

    invalidate(prop, message?) {
        this.errors[prop] = [message];
        this.modified[prop] = true;
    }

    validate(state, modifiedProp?):ValidationState {
        const self = this;
        self.required = {};
        self.errors = {};
        let hasErrors = false;

        if (modifiedProp) {
            set(self.modified, modifiedProp, true);
        }

        forEach(self.rules, (validators: Array<ValidatorType>, prop) => {
            if (get(self.modified, prop) || self.options.reportUnmodified) {
                const errors = [];
                forEach(validators, (rule) => {
                    const isValid = isFunction(rule.isValid) ? rule.isValid(prop, state, get(self.modified, prop), self.resolve) : false;
                    if (typeof isValid === 'string') {
                        hasErrors = true;
                        if (rule.isRequired) {
                            set(self.required, prop, true);
                        } else {
                            errors.push(isValid);
                        }
                    } else if (typeof isValid === 'boolean' && !isValid) {
                        hasErrors = true;
                        if (rule.isRequired) {
                            set(self.required, prop, true);
                        } else if (rule.messageKey) {
                            const message = mockBundle[rule.messageKey] ? mockBundle[rule.messageKey] : rule.messageKey;
                            errors.push(message);
                        } else {
                            console.warn('Missing messageKey on validation rule for:', rule);
                        }
                    }
                    if (hasErrors && !self.options.runAll) {
                        return false;
                    }
                });
                if (errors.length > 0) {
                    set(self.errors, prop, errors);
                } else {
                    // delete self.errors[prop];
                    set(self.errors, prop, undefined);
                }
            }
        });
        return {
            isValid: !hasErrors,
            errors: self.errors,
            required: self.required,
            modified: self.modified
        };
    }

    isValid() {
        return isEmpty(this.errors);
    }

    // isModified(prop) {
    //     return this.modified[prop];
    // }

    // getErrors(prop) {
    //     return this.errors[prop];
    // }

    hasErrors(prop) {
        return Array.isArray(this.errors[prop]) && this.errors[prop].length > 0;
    }
}

export const validateEmail = (email) => {
    return isEmpty(email) || EMAIL_FORMAT_REGEX.test(email);
};
