import axios from 'axios';

export const getTimezone = (lat: number, lon: number) => {
    const latlon = [lat, lon].join(',');
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${latlon}&timestamp=1331161200&key=${window._env_.GOOGLE_MAPS_LOCATION_API_KEY}`;
    return axios
        .get(url)
        .then((response) => {
            return {
                id: response.data.timeZoneId,
                name: response.data.timeZoneName
            };
        })
        .catch(function (error) {
            console.error(error);
        });
};
