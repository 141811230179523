import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { InquiryInput } from 'sr-types/lib/shortlist/v1/graphql';
import { useMutationWithContext } from '../../../common/hooks/useMutationWithContext';
import { failedToastMessage, successToastMessage } from '../../../common/utils/commonUtils';
import { detailsQuery } from '../../../production/helpers/production';
import { client } from '../../shortlist';

export const SendInquiries: DocumentNode = gql`
    mutation sendInquiries($inquiries: [InquiryInput]!) {
        sendInquiries(inquiries: $inquiries) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const useSendInquiry = () => {
    const [sendInquiry, { loading: isInquirySending }] = useMutationWithContext(SendInquiries, client);

    const sendInquiryInvitation = (inquiries: InquiryInput[]) => {
        return new Promise<void>((resolve, reject) => {
            sendInquiry({
                variables: { inquiries }
            })
                .then(() => {
                    successToastMessage('form.shortlistLineItem.inquiry.success');
                    resolve();
                })
                .catch(() => {
                    failedToastMessage('form.shortlistLineItem.inquiry.error');
                    reject();
                })
                .finally(() => {
                    setTimeout(() => {
                        client.refetchQueries({
                            include: [detailsQuery]
                        });
                    }, 2000);
                });
        });
    };

    return { sendInquiryInvitation, isInquirySending };
};
