import { ApolloProvider, useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { SearchkitClient, SearchkitProvider } from '@searchkit/client';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UserContext } from '../../common/auth/UserContext';
import { usePersistedSearchkitVariables } from '../../common/components/filtering/filters';
import Loading from '../../common/components/Loading';
import { constants } from '../../common/constants';
import FormModal from '../../common/form/FormModal';
import I18n, { useI18n } from '../../common/i18n/I18n';
import DataList from '../../common/list/DataList';
import ListView from '../../common/list/ListView';
import { searchClient, slimResultsQuery } from '../../common/list/slimQuery';
import { ActivityFormType, EMPTY_ACTIVITY, getKeyActivitiesRefs } from '../helpers/activityUtils';
import { ProductionTypeSuperset, useSaveKeyActivities } from '../helpers/productionApi';
import ActivityForm from './ActivityForm';
import ActivityListTile from './ActivityListTile';

const List = ({
    id,
    loading,
    data,
    entity,
    feature,
    productionId,
    planId,
    setSelectedActivity,
    setIsCreate,
    setPrepopulatedActivity,
    viewStateStableRef,
    setViewState,
    ...props
}) => {
    const { saveKeyActivities } = useSaveKeyActivities();
    const { activeOrganizationAccount } = useContext(UserContext);
    const isButtonLoading = useRef({ id: '', isLoading: false });
    const { variables } = usePersistedSearchkitVariables(id);

    //need to add reference field for activities in production query on service
    const { data: activityData } = useQuery(slimResultsQuery('Activity'), {
        variables: {
            filters: [
                {
                    identifier: 'entity',
                    value: 'Activity'
                },
                {
                    identifier: 'production',
                    value: productionId
                }
            ],
            page: { size: 1000, from: 0 }
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true,
        skip: !productionId
    });

    const keyActivities = useMemo(() => {
        if (viewStateStableRef.current?.plans?.[0]?.keyActivities) {
            return viewStateStableRef.current.plans[0].keyActivities.map((activity) => activity.id);
        }
        return [];
    }, [viewStateStableRef.current?.plans?.[0].keyActivities]);

    const dataWithKeyDates = useMemo(() => {
        if (data?.results?.hits?.items) {
            return data?.results?.hits?.items.map((item) => {
                return { ...item, isKeyDate: keyActivities.includes(item.id) };
            });
        } else return [];
    }, [data?.results?.hits?.items, keyActivities]);

    return !loading ? (
        dataWithKeyDates && viewStateStableRef.current && (
            <DataList
                {...props}
                id={id}
                feature={feature}
                pagination={data?.results?.hits?.page}
                items={dataWithKeyDates}
                emptyListElement={
                    <Button
                        onClick={() => {
                            setIsCreate(true);
                            setPrepopulatedActivity({ ...EMPTY_ACTIVITY, name: variables.query });
                        }}
                    >
                        <I18n token="form.activity.createNewActivity" />
                    </Button>
                }
                ItemRenderer={(props) => {
                    return (
                        <ActivityListTile
                            {...props}
                            isLoading={isButtonLoading}
                            markActiivtyId={(activityId) => {
                                isButtonLoading.current = { id: activityId, isLoading: true };
                                saveKeyActivities(
                                    planId,
                                    getKeyActivitiesRefs(
                                        activityId,
                                        keyActivities,
                                        activityData?.results?.hits?.items,
                                        true
                                    ),
                                    true
                                ).then((res) => {
                                    isButtonLoading.current = { id: '', isLoading: false };
                                    setViewState((prevVal) => {
                                        return {
                                            ...prevVal,
                                            plans: [
                                                {
                                                    ...viewStateStableRef.current.plans[0],
                                                    keyActivities: getKeyActivitiesRefs(
                                                        activityId,
                                                        keyActivities,
                                                        activityData?.results?.hits?.items,
                                                        true
                                                    )
                                                }
                                            ]
                                        } as ProductionTypeSuperset;
                                    });
                                });
                            }}
                            unmarkActivityId={(activityId) => {
                                isButtonLoading.current = { id: activityId, isLoading: true };
                                saveKeyActivities(
                                    planId,
                                    getKeyActivitiesRefs(
                                        activityId,
                                        keyActivities,
                                        activityData?.results?.hits?.items,
                                        false
                                    ),
                                    true
                                ).then((res) => {
                                    isButtonLoading.current = { id: '', isLoading: false };
                                    setViewState((prevVal) => {
                                        return {
                                            ...prevVal,
                                            plans: [
                                                {
                                                    ...viewStateStableRef.current.plans[0],
                                                    keyActivities: getKeyActivitiesRefs(
                                                        activityId,
                                                        keyActivities,
                                                        activityData?.results?.hits?.items,
                                                        false
                                                    )
                                                }
                                            ]
                                        } as ProductionTypeSuperset;
                                    });
                                });
                            }}
                            isDisabled={isButtonLoading.current.isLoading}
                        />
                    );
                }}
                disableRipple={true}
                onClick={(item) => {
                    !isButtonLoading.current.isLoading && setSelectedActivity(item.id);
                }}
            />
        )
    ) : (
        <Loading />
    );
};

export default ({
    open,
    onClose,
    planId = undefined,
    id = undefined,
    prefilters = [],
    productionId = undefined,
    activityId = undefined,
    setViewState,
    viewState
}) => {
    const searchkit = new SearchkitClient();
    const [selectedActivity, setSelectedActivity] = useState('');
    const [prepopulatedActivity, setPrepopulatedActivity] = useState<ActivityFormType>(EMPTY_ACTIVITY);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isCreate, setIsCreate] = useState(false);

    const activityFormRef = useRef(null);
    const viewStateSableRef = useRef(viewState);

    useEffect(() => {
        viewStateSableRef.current = viewState;
    }, [viewState]);

    useEffect(() => {
        if (activityId) {
            setSelectedActivity(activityId);
        }
    }, [activityId]);

    return (
        <FormModal
            width={'580px'}
            isOpen={open}
            onClose={() => {
                setSelectedActivity('');
                setIsCreate(false);

                onClose();
            }}
            title={<I18n token="form.activity.addKeyDate" />}
            id={`keyDates`}
            hideButtons={!(selectedActivity || isCreate)}
            hideDefaultButtons={true}
            additionalActions={[
                {
                    label: useI18n('dialog.cancel'),
                    onClick: () => {
                        setSelectedActivity(undefined);
                        setIsCreate(false);
                    },
                    variant: 'text'
                },
                {
                    label: useI18n('dialog.save'),
                    onClick: () => {
                        activityFormRef.current.handleSave().then(() => {
                            setIsCreate(false);
                            setSelectedActivity('');
                        });
                    },
                    variant: 'contained',
                    disabled: isSaveDisabled
                }
            ]}
        >
            {selectedActivity || isCreate ? (
                <ActivityForm
                    ref={activityFormRef}
                    isInModal={false}
                    showBasic={true}
                    onClose={() => {
                        setSelectedActivity('');
                        setIsCreate(false);
                    }}
                    // for now we are saving activity to the first plan, in future we will have plan list and plan details, add new activity form will be in plan details
                    planId={planId}
                    productionId={productionId}
                    activityId={selectedActivity}
                    setActivityId={setSelectedActivity}
                    prepopulatedActivity={prepopulatedActivity}
                    setPrepopulatedActivity={setPrepopulatedActivity}
                    isCreate={isCreate}
                    setIsSaveDisabled={setIsSaveDisabled}
                />
            ) : (
                <ApolloProvider client={searchClient}>
                    <SearchkitProvider client={searchkit}>
                        <>
                            <ListView
                                id={id}
                                entity="Activity"
                                feature="Activity"
                                uri={undefined}
                                query={slimResultsQuery('Activity')}
                                EntityListRenderer={(props) => {
                                    return (
                                        <List
                                            {...props}
                                            productionId={productionId}
                                            planId={planId}
                                            setSelectedActivity={setSelectedActivity}
                                            setPrepopulatedActivity={setPrepopulatedActivity}
                                            setIsCreate={setIsCreate}
                                            setViewState={setViewState}
                                            viewStateStableRef={viewStateSableRef}
                                        />
                                    );
                                }}
                                enableSearch={true}
                                preFilters={prefilters}
                                immutableFilters={[{ identifier: 'production', value: productionId }]}
                                showBreadCrumbs={false}
                                defaultSortModel={{ field: 'start', sort: 'asc' }}
                                containerSx={{ height: '100%', m: 0, p: 1 }}
                                facetsVisibility={{ filters: false, searchPanel: true, promotedFacets: false, quickFilter: false }}
                                enableSearchLoader={true}
                                searchPlaceholder={useI18n('activity.search.placeholder')}
                            />
                        </>
                    </SearchkitProvider>
                </ApolloProvider>
            )}
        </FormModal>
    );
};
