import React from 'react';
import FormItem from '../../common/form/FormItem';
import FormPanel from '../../common/form/FormPanel';
import FormWidget from '../../common/form/FormWidget';
import I18n from '../../common/i18n/I18n';
import { V } from '../../common/Layout';

export default () => {
    return (
        <FormPanel>
            <FormItem>
                <V sx={{ minHeight: '200px' }}>
                    <FormWidget name="content" label="Note" component="RichText" contentPlaceholder={<I18n token="shortlist.rich.text.contentPlaceholder" />} />
                </V>
            </FormItem>
        </FormPanel>
    );
};
