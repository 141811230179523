import React from 'react';
import DOMPurify from 'dompurify';
import { Alert, Typography } from '@mui/material';
import I18n from '../../../common/i18n/I18n';
import { useStyling } from '../../../common/Theme';
import {
    CREW_RESPONSE_ID,
    removeSpanFromHtmlText,
    VENDOR_KEY,
    VENDOR_RESPONSE_ID,
    VENDOR_SALUTATION_ID,
    CREW_SALUTATION_ID
} from '../opportunityInquiry';

export default ({
    content,
    numberOfProducts,
    inquiryFor,
    isPreview
}: {
    content: string;
    numberOfProducts?: number;
    inquiryFor: string;
    isPreview: boolean;
}) => {
    const { theme } = useStyling();

    const isVendor = inquiryFor === VENDOR_KEY;

    const responseId = isVendor ? VENDOR_RESPONSE_ID : CREW_RESPONSE_ID;
    let salutationId = '';
    if (isPreview) {
        salutationId = isVendor ? VENDOR_SALUTATION_ID : CREW_SALUTATION_ID;
    }

    const modifiedContent = removeSpanFromHtmlText(content, responseId, salutationId);

    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(modifiedContent, {
                        FORCE_BODY: true
                    })
                }}
            />
            {!isPreview && isVendor && (
                <Alert severity="info" sx={{ width: '100%', fontSize: '14px' }}>
                    {numberOfProducts ? (
                        <Typography color={theme.palette.text.primary}>
                            {numberOfProducts}
                            <I18n token={'form.products.available'} />
                        </Typography>
                    ) : (
                        <Typography color={theme.palette.text.primary}>
                            <I18n token={'form.products.unAvailable'} />
                        </Typography>
                    )}
                </Alert>
            )}
        </>
    );
};
