import React from 'react';
import { createSvgIcon } from '@mui/material';

export const ResponseReceived = createSvgIcon(
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.5 4.0019C3.5 2.43602 4.97746 1.16663 6.8 1.16663H21.2C23.0225 1.16663 24.5 2.43602 24.5 4.0019V17.405H22.7V4.0019C22.7 3.29014 22.0284 2.71314 21.2 2.71314H6.8C5.97157 2.71314 5.3 3.29014 5.3 4.0019V20.498C5.3 21.2098 5.97157 21.7868 6.8 21.7868L18.2071 21.6997V23.2462L6.8 23.3333C4.97746 23.3333 3.5 22.0639 3.5 20.498V4.0019Z"
            fill="currentColor"
            fillOpacity="0.54"
        />
        <path
            d="M16.9167 8.74996C16.9167 10.3541 15.6042 11.6666 14 11.6666C12.3958 11.6666 11.0833 10.3541 11.0833 8.74996C11.0833 7.14579 12.3958 5.83329 14 5.83329C15.6042 5.83329 16.9167 7.14579 16.9167 8.74996Z"
            fill="currentColor"
            fillOpacity="0.54"
        />
        <path
            d="M18.0542 13.9708C19.1333 14.4375 19.8333 15.4875 19.8333 16.6687V17.5H8.16667V16.6687C8.16667 15.4875 8.86667 14.4375 9.94583 13.9708C11.1854 13.4312 12.5562 13.125 14 13.125C15.4437 13.125 16.8146 13.4312 18.0542 13.9708Z"
            fill="currentColor"
            fillOpacity="0.54"
        />
        <path
            d="M23.089 18.0671C20.835 18.0671 19.0098 19.8965 19.0098 22.1505C19.0098 24.4045 20.835 26.2338 23.089 26.2338C25.3471 26.2338 27.1764 24.4045 27.1764 22.1505C27.1764 19.8965 25.3471 18.0671 23.089 18.0671Z"
            fill="#3640B2"
        />
    </svg>,
    'ResponseReceived'
);
