import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { BreakpointSize, isWithinBreakpoints } from './breakpoints';

export const useIsWithinBreakpoints = (sizes: BreakpointSize[], isActive: boolean = true) => {
    const [isWithinBreakpointsValue, setIsWithinBreakpointsValue] = useState<boolean>(
        isWithinBreakpoints(window.innerWidth, sizes)
    );

    const handleResize = useMemo(
        () =>
            debounce(() => {
                const newValue = isWithinBreakpoints(window.innerWidth, sizes);
                if (newValue !== isWithinBreakpointsValue) {
                    setIsWithinBreakpointsValue(newValue);
                }
            }, 100),
        [isWithinBreakpointsValue, sizes]
    );

    useEffect(() => {
        if (isActive) {
            window.removeEventListener('resize', handleResize);
            window.addEventListener('resize', handleResize);
            handleResize();
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize, isActive]);

    return isWithinBreakpointsValue;
};
