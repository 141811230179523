import React, { useEffect, useContext, useState, Fragment } from 'react';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';
import { FormContext } from '../../common/form/FormContext';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { getToken } from '../../common/auth/api';
import axios from 'axios';
import { useStyling } from '../../common/Theme';
import { H, V } from '../../common/Layout';
import EllipsisText from '../../common/components/EllipsisText';
import { isEmpty } from 'lodash';
import { Icons } from '../../common/icons/Icons';

const ResourceInfo = ({ webReferenceLink, searchResult }) => {
    const { theme } = useStyling();
    const Icon = Icons.SquareIcon;

    return (
        <Grid lg={3} md={4} sm={6} xs={12} width={'100%'}>
            <Box component="div" sx={{
                position: 'relative',
                '&:hover': {
                    '& .listItemActions': {
                        visibility: 'visible'
                    }
                },
                border: `1px solid ${theme.palette.primary.main}`
            }}>
                <H sx={{
                    height: '126px',
                    alignItems: 'stretch',
                    backgroundColor: theme.palette.background.paper,
                    flex: 1,
                    width: '100%'
                    }}
                    p={0}
                >
                    <H sx={{ alignItems: 'flex-start', height: '100%' }}>
                        <H
                            sx={{
                                height: '100%',
                                width: '85px',
                                padding: '12px 8px 12px 12px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {searchResult.image ? (
                                <img src={searchResult.image} style={{ height: '100%', width: '100%'}} />
                            ) : (
                                <Icon
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        color: theme.palette.background.default,
                                    }}
                                />
                            )}
                        </H>
                        <V p={2} sx={{ overflow: 'hidden', padding: '12px 12px 0px 0px' }}>
                            <V pb={'12px'}>
                                {searchResult.name && (
                                    <H pb={'4px'} sx={{ height: '100%' }}>
                                        <EllipsisText text={searchResult.name} />
                                    </H>
                                )}
                                {searchResult.summary &&
                                    (<Typography variant="subtitle2">
                                        {searchResult.summary}
                                    </Typography>
                                    )
                                }
                                <H pt={'12px'} sx={{ height: '100%' }}>
                                    <EllipsisText text={webReferenceLink} sx={{fontSize:"smaller", color:theme.palette.text.disabled}} />
                                </H>
                            </V>
                        </V>
                    </H>
                </H>
            </Box>
        </Grid>
    )
}

export default () => {
    const { state, validation } = useContext(FormContext)
    const [searchResult, setSearchResult] = useState({})
    const [isUrlValid, setIsUrlValid] = useState(false)

    const onSearch = (url: string, urlSearchText: string) => {
        const token = getToken()

        const formData = new FormData();
        formData.append('urlOrSearchText', urlSearchText);

        return axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: token ? 'Bearer ' + token.token : '',
                Accept: 'application/json'
            }
        });
    };
    
    useEffect(() => {
        if (state.webReferenceLink && validation && validation.modified && validation.modified.webReferenceLink && validation.errors && validation.errors.webReferenceLink === undefined) { 
            onSearch('/resourceinfocollector/v1/collect', state.webReferenceLink)
                .then(resp => {
                    if (!isEmpty(resp.data)) {
                        setSearchResult(resp.data)
                    } else {
                        const data = {
                            name: 'Information Not Available',
                        }
                        setSearchResult(data)
                    }
                    setIsUrlValid(true)
                })
                .catch((err) => {
                    console.log('Error: ' + err)
                    const data = {
                        name: 'Page Not Found',
                        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.'
                    }
                    setSearchResult(data)
                });
        } else if (!state.webReferenceLink && validation && validation.modified) {
            setIsUrlValid(false)
        }
    }, [validation, state.webReferenceLink]);

    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget name="webReferenceLink" label="Web Reference" />
                </FormItem>
            </FormContainer>
            {isUrlValid && !isEmpty(searchResult) && (
                <ResourceInfo webReferenceLink={state.webReferenceLink} searchResult={searchResult} />
            )}
        </FormPanel>
    );
};