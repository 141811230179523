import { ApolloProvider } from '@apollo/client';
import { Avatar, Grid, Link, Typography } from '@mui/material';
import { SearchkitClient, SearchkitProvider } from '@searchkit/client';
import React, { useContext } from 'react';
import { UserContext } from '../common/auth/UserContext';
import SidePanel from '../common/components/layout/SidePanel';
import { Icons } from '../common/icons/Icons';
import { H, ScrollableLayout, V } from '../common/Layout';
import DataList from '../common/list/DataList';
import ListView from '../common/list/ListView';
import { searchClient, slimResultsQuery } from '../common/list/slimQuery';
import { SUPPLIER } from '../common/nav/apps';
import { getInitials } from '../common/nav/UserAvatar';
import useHistory from '../common/utils/useHistory';

const UserTile = (props) => {
    const { location } = useHistory();
    const { id, name, productionRole, productionDepartment, title } = props.item;
    const productionInfo = [productionRole, title].filter(Boolean).join(' | ');
    const nameArray = name.split(' ');
    const fullName = {
        firstName: nameArray[0],
        lastName: nameArray[1]
    };
    return (
        <Grid item xs={12} sx={{ p: 1, width: '100%' }}>
            <H sx={{ gap: 2 }}>
                <Avatar>{getInitials(fullName)}</Avatar>
                <V sx={{ overflow: 'hidden' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>{name}</Typography>
                    <Typography sx={{ fontSize: 'small' }}>{productionInfo}</Typography>
                    <Typography sx={{ fontSize: 'small' }}>{productionDepartment}</Typography>
                </V>
                <Link
                    className="clickable"
                    target={'_blank'}
                    href={`${SUPPLIER.home}/people/${id}${location.search}`}
                    rel="noopener"
                >
                    <Icons.Launch />
                </Link>
            </H>
        </Grid>
    );
};

const Users = ({ data, loading }) => {
    return (
        <DataList
            loading={loading}
            items={data?.results?.hits?.items}
            ItemRenderer={UserTile}
            onClick={() => {}}
            showActions={false}
        />
    );
};

export default ({ productionName, onClose = undefined }) => {
    const searchkit = new SearchkitClient();
    const { application } = useContext(UserContext);
    return (
        <SidePanel
            id={'guests-contaner'}
            title={'Related People'}
            actions={
                onClose
                    ? [
                          {
                              icon: Icons.Close,
                              label: 'Close',
                              onClick: onClose
                          }
                      ]
                    : []
            }
        >
            <ScrollableLayout>
                <V sx={{ width: '90%' }}>
                    <ApolloProvider client={searchClient}>
                        <SearchkitProvider client={searchkit}>
                            <ListView
                                id="related-ppl"
                                feature="People Resources"
                                entity="Person"
                                uri={`${application.home}/fields`}
                                query={slimResultsQuery('Person', true)}
                                EntityListRenderer={Users}
                                preFilters={[
                                    { identifier: 'showName', value: productionName },
                                    { identifier: 'public', value: 'false' }
                                ]}
                                showBreadCrumbs={false}
                                enableSearch={true}
                            />
                        </SearchkitProvider>
                    </ApolloProvider>
                </V>
            </ScrollableLayout>
        </SidePanel>
    );
};
