import React, { Fragment, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { H, V } from '../../Layout';
import {
    Button,
    Dialog,
    DialogContent,
    Slider,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';
import { search } from 'sr-types';
import { useStyling } from '../../Theme';
import { UserContext } from '../../auth/UserContext';
import { useI18n } from '../../i18n/I18n';
import { SxProps } from '@mui/system';
import Cropper, { ReactCropperElement } from 'react-cropper';
import upload, { UPLOAD_URL } from '../../utils/upload';
import { enqueueSnackbar } from '../../components/Toast';
import { LoadingButton } from '@mui/lab';
import mime from 'mime';
import { Icons } from '../../icons/Icons';

const MIN_ZOOM = 0.1;
const MAX_ZOOM = 3;
const DEFAULT_ZOOM = 0;

const ASPECTS = {
    '1:1': 1,
    '4:3': 4 / 3,
    '16:9': 16 / 9,
    Free: null
};

// const ORIENTATION_TO_ANGLE: any = {
//     3: 180,
//     6: 90,
//     8: -90
// };

export type ImageType = {
    id: string;
    name: string;
    type?: string;
    ref?: search.Reference;
    records?: any;
};

const resizeImage = (image, width, height) => {
    // Initialize the canvas and it's size
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.mozImageSmoothingEnabled = true;
    ctx.webkitImageSmoothingEnabled = true;
    ctx.msImageSmoothingEnabled = true;
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    // Set width and height
    canvas.width = width;
    canvas.height = height;

    // Draw image and export to a data-uri
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    const dataURI = canvas.toDataURL();

    // Do something with the result, like overwrite original
    // img.src = dataURI;
    return dataURI;
};

/*
cropbox.getData()
    height: 264.27360817974494
    rotate: 55
    scaleX: 1
    scaleY: 1
    width: 528.5472163594899
    x: 135.25490169612902
    y: 465.23603346212616
cropbox.getCanvasData()
    height: 407.449269348754
    left: 243.259696860145
    naturalHeight: 995.3972378226889
    naturalWidth: 1010.1317742989658
    top: -50.224634674377
    width: 413.48060627970995
cropbox.getCropBoxData()
    height: 108.17599694824224
    left: 298.6240366210938
    top: 140.21198168945327
    width: 216.3519938964845
*/
const CropperDialog = ({ name, file, imageSrc, dimensions, onChange, onClose, save = false }) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { isMobile } = useStyling();
    const previewLabel = useI18n('image.preview');
    const uploadLabel = useI18n('image.upload');
    const saveLabel = useI18n('image.save');
    const cancelLabel = useI18n('image.cancel');
    const zoomLabel = useI18n('image.zoom');
    const aspectLabel = useI18n('image.aspect');
    const rotationLabel = useI18n('image.rotation');
    const modeLabel = useI18n('image.mode');

    const [uploading, setUploading] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [croppedImage, setCroppedImage] = useState<Blob>(null);
    const [fileName, setFileName] = useState<string>(file.name);
    const [mode, setMode] = useState<'crop' | 'move'>('crop');
    const [showPreview, setShowPreview] = useState(false);
    const [aspect, setAspect] = useState('Free');
    const [cropperData, setCropperData] = useState();
    const [canvasData, setCanvasData] = useState();
    const [cropBoxData, setCropBoxData] = useState();
    const predefinedAspect = dimensions && dimensions[0] > 0 && dimensions[1] > 0;
    const cropperRef = useRef<ReactCropperElement>(null);
    const buttonsContainerSx: SxProps = {
        px: 2,
        py: 1,
        gap: 1,
        justifyContent: 'flex-end',
        flexGrow: 0,
        alignItems: 'flex-end'
    };
    const labelSx: SxProps = { width: 80 };

    // Going from preview to editing
    useEffect(() => {
        const cropper = cropperRef.current?.cropper;
        if (cropper && typeof croppedImage === 'undefined' && !showPreview) {
            // cropper.setData(cropperData);
            // cropper.setCropBoxData(cropBoxData);
            // cropper.setAspectRatio(cropBoxData.width / cropperData.height);
            // cropper.setCanvasData(canvasData);
            // cropper.zoomTo(zoom);
            // cropper.rotateTo(rotation);
        }
    }, [cropperRef.current, croppedImage]);

    // useEffect(() => {
    //     const cropper = cropperRef.current?.cropper;
    //     if (cropper) {
    //         cropper.zoomTo(zoom);
    //     }
    // }, [cropperRef.current, zoom]);

    useEffect(() => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.rotateTo(rotation);
        }
    }, [cropperRef.current, rotation]);

    useEffect(() => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.setDragMode(mode);
        }
    }, [cropperRef.current, mode]);

    useEffect(() => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.setAspectRatio(predefinedAspect ? dimensions[0] / dimensions[1] : ASPECTS[aspect]);
        }
    }, [cropperRef.current, aspect, dimensions]);

    const onUpload = () => {
        const ext = file.name.substring(file.name.lastIndexOf('.'));
        const mimeType = mime.getType(ext);
        const blobFile = new File([croppedImage], file.name, { type: mimeType });
        setUploading(true);
        upload(activeOrganizationAccount, UPLOAD_URL, blobFile)
            .then((res) => {
                setUploading(false);
                if (res.status === 201) {
                    enqueueSnackbar('Upload success', { variant: 'success' });
                    const ret: search.Reference = {
                        label: fileName,
                        ...res.data.reference
                    };
                    onChange(name, ret);
                    onClose();
                }
            })
            .catch((err) => {
                setUploading(false);
                enqueueSnackbar('Upload failed', { variant: 'error' });
            });
    };

    return (
        <Dialog keepMounted={false} open={true} fullScreen={isMobile} maxWidth={'md'} fullWidth={true}>
            <DialogContent sx={{ width: '100%', height: 480, overflow: 'hidden', p: 0 }}>
                <V sx={{ height: '100%' }}>
                    <V sx={{ display: showPreview ? 'flex' : 'none', alignItems: 'center', height: '100%' }}>
                        <H sx={{ overflow: 'hidden', p: 2, justifyContent: 'center', flexGrow: 2 }}>
                            <img
                                style={{
                                    objectFit: 'contain',
                                    maxHeight: '90%',
                                    maxWidth: '90%',
                                    filter: 'drop-shadow(5px 5px 10px #333)'
                                }}
                                src={showPreview && croppedImage ? URL.createObjectURL(croppedImage) : null}
                                alt="Image preview"
                            />
                        </H>
                        <H sx={buttonsContainerSx}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setCroppedImage(undefined);
                                    setShowPreview(false);
                                }}
                            >
                                {cancelLabel}
                            </Button>
                            <LoadingButton
                                loading={uploading}
                                disabled={uploading}
                                type={'button'}
                                variant="contained"
                                color="primary"
                                onClick={onUpload}
                            >
                                {save ? saveLabel : uploadLabel}
                            </LoadingButton>
                        </H>
                    </V>

                    <V sx={{ display: showPreview ? 'none' : 'flex', height: '100%' }}>
                        <H sx={{ position: 'relative', overflow: 'hidden' }}>
                            <Cropper
                                src={imageSrc}
                                style={{ height: '240px', width: '100%' }}
                                // Cropper.js options
                                initialAspectRatio={predefinedAspect ? dimensions[0] / dimensions[1] : ASPECTS[aspect]}
                                aspectRatio={
                                    predefinedAspect
                                        ? dimensions[0] / dimensions[1]
                                        : cropperData
                                          ? cropperData.width / cropperData.height
                                          : ASPECTS[aspect]
                                }
                                guides={true}
                                // crop={onCrop}
                                ref={cropperRef}
                                data={cropperData}
                                zoomTo={zoom}
                                rotatable
                                // rotateTo={rotation}
                                // zoom={(e) => {
                                //     console.log('zoom:', e);
                                // }}
                            />
                        </H>
                        <V sx={{ gap: 1, px: 2, py: 1 }}>
                            <H>
                                <Typography sx={labelSx} variant={'overline'}>
                                    Name
                                </Typography>
                                <TextField
                                    value={fileName}
                                    variant={'outlined'}
                                    size={'small'}
                                    onChange={(e) => setFileName(e.target.value)}
                                />
                            </H>
                            <H>
                                <Typography sx={labelSx} variant={'overline'}>
                                    {modeLabel}
                                </Typography>
                                <ToggleButtonGroup exclusive value={mode} onChange={(e, mode) => setMode(mode)}>
                                    <ToggleButton value={'crop'} selected={mode === 'crop'}>
                                        <Icons.Crop fontSize={'small'} />
                                    </ToggleButton>
                                    <ToggleButton value={'move'} selected={mode === 'move'}>
                                        <Icons.OpenWith fontSize={'small'} />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </H>
                            {predefinedAspect ? (
                                <Fragment />
                            ) : (
                                <H>
                                    <Typography sx={labelSx} variant={'overline'}>
                                        {aspectLabel}
                                    </Typography>
                                    <ToggleButtonGroup
                                        exclusive
                                        value={aspect}
                                        onChange={(e, aspect) => setAspect(aspect)}
                                    >
                                        {Object.keys(ASPECTS).map((key, index) => {
                                            return (
                                                <ToggleButton key={index} value={key} selected={aspect === key}>
                                                    {key}
                                                </ToggleButton>
                                            );
                                        })}
                                    </ToggleButtonGroup>
                                </H>
                            )}
                            <H>
                                <Typography sx={labelSx} variant={'overline'}>
                                    {zoomLabel}
                                </Typography>
                                <Slider
                                    sx={{ ml: 2 }}
                                    value={zoom}
                                    min={MIN_ZOOM}
                                    max={MAX_ZOOM}
                                    step={0.1}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </H>
                            <H>
                                <Typography sx={labelSx} variant={'overline'}>
                                    {rotationLabel}
                                </Typography>
                                <Slider
                                    sx={{ ml: 2 }}
                                    value={rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    onChange={(e, rotation) => setRotation(rotation)}
                                />
                            </H>
                        </V>
                        <H sx={buttonsContainerSx}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {cancelLabel}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const cropper = cropperRef.current?.cropper;
                                    cropper
                                        .getCroppedCanvas({
                                            imageSmoothingEnabled: true,
                                            imageSmoothingQuality: 'high',
                                            width: dimensions.width,
                                            height: dimensions.height
                                        })
                                        .toBlob((blob) => {
                                            setCroppedImage(blob);
                                            setCropperData(cropper.getData(false));
                                            setCanvasData(cropper.getCanvasData());
                                            setCropBoxData(cropper.getCropBoxData());
                                            setShowPreview(true);
                                        }, file.type);
                                }}
                            >
                                {previewLabel}
                            </Button>
                        </H>
                    </V>
                </V>
            </DialogContent>
        </Dialog>
    );
};

type AttachmentWidgetProps = {
    name: string;
    onChange: (name: string, ret: ImageType, coarceToArray: boolean) => void;
    dimensions: [width: number, height: number];
    label?: string | ReactElement;
    save?: boolean;
    buttonSx?: SxProps;
};

export default (props: AttachmentWidgetProps) => {
    const { name, label = useI18n('image.add'), onChange, dimensions, save, buttonSx } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState<File>();
    const [imageSrc, setImageSrc] = React.useState(null);

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            try {
                const file = e.target.files[0];
                let imageDataUrl = await readFile(file);
                // const orientation = await getOrientation(file);
                // const rotation = ORIENTATION_TO_ANGLE[orientation];
                // if (rotation) {
                //     imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
                // }
                setFile(file);
                setImageSrc(imageDataUrl);
                setIsDialogOpen(true);
            } catch (err) {
                console.warn('Failed to open file', err);
            }
        }
    };

    return (
        <>
            <H sx={{ width: 'unset', whiteSpace: 'nowrap', alignItems: 'flex-end' }}>
                <Button variant="text" color="primary" component="label" sx={buttonSx}>
                    {label}
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        hidden
                        onInput={onFileChange}
                        onClick={() => {
                            if (fileInputRef && fileInputRef.current) {
                                fileInputRef.current.value = null;
                            }
                        }}
                    />
                </Button>
            </H>

            {isDialogOpen ? (
                <CropperDialog
                    file={file}
                    name={name}
                    imageSrc={imageSrc}
                    dimensions={dimensions}
                    onChange={onChange}
                    onClose={() => {
                        setIsDialogOpen(false);
                    }}
                    save={save}
                />
            ) : (
                <Fragment />
            )}
        </>
    );
};
