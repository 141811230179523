import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FormContainer } from '../../../common/form/FormContainer';
import { FormContext } from '../../../common/form/FormContext';
import FormItem from '../../../common/form/FormItem';
import FormWidget from '../../../common/form/FormWidget';
import I18n from '../../../common/i18n/I18n';

export default ({ data }) => {
    const { handleChange } = useContext(FormContext);

    const [isEditing, setIsEditing] = useState(!data.resourceNote);

    useEffect(() => {
        if (data?.resourceNote && isEmpty(data.resourceNote?.text)) {
            setIsEditing(true);
        }
    }, [data?.resourceNote]);

    return (
        <>
            <FormContainer>
                <FormItem>
                    <FormWidget
                        name="resource"
                        label={<I18n token="organization.invite.form.label.resource" />}
                        component="ReferenceAutocomplete"
                        entity={'Resource'}
                        data-testid={'resource'}
                        disabled
                        preload={false}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        component="MetadataAutocomplete"
                        metadataName="Category"
                        name="resourceCategory"
                        label={<I18n token="organization.invite.form.label.resourceCategory" />}
                        data-testid={'resource-category'}
                        disabled
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        name="locations"
                        component="Location"
                        label={<I18n token="organization.invite.form.label.locations" />}
                        multiple
                        data-testid={'location'}
                        disabled={Boolean(data?.locations?.length)}
                    />
                </FormItem>
            </FormContainer>
            <FormContainer title={<I18n token="organization.invite.form.label.resource.notes" />}>
                <FormItem>
                    <FormWidget
                        name="resourceNote.content"
                        label={<I18n token="organization.invite.form.label.resource.notes" />}
                        component="RichText"
                        contentPlaceholder={<I18n token="resource.notes.form.content.placeholder" />}
                        onSave={undefined}
                        onCancel={undefined}
                        readOnly={!isEditing}
                        disabled={data?.resourceNotes}
                        getTextFromContent={(text: string) => handleChange('resourceNote.text', text)}
                    />
                </FormItem>
            </FormContainer>
        </>
    );
};
