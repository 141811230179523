import { REDO_COMMAND, UNDO_COMMAND } from 'lexical';
import React from 'react';
import { KeyValueMap } from '../../../common/KeyValueMap';
import { ActionType } from '../../../common/components/ActionType';
import ResponsiveToolbar from '../../../common/components/ResponsiveToolbar';
import { Icons } from '../../../common/icons/Icons';

export const undoRedo = (editor): KeyValueMap<string, ActionType> => {
    return {
        undo: {
            label: 'Undo',
            icon: Icons.Undo,
            onClick: () => {
                editor.dispatchCommand(UNDO_COMMAND, undefined);
            }
        },
        redo: {
            label: 'Redo',
            icon: Icons.Redo,
            onClick: () => {
                editor.dispatchCommand(REDO_COMMAND, undefined);
            }
        }
    };
};

export default ({ editor, disabled }) => {
    return <ResponsiveToolbar disabled={disabled} multi={false} isSmall={false} options={undoRedo(editor)} />;
};
