import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache, useMutation, useQuery } from '@apollo/client';
import { authLink, UserProfile } from '../common/auth/api';
import { AddressFragment, Location, LOCATION_FIELDS } from '../common/components/location';
import { constants } from '../common/constants';
import { Reference } from '../common/reference/reference';
import { ReferenceFragment } from '../common/list/fragments';
import { ContactInput } from 'sr-types/lib/metadata/v1/graphql';
import { ContactFragment } from '../supply/opportunity/orders/order';
import { UserContext } from '../common/auth/UserContext';
import { useContext } from 'react';
import { useI18n } from '../common/i18n/I18n';
import { AttachmentInput, Resource } from 'sr-types/lib/production/v1/graphql';
import { enqueueSnackbar } from '../common/components/Toast';
import { areAllFiltersInList, failedToastMessage, successToastMessage } from '../common/utils/commonUtils';
import { extractNames } from '../production/helpers/peopleUtils';
import { searchClient } from '../common/list/slimQuery';
import { isEmpty } from 'lodash';
import { Security } from 'sr-types/lib/organization/v1/graphql';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/organization/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const EMPTY_STATE = {
    publicName: undefined,
    organizationTypes: [],
    categories: [],
    hierarchicalCategories: [],
    isSharedInDirectory: false,
    locations: [],
    summary: {
        about: '',
        headline: ''
    },
    contact: undefined,
    website: '',
    websiteURL: constants.defaultURL,
    reference: undefined,
    parentOrganization: undefined,
    contactLocation: undefined,
    security: undefined
};

export const detailsQuery: DocumentNode = gql`
    query Organization($id: ID!) {
        organization(id: $id) {
            publicName
            isSharedInDirectory
            summary {
                headline
                about
            }
            locations {
                ...LocationFields
                timezone {
                    id
                    name
                }
            }
            contact {
                ...Contact
            }
            branding {
                useCustom
                brandNameOrText
                smallLogo {
                    ...Reference
                }
                bigLogo {
                    ...Reference
                }
            }
            address {
                ...AddressFields
                label
            }
            organizationTypes
            parentOrganization {
                label
                reference {
                    ...Reference
                }
            }
            categories
            hierarchicalCategories {
                lineage {
                    value
                }
                value
            }
            website
            websiteURL
            reference {
                ...Reference
            }
            security {
                allowedActions
            }
        }
    }
    ${LOCATION_FIELDS}
    ${AddressFragment}
    ${ReferenceFragment}
    ${ContactFragment}
`;

export type Organization = {
    publicName: string;
    organizationTypes: Array<string>;
    categories: Array<string>;
    hierarchicalCategories: Array<Category>;
    locations: Array<Location>;
    isSharedInDirectory: boolean;
    summary: {
        about: string;
        headline: string;
    };
    website: string;
    websiteURL: string;
    reference: object;
    parentOrganization: Reference;
    contactLocation: Location;
    contact: Array<ContactInput>;
    security: Security;
};

export const rowToOrganization = (rows, colDefs, activeOrganizationAccount) => {
    return rows.map((row) => {
        const organizationEntity = {
            publicName: row.name,
            organizationTypes: [row.type],
            hierarchicalCategories: [
                {
                    value: row.category ? row.category : '',
                    lineage: {
                        value: row.category ? row.category : ''
                    }
                }
            ],
            locations: row.organizationLocations ? [row.organizationLocations] : [],
            summary: {
                about: row.about ? row.about : '',
                headline: row.headline ? row.headline : ''
            },
            parentOrganization: {
                reference: null,
                label: row.parentOrganization ? row.parentOrganization : ''
            },
            website: '',
            websiteURL: constants.defaultURL + [row.website],
            branding: {
                brandNameOrText: row.brandname ? row.brandname : ''
            },
            isSharedInDirectory: row.directory && row.directory.toLowerCase() === 'true' ? true : false
        };
        return organizationEntity;
    });
};

type Category = {
    value: string;
    lineage: {
        value: string;
    };
};

export const saveQuery: DocumentNode = gql`
    mutation SaveOrganization($input: OrganizationInput!) {
        saveOrganization(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const bulkSaveQuery: DocumentNode = gql`
    mutation BulkSaveOrganization($input: BulkSaveOrganizationRequest!) {
        bulkSaveOrganization(input: $input) {
            createdCount
            updatedCount
            unprocessedCount
            results {
                id
                errors {
                    field
                    message
                }
                reference {
                    id
                }
            }
            logFile {
                id
                uriPrefix
            }
        }
    }
`;

export const deleteQuery: DocumentNode = gql`
    mutation DeleteOrganization($id: ID!) {
        deleteOrganization(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const autoCreateQuery: DocumentNode = gql`
    mutation AutoCreateOrganization($input: OrganizationInput!) {
        autoCreateOrganization(input: $input) {
            id
            reference {
                id
                eventId
                type
                uriPrefix
                deleted
            }
            errors {
                field
                message
            }
        }
    }
`;

export const inactivateMutation: DocumentNode = gql`
    mutation InactivateOrganization($id: ID!) {
        inactivateOrganization(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const activateMutation: DocumentNode = gql`
    mutation ActivateOrganization($id: ID!) {
        activateOrganization(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const shareOrganizationInDirectoryQuery: DocumentNode = gql`
    mutation ShareOrganizationInDirectory($id: ID!) {
        shareOrganizationInDirectory(id: $id) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const organizationAttachmentDetails: DocumentNode = gql`
    query Attachments($orgId: String!) {
        attachments(orgId: $orgId) {
            name
            size
            type
            createdOn
            createdBy
            entityReference {
                ...Reference
            }
        }
    }
    ${ReferenceFragment}
`;

export const deleteAttachmentQuery: DocumentNode = gql`
    mutation DeleteAttachments($orgId: ID!, $input: [AttachmentInput]) {
        deleteAttachments(orgId: $orgId, input: $input) {
            id
            errors {
                field
                key
                message
            }
        }
    }
`;

export const saveAttachmentQuery: DocumentNode = gql`
    mutation SaveAttachments($orgId: ID!, $input: [AttachmentInput]!) {
        saveAttachments(input: $input, orgId: $orgId) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const useGetAttachments = (orgId: string, skip: boolean) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { data, loading, refetch } = useQuery(organizationAttachmentDetails, {
        variables: {
            orgId
        },
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        client: client,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true,
        skip
    });
    return { data, loading, refetch };
};

export const useDeleteAttachments = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [deleteAttachmentMutation] = useMutation(deleteAttachmentQuery, { client: client });

    const successMessage = useI18n('attachment.delete.success');
    const errorMessage = useI18n('attachment.delete.error');

    const deleteAttachment = (orgId: string, input: AttachmentInput) => {
        return new Promise((resolve, reject) => {
            deleteAttachmentMutation({
                variables: {
                    orgId,
                    input
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((deleteAttachmentResponse) => {
                    if (deleteAttachmentResponse.data.deleteAttachments.errors) {
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                    } else {
                        setTimeout(() => {
                            client.refetchQueries({
                                include: [organizationAttachmentDetails]
                            });
                        }, 300);
                        enqueueSnackbar(successMessage, {
                            variant: 'success'
                        });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                });
        });
    };

    return { deleteAttachment };
};

export const useSaveAttachment = () => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const [save, { loading: isSaving }] = useMutation(saveAttachmentQuery, {
        client: client,
        context: { headers: { ownerId: activeOrganizationAccount } }
    });

    const successMessage = useI18n('attachment.save.success');
    const errorMessage = useI18n('attachment.save.error');

    const attachmentSave = (orgId: string, input: AttachmentInput[]) => {
        return new Promise((resolve, reject) => {
            save({
                variables: {
                    orgId,
                    input
                }
            })
                .then((activitySaveResponse) => {
                    if (!activitySaveResponse.data.errors) {
                        enqueueSnackbar(successMessage, {
                            variant: 'success'
                        });
                        resolve(activitySaveResponse);
                    } else {
                        enqueueSnackbar(errorMessage, {
                            variant: 'error'
                        });
                        reject(activitySaveResponse);
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(errorMessage, {
                        variant: 'error'
                    });
                    reject(err);
                })
                .finally(() =>
                    setTimeout(() => {
                        client.refetchQueries({
                            include: [organizationAttachmentDetails]
                        });
                    }, 300)
                );
        });
    };

    return { attachmentSave };
};
export const useGetOrganizationsDetails = (orgId: string, skip = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: organizationDetails,
        loading: organizationDetailsLoading,
        refetch: organizationDetailsRefetch
    } = useQuery(detailsQuery, {
        variables: { id: orgId },
        client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        skip: skip,
        notifyOnNetworkStatusChange: true
    });

    return { organizationDetails, organizationDetailsLoading, organizationDetailsRefetch };
};
export const organizationLineItemSave = (
    savedEntity,
    save,
    setIsFormSaving,
    setOrganizationModalState,
    lineItemValues,
    setLineItemValues,
    onAddToShortlist = null
) => {
    const { isSharedInDirectory, ...rest } = savedEntity;
    save({
        variables: {
            input: { ...rest }
        }
    })
        .then((res) => {
            if (res.data?.autoCreateOrganization?.errors || res.data?.saveOrganization?.errors) {
                failedToastMessage('form.save.failure', { name: savedEntity?.publicName });
                setIsFormSaving(false);
            } else {
                const inputName = extractNames(savedEntity?.publicName);
                const id = res.data?.autoCreateOrganization?.id
                    ? res.data.autoCreateOrganization.id
                    : res.data?.saveOrganization?.id;

                setLineItemValues({
                    ...lineItemValues,
                    itemInfo: {
                        ref: {
                            id,
                            label: `${inputName.firstName} ${inputName.lastName}`,
                            type: 'Organization'
                        },
                        label: `${inputName.firstName} ${inputName.lastName}`
                    },
                    name: `${inputName.firstName} ${inputName.lastName}`
                });
                if (onAddToShortlist) {
                    onAddToShortlist({
                        name: savedEntity.publicName,
                        reference: {
                            id,
                            label: savedEntity.publicName,
                            type: 'Organization'
                        }
                    });
                }

                successToastMessage('form.save.success', { name: savedEntity?.publicName });
                setIsFormSaving(false);
                setOrganizationModalState(false);
            }
        })
        .catch((err) => {
            failedToastMessage('form.save.failure', { name: savedEntity?.publicName });
            setIsFormSaving(false);
        })
        .finally(() => {
            searchClient.refetchQueries({
                include: ['slimResultsQuery'],
                onQueryUpdated(observableQuery) {
                    return areAllFiltersInList(observableQuery.options.variables.filters, [
                        {
                            identifier: 'entity',
                            value: 'Organization'
                        }
                    ]);
                }
            });
        });
};

export const onActivateOrInactivateHandle = (
    org,
    isInactivate,
    isActivate,
    activeOrganizationAccount,
    activate,
    inactivate
) => {
    return new Promise<void>((resolve, reject) => {
        if (isInactivate) {
            inactivate({
                variables: {
                    id: org.id
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((res) => {
                    if (res.data.inactivateOrganization.error) {
                        failedToastMessage('organization.inactivate.failure');
                        reject();
                    } else {
                        successToastMessage('organization.inactivate.success');

                        resolve();
                    }
                })
                .catch(() => {
                    failedToastMessage('organization.inactivate.failure');
                    reject();
                });
        }
        if (isActivate) {
            activate({
                variables: {
                    id: org.id
                },
                context: {
                    headers: {
                        ownerId: activeOrganizationAccount
                    }
                }
            })
                .then((res) => {
                    if (res.data.activateOrganization.error) {
                        failedToastMessage('organization.activate.failure');
                        reject();
                    } else {
                        successToastMessage('organization.activate.success');
                        resolve();
                    }
                })
                .catch(() => {
                    failedToastMessage('organization.activate.failure');
                    reject();
                });
        }
    });
};

export const checkForShowrunnrAccount = (activeOrganizationAccount: string, userProfile: UserProfile) => {
    const showrunnrAccount = userProfile.memberships.find((m) => m.name === 'Showrunnr');
    if (showrunnrAccount && showrunnrAccount.id === activeOrganizationAccount) {
        return true;
    }
    return false;
};

export const inputEntityForQuickAddOrg = (organizationState: Organization, resourceData: Resource) => {
    return {
        ...organizationState,
        contact: organizationState?.contact?.length ? organizationState.contact[0] : undefined,
        hierarchicalCategories: [
            {
                lineage: { value: resourceData.resourceCategory },
                value: resourceData.resourceCategory
            }
        ],
        locations: resourceData.locations || organizationState.locations,
        websiteURL: ''
    };
};

export const showLocationForQuickAddOrg = (orgState: Organization, resourceData: Resource) => {
    return orgState.organizationTypes.includes('Campus') && isEmpty(resourceData.locations);
};

export const validationRulesForQuickAddOrg = (Validator: any, orgState: Organization, resourceData: Resource) => {
    const validationRules = {
        publicName: [Validator.RULES.isRequired],
        contact: [Validator.RULES.isRequired]
    };

    if (showLocationForQuickAddOrg(orgState, resourceData)) {
        validationRules['locations'] = [Validator.RULES.isRequired];
    }
    return validationRules;
};
