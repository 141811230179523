import React, { useContext } from 'react';
import { UserContext } from '../auth/UserContext';
import { IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import { AppDefinition, APPLICATIONS } from './apps';
import UserAvatar from './UserAvatar';
import I18n from '../i18n/I18n';
import { Icons } from '../icons/Icons';

export default ({ selectedApp, setSelectedApp }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { activeOrganizationAccount, userProfile } = useContext(UserContext);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const menuItems = React.useMemo(() => {
        if (userProfile) {
            const availableApps: AppDefinition[] = [];
            APPLICATIONS.forEach((app) => {
                userProfile.memberships.forEach((membership) => {
                    // Only go for non-personal currently selected organization.
                    if (membership.id === activeOrganizationAccount) {
                        membership.access.forEach((access) => {
                            if (app.name === access.application && !availableApps.includes(app)) {
                                availableApps.push(app);
                            }
                        });
                    }
                });
            });

            const preferredAppKey = 'preferredApp.' + userProfile.accountId;
            const preferedApp = localStorage.getItem(preferredAppKey);
            const onStarClick = (appId) => {
                localStorage.setItem(preferredAppKey, appId);
            };
            return availableApps.map((app: AppDefinition, idx) => {
                return (
                    <MenuItem
                        key={idx}
                        selected={app.id === selectedApp}
                        onClick={() => {
                            handleClose();
                            setSelectedApp(app);
                        }}
                    >
                        {app.icon ? app.icon : <UserAvatar />}
                        <Typography sx={{ ml: 1, width: '100%', mr: 1 }}>{app.name}</Typography>
                        {app.id === preferedApp ? (
                            <Tooltip title={<I18n token={'account.preferredApp.starred.tooltip'} />}>
                                <Icons.Star color={'primary'} />
                            </Tooltip>
                        ) : (
                            <Tooltip title={<I18n token={'account.preferredApp.star.tooltip'} />}>
                                <Icons.StarOutline color={'disabled'} onClick={() => onStarClick(app.id)} />
                            </Tooltip>
                        )}
                    </MenuItem>
                );
            });
        } else {
            return [];
        }
    }, [selectedApp, setSelectedApp, userProfile, activeOrganizationAccount]);

    return (
        <React.Fragment>
            <IconButton
                sx={{ width: { xs: '24px', md: '48px' }, color: 'inherit' }}
                onClick={handleClick}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Icons.AppsOutlined />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                {menuItems}
            </Menu>
        </React.Fragment>
    );
};
