import { Box, SvgIcon } from '@mui/material';
import { find, findIndex } from 'lodash';
import React, { Suspense, useContext, useEffect } from 'react';
import { KeyValueMap } from '../KeyValueMap';
import { useStyling } from '../Theme';
import InactivityMonitor from '../auth/InactivityMonitor';
import { UserContext } from '../auth/UserContext';
import { AllPermissions, UserProfile, getFeaturePermissions } from '../auth/api';
import Loading from '../components/Loading';
import { log } from '../utils/commonUtils';
import useHistory, { ChangeRoute } from '../utils/useHistory';
import AppDrawer from './AppDrawer';
import Nav from './Nav';
import PermissionDenied from './PermissionDenied';
import { APPLICATIONS, AppDefinition, PROFILES } from './apps';
import useDefaultRoute from './useDefaultRoute';

export type RouteType = {
    // Label to show in the app drawer
    label?: string;
    // Application this route belongs to
    app?: AppDefinition;
    // Icon to show in the app drawer
    icon?: typeof SvgIcon;
    // Children app drawer items
    items?: Array<RouteType>;
    // If true this item will be disabled
    disabled?: boolean;
    // Uri to use when use clicks on the app drawer link
    link?: string;
    // Query to pass along with above url i.e. query params along with ? ex. ?param=value
    query?: KeyValueMap<string, string>;
    // If onClick is defined it will be called instead of routing to the link above.
    onClick?: any;
    // Route to use to set up application routing
    path?: string;
    // If redirect is defined router will redirect to this uri if user lands on the 'route' above
    redirect?: string;
    // React component to render when user lands on this route
    component?: any;
    // Name of the permission related to this route.
    feature?: string;
    // Add this item to app drawer.
    drawer?: boolean;
    // If true draw divider after this item in the drawer.
    divider?: boolean;
};

const getDefaultApp = (userProfile: UserProfile): AppDefinition => {
    const firstOrg =
        userProfile.memberships && userProfile.memberships.length
            ? find(userProfile.memberships, (m) => !m.personal)
            : undefined;
    const firstOrgApp = find(APPLICATIONS, (app) => {
        return firstOrg && firstOrg.access && firstOrg.access.length
            ? app.name === firstOrg.access[0].application
            : false;
    });

    if (firstOrg) {
        return firstOrgApp;
    } else if (!firstOrg && userProfile.memberships.length === 1 && userProfile.memberships[0].personal === true) {
        //case where user is not subscribed of any account
        return PROFILES;
    } else {
        throw new Error('Failed to find active organization or default application for this user!');
    }
};

export default (props) => {
    const { routes, component: Component, feature, path, app, ...rest } = props;
    const {
        application,
        setApplication,
        activeOrganizationAccount,
        setActiveOrganizationAccount,
        isAuthenticated,
        userProfile
    } = useContext(UserContext);
    const { theme, isDarkMode } = useStyling();
    const featurePermissions = getFeaturePermissions(userProfile, app, activeOrganizationAccount, feature);
    const { logOut, searchParams } = useHistory();
    const { url } = useDefaultRoute();

    useEffect(() => {
        if (!isAuthenticated) {
            log('User accessing secure route wihtout being authenticated. Logging out...');
            logOut();
        }
    }, [isAuthenticated, logOut]);

    /*
     * Setting activeOrganizationAccount from orgId in url.
     */
    useEffect(() => {
        if (isAuthenticated) {
            const orgId = searchParams.get('orgId');
            if (orgId && orgId !== activeOrganizationAccount) {
                log('Setting activeOrganizationAccount from url to:', orgId);
                setActiveOrganizationAccount(orgId);
            }
        }
    }, []);

    /*
     * Setting application for logged in user.
     */
    useEffect(() => {
        if (userProfile && (!application || application.id !== app.id)) {
            const hasRouteApp =
                findIndex(userProfile.memberships, (m) => findIndex(m.access, (a) => app.name === a.application) > -1) >
                -1;
            if (hasRouteApp) {
                log('Setting application:', app);
                setApplication(app);
            } else {
                // TODO: should this be a redirect?
                const defaultApp = getDefaultApp(userProfile);
                log('Setting default application:', defaultApp);
                setApplication(defaultApp);
            }
        }
    }, [userProfile, app, application, setApplication]);

    // Wait for load to finish (React may be updating various states).
    const wait = React.useMemo(() => {
        const newOrgId: string = searchParams.get('orgId');
        const orgIdDifferent = !newOrgId || newOrgId !== activeOrganizationAccount;
        return !userProfile || orgIdDifferent;
    }, [userProfile, searchParams, activeOrganizationAccount]);

    if (!isAuthenticated || wait) {
        return <Loading />;
    } else if (!featurePermissions[AllPermissions.Read]) {
        const comingFromOutside = false;
        if (comingFromOutside) {
            return <PermissionDenied />;
        } else if (url) {
            return <ChangeRoute path={url} />;
        } else {
            throw new Error('No default route found!');
        }
    } else {
        return (
            <>
                <Box
                    id="app-page"
                    className={isDarkMode ? 'sr-dark' : 'sr-light'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                        backgroundColor: theme.palette.background.default
                    }}
                >
                    <Nav />
                    <Box
                        id="body-container"
                        sx={{
                            mt: 1,
                            flexGrow: 2,
                            overflowY: 'auto',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        {application && userProfile ? (
                            <Suspense fallback={<Loading />}>
                                <AppDrawer routes={routes} />
                                <Component />
                            </Suspense>
                        ) : (
                            <Loading />
                        )}
                    </Box>
                </Box>
                <InactivityMonitor />
            </>
        );
    }
};
