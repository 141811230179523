import React from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import FormPanel from '../../common/form/FormPanel';

export default () => {
    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget name="label" label="Label" />
                </FormItem>
                <FormItem>
                    <FormWidget name="group" label="Group" />
                </FormItem>
                <FormItem>
                    <FormWidget name="description" component="TextArea" label="Description" />
                </FormItem>
                <FormItem half>
                    <FormWidget name="min" label="Min" />
                </FormItem>
                <FormItem half>
                    <FormWidget name="max" label="Max" />
                </FormItem>
                <FormItem>
                    <FormWidget name="decimalsNumber" component="Number" label="Number of decimals" />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
