// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ribbon-2 {
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 5px; /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1 * var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(
        0 0,
        100% 0,
        100% calc(100% - var(--f)),
        calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),
        0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f) / 2)
    );
    background: #757575;
    color: white;
    box-shadow: 0 calc(-1 * var(--f)) 0 inset #75757505;
    opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/ribbon2.css"],"names":[],"mappings":"AAAA;IACI,SAAS,EAAE,2BAA2B;IACtC,SAAS,EAAE,6BAA6B;IACxC,QAAQ,EAAE,mBAAmB;;IAE7B,kBAAkB;IAClB,6CAA6C;IAC7C,8CAA8C;IAC9C;;;;;;;;KAQC;IACD,mBAAmB;IACnB,YAAY;IACZ,mDAAmD;IACnD,YAAY;AAChB","sourcesContent":[".ribbon-2 {\n    --f: 10px; /* control the folded part*/\n    --r: 15px; /* control the ribbon shape */\n    --t: 5px; /* the top offset */\n\n    position: absolute;\n    inset: var(--t) calc(-1 * var(--f)) auto auto;\n    padding: 0 10px var(--f) calc(10px + var(--r));\n    clip-path: polygon(\n        0 0,\n        100% 0,\n        100% calc(100% - var(--f)),\n        calc(100% - var(--f)) 100%,\n        calc(100% - var(--f)) calc(100% - var(--f)),\n        0 calc(100% - var(--f)),\n        var(--r) calc(50% - var(--f) / 2)\n    );\n    background: #757575;\n    color: white;\n    box-shadow: 0 calc(-1 * var(--f)) 0 inset #75757505;\n    opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
