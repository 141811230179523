import React from 'react';
import { Typography } from '@mui/material';
import I18n from '../../common/i18n/I18n';
import { useStyling } from '../../common/Theme';

type AddMoreProps = {
    i18nToken: string;
    onAdd: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
};

export default ({ i18nToken, onAdd, disabled = false }: AddMoreProps) => {
    const { theme } = useStyling();

    return (
        <Typography
            sx={{
                color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
                '&:hover': {
                    cursor: 'pointer'
                }
            }}
            onClick={disabled ? () => {} : onAdd}
        >
            <I18n token={i18nToken} />
        </Typography>
    );
};
