import React from 'react';
import { enqueueSnackbar } from '../common/components/Toast';
import { validateEmail } from '../common/form/Validator';
import I18n, { useI18n } from '../common/i18n/I18n';
import { Contact } from 'sr-types/lib/production/v1/graphql';

export const useImportPersonRowValidation = (fieldToValidate = 'contactInfo.email.address') => {
    const duplicatePersonToolTipMsg = useI18n('person.import.error.message');
    const invalidEmailMsg = useI18n('validation.email.invalid');
    const validation = (importedData, existingData, setInvalidData, setErrors) => {
        const validationRows = {};
        validateDuplicatePerson(importedData, existingData, fieldToValidate, validationRows, duplicatePersonToolTipMsg);
        validatePersonEmail(importedData, fieldToValidate, validationRows, invalidEmailMsg);
        if (Object.keys(validationRows).length) {
            setInvalidData(true);
            setErrors(validationRows);
        }
    };
    return validation;
};
function validateDuplicatePerson(
    importedData: any,
    guests: Contact[],
    fieldToValidate: string,
    validationRows: {},
    duplicatePersonToolTipMsg: string
) {
    if (guests.length > 0) {
        var failedValidation = false;
        importedData.forEach((row) => {
            guests.forEach((guest) => {
                if (guest.contactInfo?.email?.address && row[fieldToValidate] === guest.contactInfo.email.address) {
                    failedValidation = true;
                    if (!validationRows[row.id]) {
                        validationRows[row.id] = {};
                    }
                    if (!validationRows[row.id][fieldToValidate]) {
                        validationRows[row.id][fieldToValidate] = [];
                    }
                    if (validationRows[row.id][fieldToValidate].indexOf(duplicatePersonToolTipMsg) === -1) {
                        validationRows[row.id][fieldToValidate].push(duplicatePersonToolTipMsg);
                    }
                }
            });
        });
        if (failedValidation) {
            handleToastMessages('person.import.error.message', {}, 'error');
        }
    }
}
function validatePersonEmail(importedData: any, fieldToValidate: string, validationRows: {}, invalidEmailMsg: string) {
    var invalidEmailAddress = false;
    importedData.forEach((row) => {
        if (!!row[fieldToValidate]) {
            const isValid = validateEmail(row[fieldToValidate]);
            if (!isValid) {
                invalidEmailAddress = true;
                if (!validationRows[row.id]) {
                    validationRows[row.id] = {};
                }
                if (!validationRows[row.id][fieldToValidate]) {
                    validationRows[row.id][fieldToValidate] = [];
                }
                validationRows[row.id][fieldToValidate].push(invalidEmailMsg);
            }
        }
    });
    if (invalidEmailAddress) {
        handleToastMessages('validation.email.invalid', {}, 'error');
    }
}
const handleToastMessages = (token: string, values, variant: 'info' | 'success' | 'error') => {
    return enqueueSnackbar(<I18n token={token} values={values} />, { variant });
};
