import { Avatar, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { H, V } from '../../Layout';
import { useStyling } from '../../Theme';
import { getInitials } from '../../nav/UserAvatar';

export default ({ option }) => {
    const { theme } = useStyling();

    const name = `${option?.name?.firstName} ${option?.name?.lastName}`;
    const email =
        option?.contactInfo?.email?.address &&
        option.contactInfo.email.address !== 'null' &&
        option.contactInfo.email.address !== 'undefined'
            ? option.contactInfo.email.address
            : '';

    return (
        <Tooltip
            title={
                <div>
                    {name}
                    <br />
                    {email}
                </div>
            }
        >
            <H sx={{ gap: 1 }}>
                <Avatar>{getInitials(option?.name)}</Avatar>
                <V sx={{ overflow: 'hidden' }}>
                    <Typography className={'ellipsis'} sx={{ width: '100%' }}>
                        {name}
                    </Typography>
                    <Typography
                        className={'ellipsis'}
                        sx={{ width: '100%', fontSize: 'smaller', color: theme.palette.text.disabled }}
                    >
                        {email}
                    </Typography>
                </V>
            </H>
        </Tooltip>
    );
};
