import React, { useContext, useEffect, useState } from 'react';
import DataGrid, { RowAction } from '../../../common/list/DataGrid';
import { useDataGridStyles } from '../../../common/list/useDataGridStyles';
import { V } from '../../../common/Layout';
import { AllPermissions } from '../../../common/auth/api';
import ContactEditor from './ContactEditor';
import { Button } from '@mui/material';
import { contactsToRows, rowToContact } from './ContactListUtils';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { Contact } from '../../../common/components/types';
import { LoadingButton } from '@mui/lab';
import { useStyling } from '../../../common/Theme';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { getReferenceId } from '../../../common/reference/reference';
import { validateEmail } from '../../../common/form/Validator';
import TooltipRenderer from '../../../common/list/TooltipRenderer';
import { getColumns } from '../../reservation/ContactListUtils';
import { useMutationWithContext } from '../../../common/hooks/useMutationWithContext';
import {
    autoCreateQuery as autoCreateProductionQuery,
    client as productionClient
} from '../../../production/helpers/production';
import {
    autoCreateQuery as autoCreateOrganizationQuery,
    client as organizationClient
} from '../../../../src/organization/organization';
import { createTermMutation, metadataClient } from '../../../common/components/MetadataAutocomplete';
import { useMutation } from '@apollo/client';
import { UserContext } from '../../../common/auth/UserContext';
import I18n from '../../../common/i18n/I18n';
import { Icons } from '../../../common/icons/Icons';

export default ({
    feature,
    contacts,
    onAddContact = undefined,
    onEditContact = undefined,
    onDeleteContact,
    onSaveContacts = undefined,
    title = undefined,
    addTitle,
    sx = {},
    featurePermissions,
    entityPermissions,
    exportFilename,
    enableImport = false,
    setImportVisible = (importVisible, isUpload) => {},
    errors = {},
    isSaving = false,
    showSaveButton = false,
    colDefs,
    columnVisibility = {},
    onError = undefined,
    doUpdate = undefined,
    errorInRow = undefined,
    setIsRowUpdated = undefined,
    isRowUpdated = false,
    isOpenConfirmationModal = false,
    autoCreate = false,
    toolbar,
    ...rest
}) => {
    const { theme } = useStyling();
    const classes = useDataGridStyles();
    const { activeOrganizationAccount } = useContext(UserContext);
    const [contactToEdit, setContactToEdit] = useState<any>(undefined);
    const [contactEditorVisible, setContactEditorVisible] = useState(false);
    const [saving, setSaving] = useState(false);
    const [rows, setRows] = useState<GridRowModel[]>([]);
    const [errorInEmail, setErrorInEmail] = useState({ error: false, errorMessage: '' });
    const [currentRow, setCurrentRow] = useState(undefined);
    const [saveProduction] = useMutationWithContext(autoCreateProductionQuery, productionClient);
    const [saveOrganization] = useMutationWithContext(autoCreateOrganizationQuery, organizationClient);
    const [createTerm] = useMutation(createTermMutation, {
        client: metadataClient,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        notifyOnNetworkStatusChange: true
    });
    const mutationObject = { production: saveProduction, organization: saveOrganization, createTerm: createTerm };
    const getNonFuzzyQueryParamsForEmail = (email: string) => {
        let query = {};
        if (validateEmail(email)) {
            query = {
                field: 'email',
                value: email
            };
        }
        return query;
    };

    useEffect(() => {
        if (errorInEmail && onError) {
            onError(errorInEmail.error);
        }
    }, [errorInEmail.error]);

    const afterSearch = (items, inputValue, currentEditRows, id) => {
        let message;
        const personId = getReferenceId(currentEditRows[id].personReference.value);
        const isValid = validateEmail(inputValue);
        let itemsAfterRemovingSelectedItem = [];
        if (personId) {
            itemsAfterRemovingSelectedItem = items.filter((item) => personId !== item.id);
        }
        const filteredItems = personId ? [...itemsAfterRemovingSelectedItem] : [...items];
        const emails = filteredItems.map((item) => item.contactInfo?.email.address);
        const foundDuplicateEmail = emails.find((email) => email === inputValue);
        if (foundDuplicateEmail) {
            message = <I18n token="reservation.guest.duplicate.email.error.message" />;
            setErrorInEmail({ error: true, errorMessage: message });
        } else if (!isValid) {
            message = <I18n token="reservation.guest.invalid.email.error.message" />;
            setErrorInEmail({ error: true, errorMessage: message });
        } else {
            setErrorInEmail({ error: false, errorMessage: '' });
        }

        return message;
    };

    const allColumns = getColumns(
        colDefs,
        setIsRowUpdated,
        afterSearch,
        getNonFuzzyQueryParamsForEmail,
        mutationObject,
        autoCreate
    );

    const updatedColumns = allColumns.map((col) => {
        if (errorInRow?.field === col.field) {
            return {
                ...col,
                renderCell: (params: GridRenderCellParams) => {
                    const id = params.row.personReference ? params.row.personReference.id : params.row.id;
                    const openTooltip = errorInRow?.id === id;
                    const isOpen = openTooltip ? true : false;
                    return <TooltipRenderer {...params} isOpen={isOpen} message={errorInRow?.message || ''} />;
                }
            };
        }
        return col;
    });

    useEffect(() => {
        setRows(contactsToRows(contacts));
    }, [contacts]);

    const handleRowAction = (action: RowAction, row, allRows) => {
        const updatedContact: Contact = rowToContact(row, colDefs);
        const updatedContacts: Contact[] = allRows.map((r) => rowToContact(r, colDefs));
        if (action === RowAction.Add) {
            return onAddContact(updatedContact, updatedContacts);
        } else if (action === RowAction.Edit) {
            return onEditContact(updatedContact, updatedContacts);
        } else if (action === RowAction.Delete) {
            return onDeleteContact(updatedContact, updatedContacts);
        }
    };

    const processRowUpdate = async (update: GridRowModel) => {
        const addNewGuest = errorInRow && errorInRow.id !== update.id;
        return new Promise((resolve, reject) => {
            if (errorInEmail?.error || addNewGuest || isOpenConfirmationModal) {
                reject();
            } else {
                const isNew = update.isNew;
                const updatedRow = { ...update, isNew: false };
                const newRows: GridRowModel[] = rows.map((r) => (r.id === update.id ? update : r));
                if (!showSaveButton) {
                    handleRowAction(isNew ? RowAction.Add : RowAction.Edit, update, newRows);
                }
                setRows(newRows);
                const rowUpdated = rowToContact(updatedRow, colDefs);
                const hasChangesInTable = isRowUpdated || isNew;
                const updateGuest = doUpdate && !isOpenConfirmationModal && hasChangesInTable;
                if (updateGuest) {
                    setCurrentRow(rowUpdated);
                    doUpdate(rowUpdated);
                }
                resolve(updatedRow);
            }
        });
    };

    const uploadButton = (
        <Button
            key={'import'}
            startIcon={<Icons.FileUpload />}
            size={'small'}
            onClick={() => setImportVisible(true, true)}
        >
            Import
        </Button>
    );

    const saveButton = (
        <LoadingButton
            loading={isSaving}
            key={'save'}
            size={'small'}
            color={'primary'}
            startIcon={<Icons.Save />}
            disabled={errorInEmail.error}
            onClick={() => {
                const updatedContacts: Contact[] = rows.map((r) => rowToContact(r, colDefs));
                return onSaveContacts(updatedContacts);
            }}
        >
            Save
        </LoadingButton>
    );

    return (
        <V
            sx={{
                height: '100%',
                ...sx,
                '& .Mui-error': {
                    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
                    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'
                },
                '& .Mui-error-row': {
                    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
                    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
                    '&.Mui-hovered  > * > [class|="MuiDataGrid-actionsCell"]': {
                        visibility: 'visible'
                    }
                },
                '& .Mui-row': {
                    fontStyle: 'italic',
                    '&.Mui-hovered  > * > [class|="MuiDataGrid-actionsCell"]': {
                        visibility: 'visible'
                    }
                }
            }}
        >
            <DataGrid
                id={'contact-list'}
                errors={errors}
                defaultColumnVisibility={columnVisibility}
                // TODO This is old details editing, should be a separate action (called from ... menu)
                onEdit={
                    featurePermissions[AllPermissions.Manage] && entityPermissions[AllPermissions.Edit] && onEditContact
                        ? (id, row) => {
                              setContactToEdit(row);
                              setContactEditorVisible(true);
                          }
                        : undefined
                }
                asIsColumns={errorInRow ? [errorInRow.field] : []}
                handleRowAction={showSaveButton ? undefined : handleRowAction}
                feature={feature}
                rowCount={rows ? rows.length : 0}
                rows={rows}
                setRows={setRows}
                columns={updatedColumns}
                serverPagination={false}
                toolbar={{
                    ...toolbar,
                    custom: toolbar.custom ? (showSaveButton ? [uploadButton, saveButton] : [uploadButton]) : undefined
                }}
                toolbarOptions={{
                    csvOptions: {
                        fileName: exportFilename
                    }
                }}
                processRowUpdate={processRowUpdate}
                enableInlineEditing={true}
                getRowClassName={(params) => {
                    const id = params.row.personReference ? params.row.personReference.id : params.row.id;
                    const showErrorInRow = errorInRow?.id === id;
                    const isSavingCurrentRow = isSaving && params.row?.id === currentRow?.id;
                    const isDeleting = params.row.deleted;
                    if (isSavingCurrentRow || isDeleting) {
                        return 'Mui-row';
                    }
                    if (showErrorInRow) {
                        return 'Mui-error-row';
                    } else {
                        return classes.tableRow;
                    }
                }}
                onDeleteHandle={onDeleteContact}
                {...rest}
            />

            {contactEditorVisible ? (
                <ContactEditor
                    title={addTitle}
                    isBusy={saving}
                    contact={contactToEdit?._raw}
                    onEditContact={(contact) => {
                        setSaving(true);
                        Promise.resolve(onEditContact(contact, contactToEdit?.id))
                            .then(() => {
                                setSaving(false);
                                setContactEditorVisible(false);
                            })
                            .catch(() => {
                                setSaving(false);
                            });
                    }}
                    onClose={() => {
                        setContactEditorVisible(false);
                    }}
                />
            ) : (
                <React.Fragment />
            )}
        </V>
    );
};
