import { Typography } from '@mui/material';
import React from 'react';
import { V } from '../../common/Layout';
import TypePreview from '../TypePreview';
import { useStyling } from '../../common/Theme';
import { Field, TermsetField } from '../attributes';
import EllipsisText from '../../common/components/EllipsisText';

type TermsetFieldPreviewProps = {
    field: Field;
    onClick?: (field) => void;
};

export default (props: TermsetFieldPreviewProps) => {
    const { field, onClick } = props;
    const { theme } = useStyling();
    const cast = field as TermsetField;

    return (
        <V onClick={onClick}>
            <TypePreview type={field.fieldType} />
            <Typography>{field.label}</Typography>
               <EllipsisText text={field.description} sx={{fontSize:"smaller", color:theme.palette.text.disabled}} />
            <Typography>{cast.termset}</Typography>
        </V>
    );
};
