import React from 'react';
import { enqueueSnackbar } from '../common/components/Toast';
import I18n from '../common/i18n/I18n';
import { ReferenceField } from '../common/reference/reference';

type AddOptionValue = {
    label: string;
    createOption: boolean;
};
type QuickAddOrganization = {
    id: string;
    name: string;
    parentOrganizationName: string;
    organizationTypes: Array<string>;
    reference: ReferenceField;
};
export const getOption = (item: QuickAddOrganization) => {
    return {
        id: item.id,
        label: item.name,
        parentOrganizationName: item.parentOrganizationName,
        organizationTypes: item.organizationTypes,
        ref: { ...item.reference, label: item.name }
    };
};

export const quickAddOrganization = (value: AddOptionValue, save: (value) => Promise<any>): Promise<any> => {
    return new Promise((resolve, reject) => {
        const savedEntity = { publicName: value.label, summary: { about: '', headline: '' } };
        save({
            variables: {
                input: savedEntity
            }
        })
            .then((res) => {
                if (res.data.autoCreateOrganization.errors) {
                    enqueueSnackbar(<I18n token="form.save.failure" values={{ name: savedEntity.publicName }} />, {
                        variant: 'error'
                    });
                } else {
                    enqueueSnackbar(<I18n token="form.save.success" values={{ name: savedEntity.publicName }} />, {
                        variant: 'success'
                    });
                    const { autoCreateOrganization } = res.data;
                    autoCreateOrganization.reference.label = savedEntity.publicName;
                    const organizationReference = {
                        id: autoCreateOrganization.id,
                        label: savedEntity.publicName,
                        ref: autoCreateOrganization.reference
                    };
                    resolve(organizationReference);
                }
            })
            .catch((err) => {
                enqueueSnackbar(<I18n token="form.save.failure" values={{ name: savedEntity.publicName }} />, {
                    variant: 'error'
                });
                reject(err);
            });
    });
};
