import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Icons } from '../icons/Icons';
import SecureImage from '../components/SecureImage';

type AttachmentsImageProps = {
    isPreviewOpen: boolean;
    setIsPreviewOpen: (open: boolean) => void;
    name?: string;
    id: string;
};

export default (props: AttachmentsImageProps) => {
    const { isPreviewOpen, setIsPreviewOpen, name, id } = props;
    return (
        <Dialog open={isPreviewOpen}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ width: '100%' }}>{name}</Typography>
                <IconButton size="small" onClick={() => setIsPreviewOpen(false)}>
                    <Icons.Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <SecureImage style={{ width: '100%', maxHeight: '100%', objectFit: 'cover' }} imageId={id} alt={name} />
            </DialogContent>
        </Dialog>
    );
};
