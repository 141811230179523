import React from 'react';
import Center from './Center';
import { V } from '../Layout';
import { Typography } from '@mui/material';
import { Icons } from '../icons/Icons';

export default ({ text = 'Error', error = undefined }) => {
    console.error(text, error);
    return (
        <Center>
            <V sx={{ alignItems: 'center' }}>
                <Icons.ErrorIcon fontSize={'large'} />
                <Typography fontSize={'medium'} mt={1}>
                    {text}
                </Typography>
            </V>
        </Center>
    );
};
