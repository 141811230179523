import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider as MaterialThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import '../global.scss';
import { ErrorBoundary, ErrorFallback } from './ErrorBoundary';
import { useStyling } from './Theme';
import { UserProvider } from './auth/UserContext';
import Toast from './components/Toast';
import { I18nProvider } from './i18n/I18n';
import { getL10nDef } from './i18n/localization';
import db from './indexedDB';
import { RouteType } from './nav/AppRoute';
import AppRouter from './nav/AppRouter';
import { EnvironmentPermissionProvider } from './auth/EnvironmentPermissionContext';

declare global {
    interface Window {
        DEBUG: boolean;
        _env_: any;
    }
}

const l10nDef = getL10nDef();
const defaultBundle = require('./i18n/bundles/en-US.json').default;

type AppProps = {
    routes: RouteType[];
};

const ShowrunnrApp = ({ routes }) => {
    const { theme } = useStyling();

    useEffect(() => {
        // Initialize IndexDB
        db.open();
    }, []);

    const styledTheme = {
        error: theme.palette.error,
        warning: theme.palette.warning,
        success: theme.palette.success
    };

    return (
        <MaterialThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={l10nDef.dateFns}>
                <I18nProvider langBundle={l10nDef.appBundle} defaultBundle={defaultBundle}>
                    <UserProvider>
                        <EnvironmentPermissionProvider>
                            <AppRouter routes={routes} />
                        </EnvironmentPermissionProvider>
                    </UserProvider>
                    <EmotionThemeProvider theme={styledTheme}>
                        <Toast />
                    </EmotionThemeProvider>
                </I18nProvider>
            </LocalizationProvider>
        </MaterialThemeProvider>
    );
};

export default (props: AppProps) => {
    const { routes } = props;

    return (
        // <StrictMode>
        <ErrorBoundary fallbackRender={ErrorFallback}>
            <RecoilRoot>
                <ShowrunnrApp routes={routes} />
            </RecoilRoot>
        </ErrorBoundary>
        // </StrictMode>
    );
};
