import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { H } from '../Layout';
import { Typography } from '@mui/material';

export default ({ size = '32px', center = true, sx = undefined, message = undefined }) => {
    return (
        <H className={'loading-container'} sx={{ display: 'flex', justifyContent: center ? 'center' : 'flex-start', alignItems: 'center', height: '100%', width: '100%', ...sx }}>
            <CircularProgress size={size} />
            {message && <Typography sx={{ p: 3, fontWeight: 600 }}> {message} </Typography>}
        </H>
    );
};
