import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache } from '@apollo/client';
import { authLink } from '../../common/auth/api';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/metadata/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const deleteQuery: DocumentNode = gql`
    mutation DeleteFromTermset($termset: String!, $input: TermInput) {
        deleteFromTermset(termset: $termset, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const deleteTerm: DocumentNode = gql`
    mutation DeleteTermsetMember($id: ID!) {
        deleteTermsetMember(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const addTerm: DocumentNode = gql`
    mutation AddToTermset($termset: String!, $input: TermInput) {
        addToTermset(termset: $termset, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const termsetListQuery: DocumentNode = gql`
    query {
        termsets(page: { from: 0, size: 50 }) {
            id
            name
            label
            description
        }
    }
`;

export const editTerm: DocumentNode = gql`
    mutation EditTermsetMember($id: ID!, $termset: String!, $input: TermInput) {
        editTermsetMember(id: $id, termset: $termset, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const saveTermset: DocumentNode = gql`
    mutation saveTermset($input: TermsetInfoInput) {
        saveTermset(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const addTermsToTermset: DocumentNode = gql`
    mutation addTermsToTermset($termset: String!, $input: [TermInput]) {
        addTermsToTermset(termset: $termset, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;
