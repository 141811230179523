import React from 'react';
import { enqueueSnackbar } from '../../common/components/Toast';
import I18n from '../../common/i18n/I18n';
import { ReferenceField } from '../../common/reference/reference';

type AddOptionValue = {
    label: string;
    createOption: boolean;
};
type QuickAddProduction = {
    id: string;
    name: string;
    productionAttributes: string;
    reference: ReferenceField;
};
export const getOption = (item: QuickAddProduction) => {
    return {
        id: item.id,
        label: item.name,
        productionAttributes: item.productionAttributes,
        ref: { ...item.reference, label: item.name }
    };
};

export const quickAddProduction = (value: AddOptionValue, save: (value) => Promise<any>): Promise<any> => {
    return new Promise((resolve, reject) => {
        const savedEntity = { publicTitle: value.label };
        save({
            variables: {
                input: savedEntity
            }
        })
            .then((res) => {
                if (res.data.autoCreateProduction.errors) {
                    enqueueSnackbar(<I18n token="form.save.failure" values={{ name: savedEntity.publicTitle }} />, {
                        variant: 'error'
                    });
                } else {
                    enqueueSnackbar(<I18n token="form.save.success" values={{ name: savedEntity.publicTitle }} />, {
                        variant: 'success'
                    });
                    const { autoCreateProduction } = res.data;
                    autoCreateProduction.reference.label = savedEntity.publicTitle;
                    const productionReference = {
                        id: autoCreateProduction.id,
                        label: savedEntity.publicTitle,
                        ref: autoCreateProduction.reference
                    };
                    resolve(productionReference);
                }
            })
            .catch((err) => {
                enqueueSnackbar(<I18n token="form.save.failure" values={{ name: savedEntity.publicTitle }} />, {
                    variant: 'error'
                });
                reject(err);
            });
    });
};
