import React, { useEffect, useContext } from 'react';
import { FormContainer } from '../common/form/FormContainer';
import FormItem from '../common/form/FormItem';
import FormPanel from '../common/form/FormPanel';
import FormWidget from '../common/form/FormWidget';
import I18n, { useI18n } from '../common/i18n/I18n';
import PersonSelector from '../common/components/PersonSelector';
import { FormContext } from '../common/form/FormContext';
import { usePersistedSearchkitVariables } from '../common/components/filtering/filters';

interface QuickAddOrganizationFormProps {
    setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    id?: string;
    checkShowLocation: () => Boolean;
}
export const QuickAddOrganizationForm: React.FC<QuickAddOrganizationFormProps> = ({
    id,
    setIsSaveDisabled,
    checkShowLocation
}) => {
    const { validation, state, setState } = useContext(FormContext);
    useEffect(() => {
        setIsSaveDisabled?.(validation && !validation.isValid);
    }, [setIsSaveDisabled, validation]);
    const { variables } = usePersistedSearchkitVariables(id);

    useEffect(() => {
        if (variables.query) {
            setState({ ...state, publicName: variables.query });
        }
    }, [variables.query]);

    return (
        <FormPanel>
            <FormContainer>
                <FormItem>
                    <FormWidget name="publicName" label={useI18n('organization.field.name')} />
                </FormItem>
                <FormItem>
                    <FormWidget
                        component="MetadataAutocomplete"
                        compressed
                        label={useI18n('organization.field.type')}
                        limitTags={3}
                        metadataName="OrganizationType"
                        multiple
                        name="organizationTypes"
                    />
                </FormItem>
                <FormItem>
                    <PersonSelector
                        id="org-quick-person-add"
                        name="contact"
                        label={useI18n('organization.field.contact')}
                        multiple={true}
                        includePublic={false}
                        autoFocus={false}
                        placeholder={useI18n('organzation.contact.placeholder')}
                        noOptionsText={useI18n('organization.search.contact')}
                        data-testid={'quickOrgContact'}
                        size={'medium'}
                    />
                </FormItem>
                {checkShowLocation() && (
                    <FormItem>
                        <FormWidget
                            component="Location"
                            label={<I18n token="organization.field.location" />}
                            appendTimeZone={true}
                            name="locations"
                        />
                    </FormItem>
                )}
            </FormContainer>
        </FormPanel>
    );
};
