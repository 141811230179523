import { lazy } from 'react';

export default (componentImport) =>
    lazy(async () => {
        const isRefreshed = JSON.parse(window.localStorage.getItem('app-refreshed') || 'false');
        try {
            const component = await componentImport();
            window.localStorage.setItem('app-refreshed', 'false');
            return component;
        } catch (error) {
            if (isRefreshed) {
                // The page has already been reloaded
                // Assuming that user is already using the latest version of the application.
                // Let's let the application crash and raise the error.
                throw error;
            } else {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                console.warn('Stale app, reloading...');
                window.localStorage.setItem('app-refreshed', 'true');
                return window.location.reload();
            }
        }
    });
