import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import I18n from '../../../common/i18n/I18n';
import { Icons } from '../../../common/icons/Icons';

export default ({
    rows,
    rowSelectionModel,
    setFilter,
    setOpenInviteModal,
    showInviteButton,
    setSelectedItems,
    setShowInviteButton
}) => {
    useEffect(() => {
        const selectedRowsData = rowSelectionModel.map((id) => rows.find((row) => row.id === id));
        const rowHasNoAccount = selectedRowsData?.find((row) => row.isOrganization && !row.hasAccount);

        if (selectedRowsData.length > 0 && !rowHasNoAccount) {
            setShowInviteButton(true);
        } else {
            setShowInviteButton(false);
        }
    }, [rowSelectionModel]);

    return (
        <Button
            key={'invite'}
            startIcon={<Icons.RequestAvailability />}
            size={'small'}
            disabled={!showInviteButton}
            onClick={() => {
                const selectedRowsData = rowSelectionModel.map((id) => rows.find((row) => row.id === id));
                const filterIds = [];
                setSelectedItems(
                    selectedRowsData.map((row) => {
                        filterIds.push(row.itemReference.id);
                        return { ...row.itemReference, shortlistLineItemId: row.identity.id };
                    })
                );
                setFilter([{ field: 'identity', value: { values: [...filterIds] } }]);
                setOpenInviteModal(true);
            }}
        >
            <I18n token="form.shortlistLineItem.inviteButton" />
        </Button>
    );
};
