import { createSvgIcon } from '@mui/material';
import React from 'react';

export const RotateSchedule = createSvgIcon(
    <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.2 7.19997H15.6V5.99997H14.4V7.19997H9.60002V5.99997H8.40002V7.19997H7.80002C7.14002 7.19997 6.60002 7.73997 6.60002 8.39997V16.8C6.60002 17.46 7.14002 18 7.80002 18H16.2C16.86 18 17.4 17.46 17.4 16.8V8.39997C17.4 7.73997 16.86 7.19997 16.2 7.19997ZM16.2 16.8H7.80002V10.8H16.2V16.8ZM7.80002 9.59997V8.39997H16.2V9.59997H7.80002ZM11.136 15.876L14.694 12.318L14.058 11.682L11.136 14.604L9.87002 13.338L9.23402 13.974L11.136 15.876Z"
                fill="currentColor"
                fillOpacity="0.56"
            />
            <path
                d="M7.08294 21.7154C3.75839 20.0332 1.36694 16.6941 1.04401 12.7935H2.41901C2.74482 16.1964 4.83983 19.0721 7.77775 20.512L8.52717 19.214L10.713 23H6.34132L7.08294 21.7154Z"
                fill="currentColor"
                fillOpacity="0.56"
            />
            <path
                d="M16.9171 2.28447C20.2416 3.96672 22.6331 7.30581 22.956 11.2064H21.581C21.2552 7.80349 19.1602 4.92777 16.2223 3.4879L15.4729 4.78594L13.287 0.999939H17.6587L16.9171 2.28447Z"
                fill="currentColor"
                fillOpacity="0.56"
            />
        </svg>
    </>,
    'RotateSchedule'
);
