import { cloneDeep } from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { InquiryInput } from 'sr-types/lib/shortlist/v1/graphql';
import { FormProvider } from '../../../common/form/FormContext';
import { getDateRangeListeners } from '../../../supply/booking/ScheduleCalculator';
import OrganizationInviteFormModal from '../OrganizationInviteFormModal';
import PersonInviteFormModal from '../PersonInviteFormModal';
import { checkForEmail, InquiryPropsType, OrganizationInviteFormData, PEOPLE_TYPE } from './inquiry';
import { useSendInquiry } from './inquiryApi';
import { useLazyQuery } from '@apollo/client';
import { inquiryPreviewQuery, client } from '../../shortlist';
import { constants } from '../../../common/constants';
import { UserContext } from '../../../common/auth/UserContext';

export default ({
    type,
    entityDetails,
    isEntityLoading,
    validationRules,
    isModalOpen,
    onCloseModal,
    data,
    onSendInquirySuccess,
    toInquiryInput
}: InquiryPropsType) => {
    const { sendInquiryInvitation, isInquirySending } = useSendInquiry();

    const [state, setState] = useState(undefined);
    const [validations, setValidations] = useState({ ...validationRules });

    const [itemsWithNoEmail, setItemsWithNoEmail] = useState(undefined);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(null);

    const [isSaving, setIsSaving] = useState(false);
    const [isPreview, setIsPreview] = useState(false);

    const { activeOrganizationAccount } = useContext(UserContext);
    const [getInquiryPreviewData, { loading: isPreviewLoading, error, data: previewData }] = useLazyQuery(
        inquiryPreviewQuery,
        {
            client,
            context: {
                headers: {
                    ownerId: activeOrganizationAccount
                }
            },
            fetchPolicy: constants.apolloFetchPolicy
        }
    );

    useEffect(() => {
        if (data) {
            const values = cloneDeep(data);
            setState({ ...values });
        }
    }, [data]);

    useEffect(() => {
        if (entityDetails) {
            const { items } = entityDetails;
            checkForEmail(items, type, state, setValidations, setIsEmailModalOpen, setItemsWithNoEmail);
        }
    }, [entityDetails, state?.[type]]);

    const sendInvitation = () => {
        const inquiryInput: InquiryInput[] = toInquiryInput(state);

        sendInquiryInvitation(inquiryInput).then(() => onSendInquirySuccess(inquiryInput));
    };

    const onClickContinue = () => {
        setIsSaving(true);
        setValidations({ ...validationRules });
        setTimeout(() => {
            setIsEmailModalOpen(false);
            setIsSaving(false);
        }, 150);
    };

    return (
        <FormProvider
            state={state}
            setState={setState}
            validationRules={validations}
            listeners={getDateRangeListeners('dateRange')}
        >
            {type === PEOPLE_TYPE ? (
                <PersonInviteFormModal
                    isPreview={isPreview}
                    setIsPreview={setIsPreview}
                    isOpen={isModalOpen}
                    onClose={onCloseModal}
                    sendInvitation={sendInvitation}
                    isSaving={isSaving || isInquirySending}
                    productionName={data.production?.name}
                    productionDateRange={data?.dateRange}
                    productionOptions={data?.productionOptions}
                    isLoading={isEntityLoading}
                    isEmailModalOpen={isEmailModalOpen}
                    peopleWithNoContact={itemsWithNoEmail}
                    onClickContinue={onClickContinue}
                    entityDetails={entityDetails}
                    getInquiryPreviewData={getInquiryPreviewData}
                    isPreviewLoading={isPreviewLoading}
                    previewData={previewData?.inquiryPreview}
                />
            ) : (
                <OrganizationInviteFormModal
                    isPreview={isPreview}
                    setIsPreview={setIsPreview}
                    isOpen={isModalOpen}
                    onClose={onCloseModal}
                    sendInvitation={sendInvitation}
                    isLoading={isEntityLoading}
                    isSaving={isSaving || isInquirySending}
                    isEmailModalOpen={isEmailModalOpen}
                    itemsWithNoEmail={itemsWithNoEmail}
                    data={data as OrganizationInviteFormData}
                    entityDetails={entityDetails}
                    onClickContinue={onClickContinue}
                    getInquiryPreviewData={getInquiryPreviewData}
                    isPreviewLoading={isPreviewLoading}
                    previewData={previewData?.inquiryPreview}
                />
            )}
        </FormProvider>
    );
};
