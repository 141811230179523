import { GridColDef } from '@mui/x-data-grid-premium';
import {
    department,
    email,
    firstName,
    lastName,
    organization,
    organizationReference,
    personReference,
    phone,
    productionDepartment,
    productionReference,
    productionRole,
    role,
    title,
    valueGetter
} from '../../supply/opportunity/details/ContactListUtils';

const hiddenProductionColDef: GridColDef = {
    field: 'production',
    headerName: 'Production',
    flex: 1,
    sortable: true,
    editable: false,
    type: 'string',
    hideable: false,
    valueGetter: valueGetter
};

export const productionEditorColDefs: GridColDef[] = [
    firstName,
    lastName,
    email,
    phone,
    productionRole,
    productionDepartment,
    organization,
    title,
    department,
    role,
    personReference,
    productionReference,
    organizationReference,
    hiddenProductionColDef
];

export const columnVisibility = {
    personReference: false,
    productionReference: false,
    organizationReference: false,
    production: false
};
