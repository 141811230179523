import { Button, Popover } from '@mui/material';
import React from 'react';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import { LoadingButton } from '@mui/lab';
import FormPanel from '../form/FormPanel';
import I18n from '../i18n/I18n';

export default ({ name, isOpen, onCancel, onAdd }) => {
    return (
        <Popover
            id={'pop'}
            open={isOpen}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
        >
            <FormPanel sx={{ width: '350px' }}>
                <FormContainer>
                    <FormItem>
                        <FormWidget name={`${name}.address.lines[0]`} label={<I18n token={'address.first.line'} />} />
                    </FormItem>
                    <FormItem>
                        <FormWidget name={`${name}.address.lines[1]`} label={<I18n token={'address.second.line'} />} />
                    </FormItem>
                    <FormItem>
                        <FormWidget name={`${name}.address.locality`} label={<I18n token={'address.city'} />} />
                    </FormItem>
                    <FormItem>
                        <FormWidget name={`${name}.address.adminArea`} label={<I18n token={'address.state'} />} />
                    </FormItem>
                    <FormItem>
                        <FormWidget name={`${name}.address.postalCode`} label={<I18n token={'address.postalCode'} />} />
                    </FormItem>
                    <FormItem>
                        <FormWidget name={`${name}.address.countryRegion`} label={<I18n token={'address.country'} />} />
                    </FormItem>
                    <FormItem>
                        <Button variant="text" onClick={onCancel}>
                            <I18n token={'dialog.cancel'} />
                        </Button>
                        <LoadingButton onClick={onAdd} variant="text">
                            <I18n token={'form.add'} />
                        </LoadingButton>
                    </FormItem>
                </FormContainer>
            </FormPanel>
        </Popover>
    );
};
