import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import React, { Fragment, useContext } from 'react';
import I18n from '../i18n/I18n';
import { H } from '../Layout';
import { FormContext } from './FormContext';

export default ({
    isSaving = false,
    onClickCancel,
    onClickSave,
    disableSave = false,
    disableCancel = false,
    containerSx = undefined,
    extraButtons = [],
    useOutlinedSave = false,
    hideButtons = [],
    saveButtonLabel = 'dialog.save'
}) => {
    const { validation } = useContext(FormContext);
    return (
        validation && (
            <H sx={{ gap: 1, justifyContent: 'flex-end', ...containerSx }}>
                {!hideButtons.includes('cancel') && (
                    <Button variant={'text'} onClick={onClickCancel} disabled={disableCancel}>
                        <I18n token={'dialog.cancel'} />
                    </Button>
                )}
                {!hideButtons.includes('save') && (
                    <LoadingButton
                        loading={isSaving}
                        variant={useOutlinedSave ? 'outlined' : 'contained'}
                        disabled={!validation.isValid || disableSave}
                        onClick={onClickSave}
                    >
                        <I18n token={saveButtonLabel} />
                    </LoadingButton>
                )}
                {extraButtons.map((button, index) => (
                    <Fragment key={`extra-${index}`}>{button(validation.isValid, hideButtons)}</Fragment>
                ))}
            </H>
        )
    );
};
