import React from 'react';
import DataGrid from '../../list/DataGrid';
import { Typography } from '@mui/material';
import I18n from '../../i18n/I18n';
import { FormContainer } from '../../form/FormContainer';
import FormItem from '../../form/FormItem';
import { C, V } from '../../Layout';

export default ({
    feature,
    isReviewAndEdit,
    category,
    dataSource,
    selectedRows,
    rows,
    defaultColumns,
    columnPreferences,
    showColumnsBasedOnPreference,
    selectionModel,
    handleSelectionChange,
    handleDelete,
    page,
    paginationModel,
    setPaginationModel,
    setPage,
    pageSize,
    setPageSize,
    setSelectedRows,
    hasDataToImport
}) => (
    <>
        <V>
            <FormContainer>
                <FormItem>
                    <Typography fontSize={24} fontWeight={600}>
                        <I18n
                            token={`import.contacts.${isReviewAndEdit ? 'preview' : 'select'}.heading`}
                            values={{ category }}
                        />
                    </Typography>
                </FormItem>
                <FormItem>
                    <C>
                        <Typography fontSize={14} fontWeight={400}>
                            <I18n
                                token={`import.contacts.${isReviewAndEdit ? 'preview' : 'select'}.subheading`}
                                values={{
                                    source: dataSource || 'uploaded file',
                                    category: category.toLowerCase()
                                }}
                            />
                        </Typography>
                        {!isReviewAndEdit && (
                            <Typography fontSize={14} fontWeight={400}>
                                <I18n
                                    token="import.contacts.select.subheading1"
                                    values={{ category: category.toLowerCase() }}
                                />
                            </Typography>
                        )}
                    </C>
                </FormItem>
            </FormContainer>
        </V>
        <V sx={{ height: '75%' }}>
            <DataGrid
                id="data-import-grid"
                containerSx={{ borderRadius: '8px !important' }}
                feature={feature}
                rowCount={isReviewAndEdit ? selectedRows.length : rows.length}
                rows={isReviewAndEdit ? selectedRows : rows}
                columns={
                    hasDataToImport
                        ? defaultColumns
                        : columnPreferences
                          ? showColumnsBasedOnPreference(defaultColumns)
                          : defaultColumns
                }
                checkboxSelection={!isReviewAndEdit}
                includeActions={isReviewAndEdit}
                enableInlineEditing={isReviewAndEdit}
                onDelete={isReviewAndEdit ? handleDelete : undefined}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={!isReviewAndEdit ? handleSelectionChange : undefined}
                serverPagination={false}
                page={page}
                paginationModel={paginationModel}
                onPageChange={(page, details) => {
                    setPaginationModel({
                        page,
                        pageSize
                    });
                    setPage(page);
                }}
                pageSize={pageSize}
                onPageSizeChange={(pageSize) => {
                    setPaginationModel({
                        page,
                        pageSize
                    });
                    setPageSize(pageSize);
                }}
                setRows={
                    isReviewAndEdit
                        ? (newRows) => {
                              const cleanedRows = newRows.map((row) => {
                                  Object.keys(row).forEach((key) => {
                                      if (row[key] === undefined) {
                                          row[key] = '';
                                      }
                                  });
                                  return row;
                              });
                              setSelectedRows(cleanedRows);
                          }
                        : undefined
                }
            />
        </V>
    </>
);
