import { ListItemText, Typography } from '@mui/material';
import {
    GridColDef,
    GridRenderEditCellParams,
    GridValueGetterParams,
    GridValueSetterParams
} from '@mui/x-data-grid-premium';
import { get, isArray, isEmpty, isString } from 'lodash';
import React, { useState } from 'react';
import { Classification } from 'sr-types/lib/organization/v1/graphql';
import I18n, { useI18n } from '../../common/i18n/I18n';
import LookupGridField from '../../common/list/LookupGridField';

export default (setIsValid) => {
    const [categoryLineage, setCategoryLineage] = useState(undefined);

    const organizationToUpdate = (colDefs, field, entity) => {
        const orgCategories = get(entity, 'hierarchicalCategories');
        setCategoryLineage(!isEmpty(orgCategories) ? orgCategories[0].lineage : undefined);
        return [
            { field: 'organization', value: get(entity, 'name') },
            { field: 'organizationReference', value: get(entity, 'reference') },
            { field: 'organizationTypes', value: get(entity, 'organizationTypes') },
            { field: 'organizationCategories', value: orgCategories }
        ];
    };

    const categoryToUpdate = (colDefs, field, entity) => {
        setCategoryLineage(get(entity, 'lineage'));
        return [{ field: 'organizationCategories', value: get(entity, 'name') }];
    };

    const typeToUpdate = (colDefs, field, entity) => {
        return [{ field: 'organizationTypes', value: get(entity, 'name') }];
    };

    const valueGetter = (params: GridValueGetterParams) => {
        return params.row[params.field];
    };

    const defaultOptionRenderer =
        (prop) =>
        ({ option }) => {
            return (
                <Typography className="ellipsis" fontSize={'small'}>
                    {get(option, prop)}
                </Typography>
            );
        };

    const categoryRenderer =
        (prop) =>
        ({ option }) => {
            return (
                <>
                    <ListItemText primary={get(option, prop)} secondary={get(option, 'lineage.value')} />
                </>
            );
        };

    const defaultEntityToOption = (entity) => {
        return entity;
    };

    const convertTypeToArray = ({ value, row }) => {
        const organizationTypes = value ? [value] : [];
        return { ...row, organizationTypes };
    };

    const typesValueGetter = ({ row }) => {
        return row.organizationTypes && !isEmpty(row.organizationTypes) ? row.organizationTypes[0] : undefined;
    };

    const parseTypes = (value) => {
        return isArray(value) && !isEmpty(value) ? value[0] : value;
    };

    const convertCategoryToObjectArray = ({ value, row }) => {
        const organizationCategories: Classification[] = value ? [{ value, lineage: categoryLineage }] : [];
        return { ...row, organizationCategories };
    };

    const categoriesValueGetter = ({ row }) => {
        return row.organizationCategories && !isEmpty(row.organizationCategories)
            ? row.organizationCategories[0].value
            : undefined;
    };

    const parseOrganization = (value) => {
        return isArray(value) && !isEmpty(value) ? value[0].value : isString(value) ? value : undefined;
    };

    const name: GridColDef = {
        field: 'organization',
        headerName: useI18n('production.tabs.associatedOrgs.organization.name'),
        flex: 1,
        sortable: true,
        editable: true,
        valueGetter: valueGetter,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Organization' }]}
                    OptionRenderer={defaultOptionRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={organizationToUpdate}
                    filter={{ expressions: [{ field: 'inactive', value: { values: ['false'] } }] }}
                    afterSearch={(items, term, currentEditRows, id) => {
                        const orgRef = currentEditRows[id]?.organizationReference?.value;
                        if (!orgRef && !orgRef?.id && term) {
                            return <I18n token="production.tabs.associatedOrgs.valMsg.org.notSelected" />;
                        } else if (!orgRef && !orgRef?.id) {
                            return <I18n token="production.tabs.associatedOrgs.valMsg.org.required" />;
                        }
                    }}
                />
            );
        }
    };

    const organizationCategories: GridColDef = {
        field: 'organizationCategories',
        headerName: useI18n('production.tabs.associatedOrgs.organization.category'),
        flex: 1,
        sortable: true,
        editable: true,
        valueGetter: categoriesValueGetter,
        valueSetter: convertCategoryToObjectArray,
        valueParser: parseOrganization,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'Category' }]}
                    OptionRenderer={categoryRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={categoryToUpdate}
                />
            );
        }
    };

    const organizationTypes: GridColDef = {
        field: 'organizationTypes',
        headerName: useI18n('production.tabs.associatedOrgs.organization.type'),
        flex: 1,
        sortable: true,
        editable: true,
        valueGetter: typesValueGetter,
        valueSetter: convertTypeToArray,
        valueParser: parseTypes,
        renderEditCell: (params: GridRenderEditCellParams) => {
            return (
                <LookupGridField
                    {...params}
                    columns={colDefs}
                    filters={[{ identifier: 'entity', value: 'OrganizationType' }]}
                    OptionRenderer={defaultOptionRenderer('name')}
                    toOption={defaultEntityToOption}
                    entityToUpdate={typeToUpdate}
                />
            );
        }
    };

    const organizationReference: GridColDef = {
        field: 'organizationReference',
        flex: 1,
        sortable: true,
        editable: true,
        hideable: false,
        valueGetter: valueGetter,
        valueSetter: (params: GridValueSetterParams) => {
            const { value, row } = params;
            row['organizationReference'] = value;
            setIsValid(!!value?.id);
            return row;
        }
    };

    const colDefs: GridColDef[] = [name, organizationTypes, organizationCategories, organizationReference];
    return colDefs;
};

export const columnVisibility = {
    organizationReference: false
};
