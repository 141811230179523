import { Typography } from '@mui/material';
import React from 'react';
import { H, V } from '../../common/Layout';
import { Field, MeasurementField } from '../attributes';
import TypePreview from '../TypePreview';
import { useStyling } from '../../common/Theme';
import EllipsisText from '../../common/components/EllipsisText';

type MeasurementAttributePreviewProps = {
    field: Field;
    onClick?: (Field) => void;
};

export default (props: MeasurementAttributePreviewProps) => {
    const { field, onClick } = props;
    const { theme } = useStyling();
    const measurementField = field as MeasurementField;

    return (
        <V onClick={onClick}>
            <TypePreview type={field.fieldType} />
            <Typography>{measurementField.label}</Typography>
            <EllipsisText text={measurementField.description} sx={{ fontSize: 'smaller', color: theme.palette.text.disabled }} />
            <H sx={{ gap: 1 }}>
                <Typography>Unit: {measurementField?.unit}</Typography>
                <Typography>Decimals: {measurementField.decimalsNumber}</Typography>
            </H>
        </V>
    );
};
