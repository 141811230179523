import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { UserContext } from '../auth/UserContext';
import download from '../utils/download';

export default ({ open, details, onConfirm }) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    return (
        <Dialog maxWidth="sm" fullWidth open={open}>
            <DialogTitle>{'Import Details'}</DialogTitle>
            <DialogContent dividers>
                <Typography>File processed {details.createdCount} records successfully.</Typography>
                <Typography pt={2} pb={2}>
                    {details.createdCount} Created, {details.updatedCount} Updated, {details.unprocessedCount} Not
                    Processed{' '}
                </Typography>
                <a
                    onClick={() => {
                        download(details.logFile.id, details.logFile.id, activeOrganizationAccount);
                    }}
                    href="#"
                >
                    Download to see details
                </a>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm}>{'OK'}</Button>
            </DialogActions>
        </Dialog>
    );
};
