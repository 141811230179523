import React from 'react';
import { FORMAT_TEXT_COMMAND, TextFormatType } from 'lexical';
import { KeyValueMap } from '../../../common/KeyValueMap';
import ResponsiveToolbar from '../../../common/components/ResponsiveToolbar';
import { ActionType } from '../../../common/components/ActionType';
import { Icons } from '../../../common/icons/Icons';

export const textStyles: KeyValueMap<string, ActionType> = {
    bold: { label: 'Bold', icon: Icons.FormatBold },
    italic: { label: 'Italic', icon: Icons.FormatItalic },
    strikethrough: { label: 'Strikethrough', icon: Icons.FormatStrikethrough },
    underline: { label: 'Underline', icon: Icons.FormatUnderline }
};

// editor.update(() => {
//     const selection = $getSelection();
//     if ($isRangeSelection(selection)) {
//         $patchStyleText(selection, {
//             [style]: option
//         });
// }
// });

export default ({ disabled, isSmall, editor, isBold, isItalic, isStrikethrough, isUnderline }) => {
    const handleFormatting = (newFormatting: string[] | null) => {
        if (newFormatting && newFormatting.length) {
            if (Array.isArray(newFormatting)) {
                newFormatting.forEach((cmd) => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, cmd as TextFormatType);
                });
            } else {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, newFormatting as TextFormatType);
            }
        }
    };

    const selected = [];
    if (isBold) selected.push('bold');
    if (isItalic) selected.push('italic');
    if (isStrikethrough) selected.push('strikethrough');
    if (isUnderline) selected.push('underline');

    return (
        <ResponsiveToolbar
            disabled={disabled}
            multi={true}
            isSmall={isSmall}
            options={textStyles}
            onSelect={handleFormatting}
            selected={selected}
            icon={Icons.FormatColorText}
        />
    );
};
