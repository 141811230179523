import React, { useContext, useState } from 'react';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import FormPanel from '../../common/form/FormPanel';
import { FormContainer } from '../../common/form/FormContainer';
import { FormContext } from '../../common/form/FormContext';

export default () => {
    const { state } = useContext(FormContext);
    const [open, toggleOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    return (
        <FormPanel>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="text" label="Text" />
                </FormItem>
                <FormItem half>
                    <FormWidget name="textDisabled" label="Text disabled" placeholder="'text' upppercased" disabled />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem>
                    <FormWidget name="textArea" label="Text area" component={'TextArea'} />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget name="numberInt" component={'Number'} label="Number integer" />
                </FormItem>
                <FormItem half>
                    <FormWidget name="numberDec" component={'Number'} label="Number with 2 decimals" decimals={2} />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        component="Autocomplete"
                        name="autocomplete"
                        label="Autocomplete"
                        options={[
                            { id: 'one', label: 'One' },
                            { id: 'two', label: 'Two' },
                            { id: 'three', label: 'Three' }
                        ]}
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        hidden={state.autocomplete !== 'one'}
                        name="conditional"
                        label="Conditional"
                        placeholder="Visible only if Autocomplete is 'One'"
                    />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        disabled
                        component="Autocomplete"
                        name="autocompleteDisabled"
                        label="Autocomplete disabled"
                        options={[
                            { id: 'one', label: 'One' },
                            { id: 'two', label: 'Two' },
                            { id: 'three', label: 'Three' }
                        ]}
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget name="mapped" label="Mapped" mapTo="aka" />
                </FormItem>
            </FormContainer>
            <FormContainer>
                <FormItem half>
                    <FormWidget
                        name="autocompleteFreeForm"
                        component="Autocomplete"
                        allowFreeForm={true}
                        label="Autocomplete free Form"
                        options={[
                            { id: 'one', label: 'One' },
                            { id: 'two', label: 'Two' },
                            { id: 'three', label: 'Three' },
                            { id: 'four', label: 'Four' },
                            { id: 'five', label: 'Five' }
                        ]}
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        name="autocompleteMulti"
                        component="Autocomplete"
                        multiple={true}
                        label="Autocomplete multi"
                        options={[
                            { id: 'one', label: 'One' },
                            { id: 'two', label: 'Two' },
                            { id: 'three', label: 'Three' },
                            { id: 'four', label: 'Four' },
                            { id: 'five', label: 'Five' }
                        ]}
                    />
                </FormItem>
                <FormItem half>
                    <FormWidget
                        name="autoCompleteAddEnabled"
                        component="Autocomplete"
                        allowFreeForm
                        label="Autocomplete Add Enabled"
                        editInputValue
                        selectOnFocus
                        renderOption
                        isAddEnabled
                        isCustomEelementOpen={open}
                        setSelectedValue={setSelectedValue}
                        toggleOpen={toggleOpen}
                        disabled={open}
                        options={[
                            { id: 'one', label: 'First value' },
                            { id: 'two', label: 'Second value' },
                            { id: 'three', label: 'Third value' },
                            { id: 'four', label: 'Fourth value' }
                        ]}
                    />
                    {open ? (
                        <FormItem half>
                            <FormWidget
                                component="Autocomplete"
                                allowFreeForm
                                value={selectedValue}
                                onChange={(name, val) => {
                                    setSelectedValue(val);
                                }}
                                name="autoCompleteAddEnabled"
                                label="Add new value"
                            />
                        </FormItem>
                    ) : (
                        <></>
                    )}
                </FormItem>

                <FormItem half>
                    <FormWidget
                        name="autoCompleteAddEnabled"
                        component="Autocomplete"
                        label="Autocomplete With Key"
                        options={[
                            { key: 'one', label: 'First value' },
                            { key: 'two', label: 'Second value' },
                            { key: 'three', label: 'Third value' },
                            { key: 'four', label: 'Fourth value' }
                        ]}
                    />
                </FormItem>
            </FormContainer>
        </FormPanel>
    );
};
