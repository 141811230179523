import { Avatar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { Icons } from '../../common/icons/Icons';
import { H, V } from '../../common/Layout';

export const ProductionOptionRenderer = ({ option }) => {
    const createOption = option && option.createOption;
    const label = createOption ? `Add ${option.label}` : option.label;
    const subLabel = option && option.productionAttributes;

    return (
        <H>
            <Avatar sx={{ bgcolor: grey[500], color: 'white' }}>
                <Icons.Production />
            </Avatar>
            <V sx={{ width: '100%', ml: 2 }}>
                <Typography sx={{ fontStyle: createOption ? 'italic' : 'normal' }} variant="subtitle1">
                    {label}
                </Typography>
                <Typography sx={{ fontStyle: createOption ? 'italic' : 'normal' }} variant="subtitle2">
                    {subLabel}
                </Typography>
            </V>
        </H>
    );
};
