import { SearchState } from '@searchkit/client/src/searchkit';
import React, { useEffect } from 'react';
import { useSearchkitVariables } from '@searchkit/client';
import useHistory from '../../utils/useHistory';
import { findIndex } from 'lodash';
import { constants } from '../../constants';

export const UNSET_SORT = '*';

const freeze = (id: string, state: SearchState) => {
    const frozen = JSON.stringify(state);
    localStorage.setItem(`filters_${id}`, frozen);
};

const thaw = (id: string): SearchState => {
    const stored = localStorage.getItem(`filters_${id}`);
    return JSON.parse(stored);
};

export type SortModel = {
    field: string;
    sort: string;
};

type UseSearchkitVariablesOptions = {
    defaultPageSize?: number;
    defaultPageNum?: number;
    defaultSortField?: string;
    saveFiltersDisabled?: boolean;
};

export const usePersistedSearchkitVariables = (id: string, options: UseSearchkitVariablesOptions = undefined) => {
    const { searchParams, changeSearch } = useHistory();
    const variables: SearchState = useSearchkitVariables();
    const pageNumKey = `${id}_page`;
    const pageSizeKey = `${id}_size`;
    const sortModelKey = `${id}_sort`;

    const defaultPageSize: number = (options && options.defaultPageSize) || constants.defaultPageSize;
    const defaultPageNum: number = (options && options.defaultPageNum) || 0;
    const defaultSortField: string = (options && options.defaultSortField) || 'name_asc';

    // Restore searchkit variables.
    useEffect(() => {
        const stored = thaw(id);
        if (stored && stored.filters) {
            stored.filters.forEach((filter) => {
                // FIXME This only works if filters are ORed. I.e. val === 'a' || val === 'b' etc.
                const found =
                    findIndex(
                        variables.filters,
                        (f) => filter.identifier === f.identifier && filter.value === f.value
                    ) > -1;
                if (!found) {
                    variables.filters.push(filter);
                }
            });
        }
    }, []);

    // Save searchkit variables.
    useEffect(() => {
        if (options && !options.saveFiltersDisabled) {
            freeze(id, variables);
        }
    }, [id, options, variables]);

    const pageNumber: number = React.useMemo(() => {
        return parseInt(searchParams.get(pageNumKey)) || defaultPageNum;
    }, [searchParams]);

    const setPageNumber = React.useCallback(
        (num: number) => {
            if (num !== pageNumber) {
                changeSearch({ [pageNumKey]: num.toString() });
            }
        },
        [changeSearch, pageNumKey, pageNumber]
    );

    const pageSize: number = React.useMemo(() => {
        const parsedPageSize = parseInt(searchParams.get(pageSizeKey));
        return parsedPageSize && constants.rowsPerPageOptions.includes(parsedPageSize)
            ? parsedPageSize
            : defaultPageSize;
    }, [defaultPageSize, pageSizeKey, searchParams]);

    const setPageSize = React.useCallback(
        (num: number) => {
            if (num !== pageSize) {
                changeSearch({ [pageSizeKey]: num.toString() });
            }
        },
        [changeSearch, pageSize, pageSizeKey]
    );

    const sortModel: SortModel = React.useMemo(() => {
        const sortModel = searchParams.has(sortModelKey) ? searchParams.get(sortModelKey) : defaultSortField;
        if (sortModel) {
            const [field, sort] = sortModel.split('_');
            return field !== UNSET_SORT ? { field, sort } : undefined;
        }
    }, [defaultSortField, searchParams, sortModelKey]);

    const setSortModel = React.useCallback(
        (newSortModel: SortModel) => {
            if (newSortModel) {
                const newSortValue = newSortModel.field + '_' + newSortModel.sort;
                if (!sortModel || newSortModel.field !== sortModel.field || newSortModel.sort !== sortModel.sort) {
                    changeSearch({ [sortModelKey]: newSortValue });
                }
            } else if (searchParams.has(sortModelKey)) {
                changeSearch({ [sortModelKey]: undefined });
            }
        },
        [changeSearch, searchParams, sortModel, sortModelKey]
    );

    return { variables, sortModel, setSortModel, pageNumber, setPageNumber, pageSize, setPageSize };
};
