import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { OptionRendererProps } from '../common/form/widgets/OptionRendererProps';
import { Contact } from 'sr-types/lib/production/v1/graphql';
import { LabelValueDisplay } from '../attributes/AttributeValue';
import { UserContext } from '../common/auth/UserContext';
import useHistory from '../common/utils/useHistory';
import { hyperlinkSx } from '../common/utils/commonUtils';

export default (props: OptionRendererProps<Contact>) => {
    const { option: contact, sx, getSubLabel, enableHyperlink = false } = props;
    const { organization, role, production, productionRole, personReference } = contact;
    const { application } = useContext(UserContext);
    const { changeRoute } = useHistory();

    const name = `${contact.name?.firstName ?? ''} ${contact.name?.lastName ?? ''}`;
    // const rawNumber = contact.contactInfo?.phone?.number;
    // const phoneNumber = rawNumber ? parsePhoneNumberFromString(rawNumber) : undefined;
    let subLabel = '';
    if (typeof getSubLabel === 'function') {
        subLabel = getSubLabel(contact);
    } else {
        const email = contact.contactInfo?.email?.address ?? 'No Email Available';
        const organizationInfo = [organization, role].filter(Boolean).join(', ');
        const productionInfo = [production, productionRole].filter(Boolean).join(', ');
        subLabel = [email, organizationInfo, productionInfo].filter(Boolean).join(' | ');
    }
    return (
        <Box sx={sx}>
            <LabelValueDisplay
                label={subLabel}
                value={name}
                labelOnTop={false}
                labelSx={{ fontWeight: '300' }}
                valueSx={{
                    fontWeight: 'medium',
                    ...(enableHyperlink && hyperlinkSx)
                }}
                onClickValue={
                    enableHyperlink
                        ? (event: React.MouseEvent<HTMLButtonElement>) => {
                              event.stopPropagation();
                              changeRoute(`${application.home}/people/${personReference?.id}/details`);
                          }
                        : undefined
                }
            />
        </Box>
    );
};
