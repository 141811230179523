import React from 'react';
import { Attribute } from '../attributes';
import { LabelValueDisplay } from '../AttributeValue';

type DecimalAttributePreviewProps = {
    attribute: Attribute;
};

export default (props: DecimalAttributePreviewProps) => {
    const { attribute } = props;
    return (
        attribute && (
            <LabelValueDisplay
                label={attribute.label}
                value={attribute.value ? Number.parseFloat(attribute.value).toFixed(2) : '-'}
            />
        )
    );
};
